define('huyang/controllers/vendor/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        serviceRequests: function () {
            return this.get('model').sortBy('created').reverseObjects();
        }.property('model.[]')
    });
});