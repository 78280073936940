define('huyang/components/request-checklist', ['exports', 'huyang-common/mixins/modal-helper'], function (exports, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_modalHelper.default, {
        classNames: ['request-checklist'],
        classNameBindings: ['checklist:has-checklist'],

        isRequest: true,

        store: Ember.inject.service(),
        _positionSort: ['position'],
        showWarning: false,

        // TODO: This will be lockable in the future
        lockChecklist: Ember.computed('parent.template.id', function () {
            if (this.get('parent.template.id')) {
                return true;
            }
            return false;
        }),

        showEditingButton: Ember.computed('parent', 'parent.isTemplate', function () {
            if (!this.get('parent') || this.get('parent.isTemplate') || !this.get('isRequest')) {
                return false;
            }
            return true;
        }),

        sortedChecklist: Ember.computed.sort('checklist.activeChecklistItems', '_positionSort'),

        showWarningObserver: Ember.observer('checklist.activeChecklistItems.@each.hasDirtyAttributes', 'newChecklistBody', function () {
            if (this.get('parent') && !this.get('parent.isTemplate')) {
                if (this.get('newChecklistBody')) {
                    this.set('showWarning', true);
                    return;
                }
                if (this.get('checklist.activeChecklistItems')) {
                    var changedItems = this.get('checklist.activeChecklistItems').filter(function (item) {
                        return item.get('hasDirtyAttributes');
                    });
                    if (changedItems.length) {
                        this.set('showWarning', true);
                    } else {
                        this.set('showWarning', false);
                    }
                }
            }
        }),

        actions: {
            addNewChecklist: function addNewChecklist() {
                this.sendAction('addChecklist');
            },

            deleteChecklist: function deleteChecklist() {
                if (this.get('sortedChecklist').length) {
                    this.openModal('confirmChecklistDelete');
                } else {
                    this.send('confirmChecklistDelete');
                }
            },

            confirmChecklistDelete: function confirmChecklistDelete() {
                this.set('checklist.activeChecklistItems', []);
                this.set('isEditing', false);
                this.set('checklist', false);
                this.sendAction('removeChecklist');
            },

            editChecklist: function editChecklist() {
                var _this = this;

                this.set('isEditing', true);

                Ember.run.next(function () {
                    // avoid newline flashing on enter keypress
                    _this.$('textarea.new-checklist-input').keypress(function (event) {
                        if (event.which === '13') {
                            return false;
                        }
                    });

                    _this.$('.new-checklist-input').focus();
                });
            },

            saveChecklist: function saveChecklist() {
                this.set('isEditing', false);
                // Rollback any unsaved edits
                this.get('checklist.activeChecklistItems').forEach(function (item) {
                    return item.rollbackAttributes();
                });
                this.set('newChecklistBody', '');
            },

            submitAddForm: function submitAddForm() {
                this.send('addNewChecklistItem');
                return false;
            },

            addNewChecklistItem: function addNewChecklistItem() {
                var newChecklistBody = this.get('newChecklistBody').split('\n');
                var store = this.get('store');
                var checklist = this.get('checklist');
                var currentItemsLength = checklist.get('checklistItems.length');
                newChecklistBody.forEach(function (value, index) {
                    if (value.trim()) {
                        // + 1 for 1-based ordering
                        var position = currentItemsLength + index + 1;

                        var item = {
                            'body': value,
                            position: position
                        };
                        var checklistItem = store.createRecord('request-checklist-item', item);
                        checklistItem.set('checklist', checklist);
                        checklistItem.save();
                    }
                });
                this.set('newChecklistBody', '');
                this.$('.new-checklist-input').focus();
            },

            showChecklistItem: function showChecklistItem(item) {
                this.set('currentChecklistItem', item);
                Ember.$('#checklistItemModal').modal();
            },

            reorderItems: function reorderItems(groupModel, itemModels) {
                var current_position = 1;
                itemModels.forEach(function (element) {
                    element.set('position', current_position);
                    element.save();
                    current_position++;
                });
            }
        }
    });
});