define('huyang/routes/requests/repeating/paused', ['exports', 'huyang/routes/requests/request-filter-base'], function (exports, _requestFilterBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _requestFilterBase.default.extend({
        documentTitle: 'Repeating requests',
        filter: 'template-canceled',

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('filter', 'template');
            controller.set('pausedCount', model.requests.meta.total);
        }
    });
});