define('huyang/serializers/asset-category-custom-field', ['exports', 'huyang-common/serializers/rest-new'], function (exports, _restNew) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _restNew.default.extend({
    serialize: function serialize(snapshot) {
      var json = {
        id: snapshot.record.get('id'),
        defaultValue: snapshot.record.get('defaultValue'),
        description: snapshot.record.get('description'),
        name: snapshot.record.get('name'),
        required: snapshot.record.get('required'),
        type: snapshot.record.get('type'),
        position: snapshot.record.get('position')
      };

      if (snapshot.record.get('listItems.length')) {
        json.listItems = snapshot.record.get('listItems');
      }

      return json;
    },

    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      var normalizedRootKey = 'customField';
      hash[normalizedRootKey] = this.serialize(snapshot, options);
    }
  });
});