define('huyang/routes/catchall', ['exports', 'huyang/mixins/redirects-to-default'], function (exports, _redirectsToDefault) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_redirectsToDefault.default, {
        beforeModel: function beforeModel() {
            this.redirectToDefault();
        }
    });
});