define('huyang/components/service-request-status-icon', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',

        icon: function () {
            var serviceReq = this.get('serviceRequest');

            if (serviceReq && serviceReq.get('response')) {
                var resp = serviceReq.get('response');

                var icons = {
                    'yes': 'fa-check-circle',
                    'no': 'fa-times-circle',
                    'maybe': 'fa-question-circle'
                };

                if (icons[resp]) {
                    return icons[resp];
                }
            }

            return '';
        }.property('request.comments.@each.serviceRequest', 'serviceRequests.[]', 'channel'),

        serviceRequest: function () {
            var comments = this.get('request.comments');
            var channelComments = comments.filterBy('channel.id', this.get('channel.id'));

            var serviceReqComment = channelComments.findBy('serviceRequest.id');

            if (serviceReqComment) {
                var serviceRequestId = serviceReqComment.get('serviceRequest.id');

                if (serviceRequestId) {
                    var serviceReq = this.get('serviceRequests').findBy('id', serviceRequestId);

                    if (serviceReq) {
                        return serviceReq;
                    }
                }
            }
        }.property('request.comments.@each.serviceRequest', 'serviceRequests.[]')
    });
});