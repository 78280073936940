define('huyang/routes/people/staff/member', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model(_ref) {
            var user_id = _ref.user_id;

            return this.store.findRecord('user', user_id);
        }
    });
});