define('huyang/routes/requests/request/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        afterModel: function afterModel(model, transition) {
            this._super(model, transition);
            this.transitionTo('requests.request.info', model.request.get('id'));
        }
    });
});