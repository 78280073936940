define('huyang/components/set-coi-info', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        api: Ember.inject.service(),
        store: Ember.inject.service(),

        commitButtonLabel: function () {
            return this.get('coi') ? 'Save' : 'Add';
        }.property('coi'),

        coiFields: function () {
            return [{
                name: 'name',
                label: 'Name',
                value: this.get('coi.name'),
                placeholder: 'General Service',
                className: 'col phn col-xs-7',
                validations: { presence: { message: 'Name must be present' } }
            }, {
                name: 'expiration',
                label: 'Expiration',
                value: this.get('coi.expiration'),
                placeholder: '2017-08-23',
                date: true,
                className: 'col phn col-xs-4 pull-right'
            }, {
                name: 'filename',
                label: 'Certificate',
                value: this.get('coi.filename'),
                singleFileUpload: true,
                uploadUrl: this.get('uploadUrl'),
                className: 'clearfix',
                validations: { presence: { message: 'Certificate file must be present' } }
            }];
        }.property('coi.name', 'coi.filename', 'coi.expiration', 'photoUrl', 'uploadUrl'),

        uploadUrl: function () {
            var accountId = this.get('vendor.account.id');
            var vendorId = this.get('vendor.id');

            return '/api/' + accountId + '/vendors/' + vendorId + '/cois';
        }.property('vendor'),

        deleteUpload: function deleteUpload(uuid) {
            return this.get('api').ajax({
                type: 'DELETE',
                dataType: 'json',
                url: this.get('uploadUrl') + '?uuid=' + uuid
            });
        },

        actions: {
            fileUploaded: function fileUploaded(uuid, data) {
                // If a file has previously been uploaded by modal, delete it from S3
                if (this.get('fileMeta.uuid')) {
                    this.deleteUpload(this.get('fileMeta.uuid'));
                }

                this.set('fileMeta', {
                    uuid: uuid,
                    filename: data.result.meta.filename,
                    attachmentType: data.result.meta.content_type
                });
            },

            saveCoi: function saveCoi(formInfo, onComplete) {
                var _this = this;

                var coi = this.get('coi') || this.get('store').createRecord('vendorCoi', { vendor: this.get('vendor') });

                coi.set('name', formInfo.name);
                coi.set('expiration', moment(formInfo.expiration, 'MM/DD/YYYY').toDate());
                var file = this.get('fileMeta');
                if (file) {
                    if (coi.get('uuid')) {
                        this.deleteUpload(coi.get('uuid'));
                    }

                    coi.set('filename', file.filename);
                    coi.set('uuid', file.uuid);
                    coi.set('attachmentType', file.attachmentType);
                }

                coi.save().then(function () {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                    _this.get('flashMessages').success('Certificate saved!');
                    _this.sendAction('cancel');
                });
            },

            cancel: function cancel() {
                //
                //
                // THIS WONT DELETE IF CLICK OUTSIDE MODAL, HIT BACK, ETC
                //
                //
                var uuid = this.get('fileMeta.uuid');
                if (uuid) {
                    this.deleteUpload(uuid);
                }
                this.sendAction('cancel');
            }
        }
    });
});