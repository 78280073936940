define('huyang/mixins/redirects-to-default', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        session: Ember.inject.service(),

        redirectToDefault: function redirectToDefault() {
            var _this = this;

            this.get('session.currentUser').then(function (user) {
                if (user.get('isVendor')) {
                    _this.transitionTo('vendor.index');
                } else {
                    _this.transitionTo('requests.mine');
                }
            });
        }
    });
});