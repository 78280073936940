define('huyang/routes/requests/unassigned', ['exports', 'huyang/routes/requests/request-filter-base'], function (exports, _requestFilterBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _requestFilterBase.default.extend({
        documentTitle: 'Unassigned requests',
        filter: 'unassigned'
    });
});