define('huyang/models/pm-program', ['exports', 'ember-data', 'huyang-common/mixins/displays-priority'], function (exports, _emberData, _displaysPriority) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(_displaysPriority.default, {
        account: _emberData.default.belongsTo('account', { async: true }),
        attachments: _emberData.default.hasMany('requestAttachment', { async: true }),
        requestType: _emberData.default.belongsTo('requestType', { async: true }),
        requestLabel: _emberData.default.belongsTo('requestLabel', { async: true }),
        schedule: _emberData.default.belongsTo('requestSchedule', { async: false }),
        originator: _emberData.default.belongsTo('user', { async: true }),
        checklists: _emberData.default.hasMany('requestChecklist', { async: true }),
        title: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        priority: _emberData.default.attr('number'),
        status: _emberData.default.attr('string'),
        created: _emberData.default.attr('date'),
        updated: _emberData.default.attr('date'),
        estimatedHours: _emberData.default.attr('number'),
        assetCategory: _emberData.default.belongsTo('asset-category', { async: true }),
        allowBalancing: _emberData.default.attr('boolean'),
        balancingSchedule: _emberData.default.attr('string'),
        renderedDescription: _emberData.default.attr('string'),
        pmProgramBuildingOwners: _emberData.default.hasMany('pmProgramBuildingOwner', { async: true }),
        pmAssignee: _emberData.default.attr(),

        images: function () {
            return this.get('attachments').filter(function (att) {
                return att.get('isImage');
            });
        }.property('attachments.@each.isImage'),

        descriptionImages: function () {
            return this.get('images').filter(function (img) {
                return !img.get('comment.id');
            });
        }.property('images'),

        checklist: Ember.computed('checklists', function () {
            return this.get('checklists').find(function (c) {
                return !c.get('archived');
            });
        }),

        save: function save() {
            var _this = this;

            // schedule is only created on new program creation
            if (this.get('isNew')) {
                var req = this._super(); // Save to get request id
                return req.then(function () {
                    return _this.saveSchedule();
                }).then(function () {
                    return req;
                });
            } else {
                return this._super();
            }
        },

        saveSchedule: function saveSchedule() {
            var schedule = this.get('schedule');
            if (!schedule) {
                schedule = this.store.createRecord('request-schedule', {
                    originator: this.get('originator'),
                    pmProgram: this
                });
            }
            // repeatRule or afterClose may be null, depending on schedule 'type'
            schedule.setProperties({
                active: true,
                afterClose: this.get('afterClose'),
                start: this.get('createDate'),
                repeatRule: this.get('repeatRule'),
                dueAfter: this.get('dueAfter'),
                previousClose: this.get('previousClose'),
                previousRelabel: this.get('previousRelabel'),
                previousLabel: this.get('previousLabel')
            });
            return schedule.save();
        }
    });
});