define('huyang/routes/people/vendors/vendor', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model(params) {
            var vendorId = params.vendor_id;

            return Ember.RSVP.hash({
                trades: this.store.findAll('trade'),
                vendor: this.store.findRecord('vendor', vendorId, { reload: true }),
                serviceRequests: this.store.query('vendorServiceRequest', { vendorId: vendorId }),
                technicians: this.store.query('vendorTechnician', { vendorId: vendorId }),
                certificates: this.store.query('vendorCoi', { vendorId: vendorId })
            });
        }
    });
});