define('huyang/mixins/finds-recent-channel', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        mostRecentChannel: function () {
            var comments = this.get('request.comments').sortBy('created');
            comments.reverse();

            if (comments.length > 0) {
                if (comments[0].get('channel.id')) {
                    return comments[0].get('channel');
                }
            }

            // otherwise default to staff channel
            return this.get('channels').findBy('isStaff');
        }.property('channels.[]', 'request.comments.@each.created'),

        // the primary channel is the one that contains the initial request comment
        // (with source == "request")
        primaryChannel: function () {
            var filedComment = this.get('request.comments').findBy('isCreationComment');

            if (filedComment) {
                return this.get('channels').findBy('id', filedComment.get('channel.id'));
            }

            return this.get('channels').findBy('isStaff');
        }.property('channels.[]', 'request.comments.[]'),

        // secondary channels are channels that contain comments on a request
        // excluding the primary channel, which contains the creation comment
        secondaryChannels: function () {
            var _this = this;

            var secondaryChannels = this.get('channels').filter(function (channel) {
                if (channel.get('id') !== _this.get('primaryChannel.id')) {
                    var channelComments = _this.get('request.comments').filter(function (c) {
                        return c.get('channel.id') === channel.id;
                    });
                    return channelComments.length > 0;
                }

                return false;
            }).sortBy('displayName');

            return secondaryChannels;
        }.property('channels.[]', 'channels.@each.displayName', 'request.comments.[]', 'primaryChannel')
    });
});