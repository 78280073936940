define('huyang/routes/requests/repeating', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        documentTitle: 'Repeating requests',

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('filter', 'template');
        }
    });
});