define('huyang/components/d3-scatterplot', ['exports', 'npm:d3'], function (exports, _npmD) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Component.extend({
        classNames: ['d3-scatterplot'],
        transitionDuration: 500,

        empty: function () {
            return !this.get('running') && !this.get('data.result.length');
        }.property('data.result.length', 'running'),

        setChartVisibility: function () {
            if (this.get('running') || !this.get('data.result.length')) {
                Ember.$('#chartWrapper').hide();
            } else {
                Ember.$('#chartWrapper').show();
            }
        }.observes('running', 'data.result.length'),

        setup: function () {
            var containerWidth = this.$('.chart').width();
            var containerHeight = this.$('.chart').height();
            var margin = { top: 10, right: 85, bottom: 30, left: 170 };
            var width = containerWidth - margin.left - margin.right;
            var height = containerHeight - margin.top - margin.bottom;
            this.setProperties({
                chartWidth: width,
                chartHeight: height,
                containerWidth: containerWidth,
                containerHeight: containerHeight,
                margin: margin
            });
            Ember.$('#chartWrapper').show();

            var svg = _npmD.default.select(this.$('.chart').get(0)).append('svg').attr('width', containerWidth).attr('height', containerHeight).append('g').attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')');

            // x axis
            svg.append('g').attr('transform', 'translate(0, ' + this.get('chartHeight') + ')').attr('class', 'x axis').transition().duration(this.get('transitionDuration'));
            this.x = _npmD.default.scaleLinear();
            this.xAxis = _npmD.default.axisBottom(this.x);

            // y axis
            svg.append('g').attr('class', 'y axis').transition().duration(this.get('transitionDuration'));
            this.y = _npmD.default.scalePoint().padding(0.1);

            svg.append('g').attr('class', 'lines connect');
            svg.append('g').attr('class', 'lines average');
            svg.append('g').attr('class', 'dots');

            this.set('svg', svg);
            Ember.$(window).on('resize', this.onResize.bind(this));
            this.drawChart();
        }.on('didInsertElement'),

        drawChart: function () {
            var _this = this;

            // this might run before didInsertElement
            if (!this.get('svg')) {
                return;
            }
            // x = row.result (count)
            var xKey = this.get('xKey') || 'result';
            // y = category 1
            var yKey = this.get('yKey');
            // color = category 2
            var colorKey = this.get('colorKey');
            /*
            [
              {building.id: 110, request_type.id: 1, result: 1}
              {building.id: 120, request_type.id: 1, result: 2}
            ]
            */
            var data = this.get('data.result') || [];

            // null -> unknown
            data.forEach(function (d) {
                [yKey, colorKey].forEach(function (key) {
                    if (!d[key]) {
                        d[key] = '';
                    }
                });
            });

            var colorDomainSet = new Set(); // IE11 doesn't support new Set([1,2,3])
            data.forEach(function (d) {
                colorDomainSet.add(d[colorKey]);
            });
            var color = _npmD.default.scaleOrdinal().domain([].concat(_toConsumableArray(colorDomainSet)).sort()).range(this.get('colors'));
            var svg = this.get('svg');

            // x axis
            var minTicks = 5;
            var maxX = _npmD.default.max(data, function (d) {
                return d[xKey];
            });
            var minX = _npmD.default.min(data, function (d) {
                return d[xKey];
            });
            this.x.range([0, this.get('chartWidth')]);

            if (this.get('xAxisTickFormat').indexOf('duration') >= 0) {
                // unit: hours, days, or weeks
                var maxSeconds = moment.duration(maxX, 'seconds');
                var unit = 'hours'; // default to hours
                var label = 'h';

                if (this.get('xAxisTickFormat') !== 'duration-hours') {
                    // try to find a better unit
                    if (maxSeconds.as('weeks') > minTicks) {
                        unit = 'weeks';
                        label = 'w';
                    } else if (maxSeconds.as('days') > minTicks) {
                        unit = 'days';
                        label = 'd';
                    }
                }
                data.forEach(function (d) {
                    d.duration = moment.duration(d[_this.get('xKey')], 'seconds').as(unit);
                });
                xKey = 'duration';

                this.x.domain([_npmD.default.min(data, function (d) {
                    return d.duration;
                }), _npmD.default.max(data, function (d) {
                    return d.duration;
                })]).nice();
                this.xAxis = _npmD.default.axisBottom(this.x).tickFormat(function (d) {
                    return '' + d + label;
                });
            } else {
                this.x.domain([minX, maxX]);
                this.xAxis = _npmD.default.axisBottom(this.x);
                if (maxX - minX < 10) {
                    this.xAxis.ticks(maxX - minX);
                }
            }
            svg.select('g.x.axis').call(this.xAxis);

            // group by category 1
            var byCategory = data.reduce(function (acc, item) {
                var key = item[yKey];

                acc[key] = acc[key] || [];
                acc[key].push(item);

                return acc;
            }, {});
            var lines = [];

            // set height to max of 300px or 20px * categories
            var margin = this.get('margin');
            var padding = margin.top + margin.bottom;
            var height = _npmD.default.max([300 - padding, Object.keys(byCategory).length * 20 + padding]);
            // update heights
            this.set('containerHeight', height + padding);
            this.set('chartHeight', height);
            Ember.$('.chart').attr('height', this.get('containerHeight'));
            Ember.$('.chart svg').attr('height', this.get('containerHeight'));
            Ember.$('g.x.axis').attr('transform', 'translate(0, ' + this.get('chartHeight') + ')');

            // y axis
            var yDomainSet = new Set(); // IE11 doesn't support new Set([1,2,3])
            data.forEach(function (d) {
                yDomainSet.add(d[yKey]);
            });
            this.y.range([0, this.get('chartHeight')]).domain([].concat(_toConsumableArray(yDomainSet)).sort());
            this.yAxis = _npmD.default.axisLeft(this.y).tickFormat(function (d) {
                var label = d ? (_this.get('data.meta.category1Labels') || {})[d] || d : _this.get('category1Empty');
                return label.length > 25 ? label.substr(0, 12) + '...' : label;
            });
            svg.select('g.y.axis').call(this.yAxis);

            Object.keys(byCategory).forEach(function (category) {
                var points = byCategory[category];
                var x1 = _npmD.default.min(points, function (d) {
                    return d[xKey];
                });
                var x2 = _npmD.default.max(points, function (d) {
                    return d[xKey];
                });
                var sum = 0;
                var count = 0;
                // weighted average
                points.forEach(function (p) {
                    var c = p.count || 1;
                    sum += c * p[xKey];
                    count += c;
                });

                var avg = count > 0 ? sum / count : 0;

                if (points.length < 2 || x1 === x2) {
                    return;
                }
                lines.push({ category: category, x1: x1, x2: x2, y: points[0][yKey], avg: avg });
            });

            // draw lines first so dots will be on top of them
            // connect min and max values
            var connect = svg.select('g.connect').selectAll('line').data(lines, function (d) {
                return d.category;
            });
            connect.enter().append('line').merge(connect).attr('id', function (d) {
                return 'connect-' + d.category;
            }).attr('x1', function (d) {
                return _this.x(d.x1);
            }).attr('y1', function (d) {
                return _this.y(d.y);
            }).attr('x2', function (d) {
                return _this.x(d.x2);
            }).attr('y2', function (d) {
                return _this.y(d.y);
            });
            connect.exit().remove();

            // vertical hash on average
            var average = svg.select('g.average').selectAll('line').data(lines, function (d) {
                return d.category;
            });
            average.enter().append('line').attr('class', 'average').merge(average).attr('id', function (d) {
                return 'avg-' + d.category;
            }).attr('x1', function (d) {
                return _this.x(d.avg);
            }).attr('y1', function (d) {
                return _this.y(d.y) - 10;
            }).attr('x2', function (d) {
                return _this.x(d.avg);
            }).attr('y2', function (d) {
                return _this.y(d.y) + 10;
            });
            average.exit().remove();

            var dots = svg.select('g.dots').selectAll('.dot').data(data, function (d) {
                return yKey + '=' + d[yKey] + ' ' + colorKey + '=' + d[colorKey];
            });
            dots.enter().append('circle').on('mouseover', function (d, i, nodes) {
                Ember.run.debounce(_this, 'showTooltip', { d: d, offset: Ember.$(nodes[i]).offset() }, 25);
            }).on('mouseout', function () {
                Ember.$('.crosstab-tooltip').hide();
            }).merge(dots).attr('data', function (d) {
                return JSON.stringify(d);
            }).attr('cx', function (d) {
                return _this.x(d[xKey]);
            }).attr('cy', function (d) {
                return _this.y(d[yKey]);
            }).style('fill', function (d) {
                return color(d[colorKey]);
            }).attr('class', 'dot').attr('r', 5);
            dots.exit().remove();

            this.set('svg', svg);
        }.observes('data.result.[]'),

        showTooltip: function showTooltip(_ref) {
            var d = _ref.d,
                offset = _ref.offset;

            this.set('tooltipValue', d[this.get('xKey')]);
            this.set('tooltipCount', d.count); // averages include value and count
            this.set('tooltipCategory1', d[this.get('yKey')] ? (this.get('data.meta.category1Labels') || {})[d[this.get('yKey')]] || d[this.get('yKey')] : this.get('category1Empty'));
            this.set('tooltipCategory2', d[this.get('colorKey')] ? (this.get('data.meta.category2Labels') || {})[d[this.get('colorKey')]] || d[this.get('colorKey')] : this.get('category2Empty'));

            this.set('tooltipX', offset.left);
            this.set('tooltipY', offset.top);
            Ember.$('.crosstab-tooltip').show();
        },
        onResize: function onResize() {
            Ember.run.debounce(this, this.resizeChart, 100);
        },
        resizeChart: function resizeChart() {
            var _this2 = this;

            var margin = this.get('margin');
            var width = this.$('.chart').width() - margin.left - margin.right;
            var svg = this.get('svg');

            this.set('chartWidth', width);
            Ember.$('svg').attr('width', this.$('.chart').width());
            this.drawChart();

            // redo x values for lines
            svg.select('g.connect').selectAll('line').attr('x1', function (d) {
                return _this2.x(d.x1);
            }).attr('x2', function (d) {
                return _this2.x(d.x2);
            });
            svg.select('g.average').selectAll('line').attr('x1', function (d) {
                return _this2.x((d.x2 + d.x1) / 2);
            }).attr('x2', function (d) {
                return _this2.x((d.x2 + d.x1) / 2);
            });
            // redo x position for dots
            svg.select('g.dots').selectAll('.dot').attr('cx', function (d) {
                return _this2.x(d[_this2.get('xKey')]);
            });
        },
        willDestroyElement: function willDestroyElement() {
            Ember.$(window).off('resize', this.onResize.bind(this));
        }
    });
});