define('huyang/controllers/requests/request', ['exports', 'huyang/mixins/finds-recent-channel', 'huyang-common/mixins/formats-channel-name', 'huyang-common/mixins/header-navigation-controller', 'huyang-common/mixins/modal-helper', 'huyang-common/mixins/renders-repeat-text', 'huyang-common/models/time-log'], function (exports, _findsRecentChannel, _formatsChannelName, _headerNavigationController, _modalHelper, _rendersRepeatText, _timeLog) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    exports.default = Ember.Controller.extend(_findsRecentChannel.default, _formatsChannelName.default, _headerNavigationController.default, _modalHelper.default, _rendersRepeatText.default, {
        session: Ember.inject.service(),

        request: Ember.computed.alias('model.request'),
        schedule: Ember.computed.alias('model.request.schedule'),

        requestsController: Ember.inject.controller('requests'),
        channels: Ember.computed.alias('requestsController.model.channels'),
        requestLabels: Ember.computed.alias('requestsController.model.requestLabels'),

        channel: Ember.computed.alias('primaryChannel'),

        vendorSendRoute: 'requests.request.send',
        vendorLoading: false,

        showEdit: false,

        modelIdString: 'request.id',
        modelString: 'request',
        routeString: 'requests.request',

        requestObserver: Ember.observer('request.schedule', function () {
            var dueDate = this.get('request.dueDate');

            if (!dueDate) {
                dueDate = moment().add(7, 'day').startOf('hour');
                dueDate.add(moment().minute() < 30 ? 1 : 2, 'hour');

                if (this.get('session.currentUser.timezone')) {
                    dueDate = moment.tz(dueDate, this.get('session.currentUser.timezone'));
                }
            }

            var createDate = this.get('request.schedule.start');

            if (createDate) {
                createDate = moment(createDate);
                if (createDate.isAfter(moment())) {
                    this.set('createLater', true);
                }
            } else {
                createDate = moment(dueDate).subtract(1, 'day');
            }

            this.set('dueDate', dueDate);
            this.set('createDate', createDate);
        }),

        unreadCount: function () {
            var meta = this.get('request.meta');
            var channels = this.get('channels');

            var count = 0;

            channels.forEach(function (channel) {
                var counts = meta[channel.get('name')];
                if (counts && counts.unread) {
                    count = count + counts.unread;
                }
            });

            return count;
        }.property('request.meta', 'channels.[]'),

        commentChannelId: function () {
            return this.get('primaryChannel.id');
        }.property('primaryChannel'),

        scheduleRepeatText: function () {
            return this.renderedRepeatText(this.get('schedule'), { lowercased: true });
        }.property('schedule.nextCreate', 'schedule.repeatRule', 'schedule.afterClose'),

        scheduleAfterClose: function () {
            var ac = this.get('request.schedule.afterClose');
            if (!ac) {
                return;
            }

            var _ac$split = ac.split(' '),
                _ac$split2 = _slicedToArray(_ac$split, 2),
                interval = _ac$split2[0],
                unit = _ac$split2[1];

            if (interval === '1') {
                unit = unit.slice(0, -1);
            }

            return interval + ' ' + unit;
        }.property('request.schedule.afterClose'),

        defaultCloseLabel: function () {
            return this.get('requestLabels').find(function (l) {
                return l.get('isDefaultClosed');
            });
        }.property(),

        totalRequestsLabel: function () {
            var count = this.get('totalRequests');
            return count + ' request' + (count === 1 ? '' : 's');
        }.property('totalRequests'),

        scheduledTableColumns: function () {
            return [Ember.Object.create({ label: 'ID', editLabel: 'Request ID', key: 'workOrder', phraseFormat: '#%@', disableSorting: true }), Ember.Object.create({ label: 'Short description', key: 'title', link: 'requests.request', disableLinkAction: true, disableSorting: true }), Ember.Object.create({ label: 'Status', key: 'status', disableSorting: true }), Ember.Object.create({ label: 'Last updated', isDate: true, relativeDate: true, key: 'updated', disableSorting: true })];
        }.property(),

        requestTimer: function () {
            var user = this.get('session.currentUser');

            return this.get('request.timeLogs').find(function (tl) {
                return tl.isTimerForUser(user);
            });
        }.property('request.timeLogs.@each.endTime'),

        actions: {
            goToRequest: function goToRequest(reqId) {
                this.transitionToRoute('requests.request', reqId);
            },

            openModal: function openModal(modalId) {
                this.openModal(modalId);
            },

            setLabel: function setLabel(label) {
                this.set('newRequestLabel', label);
                // Add boolean flag, since `newRequestLabel` can be either an object OR undefined
                this.set('setNewRequestLabel', true);
            },

            channelSelected: function channelSelected(channel) {
                this.set('channel', channel);
            },

            clickCancelRepeat: function clickCancelRepeat() {
                this.set('setNewRequestLabel', false);
                this.openModal('cancelRepeat');
            },

            clickRestartRepeat: function clickRestartRepeat() {
                this.set('setNewRequestLabel', false);
                this.openModal('restartRepeat');
            },

            cancelRepeat: function cancelRepeat() {
                var _this = this;

                var request = this.get('request'),
                    schedule = this.get('request.schedule');

                if (this.get('setNewRequestLabel')) {
                    request.set('requestLabel', this.get('newRequestLabel'));
                } else {
                    request.set('requestLabel', this.get('defaultCloseLabel'));
                }

                request.save().then(function () {
                    schedule.set('active', false);
                    return schedule.save();
                }).then(function () {
                    _this.get('flashMessages').success('The repeat series has been canceled.');
                });
            },

            restartRepeat: function restartRepeat() {
                var _this2 = this;

                var request = this.get('request'),
                    schedule = this.get('request.schedule');

                if (this.get('setNewRequestLabel')) {
                    request.set('requestLabel', this.get('newRequestLabel'));
                } else {
                    request.set('requestLabel', this.get('defaultCloseLabel'));
                }

                request.save().then(function () {
                    var originalStart = moment(schedule.get('start')),
                        now = moment();
                    if (now > originalStart) {
                        schedule.set('start', now.toDate());
                    }
                    schedule.set('active', true);
                    return schedule.save();
                }).then(function () {
                    _this2.get('flashMessages').success('The repeat series has been restarted.');
                });
            },

            clickClose: function clickClose() {
                var timer = this.get('requestTimer');

                if (timer) {
                    this.set('saveTimerOnClose', true);
                    this.set('closeTimeChangeset', timer.changeset());
                } else {
                    this.set('saveTimerOnClose', false);
                    this.set('closeTimeChangeset', _timeLog.default.changeset({
                        account: this.get('request.account'),
                        request: this.get('request'),
                        logger: this.get('session.currentUser')
                    }));
                }

                this.set('closeComment', '');
                this.set('setNewRequestLabel', false);
                this.get('request').set('commentBody', '');

                this.openModal('closeComment');
            },

            toggleSaveTimerOnClose: function toggleSaveTimerOnClose(val) {
                this.set('saveTimerOnClose', val);
            },


            closeAndComment: function closeAndComment() {
                var _this3 = this;

                var request = this.get('request');
                request.set('status', 'closed');

                if (this.get('setNewRequestLabel')) {
                    request.set('requestLabel', this.get('newRequestLabel'));
                } else {
                    request.set('requestLabel', this.get('defaultCloseLabel'));
                }

                if (this.get('closeComment')) {
                    request.set('commentBody', this.get('closeComment'));
                    request.set('commentChannelId', this.get('commentChannelId'));
                }

                // FIXME: make this an array of promises so they perform concurrently

                request.save().then(function (req) {
                    _this3.set('setNewRequestLabel', false);
                    _this3.set('newRequestLabel', null);

                    // Destroy pending (non-repeating) request's schedule
                    if (req.get('schedule.id')) {
                        return req.get('schedule').destroyRecord();
                    }
                }).then(function () {
                    var timerChangeset = _this3.get('closeTimeChangeset');

                    if (_this3.get('saveTimerOnClose')) {
                        // The time being logged can either be through an existing record (the timer),
                        // or manually as a new one which needs to be created
                        if (timerChangeset.get('_content.save')) {
                            return timerChangeset.save();
                        } else {
                            return timerChangeset.save().then(function (_ref) {
                                var _content = _ref._content;

                                return _this3.store.createRecord('time-log', _content).save();
                            });
                        }
                    } else {
                        timerChangeset.destroy();

                        // The changeset refers to either an existing record or a new POJO,
                        // so it needs to be destroyed in the first case
                        var content = timerChangeset.get('_content');

                        if (content.destroyRecord) {
                            content.destroyRecord();
                        }
                    }
                }).then(function () {
                    var meta = _this3.get('request.meta');
                    if (meta && meta.commentId) {
                        _this3.store.fetchById('request-comment', meta.commentId).then(function (comment) {
                            _this3.get('request.comments').pushObject(comment);
                        });
                    }
                    _this3.get('flashMessages').success('Request closed!');
                    Ember.run.later(function () {
                        return _this3.get('requestsController').loadRequestCounts();
                    }, 500);
                });
            },

            clickOpen: function clickOpen() {
                this.set('reopenComment', null);
                this.set('setNewRequestLabel', false);
                this.get('request').set('commentBody', '');
                this.openModal('reopenComment');
            },

            reopenAndComment: function reopenAndComment() {
                var _this4 = this;

                var request = this.get('request');
                request.set('status', 'open');

                // Use boolean flag, since 'newRequestLabel' can be undefined
                if (this.get('setNewRequestLabel')) {
                    request.set('requestLabel', this.get('newRequestLabel'));
                    this.set('newRequestLabel', '');
                }

                if (this.get('reopenComment')) {
                    request.set('commentBody', this.get('reopenComment'));
                    request.set('commentChannelId', this.get('commentChannelId'));
                }

                request.save().then(function () {
                    var meta = _this4.get('request.meta');
                    if (meta && meta.commentId) {
                        _this4.store.fetchById('request-comment', meta.commentId).then(function (comment) {
                            _this4.get('request.comments').pushObject(comment);
                        });
                    }
                    _this4.get('flashMessages').success('Request reopened');
                    Ember.run.later(function () {
                        return _this4.get('requestsController').loadRequestCounts();
                    }, 500);
                });
            },

            onUpdateMeta: function onUpdateMeta() {
                var _this5 = this;

                Ember.run.later(this, function () {
                    _this5.get('requestsController').loadRequestCounts();
                }, 500);
            },

            addFollowers: function addFollowers(followerIds, callback) {
                var _this6 = this;

                this.createFollowers(followerIds, this.get('request.building.id'), this.get('request.id')).then(function (result) {
                    _this6.get('request').addFollowers(result.followerIds).then(function () {
                        if (callback) {
                            callback();
                        }
                        _this6.set('showAddFollowers', false);
                        if (result.newUserIds && result.newUserIds.length) {
                            _this6.transitionToRoute('requests.new-occupants', _this6.get('request.id'), {
                                queryParams: {
                                    userIds: result.newUserIds.join(',')
                                }
                            });
                        }
                    });
                });
            },

            openVendorModal: function openVendorModal() {
                this.transitionToRoute(this.get('vendorSendRoute'));
            },

            storeRepeatRule: function storeRepeatRule(props) {
                this.set('afterClose', {});
                this.set('repeatRule', props);
            },

            storeAfterClose: function storeAfterClose(props) {
                this.set('repeatRule', {});
                this.set('afterClose', props);
            },

            saveRepeat: function saveRepeat() {
                var _this7 = this;

                var repeatRule = this.get('repeatRule');
                var afterClose = this.get('afterClose');
                var schedule = this.get('request.schedule');

                var _ref2 = afterClose.createDate ? afterClose : repeatRule,
                    createDate = _ref2.createDate,
                    dueAfter = _ref2.dueAfter;

                // Assign all regardless of which repeat type it is, since this will
                // clear repeat rule fields if after close and vice versa


                schedule.setProperties({
                    repeatRule: repeatRule.repeatRule,
                    previousClose: repeatRule.previousClose,
                    previousRelabel: repeatRule.previousRelabel,
                    previousLabel: repeatRule.previousLabel,
                    afterClose: afterClose.afterClose,
                    start: createDate,
                    dueAfter: dueAfter
                });

                schedule.save().then(function () {
                    _this7.get('flashMessages').success('Repeat schedule has been updated');
                });
            },
            storeScheduleDates: function storeScheduleDates(dates) {
                this.set('dueDate', dates.dueDate);
                this.set('createDate', dates.createDate);
            },
            cancelScheduleDates: function cancelScheduleDates() {
                var _this8 = this;

                // `skipPickerAction` was added to work around modifying "dueDate"
                // twice in a single render. DDAU is all good until you have a rogue
                // js component that wants to keep firing the change event.
                this.set('skipPickerAction', true);
                this.set('dueDate', this.get('request.dueDate'));
                Ember.run.next(function () {
                    _this8.set('skipPickerAction', false);
                });
            },
            saveScheduleDates: function saveScheduleDates() {
                var _this9 = this;

                var request = this.get('request'),
                    prevDueDate = request.get('dueDate'),
                    schedule = request.get('schedule'),
                    dueDate = this.get('dueDate'),
                    createDate = this.get('createDate'),
                    now = moment(),
                    unschedule = false;

                if (!this.get('createLater')) {
                    createDate = undefined;
                }

                if (dueDate) {
                    dueDate = moment(dueDate).toDate();
                }
                request.set('dueDate', dueDate);
                if (!createDate || createDate.isBefore(now)) {
                    // Can only occur on pending request, so status is pending
                    request.setProperties({
                        status: 'open',
                        dueDate: dueDate,
                        created: moment().toDate()
                    });
                    unschedule = true;
                } else {
                    // This is only for the view - updating schedule.nextCreate will
                    // automatically update request.created on backend
                    // preserve create date offset from due date
                    var created = moment(request.get('created'));
                    if (dueDate && prevDueDate && created.isAfter(now)) {
                        var diff = moment(prevDueDate) - created;
                        createDate = moment(moment(dueDate) - diff);
                    }
                    // request.save looks for createDate
                    // update request ignores created; update schedule sets request.created
                    request.set('createDate', createDate.toDate());
                }

                request.save().then(function () {
                    if (unschedule) {
                        schedule.deleteRecord();
                        Ember.run.later(function () {
                            return _this9.get('requestsController').loadRequestCounts();
                        }, 500);
                    } else {
                        createDate = createDate.toDate();
                        schedule.setProperties({ start: createDate, nextCreate: createDate });
                    }
                    return schedule.save();
                }).then(function () {
                    _this9.closeModal('dueDateModal');
                    _this9.get('flashMessages').success('Request schedule has been updated');
                });
            }
        }
    });
});