define('huyang/controllers/settings/buildings/building/request-types', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        building: Ember.computed.alias('model.building'),

        unusedRequestTypes: function () {
            var buildingTypes = this.get('building.requestTypes');

            return this.get('model.requestTypes').filter(function (rt) {
                var found = buildingTypes.find(function (brt) {
                    return brt.get('requestType.id') === rt.get('id');
                });

                return !found;
            });
        }.property('model.requestTypes.[]', 'building.requestTypes.[]'),

        actions: {
            addRequestType: function addRequestType(rt) {
                var record = this.store.createRecord('request-type-building', {
                    building: this.get('building'),
                    requestType: rt
                });

                record.save();
            },
            removeType: function removeType(brt) {
                brt.destroyRecord();
            }
        }
    });
});