define('huyang/controllers/admin/create-account', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        api: Ember.inject.service(),
        flashMessages: Ember.inject.service(),

        fields: function () {
            return [{
                name: 'email',
                label: 'Email address',
                placeholder: 'you@yourcompany.com',
                validations: {
                    presence: true,
                    format: { with: /.+@.+\..+/, allowBlank: false, message: 'Please enter a valid email address' }
                },
                value: this.get('email')
            }, {
                name: 'name',
                label: 'Name',
                placeholder: 'John Smith',
                validations: { presence: true },
                value: this.get('name')
            }, {
                name: 'organization',
                label: 'Organization name',
                placeholder: 'ACME Inc',
                validations: { presence: true },
                value: this.get('organization')
            }, {
                label: 'Workflow website address',
                name: 'url',
                subdomain: true,
                domain: 'tikkit.gridium.com',
                secondaryDomainMessage: 'Tenants would log in at',
                secondaryDomain: 'tikkit.us',
                validations: {
                    presence: true,
                    length: { minimum: 3 },
                    subdomain: true
                },
                value: this.get('url')
            }, {
                label: 'Gridium account owner',
                name: 'owner',
                validations: { presence: true },
                placeholder: 'Slack username',
                value: this.get('owner')
            }];
        }.property('email', 'name', 'organization', 'url', 'owner'),

        splitName: function () {
            var name = this.get('name') || '';
            this.set('firstName', name.split(' ')[0]);
            this.set('lastName', name.substr(this.get('firstName').length + 1));
        }.observes('name'),

        dasherizeUrl: function dasherizeUrl(url) {
            return url.replace(/[^\w]/, '-').dasherize().replace(/-+/, '-');
        },

        checkEmail: function checkEmail(email) {
            return this.get('api').ajax({
                url: '/api/email_exists',
                data: { email: email }
            });
        },

        actions: {
            save: function save(formData, onComplete) {
                var _this = this;

                Object.keys(formData).forEach(function (key) {
                    _this.set(key, formData[key]);
                });
                formData.url = this.dasherizeUrl(formData.url).toLowerCase();
                this.checkEmail(this.get('email')).then(function (exists) {
                    _this.set('emailExists', exists);
                    if (exists) {
                        onComplete();
                        return;
                    }
                    _this.get('api').ajax({
                        type: 'POST',
                        url: '/api/signup',
                        contentType: 'application/json',
                        data: JSON.stringify(formData),
                        success: function success() {
                            _this.get('flashMessages').success('Account created!');
                            _this.set('accountCreated', true);
                            onComplete();
                        }
                    });
                });
            }
        }
    });
});