define('huyang/components/channel-unread-badge', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        classNames: ['channel-unread-badge'],

        count: function () {
            if (this.get('channel.unreadKey') && this.get('request.unreadComments')) {
                var unread = this.get('request.unreadComments');
                var key = this.get('channel.unreadKey');

                if (unread[key]) {
                    return unread[key];
                }
            }

            return 0;
        }.property('request.unreadComments', 'channel.unreadKey')
    });
});