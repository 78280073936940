define('huyang/controllers/admin/tracking', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        api: Ember.inject.service(),

        actions: {
            add: function add() {
                var _this = this;

                var newTrackee = this.get('newTrackee');

                if (this.get('model').filterBy('email', newTrackee).length) {
                    this.get('flashMessages').danger(newTrackee + ' is already being tracked');
                    return false;
                }

                this.get('api').ajax({
                    type: 'POST',
                    url: '/api/trackedUsers',
                    dataType: 'json',
                    data: JSON.stringify({ trackedUser: { email: newTrackee } })
                }).then(function (response) {
                    _this.get('model').pushObject({
                        id: response.trackedUser.id,
                        email: response.users[0].email
                    });
                    _this.set('newTrackee', null);
                    _this.get('flashMessages').success(newTrackee + ' is now being tracked');
                }, function (err) {
                    console.log(err);
                    _this.get('flashMessages').danger('Unable to track user');
                });
            },
            remove: function remove(user) {
                var _this2 = this;

                this.get('api').ajax({
                    type: 'DELETE',
                    url: '/api/trackedUsers/' + user.id,
                    dataType: 'json'
                }).then(function () {
                    var dtu = _this2.get('model').findBy('email', user.email);
                    _this2.get('model').removeObject(dtu);
                    _this2.get('flashMessages').success(user.email + ' is no longer being tracked');
                }, function (err) {
                    console.log(err);
                    _this2.get('flashMessages').danger('There was a problem removing tracking from this user');
                });
            }
        }
    });
});