define('huyang/controllers/people/occupants/manage/users', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['q', 'ids'],
        selectedOccupants: [],

        occupants: Ember.computed.alias('model.tenantUsers'),
        groups: Ember.computed.alias('model.groups'),

        transitionToSearch: function () {
            if (this.get('q')) {
                this.transitionToRoute('people.occupants.manage.users.search');
            }
        }.observes('q'),

        pendingUsers: function () {
            return this.get('occupants').filterBy('isPending');
        }.property('occupants.@each.isPending'),

        hasSelectedOccupants: function () {
            return this.get('selectedOccupants.length') > 0;
        }.property('selectedOccupants.length'),

        actions: {
            setBulkStatus: function setBulkStatus(status) {
                var _this = this;

                this.get('selectedOccupants').forEach(function (occ) {
                    occ.set('status', status);
                    occ.save().then(function () {
                        var labels = {
                            active: 'Activated users',
                            inactive: 'Deactivated users',
                            blocked: 'Blocked users'
                        };

                        occ.set('selected', false);
                        _this.get('flashMessages').success(labels[status] || 'Status updated');
                    });
                });
            },
            setBulkGroup: function setBulkGroup(group) {
                var _this2 = this;

                var users = this.get('selectedOccupants');

                users.forEach(function (occ) {
                    occ.set('occupantGroup', group);
                    occ.set('grantAll', false);

                    occ.save().then(function () {
                        occ.set('selected', false);

                        var message = '';

                        if (users.length === 1) {
                            var occupant = users.get('firstObject');
                            var name = occupant.get('user.name');

                            if (!name) {
                                name = 'User';
                            }

                            message = name + ' is now a member of ' + group.get('name');
                        } else {
                            message = 'Assigned users to ' + group.get('name');
                        }

                        _this2.get('flashMessages').success(message);
                    });
                });
            }
        }
    });
});