define('huyang/adapters/asset-category-custom-field', ['exports', 'huyang/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      return '/api/' + snapshot.record.get('category.account.id') + '/' + snapshot.record.get('category.id') + '/assetCustomFields';
    },

    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      return '/api/' + snapshot.record.get('category.account.id') + '/' + snapshot.record.get('category.id') + '/assetCustomFields/' + id;
    },

    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      return '/api/' + snapshot.record.get('category.account.id') + '/' + snapshot.record.get('category.id') + '/assetCustomFields/' + id;
    }
  });
});