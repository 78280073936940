define('huyang/models/asset', ['exports', 'huyang/validations/asset', 'ember-changeset', 'ember-data', 'ember-changeset-validations', 'huyang-common/utils/time'], function (exports, _asset, _emberChangeset, _emberData, _emberChangesetValidations, _time) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Asset = _emberData.default.Model.extend({
        assetCategory: _emberData.default.belongsTo('asset-category', { async: true }),
        attachments: _emberData.default.hasMany('asset-attachment', { async: true }),
        tag: _emberData.default.attr('string'),
        building: _emberData.default.belongsTo('building', { async: true }),
        subspace: _emberData.default.belongsTo('subspace', { async: true }),
        requests: _emberData.default.hasMany('request', { async: true }),
        description: _emberData.default.attr('string'),
        assetMakeModel: _emberData.default.belongsTo('asset-make-model', { async: true }),
        serialNumber: _emberData.default.attr('string'),
        dateOfAcquisition: _emberData.default.attr('date'),
        dateOfDecommissioning: _emberData.default.attr('date'),
        cost: _emberData.default.attr('number'),
        notes: _emberData.default.attr('string'),
        warrantyExpiration: _emberData.default.attr('date'),
        archived: _emberData.default.attr('date'),
        account: _emberData.default.belongsTo('account', { async: true }),
        makeModel: _emberData.default.attr('string'),
        renderedNotes: _emberData.default.attr('string'),
        customFields: _emberData.default.hasMany('asset-custom-field', { async: false }),
        lastMaintenanceDate: _emberData.default.attr('date'),
        nextMaintenanceDate: _emberData.default.attr('date'),
        maintenanceCost: _emberData.default.attr('number'),
        maintenanceHours: _emberData.default.attr('number'),

        totalMaintenanceHours: Ember.computed('maintenanceHours', function () {
            if (this.get('maintenanceHours')) {
                // Maintenance hours is in seconds - convert to hours/minutes
                var hours = Math.floor(this.get('maintenanceHours') / 3600);
                var minutes = Math.floor(this.get('maintenanceHours') % 3600 / 60);

                return (0, _time.timerString)(hours, minutes, null, { zeroIfEmpty: false });
            }
        }),

        isArchived: Ember.computed('archived', function () {
            if (this.get('archived')) {
                return true;
            }
        }),

        // requests created from a recurring series (request template)
        maintenanceRequests: Ember.computed('requests.@each', function () {
            return this.get('requests').filter(function (a) {
                return a.get('template.id') !== undefined;
            });
        }),

        changeset: function changeset() {
            var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            // Need to pass in all three (content, validatorFn, validationMap), even though
            // documentation just shows first two args...
            // See https://github.com/DockYard/ember-changeset/issues/202
            var changeset = new _emberChangeset.default(this, (0, _emberChangesetValidations.default)(_asset.default), _asset.default);

            changeset.setProperties(props);

            return changeset;
        }
    });

    Asset.reopenClass({
        changeset: function changeset() {
            var changeset = new _emberChangeset.default({}, (0, _emberChangesetValidations.default)(_asset.default), _asset.default);
            return changeset;
        }
    });

    exports.default = Asset;
});