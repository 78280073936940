define('huyang/serializers/time-log', ['exports', 'huyang-common/serializers/rest-new', 'huyang-common/utils/time'], function (exports, _restNew, _time) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    exports.default = _restNew.default.extend({
        normalize: function normalize(typeClass, hash, prop) {
            var _elapsedToTimer = (0, _time.elapsedToTimer)(hash.elapsed),
                _elapsedToTimer2 = _slicedToArray(_elapsedToTimer, 3),
                hours = _elapsedToTimer2[0],
                minutes = _elapsedToTimer2[1],
                seconds = _elapsedToTimer2[2];

            hash.hours = hours;
            hash.minutes = minutes;
            hash.seconds = seconds;
            delete hash.elapsed;

            return this._super(typeClass, hash, prop);
        },
        serialize: function serialize(snapshot, options) {
            var data = this._super(snapshot, options);

            data.elapsed = (0, _time.timerToElapsed)(data.hours, data.minutes, data.seconds);
            delete data.hours;
            delete data.minutes;
            delete data.seconds;

            return data;
        }
    });
});