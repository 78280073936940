define('huyang/components/set-request-type-info', ['exports', 'ember-validations'], function (exports, _emberValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        buttonLabel: 'Update',
        selectedBuildings: [],

        fields: function () {
            return [{
                name: 'label',
                label: 'Name',
                placeholder: 'Too hot',
                value: this.get('label'),
                currentRequestTypes: this.get('requestTypes'),
                validations: {
                    presence: true,
                    inline: (0, _emberValidations.validator)(function () {
                        // can't have same name as another active request type
                        var propName = this.property;
                        var field = this.model.fields.find(function (f) {
                            return f.name === propName;
                        });
                        var label = this.get('fieldValues.label');
                        if (!field || !label) {
                            return; // let presence validator handle this
                        }
                        // unchanged is ok
                        if (label === field.value) {
                            return;
                        }
                        label = label.toLowerCase();
                        var dup = field.currentRequestTypes.find(function (rt) {
                            return rt.get('active') && rt.get('label').toLowerCase() === label;
                        });
                        if (dup) {
                            this.errors.pushObject('must not be in use already');
                        }
                    })
                }
            }, {
                name: 'iconClass',
                label: 'Icon',
                validations: { presense: true },
                component: 'icon-picker',
                properties: {
                    selectedIcon: this.get('iconClass') ? this.get('iconClass') : 'question',
                    iconSet: this.get('baseRequestTypes').map(function (rt) {
                        return {
                            icon: 'icon huyang-' + rt.get('iconClass'),
                            iconClass: rt.get('iconClass'),
                            label: rt.get('label')
                        };
                    })
                },
                action: 'chooseIcon'
            }, {
                name: 'location',
                label: 'Locations',
                helpText: 'Edit the list of locations where this request type is available',
                component: 'location-select-menu',
                properties: {
                    buildings: this.get('buildings'),
                    selectedBuildings: this.get('selectedBuildings')
                },
                action: 'locationsUpdated'
            }, {
                name: 'isDefault',
                checkboxText: 'Add to new locations by default',
                value: this.get('isDefault'),
                checkbox: true,
                label: 'Default',
                helpText: 'Default request types are automatically added to new locations'
            }];
        }.property('name', 'icon', 'isDefault', 'selectedBuildings'),

        showError: function () {
            return this.get('isInvalid') && this.get('clickedSave');
        }.property('isInvalid', 'clickedSave'),

        commitButtonLabel: function () {
            return this.get('model') ? 'Save changes' : 'Create';
        }.property('model'),

        hideDelete: function () {
            return !this.get('model.id');
        }.property('model'),

        actions: {
            save: function save(info, onComplete, onError) {
                info.buildingIds = this.get('selectedBuildingIds');
                info.iconClass = this.get('iconClass');
                this.sendAction('action', info, onComplete, onError);
            },

            cancel: function cancel() {
                this.sendAction('onCancel');
            },

            clickDelete: function clickDelete() {
                this.sendAction('onDelete');
            },

            selectChanged: function selectChanged(name, value) {
                console.log('select ' + name + ' changed to ' + value);
            },

            chooseIcon: function chooseIcon(data) {
                this.set('iconClass', data.selectedIcon);
            },

            locationsUpdated: function locationsUpdated(info) {
                var buildings = info.selectedBuildings;
                var selectedIds = [];

                if (buildings && buildings.length) {
                    selectedIds = buildings.filterBy('selected').map(function (building) {
                        return building.get('id');
                    });
                }

                this.set('selectedBuildingIds', selectedIds);
            }
        }
    });
});