define('huyang/components/recent-message-snippet', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['channel-snippet'],

        recentComment: function () {
            var channel = this.get('channel');
            var comments = [];

            if (channel) {
                comments = this.get('comments').filter(function (comment) {
                    // force to ints, sometimes this are strings. sad!
                    var a = parseInt(comment.get('channel.id'));
                    var b = parseInt(channel.get('id'));

                    return a === b;
                });
            }

            if (comments.length > 0) {
                comments = comments.sortBy('created');
                comments.reverse();
                return comments[0];
            }

            return null;
        }.property('channel', 'comments.[]')
    });
});