define('huyang/routes/requests/preventative-maintenance/program/info/edit', ['exports', 'huyang/routes/base-account', 'huyang/mixins/modal-route'], function (exports, _baseAccount, _modalRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend(_modalRoute.default, {
        modalSelector: '.new-request',
        returnRoute: 'requests.preventative-maintenance.program.info',

        model: function model() {
            return this.modelFor('requests.preventative-maintenance.program');
        },

        afterModel: function afterModel(model) {
            this.set('returnId', model.pmProgram.get('id'));
            // model.startEdit();
        }
    });
});