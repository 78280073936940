define('huyang/components/vendor-technicians', ['exports', 'huyang-common/mixins/modal-helper'], function (exports, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_modalHelper.default, {
        classNames: ['vendor-technician-list'],

        confirmDeleteTitle: function () {
            return 'Delete \'' + this.get('deletedTechnician.user.name') + '\'';
        }.property('deletedTechnician'),

        sortedTechnicians: function () {
            return this.get('vendor.technicians').sortBy('user.displayName');
        }.property('vendor.technicians.[]'),

        label: function () {
            var len = this.get('vendor.technicians.length');
            var word = len === 1 ? 'technician' : 'technicians';

            return len + ' ' + word;
        }.property('vendor.technicians.length'),

        actions: {
            deleteTechnician: function deleteTechnician(tech) {
                this.set('deletedTechnician', tech);
                this.openModal('confirmDeleteTechnician');
            },

            confirmDeleteTechnician: function confirmDeleteTechnician() {
                var _this = this;

                var tech = this.get('deletedTechnician');
                tech.destroyRecord().then(function () {
                    _this.set('deletedTechnician', null);
                    _this.get('flashMessages').success(tech.get('user.name') + ' deleted!');
                    _this.closeModal();
                });
            }
        }
    });
});