define('huyang/components/set-asset-category-info', ['exports', 'huyang/components/inline-editable-table', 'ember-uuid'], function (exports, _inlineEditableTable, _emberUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _inlineEditableTable.default.extend({
    store: Ember.inject.service(),
    classNames: ['form set-asset-category-info'],
    _positionSort: ['position'],
    items: Ember.computed.sort('assetCategory.customFields', '_positionSort'),

    confirmDeleteTitle: Ember.computed('assetCategory', function () {
      return 'Delete \'' + this.get('assetCategory.title') + '\'';
    }),

    deleteMessage: Ember.computed('counts', 'assetCategory', function () {
      var count = void 0,
          activeAssetsMessage = '';

      if (this.get('counts') && this.get('counts.categories') && this.get('assetCategory')) {
        count = this.get('counts.categories')[this.get('assetCategory.id')];
      }

      if (count) {
        var subject = 'are ' + count + ' active assets';
        if (count === 1) {
          subject = 'is ' + count + ' active asset';
        }
        activeAssetsMessage = 'There ' + subject + ' in this category.';
      }

      return activeAssetsMessage + '      Deleting the category will not affect existing assets but will make the      category unavailable for new assets.';
    }),

    actions: {
      addCustomField: function addCustomField() {
        this.get('items').pushObject(this.get('store').createRecord('asset-category-custom-field', {
          id: (0, _emberUuid.v4)(),
          category: this.get('assetCategory'),
          isEditing: true,
          position: this.get('items').length + 1
        }));
      },
      saveButtonClicked: function saveButtonClicked() {
        this.get('save')();
      },
      cancel: function cancel() {
        this.get('cancel')();
      },
      clickDelete: function clickDelete() {
        this.openModal('confirmDeleteCategory');
      },
      confirmDeleteCategory: function confirmDeleteCategory() {
        this.get('delete')();
      },
      confirmDeleteItemAction: function confirmDeleteItemAction() {
        var _this = this;

        this.get('deletedItem').destroyRecord().then(function () {
          _this.get('flashMessages').success('Custom field deleted');
        });
      },

      reorderItems: function reorderItems(items) {
        items.forEach(function (item, position) {
          item.set('position', position + 1);
        });
      }
    }
  });
});