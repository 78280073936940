define('huyang/routes/requests/preventative-maintenance/program/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      this.transitionTo('requests.preventative-maintenance.program.info');
    }
  });
});