define('huyang/routes/settings/organization/occupants', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),

        model: function model() {
            var _this = this;

            var accountId = this.get('session.data.authenticated.accountId');

            return this.store.findAll('user').then(function (users) {
                return Ember.RSVP.hash({
                    account: _this.store.findRecord('account', _this.get('session.data.authenticated.accountId')),
                    staff: users.filter(function (u) {
                        return u.hasAnyRole(accountId, ['staff', 'staff-all', 'admin']);
                    }),
                    approvers: users.filter(function (u) {
                        return u.hasAnyRole(accountId, ['occupant_approver']);
                    })
                });
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('originalApprovers', Ember.copy(model.approvers));
        }
    });
});