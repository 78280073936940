define('huyang/routes/people/staff', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        /*
            load these now to make it easier to work on sub-pages
        */
        model: function model() {
            return Ember.RSVP.hash({
                account: this.store.findRecord('account', this.get('session.data.authenticated.accountId')),
                // only show active buildings
                buildings: this.store.findAll('building').then(function (resp) {
                    return resp.filterBy('active');
                }),
                users: this.store.findAll('user'),
                roles: this.store.findAll('role'),
                openTimers: this.store.query('time-log', { end_time: null })
            });
        }
    });
});