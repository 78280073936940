define('huyang/routes/login', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        documentTitle: 'Login',

        model: function model() {
            // validate subdomain
            return this.store.findRecord('primary-building', 0);
        },

        afterModel: function afterModel(model) {
            if (model.get('account.status') === 'inactive') {
                this.transitionTo('inactive');
            }
        },


        renderTemplate: function renderTemplate() {
            this.render('login', { into: 'application' });
        }
    });
});