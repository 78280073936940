define('huyang/components/request-time-logs', ['exports', 'huyang-common/models/time-log', 'huyang-common/mixins/modal-helper'], function (exports, _timeLog, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var _changesetNew = 'changesetNew';
    var _changesetEdit = 'changesetEdit';
    var _toDelete = 'recordToDelete';

    exports.default = Ember.Component.extend(_modalHelper.default, {
        classNames: ['request-time-logs', 'request-logs'],
        session: Ember.inject.service(),
        store: Ember.inject.service(),

        clickStart: function clickStart() {},
        user: function user() {
            return this.get('session.currentUser');
        },
        newChangeset: function newChangeset() {
            var user = this.user();

            return _timeLog.default.changeset({
                account: this.get('request.account'),
                request: this.get('request'),
                logger: user,
                worker: user
            });
        },


        requestTimer: function () {
            var user = this.get('session.currentUser');

            return this.get('request.timeLogs').find(function (tl) {
                return tl.isTimerForUser(user);
            });
        }.property('request.timeLogs.@each.active'),

        emptyTimeString: '0hr 0m',

        timeLoggedBillableString: Ember.computed('request.savedLogs.@each.billable', function () {
            var logs = this.get('request.savedLogs').filter(function (log) {
                return log.get('billable');
            });
            if (logs.get('length')) {
                return this.get('request.timeLoggedBillableString');
            }

            return this.get('emptyTimeString');
        }),

        timeLoggedNonBillableString: Ember.computed('request.savedLogs.@each.billable', function () {
            var logs = this.get('request.savedLogs').filter(function (log) {
                return !log.get('billable');
            });
            if (logs.get('length')) {
                return this.get('request.timeLoggedNonBillableString');
            }

            return this.get('emptyTimeString');
        }),

        actions: {
            addRecord: function addRecord() {
                this.set(_changesetNew, this.newChangeset());
                this.openModal('addRecord');
            },
            confirmAdd: function confirmAdd() {
                var _this = this;

                var changeset = this.get(_changesetNew);

                changeset.validate().then(function () {
                    if (!changeset.get('isValid')) {
                        return;
                    }

                    _this.closeModal();

                    changeset.save().then(function (_ref) {
                        var _content = _ref._content;

                        return _this.get('store').createRecord('time-log', _content).save();
                    }).then(function () {
                        _this.set(_changesetNew, _this.newChangeset());
                    });
                    // FIXME: catch errors
                });
            },
            editRecord: function editRecord(log) {
                this.set(_changesetEdit, log.changeset({ logger: this.user() }));
                this.openModal('editRecord');
            },
            confirmEdit: function confirmEdit() {
                var _this2 = this;

                var changeset = this.get(_changesetEdit);

                changeset.validate().then(function () {
                    if (!changeset.get('isValid')) {
                        return;
                    }

                    // FIXME: catch errors
                    return changeset.save().then(function () {
                        _this2.closeModal();
                        _this2.set(_changesetEdit, null);
                    });
                });
            },
            deleteRecord: function deleteRecord(log) {
                this.set(_toDelete, log);
                this.openModal('deleteRecord');
                // FIXME: catch errors - can I un-delete?
            },
            confirmDelete: function confirmDelete() {
                this.get(_toDelete).destroyRecord();
            },
            cancelDelete: function cancelDelete() {
                this.set(_toDelete, null);
            }
        }
    });
});