define('huyang/controllers/settings/customization/trades', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        columns: [{ key: 'label', label: 'Trade name' }, { key: 'vendorCount', label: 'Vendors' }],

        hasError: function () {
            return this.get('tradeError.length') > 0;
        }.property('tradeError'),

        baseTrades: function () {
            var _this = this;

            return this.get('model.baseTrades').filter(function (bt) {
                var existingTrade = _this.get('model.trades').findBy('label', bt.get('label'));
                return !existingTrade;
            }).sortBy('label');
        }.property('model.trades.@each.label', 'model.baseTrades.@each.label'),
        // actions helpers

        validateLabel: function validateLabel() {
            var format = function format(str) {
                // Convert to lowercase and trim whitespace
                if (str) {
                    return str.toLowerCase().trim();
                }
            };

            var label = format(this.get('tradeLabel'));
            if (!label) {
                this.set('tradeError', 'Trade must be present');
                return;
            }

            var duplicate = this.get('model.trades').find(function (trade) {
                return format(trade.get('label')) === label;
            });

            if (duplicate) {
                this.set('tradeError', 'Trade must be unique');
                return;
            }

            return true;
        },

        actions: {
            submitTrade: function submitTrade() {
                var _this2 = this;

                if (this.validateLabel()) {
                    this.set('tradeError', null);

                    var trade = this.store.createRecord('trade', {
                        label: this.get('tradeLabel'),
                        account: this.get('model.account'),
                        vendorCount: 0
                    });
                    trade.save().then(function () {
                        _this2.set('tradeLabel', null);
                        _this2.get('flashMessages').success('Trade saved!');
                    });
                }
            },
            addTradeFromBase: function addTradeFromBase(bt) {
                var _this3 = this;

                var trade = this.store.createRecord('trade', {
                    label: bt.get('label'),
                    account: this.get('model.account'),
                    vendorCount: 0
                });

                trade.save().then(function () {
                    _this3.get('flashMessages').success('Trade saved!');
                });
            }
        }
    });
});