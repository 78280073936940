define('huyang/routes/settings/customization/request-labels/edit', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            return this.store.findRecord('request-label', params.req_label_id, { reload: true });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('account', this.modelFor('settings.customization.request-labels').account);
            controller.set('requestLabels', this.modelFor('settings.customization.request-labels').requestLabels);
        }
    });
});