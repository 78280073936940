define('huyang/controllers/login', ['exports', 'huyang-common/controllers/login'], function (exports, _login) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _login.default.extend({
        getLookupUrl: function getLookupUrl() {
            return '/api/building/email_lookup';
        }
    });
});