define('huyang/components/set-staff-info', ['exports', 'ember-validations', 'huyang-common/mixins/validates-email'], function (exports, _emberValidations, _validatesEmail) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_validatesEmail.default, {
        intlTelInput: Ember.inject.service(),
        session: Ember.inject.service(),

        buttonLabel: 'Update',
        selectedBuildings: [],
        user: Ember.computed.alias('model'),
        _emailValidations: Ember.computed('emailValidations', 'validateEmailExists', function () {
            var ev = Object.assign({}, this.get('emailValidations'));

            // Adding to the validations below can cause the property to persist
            // when it shouldn't, which is causing test failures (and theoretically
            // potential real-world problems too).
            delete ev.email;

            // validate email exists only on edit
            if (this.get('validateEmailExists')) {
                ev.email = { currentValue: this.get('email') };
            }

            return ev;
        }),

        fields: function () {
            var accountId = parseInt(this.get('session.data.authenticated.accountId'));
            var controller = this;
            var user = this.get('user');

            // Default hasAllAccess to true when creating new staff member
            var hasAllAccess = true;
            if (user) {
                hasAllAccess = user.hasRole(accountId, 'staff-all');
            }

            return [{
                section: {
                    label: 'Contact info',
                    icon: 'fa-user'
                },
                name: 'name',
                label: 'Staff member name',
                placeholder: 'George Costanza',
                validations: { presence: true },
                value: this.get('name')
            }, {
                name: 'email',
                type: 'email',
                label: 'Email address',
                placeholder: 'george@vandelay.com',
                validations: this.get('_emailValidations'),
                value: this.get('email')
            }, {
                name: 'mobilePhone',
                label: 'Mobile phone',
                component: 'huyang-form-phone',
                properties: Ember.copy({
                    phone: this.get('mobilePhone')
                }),
                validations: {
                    inline: (0, _emberValidations.validator)(function () {
                        var validation = controller.get('intlTelInput').validate();

                        var errors = {};
                        if (validation.error) {
                            errors.mobilePhone = validation.error;
                            this.errors.pushObject(errors.mobilePhone);
                        }
                    })
                }
            }, {
                name: 'buildingIds',
                section: {
                    label: 'Location access',
                    icon: 'fa-building-o'
                },
                placeholder: 'Empire State Building',
                locationSelect: true,
                required: true,
                selectId: 'userBuildingSelect',
                selectOptions: this.get('buildingSelectOptions'),
                hasAllAccess: hasAllAccess,
                items: this.get('buildings'),
                keyPath: 'id',
                selectedPath: 'selected',
                labelPath: 'name',
                validations: {
                    inline: (0, _emberValidations.validator)(function () {
                        // it's ok to have an admin user without buildings, or an all access pass
                        if (this.get('isAdmin') || this.get('hasAllAccess')) {
                            return;
                        }
                        var propName = this.property; // buildingIds
                        // this.model is the controller; get this field
                        var field = this.model.fields.find(function (f) {
                            return f.name === propName;
                        });
                        if (!field) {
                            // shouldn't happen
                            return;
                        }
                        // find the first selected item
                        var selected = field.items.find(function (item) {
                            return item.get(field.selectedPath);
                        });
                        if (!selected) {
                            this.errors.pushObject('should have at least one building');
                        }
                    })
                }
            }, {
                section: {
                    label: 'Notification preferences',
                    icon: 'fa-bell-o'
                },
                name: 'notifications',
                helpText: 'Set notification preferences for this staff member.',
                notificationTable: true,
                user: this.get('notificationsObject'),
                labels: {
                    header: 'Notify staff member when',
                    newRequest: 'New request is created',
                    assign: 'Request assigned',
                    comment: 'Comment is made on a followed request',
                    closed: 'Followed request is closed or re-opened',
                    due: 'Request is due soon',
                    product: 'New product features are available',
                    timer: 'Active timer is running at the end of the day'
                },
                notifications: ['new', 'assign', 'comment', 'closed', 'timer'],
                action: 'notificationUpdated'
            }, {
                section: {
                    label: 'Administrative access',
                    icon: 'fa-lock'
                },
                name: 'isAdmin',
                helpText: 'Administrators can create locations, add staff and occupants, and perform other admin functions.',
                checkbox: true,
                checkboxText: 'This user is an administrator',
                value: this.get('isAdmin')
            }];
        }.property('name', 'email', 'mobilePhone'),

        notificationsObject: function () {
            if (this.get('model')) {
                return this.get('model');
            }
            return Ember.Object.create({
                notifyNew: 1,
                notifyAssign: 1,
                notifyProduct: 1,
                notifyComment: 1,
                notifyClosed: 1,
                notifyTimer: 1
            });
        }.property('user'),

        buildingSelectOptions: {
            width: '100%'
        },

        setSelected: function () {
            var selected = this.get('selectedBuildings');

            this.setSelectedString();

            this.get('buildings').forEach(function (building) {
                building.set('selected', selected.includes(building.get('id')));
            });
        }.observes('selectedBuildings.[]'),

        setSelectedString: function setSelectedString() {
            // for change tracking
            this.set('selectedBuildingsString', this.get('selectedBuildings').sort().join(' '));
        },

        setupFields: function () {
            this.setSelected();
        }.on('init', 'didInsertElement'),

        teardown: function () {
            this.get('selectedBuildings').clear();
        }.on('willDestroyElement'),

        showError: function () {
            return this.get('isInvalid') && this.get('clickedSave');
        }.property('isInvalid', 'clickedSave'),

        commitButtonLabel: function () {
            return this.get('model') ? 'Update' : 'Create staff member';
        }.property('model'),

        actions: {
            notificationUpdated: function notificationUpdated(eventType, value) {
                this.set('notificationsObject.' + eventType, value);
            },

            accessUpdated: function accessUpdated(hasAllAccess) {
                this.set('hasAllAccess', hasAllAccess);
            },

            locationsUpdated: function locationsUpdated(evt, params, selectedValues) {
                this.set('selectedBuildings', selectedValues || []);
            },

            setAdmin: function setAdmin(value, checked) {
                this.set('isAdmin', checked);
            },

            save: function save(info, onComplete, onError) {
                info.buildingIds = this.get('selectedBuildings');
                info.hasAllAccess = this.get('hasAllAccess');
                info.mobilePhone = this.get('intlTelInput.iti').getNumber();
                if (!this.get('model')) {
                    var notify = this.get('notificationsObject');

                    Object.keys(notify).forEach(function (key) {
                        info[key] = notify.get(key);
                    });
                }

                this.sendAction('action', info, onComplete, onError);
            },

            cancel: function cancel() {
                this.sendAction('onCancel');
            }
        }
    });
});