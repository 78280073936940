define('huyang/controllers/settings/customization/cost-categories', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        columns: [{ key: 'label', label: 'Category name' }],

        activeCategories: Ember.computed.filterBy('model.categories', 'active', true),

        hasError: function () {
            return this.get('tradeError.length') > 0;
        }.property('tradeError'),

        validateLabel: function validateLabel() {
            var format = function format(str) {
                // Convert to lowercase and trim whitespace
                if (str) {
                    return str.toLowerCase().trim();
                }
            };

            var label = format(this.get('categoryLabel'));
            if (!label) {
                this.set('categoryError', 'Category must be present');
                return;
            }

            var duplicate = this.get('activeCategories').find(function (category) {
                return format(category.get('label')) === label;
            });

            if (duplicate) {
                this.set('categoryError', 'Category must be unique');
                return;
            }

            return true;
        },

        deleteMessage: '<p>Any costs that already belong to this category will continue to belong to this category. Deleting the category will make it unavailable for new costs.</p>' + '<p>To bring the category back in the future, simply create a new one with the same name.</p>' + '<p>Do you want to continue?</p>',

        actions: {
            submitCategory: function submitCategory() {
                var _this = this;

                if (this.validateLabel()) {
                    this.set('categoryError', null);

                    var category = this.store.createRecord('cost-category', {
                        label: this.get('categoryLabel'),
                        account: this.get('model.account'),
                        active: true,
                        canBeDeleted: true
                    });
                    category.save().then(function () {
                        _this.set('categoryLabel', null);
                        _this.get('flashMessages').success('Cost category saved!');
                    });
                }
            }
        }
    });
});