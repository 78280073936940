define('huyang/components/crosstab-tooltip', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['crosstab-tooltip'],
        attributeBindings: ['position:style'],

        categoryMap: {
            'building.id': 'in ',
            'owner.id': 'assigned to ',
            'request_type.id': 'of type ',
            'group.id': 'on behalf of ',
            'request_label.id': 'with label ',
            'category.id': 'of category '
        },

        categoryText: function categoryText(categoryId, category) {
            var quote = categoryId === 'request_label.id' ? '"' : '';

            return this.get('categoryMap')[categoryId] + ' ' + quote + category + quote;
        },

        text: Ember.computed('reportType', 'value', 'count', 'category1Id', 'category1', 'category2Id', 'category2', function txt() {
            var parts = [];
            var value = this.get('value');
            var count = this.get('count');
            var label = value === 1 ? 'request' : 'requests';
            var instances = count === 1 ? 'instance' : 'instances';
            var reportType = this.get('reportType');

            switch (reportType) {
                case 'request_open:count':
                    parts.push(value + ' opened ' + label);
                    break;
                case 'request_closed:count':
                    parts.push(value + ' closed ' + label);
                    break;
                case 'request_closed:average':
                    var closed = moment.duration(value, 'seconds');
                    parts.push(parseInt(closed.as('days')) + 'd ' + closed.hours() + 'h ' + closed.minutes() + 'm - average time to resolve requests');
                    break;
                case 'time_log:average':
                    var time = moment.duration(value, 'seconds');
                    parts.push(Math.round(time.asHours() * 10) / 10 + 'h - average time logged');
                    break;
                case 'cost_log:sum':
                    parts.push((0, _formatMoney.default)(value) + ' total cost');
                    break;
            }

            parts.push(this.categoryText(this.get('category1Id'), this.get('category1')) + ',');
            parts.push('' + this.categoryText(this.get('category2Id'), this.get('category2')));
            if (reportType === 'request_closed:average' || reportType === 'time_log:average') {
                parts.push('(' + count + ' ' + instances + ' in set)');
            }
            return parts.join(' ');
        }),

        position: Ember.computed('tooltipX', 'tooltipY', function position() {
            if (!this.get('tooltipX') || !this.get('tooltipY')) {
                return 'display: none';
            }

            return 'left: ' + (this.get('tooltipX') - 100) + 'px; top: ' + (this.get('tooltipY') + 5) + 'px;';
        })
    });
});