define('huyang/routes/requests/from-template', ['exports', 'huyang/routes/requests/request-filter-base'], function (exports, _requestFilterBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _requestFilterBase.default.extend({
        documentTitle: 'Repeating Requests',

        model: function model(params) {
            return Ember.RSVP.hash({
                requests: this.infinityModel('request', {
                    perPage: this.get('perPage'),
                    startingPage: 1,
                    modelPath: 'controller.requests',
                    template_id: params.template_id,
                    sortColumn: this.get('sortColumn'),
                    sortDirection: this.get('sortDirection')
                }),
                template: this.store.findRecord('request', params.template_id),
                viewConfig: this.store.queryRecord('view-config', { view_name: this.get('viewName') })
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('filter', 'from-template');
        }
    });
});