define('huyang/controllers/people/occupants/manage/users/filter-base', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        parentController: Ember.inject.controller('people/occupants/manage/users'),

        actions: {
            selectedOccupantsChanged: function selectedOccupantsChanged(occupants) {
                this.get('parentController').set('selectedOccupants', occupants);
            }
        }
    });
});