define('huyang/components/vendor-wizard', ['exports', 'huyang-common/mixins/creates-vendors'], function (exports, _createsVendors) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_createsVendors.default, {
        classNames: ['vendor-wizard', 'modal', 'fade'],
        intlTelInput: Ember.inject.service(),

        setup: function () {
            var _this = this;

            this.reset();

            // Add listener to focus email input field on every form open
            Ember.$('#' + this.get('id')).on('shown.bs.modal', function () {
                var field = document.querySelector('input[name="email"]');
                if (field) {
                    field.focus();
                }
            });

            // Add listener to reset whenever/however modal gets closed
            Ember.$('#' + this.get('id')).on('hidden.bs.modal', function () {
                _this.reset();
            });
        }.on('didInsertElement'),

        teardown: function () {
            Ember.$('#' + this.get('id')).off('shown.bs.modal');
            Ember.$('#' + this.get('id')).off('hidden.bs.modal');
        }.on('willDestroyElement'),

        reset: function reset() {
            this.set('vendor', {});
            this.set('slide', 'email');
        },

        onEmail: Ember.computed.equal('slide', 'email'),
        onSearching: Ember.computed.equal('slide', 'searching'),
        onResults: Ember.computed.equal('slide', 'results'),
        onTrade: Ember.computed.equal('slide', 'trade'),
        onForm: Ember.computed.equal('slide', 'form'),
        onSaving: Ember.computed.equal('slide', 'saving'),
        onSaved: Ember.computed.equal('slide', 'saved'),
        onExists: Ember.computed.equal('slide', 'exists'),

        emailField: function () {
            return [{
                name: 'email',
                value: this.get('vendor.email'),
                placeholder: 'service@acmeplumbing.com',
                validations: this.emailValidations
            }];
        }.property('vendor.email'),

        actions: {
            submitEmail: function submitEmail(values) {
                var _this2 = this;

                if (this.get('vendors').findBy('primaryUser.email', values.email)) {
                    // email belongs to an existing vendor
                    this.set('slide', 'exists');
                } else {
                    this.set('slide', 'searching');
                    this.set('vendor.email', values.email);

                    this.lookupEmail(values.email).then(function () {
                        Ember.run(function () {
                            // successful lookup
                            if (_this2.get('vendor.name')) {
                                _this2.set('slide', 'results');
                            } else {
                                _this2.set('slide', 'form');
                            }
                        });
                    }, function () {
                        // had error
                        Ember.run(function () {
                            _this2.set('slide', 'form');
                        });
                    });
                }
            },

            goToForm: function goToForm() {
                // Search results are rejected, so clear all attributes except for email
                this.set('vendor', { email: this.get('vendor.email') });
                this.set('slide', 'form');
            },

            verifySearch: function verifySearch() {
                // Set rest of company information once verified
                var company = this.get('vendor.searchResults.company');

                if (company.geo.streetNumber && company.geo.streetName) {
                    this.set('vendor.address1', company.geo.streetNumber + ' ' + company.geo.streetName);
                }

                this.set('vendor.address2', company.geo.subPremise);
                this.set('vendor.city', company.geo.city);
                this.set('vendor.addressState', company.geo.stateCode);
                this.set('vendor.zipCode', company.geo.postalCode);

                this.set('slide', 'trade');
            },

            submitTrade: function submitTrade() {
                this.send('submitVendor', this.get('vendor'));
            },

            submitVendor: function submitVendor(values) {
                var _this3 = this;

                this.set('slide', 'saving');

                this.set('vendor', {
                    name: values.name,
                    email: values.email,
                    phone: this.get('intlTelInput.iti') ? this.get('intlTelInput.iti').getNumber() : '',
                    trade: this.get('tradeId')
                });

                this.saveVendor(this.get('vendor')).then(function (v) {
                    _this3.set('slide', 'saved');
                    _this3.set('storedVendor', v);
                });
            },

            cancel: function cancel() {
                this.sendAction('cancel');
            },

            tradeChanged: function tradeChanged(values) {
                if (values.trade) {
                    this.set('tradeId', values.trade);
                }
            },

            goToVendorPage: function goToVendorPage(pageName) {
                this.sendAction('goToVendorPage', pageName, this.get('storedVendor.id'));
            },

            reset: function reset() {
                this.reset();
            }
        }
    });
});