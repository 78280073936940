define('huyang/routes/settings/index', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        beforeModel: function beforeModel() {
            var _this = this;

            // send admins to settings.organization, non-admins to settings.user
            // is there a better way to do this, without these nested promises?

            this.get('session.currentAccount').then(function (account) {
                _this.get('session.currentUser').then(function (user) {
                    if (user.hasRole(account.get('id'), 'admin')) {
                        _this.transitionTo('settings.organization');
                    } else {
                        _this.transitionTo('settings.user');
                    }
                });
            });
        }
    });
});