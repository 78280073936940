define('huyang/components/request-table-comment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'request-table-comment',
    classNameBindings: ['commentCollapsed'],
    commentCollapsed: Ember.computed.not('commentExpanded'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // this feels a bit flimsy: if the height of this component is equal to the
      // css max-height, it assumes the comment is clipped and needs the expander
      var $text = this.$('.request-table-comment-text');

      if ($text.outerHeight() + 'px' === $text.css('max-height')) {
        this.set('showExpander', true);
      }
    },


    fullText: Ember.computed('item', 'key', function () {
      var item = this.get('item');
      var key = this.get('key');
      var text = item.get(key);

      if (text) {
        // max out preview at 2k characters (arbitrary number)
        var maxTextLength = 2000;

        if (text.length > maxTextLength) {
          return text.substring(0, maxTextLength) + '...';
        }

        return text;
      }

      return '';
    }),

    upstreamCommentsExpandedObserver: Ember.observer('commentsExpanded', function () {
      this.set('commentExpanded', this.get('commentsExpanded'));
    }),

    actions: {
      toggleExpander: function toggleExpander() {
        this.set('commentExpanded', !this.get('commentExpanded'));
      }
    }
  });
});