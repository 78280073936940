define('huyang/controllers/requests/filters/filter', ['exports', 'huyang-common/mixins/modal-helper', 'huyang/mixins/request-list', 'huyang/mixins/saves-view-config', 'huyang-common/mixins/sorts-multiple-columns'], function (exports, _modalHelper, _requestList, _savesViewConfig, _sortsMultipleColumns) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    exports.default = Ember.Controller.extend(_requestList.default, _modalHelper.default, _savesViewConfig.default, _sortsMultipleColumns.default, {
        viewConfig: Ember.computed.alias('model.viewConfig'),
        session: Ember.inject.service(),

        queryParams: ['dateRange', 'dueDateRange', 'building', 'subspace', 'owner', 'originator', 'priority', 'requestType', 'requestLabel', 'status', 'group', 'recurring', 'buildingAll', 'dueDateRangeAll', 'subspaceAll', 'ownerAll', 'originatorAll', 'priorityAll', 'requestTypeAll', 'requestLabelAll', 'statusAll', 'groupAll', 'recurringAll', 'sort', 'from'],

        from: 0,
        timeframe: '',
        startDate: '',
        endDate: '',

        // default values for query params; needed to reset in navbar links
        dateRange: '',
        dueDateRange: '',

        sort: '',

        building: '',
        subspace: '',
        owner: '',
        originator: '',
        priority: '',
        requestType: '',
        requestLabel: '',
        status: '',
        group: '',
        recurring: '',

        buildingAll: '',
        dueDateRangeAll: '',
        subspaceAll: '',
        ownerAll: '',
        originatorAll: '',
        priorityAll: '',
        requestTypeAll: '',
        requestLabelAll: '',
        statusAll: '',
        groupAll: '',
        recurringAll: '',

        filter: Ember.computed.alias('model.filter'),
        results: Ember.computed.alias('model.results'),
        requests: Ember.computed.alias('model.results'),
        requestsController: Ember.inject.controller('requests'),
        routeName: 'requests.filters.filter',

        routeModelId: function () {
            return this.get('filter.id');
        }.property('filter.id'),

        resultsLabel: function () {
            var label = this.get('results.length') === 1 ? 'request that matches' : 'requests that match';
            return 'We found ' + this.get('total') + ' ' + label + ' your filters';
        }.property('total'),

        filterName: function () {
            return this.get('filter.id') === 'new' ? 'New view' : this.get('filter.name');
        }.property('filter.id', 'filter.name'),

        isNew: function () {
            return this.get('filter.id') === 'new';
        }.property('filter.id'),

        editableName: function () {
            return this.get('filter.id') === 'new' || this.get('isEdit');
        }.property('filter.id', 'isEdit'),

        hasMore: function () {
            return this.get('results.length') < this.get('total');
        }.property('results.length', 'total'),

        notShown: function () {
            return this.get('total') - this.get('results.length');
        }.property('results.length', 'total'),

        filters: function () {
            var _this = this;

            var filters = {};
            // multi value
            ['building', 'subspace', 'owner', 'originator', 'priority', 'requestType', 'requestLabel', 'status', 'group', 'recurring'].forEach(function (filter) {
                var queryFilter = _this.get(filter);
                if (queryFilter) {
                    filters[filter] = queryFilter.split(',');
                } else if (_this.get(filter + 'All')) {
                    filters[filter] = [];
                } else {
                    filters[filter] = _this.get('filter.' + filter) || [];
                }
            });
            // single value
            ['dateRange', 'dueDateRange'].forEach(function (filter) {
                var queryFilter = _this.get(filter);
                if (queryFilter) {
                    filters[filter] = queryFilter;
                } else {
                    filters[filter] = _this.get('filter.' + filter) || '';
                }
            });
            if (this.get('dueDateRangeAll')) {
                filters.dueDateRange = [];
            }
            return filters;
        }.property('filter', 'dateRange', 'dueDateRange', 'dueDateRangeAll', 'building', 'buildingAll', 'subspace', 'subspaceAll', 'owner', 'ownerAll', 'originator', 'originatorAll', 'priority', 'priorityAll', 'requestType', 'requestTypeAll', 'requestLabel', 'requestLabelAll', 'status', 'statusAll', 'group', 'groupAll', 'recurring', 'recurringAll'),

        creators: function () {
            var accountId = this.get('session.data.authenticated.accountId');

            return this.get('model.users').filter(function (user) {
                return user.hasAnyRole(accountId, ['staff', 'staff-all', 'vendor', 'vendor_technician', 'tenant']);
            });
        }.property('model.users'),

        owners: function () {
            var accountId = this.get('session.data.authenticated.accountId');

            return this.get('model.users').filter(function (user) {
                return user.hasAnyRole(accountId, ['staff', 'staff-all', 'vendor', 'vendor_technician']);
            });
        }.property('model.users'),

        nameInputClassNames: function () {
            return this.get('hasError') ? 'form-control has-error' : 'form-control';
        }.property('hasError'),

        clearQueryParams: function clearQueryParams() {
            var _this2 = this;

            this.get('queryParams').forEach(function (param) {
                _this2.set(param, '');
            });
        },

        emptyQueryParams: function () {
            var params = {};
            this.get('queryParams').forEach(function (param) {
                params[param] = '';
            });
            return params;
        }.property(),

        activeRequestTypes: function () {
            return this.get('model.requestTypes').filterBy('active');
        }.property('model.requestTypes'),

        sortedFilters: function () {
            return this.get('requestsController.sortedFilters');
        }.property(),

        availableBuildings: function () {
            // exclude inactive buildings unless they're in the filter
            var buildingIds = this.get('filter.building') || [];
            return this.get('model.buildings').filter(function (building) {
                return building.get('active') || buildingIds.indexOf(parseInt(building.get('id'))) >= 0;
            });
        }.property('model.buildings'),

        buildQueryParams: function buildQueryParams(filters) {
            var params = {};
            // serialize filter to query params
            this.get('queryParams').forEach(function (param) {
                if (param.indexOf('All') >= 0) {
                    return;
                }
                var values = filters[param];
                if (values && values.length) {
                    params[param] = values;
                } else {
                    params[param] = ''; // have to do this to clear it in the URL
                }
                params[param + 'All'] = !values || !values.length ? 'true' : '';
            });
            // [{'field1': {'order': 'asc'}}, {'field2': {'order': 'desc'}}] =>
            // sort=field1:asc,field2:desc
            var fields = (this.get('filter.sort') || []).map(function (field) {
                var name = Object.keys(field)[0];
                return name + ':' + field[name].order;
            });
            params.sort = fields.join(',');
            return params;
        },

        refilter: function refilter(filters) {
            var params = this.buildQueryParams(filters);
            this.transitionToRoute('requests.filters.filter', this.get('filter.id'), { queryParams: params });
        },

        sortColumn: function () {
            if (!this.get('filter.sort.length')) {
                return null;
            }
            return Object.keys(this.get('filter.sort')[0])[0];
        }.property('filter.sort.[]'),

        sortDirection: function () {
            if (!this.get('filter.sort.length')) {
                return null;
            }
            var sort = this.get('filter.sort')[0];
            return sort[Object.keys(sort)[0]].order;
        }.property('filter.sort.[]'),

        downloadUrl: function () {
            var params = this.buildQueryParams(this.get('filters'));
            var queryParams = Object.keys(params).map(function (key) {
                return key + '=' + encodeURIComponent(params[key]);
            }).join('&');
            // add id if it exists
            if (this.get('filter.id')) {
                queryParams += '&filter_id=' + this.get('filter.id');
            }
            var url = this.store.adapterFor('filter-search-result').urlForQuery(params, 'filter-search-result');
            return url + '/csv?' + queryParams;
        }.property('queryParams', 'filters'),

        actions: {
            saveOnEnter: function saveOnEnter() {
                this.set('sendSave', true);
            },
            editNameClicked: function editNameClicked() {
                this.set('sendSave', false);
                this.openModal('editNameModal');

                setTimeout(function () {
                    return Ember.$('#editNameModal input').select();
                }, 400);
            },

            renameView: function renameView() {
                var _this3 = this;

                if (this.get('filter.name')) {
                    this.closeModal();
                    this.get('filter').save().then(function () {
                        return _this3.get('flashMessages').success('View renamed!');
                    });
                } else {
                    this.get('flashMessages').danger('Name can\'t be blank');
                    this.get('filter').rollbackAttributes();
                }
            },

            cancelRenameView: function cancelRenameView() {
                this.get('filter').rollbackAttributes();
            },

            refreshFilters: function refreshFilters(filters) {
                this.refilter(filters);
            },

            showSaveModal: function showSaveModal() {
                this.set('filter.name', 'New view');
                this.set('sendSave', false);
                this.openModal('confirmSave');

                setTimeout(function () {
                    return Ember.$('#confirmSave input').select();
                }, 400);
            },

            saveButtonClicked: function saveButtonClicked() {
                var _this4 = this;

                this.closeModal();

                if (!this.get('filter.name')) {
                    this.set('hasError', true);
                    return;
                }

                this.set('hasError', false);
                this.set('isLoading', true);

                var filters = this.get('filters');
                filters.name = this.get('filter.name');
                filters.account = localStorage.getItem('accountId');

                var filter = this.store.createRecord('filter', filters);

                filter.save().then(function (f) {
                    var view = _this4.get('viewConfig');
                    if (view) {
                        view.set('viewName', f.get('id'));
                        return view.save();
                    }
                }).then(function () {
                    _this4.set('isLoading', false);
                    _this4.get('flashMessages').success('View saved!');

                    // might take up to 1s for new item to be searchable so set it
                    // from route action
                    _this4.set('newFilterId', filter.get('id'));
                    _this4.set('newFilterCount', _this4.get('total'));
                    _this4.transitionToRoute('requests.filters.filter', filter.get('id'), { queryParams: _this4.get('emptyQueryParams') });
                });
            },

            updateButtonClicked: function updateButtonClicked() {
                var _this5 = this;

                if (!this.get('filter.name')) {
                    this.set('hasError', true);
                    return;
                }
                this.set('hasError', false);
                this.set('isLoading', true);
                this.set('isEdit', false);
                var filter = this.get('filter');
                Object.keys(this.get('filters')).forEach(function (filterName) {
                    filter.set(filterName, _this5.get('filters.' + filterName));
                });
                filter.save().then(function () {
                    _this5.set('isLoading', false);
                    _this5.get('flashMessages').success('View saved!');
                    _this5.clearQueryParams();
                    Ember.run.later(function () {
                        return _this5.get('requestsController').loadRequestCounts();
                    }, 500);
                });
            },

            confirmDelete: function confirmDelete() {
                var _this6 = this;

                this.closeModal();
                this.get('filter').destroyRecord().then(function () {
                    _this6.transitionToRoute('requests');
                });
            },

            deleteButtonClicked: function deleteButtonClicked() {
                this.set('confirmDeleteTitle', 'Delete ' + this.get('filter.name') + '?');
                this.set('sendSave', false);
                this.openModal('confirmDelete');
            },

            saveColumns: function saveColumns() {
                var cols = this.get('orderedColumns');
                var selected = cols.filter(function (col) {
                    return col.selected;
                });
                this.saveViewConfig(selected.map(function (col) {
                    return col.key;
                }), this.get('model.filter.id'));
            },

            sortBy: function sortBy(sortColumn, addToSort) {
                // add to sortColumns attribute, which manages the appearance of the table header columns
                this.sortByMultiple(sortColumn, addToSort);

                var filter = this.get('filter');

                // convert the sortColumns attribute into the format that search prefers
                var sort = this.get('sortColumns').map(function (sc) {
                    var _sc = _slicedToArray(sc, 2),
                        name = _sc[0],
                        direction = _sc[1];

                    var sortDef = {};

                    sortDef[name] = { order: direction };

                    return sortDef;
                });

                filter.set('sort', sort);

                // don't save if it's new
                if (this.get('filter.id') !== 'new') {
                    filter.save();
                }

                var filters = this.get('filters');

                filters.sort = filter.get('sort');
                this.refilter(filters);
            }
        }
    });
});