define('huyang/routes/people/occupants/manage', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model() {
            return Ember.RSVP.hash({
                account: this.store.findRecord('account', localStorage.getItem('accountId'))
            });
        }
    });
});