define('huyang/components/inline-editable-table', ['exports', 'huyang-common/mixins/modal-helper'], function (exports, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_modalHelper.default, {
        classNames: ['inline-editable-table'],

        labelProperty: function () {
            if (this.get('items.firstObject.title')) {
                return 'title';
            }

            return 'label';
        }.property('items.[]'),

        confirmDeleteTitle: function () {
            var label = this.get('deletedItem.' + this.get('labelProperty'));
            return 'Delete \'' + label + '\'';
        }.property('deletedItem'),

        sortedItems: function () {
            return this.get('items').sortBy(this.get('labelProperty'));
        }.property('items.@each.label'),

        actions: {
            editItem: function editItem(item) {
                if (this.get('editedItem')) {
                    this.get('editedItem').set('isEditing', false);
                }

                this.set('editedItem', item);
                this.set('editedItemLabel', item.get(this.get('labelProperty')));
                item.set('isEditing', true);
            },

            cancelEdit: function cancelEdit(item) {
                this.set('editedItem', null);
                item.set('isEditing', false);
                item.set('error', null);
                item.rollbackAttributes();
            },

            saveEdit: function saveEdit() {
                var _this = this;

                if (this.validateItem()) {
                    var item = this.get('editedItem');

                    item.set(this.get('labelProperty'), this.get('editedItemLabel'));
                    item.save().then(function () {
                        _this.get('flashMessages').success(item.get(_this.get('labelProperty')) + ' saved!');
                        item.set('isEditing', false);
                    });
                }
            },

            deleteItem: function deleteItem(item) {
                this.set('deletedItem', item);
                this.openModal('confirmDeleteItem');
            },

            confirmDeleteItem: function confirmDeleteItem() {
                var _this2 = this;

                var item = this.get('deletedItem');

                if (this.get('deleteAction')) {
                    this.sendAction('deleteAction', item);
                    this.set('deletedItem', null);
                    this.get('flashMessages').success('\'' + item.get(this.get('labelProperty')) + '\' has been deleted!');
                } else {
                    item.destroyRecord().then(function () {
                        _this2.set('deletedItem', null);
                        _this2.get('flashMessages').success('\'' + item.get(_this2.get('labelProperty')) + '\' has been deleted!');
                    });
                }
            }
        },

        // actions helpers

        validateItem: function validateItem() {
            var _this3 = this;

            var format = function format(str) {
                // Convert to lowercase and trim whitespace
                if (str) {
                    return str.toLowerCase().trim();
                }
            };

            var item = this.get('editedItem');
            var label = format(this.get('editedItemLabel'));

            if (!label) {
                item.set('error', 'Must be present');
                return;
            }

            var duplicate = this.get('items').find(function (t) {
                return t.get('id') !== item.get('id') && format(t.get(_this3.get('labelProperty'))) === label;
            });

            if (duplicate) {
                item.set('error', 'Must be unique');
                return;
            }

            return true;
        }
    });
});