define('huyang/components/location-select-menu', ['exports', 'huyang/components/popover-menu'], function (exports, _popoverMenu) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _popoverMenu.default.extend({
        elementSelector: '.location-select-menu',
        targetSelector: '.locations-button',
        attachment: 'center left',
        targetAttachment: 'center right',
        targetOffset: '0 10px',
        menuPosition: 'right',
        buttonSelector: '.locations-button',

        buildingsSortDef: ['name:asc'],
        buildingsSorted: Ember.computed.sort('buildings', 'buildingsSortDef'),

        propertiesToSend: ['selectedBuildings'],

        watchForChanges: function () {
            Ember.run.once(this, 'notifyOfChanges');
        }.observes('selectedBuildings.[]').on('init'),

        setupBuildings: function () {
            var selectedBuildingIds = this.get('selectedBuildings').map(function (b) {
                return b.get('id');
            });

            this.get('buildings').forEach(function (b) {
                b.set('selected', selectedBuildingIds.includes(b.get('id')));
            });
        }.on('didInsertElement'),

        determineMenuPosition: function () {
            var CUTOFF_WIDTH = 640;

            if (Ember.$(window).width() <= CUTOFF_WIDTH) {
                this.set('attachment', 'top center');
                this.set('targetAttachment', 'bottom center');
                this.set('targetOffset', '10px 0');
                this.set('menuPosition', 'bottom');
            }
        }.on('didInsertElement'),

        actions: {
            toggleBuilding: function toggleBuilding(building) {
                building.toggleProperty('selected', true);
            },
            selectAll: function selectAll() {
                this.get('buildings').forEach(function (b) {
                    return b.set('selected', true);
                });
            },
            selectNone: function selectNone() {
                this.get('buildings').forEach(function (b) {
                    return b.set('selected', false);
                });
            },
            done: function done() {
                var selectedBuildings = this.get('buildings').filterBy('selected');

                this.set('selectedBuildings', selectedBuildings);
                // this.sendAction('locationsUpdated', selectedBuildings);

                this.toggleMenu();
            }
        }
    });
});