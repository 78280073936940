define('huyang/mixins/validate-custom-fields', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    validateCategoryCustomFieldEdit: function validateCategoryCustomFieldEdit(field) {
      if (!field.get('name') || !field.get('type')) {
        this.get('flashMessages').danger('Field name and type are required.');
        return false;
      }

      if (field.get('isList')) {
        var removedBlanks = field.get('listItems').filter(function (dv) {
          if (dv.string) {
            return dv;
          }
        });

        // don't allow a list with no items
        if (!removedBlanks.get('length')) {
          this.get('flashMessages').danger('At least one list option is required.');
          return false;
        }

        field.set('listItems', removedBlanks);
      }

      return true;
    },
    validateCustomFields: function validateCustomFields(customFields) {
      var errors = [];
      customFields.forEach(function (cf) {
        var isRequired = cf.get('assetCategoryCustomField.required');
        var isCheckbox = cf.get('assetCategoryCustomField.isCheckbox');
        if (isRequired && !cf.get('value') && !isCheckbox) {
          errors.push(cf.get('assetCategoryCustomField.name'));
        }
      });

      return errors;
    }
  });
});