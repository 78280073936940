define('huyang/controllers/settings/customization/request-types/edit', ['exports', 'huyang-common/mixins/modal-helper'], function (exports, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalHelper.default, {
        selectedBuildings: function () {
            return this.get('model.requestType.requestTypeBuildings').map(function (rtb) {
                return rtb.get('building');
            });
        }.property('model.requestType.requestTypeBuildings.[]'),

        hasRequests: function () {
            var counts = this.get('requestCounts');
            return counts.open + counts.closed > 0;
        }.property('requestCounts'),

        requestCountsDescription: function () {
            var counts = this.get('requestCounts');
            var desc = '';

            if (!counts.open && !counts.closed) {
                return '';
            }

            // x of them is/are currently open.
            if (counts.open > 0) {
                if (counts.open === 1) {
                    desc += '1 open request has this request type.';
                } else {
                    desc += counts.open + ' open requests have this request type.';
                }
            }

            return desc;
        }.property('requestCounts'),

        actions: {
            save: function save(info, callback /*, onError*/) {
                var _this = this;

                var reqType = this.get('model.requestType');
                reqType.set('label', info.label);
                reqType.set('iconClass', info.iconClass);
                reqType.set('isDefault', info.isDefault);
                reqType.save().then(function (rt) {
                    // add to buildings if specified
                    var buildingIds = info.buildingIds || [];
                    rt.setBuildings(buildingIds).then(function () {
                        _this.get('flashMessages').success('Request type saved!');
                        if (Ember.typeOf(callback) === 'function') {
                            callback();
                        }
                    });
                });
            },

            cancel: function cancel() {
                this.get('model.requestType').rollbackAttributes();
                this.transitionToRoute('settings.customization.request-types');
            },

            confirmDelete: function confirmDelete() {
                this.openModal('confirmDelete');
            },

            doDelete: function doDelete() {
                var _this2 = this;

                this.closeModal();
                var reqType = this.get('model.requestType');
                if (this.get('hasRequests')) {
                    // has requests: inactivate
                    reqType.set('active', false);
                    reqType.save().then(function () {
                        _this2.transitionToRoute('settings.customization.request-types');
                    });
                } else {
                    // no requests: delete
                    reqType.destroyRecord().then(function () {
                        _this2.transitionToRoute('settings.customization.request-types');
                    });
                }
            }
        }
    });
});