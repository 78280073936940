define('huyang/components/time-log-form', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    exports.default = Ember.Component.extend({
        classNames: ['time-log-form'],
        session: Ember.inject.service(),

        datePickerOptions: computed(function () {
            return {
                inline: true,
                sideBySide: false,
                minDate: moment(this.get('request.created')).subtract(1, 'month'),
                maxDate: moment(),
                defaultDate: this.get('log.endTime')
            };
        }),

        workerDropdownId: computed('log.id', function () {
            return 'workerDropdown' + this.get('log.id');
        }),

        endTime: computed('log.endTime', function () {
            return moment(this.get('log.endTime')).format('ddd M/D/YYYY h:mm a');
        }),

        workerName: computed('log.worker', function () {
            return this.get('log.worker.displayName');
        }),

        filteredStaff: computed('staffFilter', 'staff.@each', function () {
            var filter = (this.get('staffFilter') || '').toLowerCase();

            return this.get('staff').filter(function (user) {
                return user.get('name').toLowerCase().indexOf(filter) > -1;
            }).sortBy('name');
        }),

        showStaffSelection: computed(function () {
            return !this.get('log.timer') && this.get('session.currentUser.isAdmin') && this.get('staff.length');
        }),

        setManuallyTimed: function setManuallyTimed() {
            this.get('log').set('manualTime', true);
        },


        actions: {
            updateEndTime: function updateEndTime(mmt) {
                this.get('log').set('endTime', mmt.toDate());
            },
            selectWorker: function selectWorker(worker) {
                this.get('log').set('worker', worker);
            },
            updateHours: function updateHours() {
                this.setManuallyTimed();
            },
            updateMinutes: function updateMinutes() {
                this.setManuallyTimed();

                // Hours are validated based on both hours and minutes fields, so it checks both when changed.
                // When minutes are changed, need to trigger a change in hours to get both validated. Could
                // make minutes check both fields as well, but then there would be duplicate error messages
                var log = this.get('log');

                log.set('hours', log.get('hours'));
            }
        }
    });
});