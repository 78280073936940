define('huyang/controllers/requests/request/info/edit', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        requestsController: Ember.inject.controller('requests'),
        requestTypes: Ember.computed.alias('requestsController.model.requestTypes'),
        requestLabels: Ember.computed.alias('requestsController.model.requestLabels'),

        staffBuildings: function () {
            var accountId = localStorage.getItem('accountId');
            var user = this.store.peekRecord('user', localStorage.getItem('userId'));
            var buildings = this.get('requestsController.model.buildings');

            // Don't filter buildings for users with all-access
            if (user.hasRole(accountId, 'staff-all')) {
                return buildings;
            }

            var bids = user.get('staffBuildingIds');
            return buildings.filter(function (building) {
                return building.get('active') && bids.includes(building.get('id'));
            });
        }.property('requestsController.model.buildings'),

        actions: {
            requestSaved: function requestSaved() {
                this.get('flashMessages').success('Request saved!');
                this.send('leaveRoute');
            },
            cancelEdit: function cancelEdit() {
                this.get('model').rollbackAttributes();
                this.send('leaveRoute');
            },
            setStatus: function setStatus(status) {
                console.log('setStatus to ', status);
            }
        }
    });
});