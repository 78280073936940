define('huyang/routes/reports/saved/crosstab', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            return this.store.findRecord('report-query', params.report_id);
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('keenQueryString', '');
            controller.createQuery();
        }
    });
});