define('huyang/components/occupant-location-access', ['exports', 'huyang/components/dynamic-component'], function (exports, _dynamicComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _dynamicComponent.default.extend({
        accessType: 'all',
        selectedGroup: 0,

        setup: function () {
            // Set as empty arrays on initialization to prevent bug where
            // they weren't clearing when navigating away and back
            this.set('removedLocations', []);
            this.set('addedLocations', []);
        }.on('init'),

        loaded: function () {
            // convert from selectedLocations param to selectedBuildings
            if (!this.get('selectedLocations')) {
                return;
            }
            var buildingIds = [];

            this.get('selectedLocations').forEach(function (location) {
                var bid = location.building;

                if (buildingIds.indexOf(bid) < 0) {
                    buildingIds.push(bid);
                }
            });
            var selectedBuildings = [];

            this.get('buildings').forEach(function (building) {
                if (buildingIds.indexOf(building.get('id')) >= 0) {
                    selectedBuildings.push(building);
                }
            });
            this.set('selectedBuildings', selectedBuildings);
        }.on('didInsertElement'),

        /*
            make sure these are also observed below in "watch for changes."
            these are the properties that will get bubbled back up to parent components
            whenever they are updated.
        */
        propertiesToSend: ['accessType', 'selectedGroupObject', 'selectedBuildings', 'addedLocations', 'removedLocations'],

        isAll: Ember.computed.equal('accessType', 'all'),
        isGroup: Ember.computed.equal('accessType', 'group'),
        isSpecific: Ember.computed.equal('accessType', 'specific'),

        watchForChanges: function () {
            // this.notifyOfChanges is part of DynamicComponent
            Ember.run.once(this, 'notifyOfChanges');
        }.observes('accessType', 'selectedGroupObject', 'selectedBuildings.[]', 'addedLocations.[]', 'removedLocations.[]').on('init'),

        actions: {
            radioChanged: function radioChanged(value) {
                this.set('accessType', value);
            },

            groupSelectChanged: function groupSelectChanged() {
                var group = this.get('groups').findBy('id', this.get('selectedGroup'));
                this.set('selectedGroupObject', group);
            },

            locationsUpdated: function locationsUpdated(buildings) {
                var selectedBuildings = buildings.filterBy('selected');
                this.set('selectedBuildings', selectedBuildings);
            },

            locationAdded: function locationAdded(building, subspace) {
                // Before the location can be flagged for creation, it must be checked
                // to see if it already exists, or if it already exists and was marked
                // for removal.. In latter case, it must be un-flagged for removal,
                // rather than flagged for creation

                var subspaceId = subspace ? subspace.get('id') : undefined,
                    toRemove = this.get('removedLocations'),
                    indexToRemove = void 0;

                for (var i = 0; i < toRemove.length; i++) {
                    var b = toRemove[i],
                        bSubspaceId = b.subspace ? b.subspace.get('id') : undefined;

                    if (b.building.get('id') === building.get('id') && bSubspaceId === subspaceId) {
                        indexToRemove = i;
                        break;
                    }
                }

                // Un-flag for removal if present in array
                if (indexToRemove !== undefined) {
                    toRemove.splice(indexToRemove, 1);
                    return;
                }

                // Do nothing if already exists
                var alreadyExists = this.get('selectedLocations').find(function (l) {
                    // l => { building: "1000" } or { building: "1000", subspace: "1023" }, etc
                    return l.building === building.get('id') && l.subspace === subspaceId;
                });
                if (alreadyExists) {
                    return;
                }

                // Do nothing if already flagged for creation
                // Important since the button can be spammed
                var alreadyAdded = this.get('addedLocations').find(function (b) {
                    return b.building.get('id') === building.get('id') && (b.subspace ? b.subspace.get('id') : undefined) === subspaceId;
                });
                if (alreadyAdded) {
                    return;
                }

                // Flag for creation
                this.get('addedLocations').push({ building: building, subspace: subspace });
            },

            locationRemoved: function locationRemoved(building, subspace) {
                // Before the location can be flagged for removal, it must be checked
                // to see if it was marked for creation.. If so, un-flag for creation,
                // rather than flag for removal

                var subspaceId = subspace ? subspace.get('id') : undefined,
                    toAdd = this.get('addedLocations'),
                    indexToRemove = void 0;

                for (var i = 0; i < toAdd.length; i++) {
                    var b = toAdd[i],
                        bSubspaceId = b.subspace ? b.subspace.get('id') : undefined;

                    if (b.building.get('id') === building.get('id') && bSubspaceId === subspaceId) {
                        indexToRemove = i;
                        break;
                    }
                }

                // Un-flag for creation if present in array
                if (indexToRemove !== undefined) {
                    toAdd.splice(indexToRemove, 1);
                    return;
                }

                // Flag for removal
                this.get('removedLocations').push({ building: building, subspace: subspace });
            }
        }
    });
});