define('huyang/controllers/settings/organization/occupants', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        isLoading: false,
        originalApprovers: [],

        fields: function () {
            var oc = this.get('model.account.occupantConfig');

            return [{
                name: 'occupantConfig',
                section: { label: 'Creating new occupants' },
                component: 'occupant-signup-settings',
                properties: {
                    signupType: oc.auto_create ? 'self' : 'staff',
                    approvalRequired: oc.approval_required,
                    approvedDomains: oc.approved_domains,
                    hasCustomApprovers: oc.custom_approvers_list,
                    users: this.get('model.staff'),
                    approvers: Ember.copy(this.get('model.approvers')),
                    createFromEmail: oc.create_from_email
                },
                action: 'occupantConfigChanged'
            }, {
                section: { label: 'Occupant settings' },
                checkbox: true,
                checkboxText: 'Allow occupants to view and set priority of requests',
                checkboxHelp: 'If checked, occupants can indicate whether priority of a request is high, low, etc. Otherwise, only staff can see or set this field.',
                name: 'priorityAllowed',
                value: oc.priority_allowed ? oc.priority_allowed : false
            }, {
                checkbox: true,
                checkboxText: 'Allow occupants to view and set due date of requests',
                checkboxHelp: 'If checked, occupants can set the due date of the request. Otherwise, only staff can see or set this field.',
                name: 'dueDateAllowed',
                value: oc.due_date_allowed ? oc.due_date_allowed : false
            }];
        }.property('model'),

        actions: {
            occupantConfigChanged: function occupantConfigChanged(config) {
                this.set('occupantConfig', config);
            },
            saveSettings: function saveSettings(fv, onComplete, onError) {
                var _this = this;

                var account = this.get('model.account');
                var oc = this.get('occupantConfig');

                oc.createFromEmail = oc.autoCreate && oc.createFromEmail;
                account.set('occupantConfig', {
                    auto_create: oc.autoCreate,
                    approval_required: oc.approvalRequired,
                    approved_domains: oc.approvalRequired ? oc.approvedDomains : '',
                    custom_approvers_list: oc.hasCustomApprovers,
                    priority_allowed: fv.priorityAllowed,
                    due_date_allowed: fv.dueDateAllowed,
                    create_from_email: oc.createFromEmail
                });

                account.save().then(function () {
                    if (oc.approvers) {
                        // add approver roles for these users
                        var promises = [];
                        console.log(_this.get('model.approvers.length'));

                        // remove the role from any existing user roles that were removed
                        var removeables = _this.get('originalApprovers').filter(function (approver) {
                            return !oc.approvers.includes(approver);
                        });

                        console.log('removeables', removeables);

                        if (removeables) {
                            removeables.forEach(function (user) {
                                promises.push(user.removeRole(account, 'occupant_approver'));
                            });
                        }

                        oc.approvers.forEach(function (user) {
                            promises.push(user.addRole(account, 'occupant_approver'));
                        });

                        // todo: improve this by creating mass role creation/deletion endpoints
                        // this could send a lot of requests at the moment
                        Ember.RSVP.all(promises).then(onComplete);
                    } else {
                        onComplete();
                    }
                }).catch(onError);
            }
        }
    });
});