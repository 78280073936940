define('huyang/components/set-vendor-info', ['exports', 'ember-validations', 'huyang-common/mixins/unsaved-changes'], function (exports, _emberValidations, _unsavedChanges) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_emberValidations.default, _unsavedChanges.default, {
        buttonLabel: 'Update',
        // TODO: get from the database
        vendorTypes: ['HVAC', 'AC', 'plumbing'],
        vendorTypesSelectOptions: {
            width: '200px'
        },
        statusOptions: ['active', 'inactive'],

        validations: {
            name: {
                presence: true,
                length: { minimum: 3 }
            },
            vendorType: {
                presence: true
            },
            email: {
                presence: true,
                length: { minimum: 10 }
            },
            phone: {
                presence: true,
                length: { minimum: 10 }
            },
            status: {
                presence: true
            }
        },

        setupFields: function () {
            this.trackUnsaved(['name', 'vendorType', 'email', 'phone', 'status']);
        }.on('init'),

        showError: function () {
            return this.get('isInvalid') && this.get('clickedSave');
        }.property('isInvalid', 'clickedSave'),

        actions: {
            save: function save() {
                if (this.get('isValid')) {
                    var info = {
                        name: this.get('name'),
                        vendorType: this.get('vendorType'),
                        email: this.get('email'),
                        phone: this.get('phone'),
                        status: this.get('status')
                    };
                    this.sendAction('action', info);
                    this.setupFields();
                } else {
                    this.set('clickedSave', true);
                }
            }
        }
    });
});