define('huyang/controllers/reports/crosstab', ['exports', 'huyang/controllers/reports/report-controller'], function (exports, _reportController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _reportController.default.extend({
        resetData: function resetData() {
            this.set('reportName', 'New crosstab report');
            this.set('reportDescription', 'Crosstab reports show how metrics vary across groups.');
            this.set('reportType', 'request_open:count');
            this.set('timeframe', 'this_7_days');
            this.set('viz', 'crosstab');
            this.set('groupBy', '');
            this.set('category1', 'building.id');
            this.set('category2', 'request_type.id');
            this.set('keenQueryString', '');
            this.set('interval', 'daily');
            this.get('filteredIds').clear();
            this.set('postprocess', false);
        }
    });
});