define('huyang/validations/time-log', ['exports', 'huyang-common/validations/time-log'], function (exports, _timeLog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _timeLog.default;
    }
  });
});