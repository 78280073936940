define('huyang/controllers/people/staff/add', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),

        getUser: function getUser(email) {
            return this.store.query('user', { email: email });
        },

        enableSms: function () {
            var acct = this.store.peekRecord('account', localStorage.getItem('accountId'));
            return acct && acct.hasFeature('sms');
        }.property(),

        addRoles: function addRoles(user, accountId, roleNames, buildings) {
            var roles = this.get('model.roles');
            var accountRoles = [];

            roleNames.forEach(function (roleName) {
                var role = roles.find(function (r) {
                    return r.get('name') === roleName;
                });

                if (roleName === 'admin' || roleName === 'staff-all') {
                    accountRoles.push({
                        user: user.get('id'),
                        account: accountId,
                        role: role.get('id')
                    });
                } else {
                    buildings.forEach(function (building) {
                        accountRoles.push({
                            user: user.get('id'),
                            account: accountId,
                            role: role.get('id'),
                            building: building.get('id')
                        });
                    });
                }
            });
            return user.addRoles(accountRoles);
        },

        actions: {
            save: function save(info, onComplete, onError) {
                var _this = this;

                var accountId = this.get('session.data.authenticated.accountId');
                var buildings = [];
                var roles = [];

                if (info.hasAllAccess) {
                    // Don't create any building-related roles
                    delete info.buildingIds;
                    roles.push('staff-all');
                } else {
                    roles.push('staff');
                }

                if (info.isAdmin) {
                    roles.push('admin');
                }

                if (info.buildingIds) {
                    buildings = info.buildingIds.map(function (bid) {
                        return _this.store.peekRecord('building', bid);
                    });
                }

                return this.getUser(info.email).then(function (results) {
                    var user = void 0;

                    if (results.get('length')) {
                        user = results.objectAt(0);
                        user.set('name', info.name);

                        _this.addRoles(user, accountId, roles, buildings).then(function () {
                            _this.transitionToRoute('people.staff.index');

                            if (!user.hasAnyRole(_this.get('session.data.authenticated.accountId'), ['staff', 'staff-all', 'admin'])) {
                                // welcome sent
                                Ember.getOwner(_this).lookup('controller:application').flashBanner({ userName: info.name }, 'staff-added');
                            } else {
                                onComplete();
                            }
                        }, function () {
                            onError();
                        });
                    } else {
                        info.welcome = 'staff';
                        user = _this.store.createRecord('user', info);

                        user.save().then(function () {
                            _this.addRoles(user, accountId, roles, buildings).then(function () {
                                _this.transitionToRoute('people.staff.index');
                                Ember.getOwner(_this).lookup('controller:application').flashBanner({ userName: info.name, welcome: true }, 'staff-added');
                            });
                        }, function () {
                            onError();
                        });
                    }
                });
            },
            cancel: function cancel() {
                this.transitionToRoute('people.staff');
            }
        }
    });
});