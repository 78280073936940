define('huyang/components/message-summary-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'ul',
        classNames: ['message-summary-list'],

        markVisibleComments: function () {
            var _this = this;

            setTimeout(function () {
                return _this.send('commentsSeen');
            }, 1000);
        }.on('didInsertElement'),

        comments: function () {
            var _this2 = this;

            var comments = this.get('request.comments').filter(function (comment) {
                return !comment.get('isCreationComment') && comment.get('channel.id') === _this2.get('channel.id');
            });

            return comments;
        }.property('request.comments.[]'),

        commentsSorted: function () {
            var comments = this.get('comments');
            var limit = this.get('limit');

            comments = comments.sortBy('created');

            if (this.get('sort') === 'desc') {
                comments.reverse();
            }

            if (limit) {
                var startIndex = comments.length - limit;
                comments = comments.splice(startIndex);
            }

            return comments;
        }.property('comments.[]', 'comments.@each.created', 'limit', 'sort'),

        hasMoreMessages: function () {
            if (this.get('hideShowAll')) {
                return false;
            }

            var comments = this.get('comments.length');
            var sorted = this.get('commentsSorted.length');

            return comments > sorted;
        }.property('commentsSorted.length', 'comments.length', 'hideShowAll'),

        actions: {
            commentClicked: function commentClicked(comment) {
                this.sendAction('commentClicked', comment);
            },
            commentsSeen: function commentsSeen() {
                this.sendAction('commentsSeen', this.get('commentsSorted'));
            }
        }
    });
});