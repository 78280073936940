define('huyang/controllers/assets/asset', ['exports', 'huyang-common/mixins/header-navigation-controller'], function (exports, _headerNavigationController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_headerNavigationController.default, {
        modelIdString: 'model.asset.id',
        modelString: 'asset',
        routeString: 'assets.asset',

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var LEFT_ARROW = 37;
            var RIGHT_ARROW = 39;

            Ember.$(window).on('keyup.assetKeyup', function (e) {
                if (e.keyCode === LEFT_ARROW && !window.preventArrowNav) {
                    _this.send('previous');
                } else if (e.keyCode === RIGHT_ARROW && !window.preventArrowNav) {
                    _this.send('next');
                }

                Ember.$('.asset .left-arrow, .asset .right-arrow').removeClass('pressed');
            });

            Ember.$(window).on('keydown.assetKeydown', function (e) {
                if (!window.preventArrowNav) {
                    if (e.keyCode === LEFT_ARROW) {
                        Ember.$('.asset .left-arrow').addClass('pressed');
                    } else if (e.keyCode === RIGHT_ARROW) {
                        Ember.$('.asset .right-arrow').addClass('pressed');
                    }
                }
            });

            // set a naughty global to prevent nav whenever elements are focused
            Ember.$('body').on('focus', 'textarea, input', function () {
                return window.preventArrowNav = true;
            });
            Ember.$('body').on('blur', 'textarea, input', function () {
                return window.preventArrowNav = false;
            });
        },
        willDestroy: function willDestroy() {
            try {
                Ember.$(window).off('keydown.assetKeydown');
                Ember.$(window).off('keyup.assetKeyup');
                Ember.$('body').off('focus', 'textarea, input');
                Ember.$('body').off('blur', 'textarea, input');
            } catch (err) {}
        }
    });
});