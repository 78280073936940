define('huyang/routes/reports/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel(transition) {
            this._super(transition);
            if (transition.targetName === 'reports.index') {
                this.transitionTo('reports.trend');
            }
        }
    });
});