define('huyang/mixins/updates-vendor-button-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        updateVendorRoute: function () {
            var controller = this.controllerFor('requests/request');
            controller.set('vendorSendRoute', this.routeName + '.send');
        }.on('activate')
    });
});