define('huyang/controllers/requests/search', ['exports', 'huyang/controllers/requests/list-base', 'huyang-common/mixins/modal-helper', 'huyang/mixins/saves-view-config', 'huyang/mixins/uses-multiselect-menu'], function (exports, _listBase, _modalHelper, _savesViewConfig, _usesMultiselectMenu) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _listBase.default.extend(_modalHelper.default, _savesViewConfig.default, _usesMultiselectMenu.default, {
        viewConfig: Ember.computed.alias('model.viewConfig'),

        queryParams: ['q', 'from', 'filterAssetCategory', 'filterBuilding', 'filterAssetBuilding', 'filterRequestType', 'filterStatus', 'filterRequestLabel', 'showingAssets'],

        q: null,
        filterAssetCategory: null,
        filterBuilding: null,
        filterAssetBuilding: null,
        filterRequestType: null,
        filterRequestLabel: null,
        filterStatus: null,
        from: 0,

        showingAssets: null,
        requests: Ember.computed.alias('model.results'), // to help the requestlist mixin
        // total and limit set by route

        resultsObserver: Ember.observer('model', function () {
            // show assets on load or search if:
            //   * assets were already being shown and there are asset results
            //   * there are asset results, but no requests
            if (this.get('model').assets.get('length') && (this.get('showingAssets') || !this.get('model').results.get('length'))) {
                this.set('showingAssets', true);
            } else {
                this.set('showingAssets', null);
            }
        }),

        assetColumns: [Ember.Object.create({ key: 'assetCategory.title', label: 'Category', assetCategory: true }), Ember.Object.create({ key: 'tag', label: 'Tag', link: 'assets.asset', linkParamId: true }), Ember.Object.create({ key: 'description', label: 'Description' }), Ember.Object.create({ key: 'assetMakeModel.title', label: 'Make / model' })],

        menuOptions: function () {
            var options = this.get('multiselectOptions');

            return Ember.merge(options, {
                allSelectedText: 'Any',
                nonSelectedText: 'Any',
                enableCaseInsensitiveFiltering: true,
                enableClickableOptGroups: false,
                includeSelectAllOption: false,
                slim: true,
                multiple: false
            });
        }.property('multiselectOptions'),

        query: function () {
            return this.get('q');
        }.property('q'),

        label: function () {
            var total = this.get('total') + this.get('assetTotal');
            if (total > 0) {
                var q = this.get('query');
                var pluralized = total === 1 ? 'result' : 'results';

                return total + ' search ' + pluralized + ' for "' + q + '"';
            }

            return 'Search requests';
        }.property('total', 'assetTotal'),

        requestTotalLabel: Ember.computed('total', function () {
            var pluralized = this.get('total') === 1 ? 'request' : 'requests';

            return this.get('total') + ' ' + pluralized;
        }),

        assetTotalLabel: Ember.computed('assetTotal', function () {
            var pluralized = this.get('assetTotal') === 1 ? 'asset' : 'assets';

            return this.get('assetTotal') + ' ' + pluralized;
        }),

        hasResults: Ember.computed.or('total', 'assetTotal'),

        hasMultipleAssetCategories: function () {
            return _.keys(this.get('aggregations.assetCategories')).length > 1;
        }.property('aggregations.assetCategories'),

        hasMultipleAssetBuildings: function () {
            return _.keys(this.get('aggregations.assetBuildings')).length > 1;
        }.property('aggregations.assetBuildings'),

        hasMultipleBuildings: function () {
            return _.keys(this.get('aggregations.buildings')).length > 1;
        }.property('aggregations.buildings'),

        hasMultipleStatus: function () {
            return _.keys(this.get('aggregations.status')).length > 1;
        }.property('aggregations.status'),

        hasMultipleRequestTypes: function () {
            return _.keys(this.get('aggregations.requestTypes')).length > 1;
        }.property('aggregations.requestTypes'),

        hasMultipleRequestLabels: function () {
            return _.keys(this.get('aggregations.requestLabels')).length > 1;
        }.property('aggregations.requestLabels'),

        assetCategories: function () {
            var assetCategories = this.get('model.assetCategories');
            var agg = this.get('aggregations.assetCategories');
            return _.keys(agg).map(function (rtId) {
                var assetCategory = assetCategories.find(function (rt) {
                    return rt.get('id') === rtId;
                });
                if (!assetCategory) {
                    return;
                }
                return Ember.Object.create({
                    id: assetCategory.get('id'),
                    label: assetCategory.get('title'),
                    badge: agg[rtId]
                });
            });
        }.property('aggregations.assetCategories', 'model.assetCategories'),

        buildings: function () {
            var buildings = this.get('model.buildings');
            var agg = this.get('aggregations.buildings');

            return _.keys(agg).map(function (buildingId) {
                var building = buildings.find(function (b) {
                    return b.get('id') === buildingId;
                });

                if (!building) {
                    return;
                }

                return Ember.Object.create({
                    id: building.get('id'),
                    label: building.get('name'),
                    badge: agg[buildingId]
                });
            });
        }.property('aggregations.buildings', 'model.buildings'),

        assetBuildings: function () {
            var buildings = this.get('model.buildings');
            var agg = this.get('aggregations.assetBuildings');

            return _.keys(agg).map(function (buildingId) {
                var building = buildings.find(function (b) {
                    return b.get('id') === buildingId;
                });

                if (!building) {
                    return;
                }

                return Ember.Object.create({
                    id: building.get('id'),
                    label: building.get('name'),
                    badge: agg[buildingId]
                });
            });
        }.property('aggregations.assetBuildings', 'model.buildings'),

        statusOptions: function () {
            var options = ['open', 'closed', 'pending', 'template'];
            var agg = this.get('aggregations.status');

            return _.keys(agg).map(function (value) {
                if (options.indexOf(value) < 0) {
                    return;
                }
                return Ember.Object.create({
                    id: value,
                    label: value.capitalize(),
                    badge: agg[value]
                });
            });
        }.property('aggregations.status', 'model.status'),

        requestTypes: function () {
            var requestTypes = this.get('model.requestTypes');
            var agg = this.get('aggregations.requestTypes');
            return _.keys(agg).map(function (rtId) {
                var reqType = requestTypes.find(function (rt) {
                    return rt.get('id') === rtId;
                });
                if (!reqType) {
                    return;
                }
                return Ember.Object.create({
                    id: reqType.get('id'),
                    label: reqType.get('label'),
                    badge: agg[rtId]
                });
            });
        }.property('aggregations.requestTypes', 'model.requestTypes'),

        requestLabels: function () {
            var requestLabels = this.get('model.requestLabels');
            var agg = this.get('aggregations.requestLabels');
            return _.keys(agg).map(function (labelId) {
                var requestLabel = requestLabels.find(function (label) {
                    return label.get('id') === labelId;
                });
                if (!requestLabel) {
                    return;
                }
                return Ember.Object.create({
                    id: requestLabel.get('id'),
                    label: requestLabel.get('name') + (' (' + agg[labelId] + ')')
                });
            });
        }.property('aggregations.requestLabels', 'model.requestLabels'),

        buildingsPlaceholder: function () {
            return _.keys(this.get('aggregations.buildings')).length + ' buildings...';
        }.property('aggregations.buildings'),

        statusPlaceholder: function () {
            var agg = this.get('aggregations.status');
            var options = _.keys(agg).map(function (value) {
                return agg[value] + ' ' + value;
            });
            return options.join(', ');
        }.property('aggregations.status'),

        requestTypesPlaceholder: function () {
            return _.keys(this.get('aggregations.requestTypes')).length + ' request types...';
        }.property('aggregations.requestTypes'),

        hasFilters: function () {
            // filter bar showing is different based on whether searched
            // requests or assets are being shown
            if (!this.get('showingAssets')) {
                return this.get('hasMultipleBuildings') || this.get('hasMultipleStatus') || this.get('hasMultipleRequestTypes') || this.get('hasMultipleRequestLabels');
            } else {
                return this.get('hasMultipleAssetCategories') || this.get('hasMultipleAssetBuildings');
            }
        }.property('hasMultipleAssetCategories', 'hasMultipleBuildings', 'hasMultipleAssetBuildings', 'hasMultipleStatus', 'hasMultipleRequestTypes', 'hasMultipleRequestLabels', 'showingAssets'),

        breadcrumbs: function () {
            var _this = this;

            var bc = [];
            if (this.get('filterStatus')) {
                bc.push({
                    id: 'filterStatus',
                    label: this.get('filterStatus')
                });
            }
            if (this.get('filterAssetCategory')) {
                var ac = this.get('model.assetCategories').find(function (ac) {
                    return ac.get('id') === _this.get('filterAssetCategory');
                });
                if (ac) {
                    bc.push({
                        id: 'filterAssetCategory',
                        label: ac.get('title')
                    });
                }
            }
            if (this.get('filterBuilding')) {
                var b = this.get('model.buildings').find(function (b) {
                    return b.get('id') === _this.get('filterBuilding');
                });
                if (b) {
                    bc.push({
                        id: 'filterBuilding',
                        label: b.get('name')
                    });
                }
            }
            if (this.get('filterAssetBuilding')) {
                var ab = this.get('model.buildings').find(function (b) {
                    return b.get('id') === _this.get('filterAssetBuilding');
                });
                if (ab) {
                    bc.push({
                        id: 'filterAssetBuilding',
                        label: ab.get('name')
                    });
                }
            }
            if (this.get('filterRequestType')) {
                var reqType = this.get('model.requestTypes').find(function (rt) {
                    return rt.get('id') === _this.get('filterRequestType');
                });
                if (reqType) {
                    bc.push({
                        id: 'filterRequestType',
                        label: reqType.get('label')
                    });
                }
            }
            if (this.get('filterRequestLabel')) {
                var requestLabel = this.get('model.requestLabels').find(function (label) {
                    return label.get('id') === _this.get('filterRequestLabel');
                });
                if (requestLabel) {
                    bc.push({
                        id: 'filterRequestLabel',
                        label: requestLabel.get('name')
                    });
                }
            }
            return bc;
        }.property('q', 'filterAssetCategory', 'filterBuilding', 'filterAssetBuilding', 'filterRequestType', 'filterRequestLabel', 'filterStatus'),

        actions: {
            goToAsset: function goToAsset(id) {
                this.transitionToRoute('assets.asset', id);
            },
            gotoPage: function gotoPage(from) {
                this.transitionToRoute({ queryParams: {
                        from: from
                    } });
            },
            search: function search() {
                this.set('q', this.get('query'));
            },
            filterAssetCategory: function filterAssetCategory(value) {
                this.set('from', 0);
                this.set('filterAssetCategory', value);
            },
            filterBuilding: function filterBuilding(value) {
                this.set('from', 0);
                this.set('filterBuilding', value);
            },
            filterAssetBuilding: function filterAssetBuilding(value) {
                this.set('from', 0);
                this.set('filterAssetBuilding', value);
            },
            filterRequestType: function filterRequestType(value) {
                this.set('from', 0);
                this.set('filterRequestType', value);
            },
            filterRequestLabel: function filterRequestLabel(value) {
                this.set('from', 0);
                this.set('filterRequestLabel', value);
            },
            filterStatus: function filterStatus(value) {
                this.set('from', 0);
                this.set('filterStatus', value);
            },
            clearFilter: function clearFilter(filter) {
                this.set(filter, null);
            },

            saveColumns: function saveColumns() {
                var cols = this.get('orderedColumns');
                var selected = cols.filter(function (col) {
                    return col.selected;
                });
                this.saveViewConfig(selected.map(function (col) {
                    return col.key;
                }), 'search');
            },

            showRequests: function showRequests() {
                this.set('showingAssets', null);
            },
            showAssets: function showAssets() {
                this.set('showingAssets', true);
            }
        }
    });
});