define('huyang/controllers/assets/new', ['exports', 'huyang/models/asset', 'huyang/mixins/validate-custom-fields'], function (exports, _asset, _validateCustomFields) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_validateCustomFields.default, {
        store: Ember.inject.service(),
        changeset: _asset.default.changeset(),

        actions: {
            save: function save(data, onComplete, onError) {
                var _this = this;

                var asset = data;
                asset.set('account', this.get('model.account'));

                this.set('validationErrors', this.validateCustomFields(asset.get('customFields')));
                if (!this.get('validationErrors.length')) {
                    asset.save().then(function (_ref) {
                        var _content = _ref._content;

                        var a = _this.get('store').createRecord('asset', _content);
                        a.set('customFields', a.get('customFields').filter(function (cf) {
                            return cf.get('value');
                        }));

                        return a.save().then(function () {
                            if (typeof onComplete === 'function') {
                                onComplete();
                            }

                            _this.set('changeset', _asset.default.changeset());
                            _this.transitionToRoute('assets.index');
                        });
                    }).catch(onError);
                }
            },
            cancel: function cancel() {
                this.set('changeset', _asset.default.changeset());
                this.transitionToRoute('assets.index');
            }
        }
    });
});