define('huyang/routes/vendor/settings', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        model: function model() {
            return this.store.findRecord('user', this.get('session.data.authenticated.userId'), { reload: true });
        }
    });
});