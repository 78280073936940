define('huyang/components/user-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['user-list'],
        selectAllChecked: false,

        hasActions: function () {
            return this.get('enableUserEditing') || this.get('enableInlineApproval');
        }.property('enableUserEditing', 'enableInlineApproval'),

        toggleSelectAll: function () {
            var _this = this;

            this.get('occupants').forEach(function (tu) {
                return tu.set('selected', _this.get('selectAllChecked'));
            });
        }.observes('selectAllChecked'),

        selectedUsersChanged: function () {
            this.sendAction('selectedOccupantsChanged', this.get('occupants').filterBy('selected'));
        }.observes('occupants.@each.selected'),

        sortedOccupants: function () {
            return this.get('occupants').sortBy('user.email');
        }.property('occupants.[]'),

        actions: {
            selectUser: function selectUser(occupant) {
                occupant.toggleProperty('selected');
            },
            approveOccupant: function approveOccupant(occupant) {
                occupant.set('status', 'active');
                occupant.save();
            },
            rejectOccupant: function rejectOccupant(occupant) {
                occupant.set('status', 'inactive');
                occupant.save();
            }
        }
    });
});