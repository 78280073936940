define('huyang/components/request-table', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            rowClicked: function rowClicked(id, routeName) {
                this.sendAction('action', id, routeName);
            },
            infinityLoad: function infinityLoad() {
                this.sendAction('infinityLoad');
            },
            sortBy: function sortBy(sortColumn, sortDirection) {
                this.sendAction('sortBy', sortColumn, sortDirection);
            }
        }
    });
});