define('huyang/mixins/keen', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        api: Ember.inject.service(),
        query: null,
        data: null,

        setup: function setup() {
            this.keen = new Keen({
                // re-route our keen requests to our own server to enforce ACL and additional logic
                host: window.location.host + '/api', // <- this might break?
                protocol: 'auto',

                // the actual project ID is set on the server, this is needed to fill out the URL format
                projectId: 'gridium',

                // we need to inject our JWT here, the actual read key is on the server
                readKey: this.get('api').getAuthorizationHeader()
            });
        },

        postprocessDuration: function postprocessDuration(data, title) {
            // find reasonable value for rollup: hours, days, weeks
            // get values; may be item.value or item.value.result
            var values = [];

            /*
                without group by: list of { timeframe: x, value: seconds }
                with group by: list of { key: seconds } or { timeframe: x, value: { key: x, result: seconds }}
            */
            data.forEach(function (item) {
                if (item.value) {
                    if (_.isArray(item.value)) {
                        item.value.forEach(function (val) {
                            if (_.isNumber(val.result)) {
                                values.push(val.result);
                            }
                        });
                    } else {
                        values.push(item.value);
                    }
                } else {
                    Object.keys(item).forEach(function (key) {
                        var val = item[key];
                        if (key === 'timeframe' || !_.isNumber(val)) {
                            return;
                        }
                        values.push(val);
                    });
                }
            });

            if (!values.length) {
                return {
                    meta: { title: title },
                    results: data
                };
            }
            var avg = _.sum(values) / values.length;
            var units = [{ label: 'seconds', seconds: 1, cutoff: 1800 }, { label: 'hours', seconds: 3600, cutoff: 43200 }, { label: 'days', seconds: 86400, cutoff: 302400 }, { label: 'weeks', seconds: 604800, cutoff: 1 // will default to this if no match
            }];
            var unit = units.find(function (u) {
                return avg < u.cutoff;
            });
            if (!unit) {
                unit = units[units.length - 1]; // use the biggest one available
            }
            // rescale
            var results = data.map(function (item) {
                if (item.value) {
                    if (_.isArray(item.value)) {
                        item.value.forEach(function (val) {
                            if (_.isNumber(val.result)) {
                                val.result = Math.round(val.result / unit.seconds * 10) / 10;
                            }
                        });
                    } else {
                        item.value = Math.round(item.value / unit.seconds * 10) / 10;
                    }
                } else {
                    Object.keys(item).forEach(function (key) {
                        var val = item[key];
                        if (key === 'timeframe' || !_.isNumber(val)) {
                            return;
                        }
                        item[key] = Math.round(item.key / unit.seconds);
                    });
                }
                return item;
            });
            return {
                meta: {
                    numberFormat: '#,###.# ' + unit.label,
                    title: title + ' (' + unit.label + ')'
                },
                results: results
            };
        },

        reportTypes: function () {
            var _this = this;

            var rt = {
                'request_open:count': {
                    id: 'open_requests',
                    collection: 'request_open_v2',
                    analysis: 'count',
                    label: 'Request opened',
                    title: 'Opened requests',
                    index: 0
                },
                'request_closed:count': {
                    id: 'closed_requests',
                    collection: 'request_closed_v2',
                    analysis: 'count',
                    label: 'Request closed',
                    title: 'Closed requests',
                    index: 1
                },
                'request_closed:average': {
                    id: 'time_to_resolution',
                    collection: 'request_closed_v2',
                    analysis: 'average',
                    label: 'Resolution time',
                    title: 'Average resolution time',
                    tooltipTitle: 'Avg resolution time',
                    targetProperty: 'secondsOpen',
                    index: 2,
                    postprocess: function postprocess(data) {
                        return _this.postprocessDuration(data, 'Average resolution time');
                    }
                },
                'time_log:average': {
                    id: 'time_logged',
                    collection: 'time_log',
                    analysis: 'average',
                    label: 'Average logged time',
                    title: 'Average time logged',
                    tooltipTitle: 'Avg time logged',
                    targetProperty: 'elapsed',
                    index: 3,
                    postprocess: function postprocess(data) {
                        return _this.postprocessDuration(data, 'Average time logged');
                    }
                },
                'time_log:sum': {
                    id: 'total_time_logged',
                    collection: 'time_log',
                    analysis: 'sum',
                    label: 'Total logged time',
                    title: 'Total time logged',
                    tooltipTitle: 'Total time logged',
                    targetProperty: 'elapsed',
                    index: 4,
                    postprocess: function postprocess(data) {
                        return _this.postprocessDuration(data, 'Total time logged');
                    }
                },
                'cost_log:sum': {
                    id: 'total_cost',
                    collection: 'cost_log',
                    analysis: 'sum',
                    label: 'Total cost',
                    title: 'Total cost',
                    tooltipTitle: 'Total cost',
                    targetProperty: 'value',
                    index: 5
                }
            };

            return rt;
        }.property(),

        report: function () {
            var r = this.get('reportType') || 'request_open:count';
            var getter = 'reportTypes.' + r;
            return this.get(getter.replace('_v2', ''));
        }.property('reportType'),

        eventCollection: function () {
            return this.get('report').collection;
        }.property('reportType'),

        analysis: function () {
            return this.get('report').analysis;
        }.property('reportType'),

        title: function () {
            // use data.title if there is one
            return this.get('data.title') || this.get('report').title;
        }.property('reportType'),

        toEmberObjects: function toEmberObjects(key, items) {
            var _this2 = this;

            return items.map(function (item) {
                var obj = Ember.Object.create(item);

                if (obj.get('id') === _this2.get(key)) {
                    obj.set('selected', true);
                }

                return obj;
            });
        },

        groupByOptions: Ember.computed('groupBy', 'groupByCategory', 'reportType', function () {
            var options = [{ id: '', label: 'None' }, { id: 'building.id', label: 'Location' }, { id: 'request_type.id', label: 'Request type' }, { id: 'group.id', label: 'Occupant group' }];

            if (this.get('reportType') === 'time_log:average' || this.get('reportType') === 'time_log:sum') {
                options.insertAt(1, { id: 'worker.id', label: 'Staff' });
            } else {
                options.insertAt(1, { id: 'owner.id', label: 'Assignee' });
            }
            if (this.get('requestLabels.length')) {
                options.insertAt(3, { id: 'request_label.id', label: 'Request label' });
            }

            if (this.get('reportType') === 'cost_log:sum') {
                options.insertAt(5, { id: 'category.id', label: 'Cost category' });
            }

            return this.toEmberObjects('groupBy', options);
        }),

        actions: {
            dataReady: function dataReady(data) {
                this.set('data', data);
            },

            dataError: function dataError(err) {
                console.error('error getting data: ', err);
            }
        }
    });
});