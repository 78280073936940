define('huyang/controllers/settings/customization/asset-categories/index', ['exports', 'ember-validations'], function (exports, _emberValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_emberValidations.default, {
        validations: {
            'title': {
                presence: true
            }
        },

        activeCategories: Ember.computed('model.categories', function () {
            return this.get('model.categories').filterBy('inactive', null).sortBy('title');
        }),

        actions: {
            goToCategory: function goToCategory(category) {
                this.transitionToRoute('settings.customization.asset-categories.edit', category);
            },


            addCategory: function addCategory() {
                var _this = this;

                this.validate().then(function () {
                    var category = _this.store.createRecord('asset-category', {
                        title: _this.get('title')
                    });
                    category.set('account', _this.get('model.account'));

                    category.save().then(function (category) {
                        _this.set('title', '');
                        _this.set('showErrors', false);
                        _this.transitionToRoute('settings.customization.asset-categories.edit', category.id);
                    });
                }).catch(function () {
                    _this.set('showErrors', true);
                });
            }
        }
    });
});