define('huyang/routes/people/vendors/vendor/technicians/technician/edit', ['exports', 'huyang/routes/base-account', 'huyang/mixins/modal-route'], function (exports, _baseAccount, _modalRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend(_modalRoute.default, {
        modalSelector: '#editVendorTechnician',
        returnRoute: 'people.vendors.vendor.technicians',

        model: function model() /*params*/{
            return this.modelFor('people.vendors.vendor.technicians.technician');
        },

        afterModel: function afterModel(model) {
            this.set('returnId', model.get('vendor.id'));
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('parentModel', this.modelFor('people.vendors.vendor'));
        }
    });
});