define('huyang/routes/reports', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        model: function model() {
            return Ember.RSVP.hash({
                account: this.store.findRecord('account', this.get('session.data.authenticated.accountId')),
                reports: this.store.findAll('report-query'),
                buildings: this.store.query('building', { withRole: true }),
                requestTypes: this.store.findAll('request-type'),
                requestLabels: this.store.findAll('request-label'),
                costCategories: this.store.findAll('cost-category'),
                groups: this.store.findAll('occupant-group'),
                users: this.store.findAll('user')
            });
        }
    });
});