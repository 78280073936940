define('huyang/controllers/people/occupants/manage/users/search', ['exports', 'huyang/controllers/people/occupants/manage/users/filter-base'], function (exports, _filterBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _filterBase.default.extend({
        usersController: Ember.inject.controller('people.occupants.manage.users'),
        q: Ember.computed.alias('usersController.q'),
        ids: Ember.computed.alias('usersController.ids'),
        users: Ember.computed.alias('model.tenantUsers'),

        emptyMessage: function () {
            return 'There are no occupants that contain the term \'' + this.get('q') + '\'';
        }.property('q'),

        searchResults: function () {
            var q = (this.get('q') || '').toLowerCase();
            var ids = [];

            if (this.get('ids')) {
                ids = this.get('ids').split(',');
            }

            return this.get('users').filter(function (tu) {
                // name is optional
                if (tu.get('user')) {
                    var name = (tu.get('user.name') || '').toLowerCase();
                    var email = tu.get('user.email').toLowerCase();

                    if (q) {
                        var nameContains = name.indexOf(q) > -1;
                        var emailContains = email.indexOf(q) > -1;

                        return nameContains || emailContains;
                    }

                    if (ids.length) {
                        return ids.includes(tu.get('user.id'));
                    }

                    return true;
                }

                return false;
            });
        }.property('users', 'users.[]', 'q', 'ids')
    });
});