define('huyang/routes/settings/staff', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        /*
            load these now to make it easier to work on sub-pages
        */
        model: function model() {
            return Ember.RSVP.hash({
                'account': this.store.findRecord('account', this.get('session.data.authenticated.accountId')),
                'buildings': this.store.findAll('building'),
                'users': this.store.findAll('user'),
                'accountRoles': this.store.findAll('accountRole'),
                'roles': this.store.findAll('role')
            });
        }
    });
});