define('huyang/routes/timer', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        model: function model(params) {
            return this.store.findRecord('time-log', params.timer_id);
        },

        afterModel: function afterModel(model, transition) {
            var superVal = this._super(model, transition);
            var userId = parseInt(this.get('session.data.authenticated.userId'));

            // make sure user is an admin or owns this timer
            if (!this.get('session.data.authenticated.isAdmin') && parseInt(model.get('logger.id')) !== userId) {
                return this.transitionTo('requests.request.info', model.get('request.id'));
            }

            return superVal;
        }
    });
});