define('huyang/models/subspace-usage', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        subspaces: _emberData.default.attr(),

        getSubspace: function getSubspace(subspaceId) {
            var ssid = parseInt(subspaceId);
            return this.get('subspaces').find(function (subspace) {
                return subspace.id === ssid;
            });
        },

        inUse: function inUse(subspaceId) {
            var ss = this.getSubspace(subspaceId);
            return !_.isUndefined(ss);
        },

        getRequestCount: function getRequestCount(subspaceId) {
            var ss = this.getSubspace(subspaceId);
            return ss && ss.requests ? ss.requests : 0;
        }
    });
});