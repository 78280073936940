define('huyang/routes/assets/asset', ['exports', 'huyang/routes/base-account', 'huyang-common/mixins/header-navigation-route'], function (exports, _baseAccount, _headerNavigationRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAccount.default.extend(_headerNavigationRoute.default, {
    parentModel: 'assets',

    model: function model(params, options) {
      this.navigationModel(options);

      return Ember.RSVP.hash({
        asset: this.store.findRecord('asset', params.asset_id, { reload: true }),
        assetAttachments: this.store.query('asset-attachment', { asset: params.asset_id }),
        assetCategories: this.store.findAll('asset-category'),
        assetMakeModels: this.store.findAll('asset-make-model'),
        buildings: this.store.findAll('building'),
        vendors: this.store.findAll('vendor')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.navigationSetupController(controller);
    }
  });
});