define('huyang/routes/reports/report', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            return this.store.findRecord('report-query', params.report_id);
        },

        afterModel: function afterModel(model, transition) {
            this._super(model, transition);
            // redirect to saved/trend or saved/crosstab so old URLs will work
            this.transitionTo(model.get('route'), model.get('id'));
        },

        setupController: function setupController(controller, model) {
            controller.set('filteredIds', []);

            var params = model.get('params');
            if (params.filters) {
                params.filters.forEach(function (filter) {
                    if (Ember.isArray(filter.property_value)) {
                        filter.property_value.forEach(function (val) {
                            return controller.get('filteredIds').pushObject(val);
                        });
                    }
                });
            }

            controller.set('model', model);
            controller.runQuery();
        }
    });
});