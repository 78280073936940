define('huyang/mixins/request-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        actions: {
            goToRequest: function goToRequest(id, routeName) {
                var _this = this;

                var requestIds = this.get('requests').map(function (r) {
                    return r.get('id');
                });
                var idx = requestIds.indexOf(id);
                var page = 1;
                if (idx >= 0) {
                    page = Math.floor(idx / this.get('perPage')) + 1; // 1-based
                }

                /*
                    ember only allows passing extra data to a route through the
                    queryParams property (afaik). they won't show up in the URL though.
                     we're passing an array of request IDs and the route name to give context
                    for navigation between requests and back to the given index
                */

                var queryParams = {
                    requestIds: requestIds,
                    previousRoute: this.get('routeName'),
                    previousModelId: this.get('routeModelId'),
                    fromId: id,
                    currentPage: page,
                    totalPages: this.get('totalPages'),
                    filter: this.get('filter'),
                    perPage: this.get('perPage'),
                    // sortColumn: this.get('sortColumn'),
                    // sortDirection: this.get('sortDirection'),
                    buildingId: this.get('buildingId')
                };

                // add any other query params
                var params = this.get('queryParams') || [];
                var paramNames = [];

                params.forEach(function (param) {
                    if (_this.get(param)) {
                        queryParams[param] = _this.get(param);
                        paramNames.push(param);
                    }
                });

                queryParams.paramNames = paramNames.join(',');

                if (!routeName) {
                    routeName = this.get('linkToRouteName');
                }

                var route = routeName ? routeName : 'requests.request';

                this.transitionToRoute(route, id, {
                    queryParams: queryParams
                });
            }
        }
    });
});