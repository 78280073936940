define('huyang/components/set-subspace-info', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isEdit: false,
        classNameBindings: ['subspace.active::inactive', 'isEdit:editing'],
        classNames: ['row', 'subspace-row'],

        isEditing: function () {
            this.sendAction('editing', this.get('subspace.id'), this.get('isEdit'));
        }.observes('isEdit'),

        actions: {
            edit: function edit() {
                this.set('isEdit', true);
            },
            undelete: function undelete() {
                var _this = this;

                this.set('subspace.active', true);

                this.get('subspace').save().then(function () {
                    _this.set('isEdit', false);
                    _this.get('flashMessages').success('Reactivated!');
                });
            },
            deleteSubspace: function deleteSubspace() {
                this.sendAction('deleteSubspace', this.get('subspace'));
            },
            cancel: function cancel() {
                // keep position
                var pos = this.get('subspace.position');
                this.get('subspace').rollbackAttributes();
                this.set('subspace.position', pos);
                this.set('isEdit', false);
            },
            save: function save() {
                var _this2 = this;

                this.get('subspace').save().then(function () {
                    _this2.set('isEdit', false);
                    _this2.onComplete();
                });
            }
        },

        onComplete: function onComplete() {
            if (this.statusTimeout) {
                clearTimeout(this.statusTimeout);
            }

            this.get('flashMessages').success('Changes saved!');
            this.set('isLoading', false);
        }
    });
});