define('huyang/routes/reports/crosstab', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController: function setupController(controller) {
            controller.resetData();
            controller.createQuery();
        }
    });
});