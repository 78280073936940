define('huyang/routes/settings/customization/asset-categories/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        category: this.store.findRecord('asset-category', params.asset_category_id),
        vendors: this.store.findAll('vendor')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var assetsController = this.controllerFor('assets');
      var countsPromise = assetsController.loadAssetCounts();

      countsPromise.then(function () {
        controller.set('counts', assetsController.get('counts'));
      });
    }
  });
});