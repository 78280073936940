define('huyang/models/request', ['exports', 'ember-data', 'huyang-common/models/request'], function (exports, _emberData, _request) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _request.default.extend({
        vendorServiceRequests: _emberData.default.hasMany('vendor-service-request', { async: false }),
        checklists: _emberData.default.hasMany('requestChecklist', { async: true }),
        asset: _emberData.default.belongsTo('asset', { async: true }),
        latestComment: _emberData.default.attr('string'),
        pmProgram: _emberData.default.belongsTo('pm-program', { async: true }),
        estimatedHours: _emberData.default.attr('number'),

        vendorResponse: function () {
            // this is a very basic initial implementation
            // a better one would look at all of the service requests for the req.
            // and display something like "1 accepted, 2 declined"

            var responses = {
                'yes': 'Accepted',
                'no': 'Declined',
                'maybe': 'Needs info'
            };

            var serviceRequest = this.get('vendorServiceRequests.firstObject');

            if (serviceRequest) {
                var response = responses[serviceRequest.get('response')];

                if (response) {
                    return response;
                }

                return 'No response';
            }

            return '';
        }.property('vendorServiceRequests.[]'),

        checklist: Ember.computed('checklists', function () {
            return this.get('checklists').find(function (c) {
                return !c.get('archived');
            });
        })
    });
});