define('huyang/routes/requests/base-new-request', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model() {
            return Ember.RSVP.hash({
                assetCategories: this.store.findAll('asset-category'),
                technicians: this.store.findAll('vendor-technician'),
                topOwners: this.store.findAll('top-owner'),
                trades: this.store.findAll('trade'),
                vendors: this.store.findAll('vendor'),
                requestTypeBuildings: this.store.findAll('request-type-building')
            });
        }
    });
});