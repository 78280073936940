define('huyang/controllers/people/occupants/manage/groups', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['q'],
        selectedGroups: [],
        selectedArchivedGroups: [],

        transitionToSearch: function () {
            if (this.get('q')) {
                this.transitionToRoute('people.occupants.manage.groups.search');
            }
        }.observes('q'),

        hasSelectedGroups: function () {
            return this.get('selectedGroups.length') > 0;
        }.property('selectedGroups.length'),

        hasSelectedArchivedGroups: function () {
            return this.get('selectedArchivedGroups.length') > 0;
        }.property('selectedArchivedGroups.length'),

        disableSearch: function () {
            return this.get('hasSelectedGroups') || this.get('hasSelectedArchivedGroups');
        }.property('hasSelectedGroups', 'hasSelectedArchivedGroups'),

        bulkStatusUpdate: function bulkStatusUpdate(items, status) {
            var _this = this;

            var groups = this.get(items);

            groups.forEach(function (group) {
                group.set('status', status);

                group.save().then(function () {
                    group.set('selected', false);

                    var messages = {
                        archived: 'Archived %@',
                        active: 'Un-archived %@'
                    };

                    var message = messages[status] || 'Group status updated';

                    _this.get('flashMessages').success(message.replace('%@', groups.length > 1 ? 'groups' : 'group'));
                });
            });
        },

        actions: {
            archiveSelected: function archiveSelected() {
                this.bulkStatusUpdate('selectedGroups', 'archived');
            },
            unarchiveSelected: function unarchiveSelected() {
                this.bulkStatusUpdate('selectedArchivedGroups', 'active');
            }
        }
    });
});