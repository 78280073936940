define('huyang/models/staff', ['exports', 'ember-data', 'huyang/models/user'], function (exports, _emberData, _user) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _user.default.extend({
        name: _emberData.default.attr('string'),
        locations: _emberData.default.hasMany('building', { async: true })
    });
});