define('huyang/adapters/subspace-usage', ['exports', 'huyang/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        buildURL: function buildURL(modelName, id) {
            // api/100/buildings/110/subspace_usages
            return this.get('api').getHost() + '/' + this.get('namespace') + '/buildings/' + id + '/subspace_usages';
        }
    });
});