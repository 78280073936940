define('huyang/routes/people/occupants/manage/users/inactive', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model() {
            var tenantUser = this.store.peekAll('tenant-user');

            return tenantUser.filter(function (tu) {
                return tu.get('isInactive');
            });
        }
    });
});