define('huyang/controllers/requests/request/messages', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        requestsController: Ember.inject.controller('requests'),
        channels: Ember.computed.alias('requestsController.model.channels'),

        requestController: Ember.inject.controller('requests/request'),
        request: Ember.computed.alias('requestController.model.request'),

        // we need to pare down the channels to just the ones relevant to this request
        requestChannels: function () {
            var request = this.get('request');
            var comments = request.get('comments');

            // staff channel is always included
            var channels = this.get('channels').filterBy('isStaff');

            // occupant channel should only be included if there are occupant participants
            if (request.get('hasAnyOccupantAccess')) {
                var occupantChannel = this.get('channels').findBy('isOccupant');
                channels.pushObject(occupantChannel);
            }

            // add any vendor channels for this request
            comments.forEach(function (c) {
                var channel = c.get('channel');
                var existing = channels.findBy('id', c.get('channel.id'));

                if (!existing && channel.get('isVendor')) {
                    channels.pushObject(channel);
                }
            });

            return channels;
        }.property('request.comments.[]', 'channels.[]', 'request.hasAnyOccupantAccess'),

        // the primary channel is the one that contains the auto-posted creation comment
        primaryChannel: function () {
            var creationComment = this.get('request.comments').findBy('isCreationComment');

            if (creationComment) {
                return creationComment.get('channel');
            }

            return this.get('requestChannels').findBy('isStaff');
        }.property('requestChannels.[]', 'request.comments.@each.isCreationComment'),

        secondaryChannels: function () {
            var _this = this;

            return this.get('requestChannels').filter(function (c) {
                return c.get('id') !== _this.get('primaryChannel.id');
            }).sortBy('displayName');
        }.property('requestChannels.[]', 'primaryChannel')
    });
});