define('huyang/controllers/reports/report', ['exports', 'huyang/controllers/reports/report-controller'], function (exports, _reportController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _reportController.default.extend({
        isSaved: true,

        groupBy: Ember.computed.alias('model.params.group_by'),
        eventCollection: Ember.computed.alias('model.params.event_collection'),
        analysis: Ember.computed.alias('model.analysis'),
        interval: Ember.computed.alias('model.params.interval'),
        timeframe: Ember.computed.alias('model.params.timeframe'),

        reportType: function () {
            return this.get('eventCollection') + ':' + this.get('analysis');
        }.property('eventCollection', 'analysis'),

        actions: {
            showDeleteModal: function showDeleteModal() {
                this.openModal('confirmDelete');
            },
            deleteReport: function deleteReport() {
                var _this = this;

                this.get('model').destroyRecord().then(function () {
                    _this.get('flashMessages').success('Report deleted');
                    _this.transitionToRoute('reports.index');
                });
            },
            showRenameModal: function showRenameModal() {
                this.openModal('confirmRename');
            },
            saveReport: function saveReport() {
                var _this2 = this;

                this.get('model').save().then(function () {
                    return _this2.get('flashMessages').success('Changes saved');
                });
            },
            rollbackReport: function rollbackReport() {
                this.get('model').rollbackAttributes();
            }
        }
    });
});