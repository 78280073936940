define('huyang/controllers/requests/repeating/active', ['exports', 'huyang/controllers/requests/list-base'], function (exports, _listBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _listBase.default.extend({
        requests: Ember.computed.alias('model.requests'),
        requestController: Ember.inject.controller('requests'),

        headerLabel: function () {
            return this.get('total') + ' active repeating requests';
        }.property('requests.length'),

        activeCount: function () {
            return this.get('activeCount');
        }.property('requests.length'),

        pausedCount: function () {
            return this.get('requestController.counts.template') - this.get('activeCount');
        }.property('requests.length', 'requestController.counts.template'),

        actions: {
            newRepeatingRequest: function newRepeatingRequest() {
                this.transitionToRoute('requests.repeating.new');
            }
        }
    });
});