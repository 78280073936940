define('huyang/components/set-request-info', ['exports', 'huyang-common/components/set-request-info', 'npm:@sentry/browser'], function (exports, _setRequestInfo, _browser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _setRequestInfo.default;
    }
  });
});