define('huyang/components/popover-menu', ['exports', 'huyang/components/dynamic-component'], function (exports, _dynamicComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _dynamicComponent.default.extend({
        willDestroy: function willDestroy() {
            // the tether library removes the menu element from the component
            // so we need to manually remove it when the component is destroyed
            Ember.$(this.get('elementSelector')).remove();
            try {
                Ember.$(document).off('click');
            } catch (err) {}
        },

        toggleMenu: function toggleMenu() {
            Ember.$(this.get('elementSelector')).toggle();
        },

        setupMenu: function setupMenu() {
            var tetherOptions = {
                element: this.get('elementSelector'),
                target: this.get('targetSelector'),
                attachment: this.get('attachment'),
                targetAttachment: this.get('targetAttachment')
            };

            if (this.get('targetOffset')) {
                tetherOptions.targetOffset = this.get('targetOffset');
            }

            this.popover = new Tether(tetherOptions);

            setTimeout(this.listenForDismissal.bind(this), 100);
        },

        listenForDismissal: function listenForDismissal() {
            var _this = this;

            Ember.$(document).on('click', function (e) {
                var el = _this.get('elementSelector');
                var btn = _this.get('buttonSelector');

                if (Ember.$(el).is(':visible') && !Ember.$(e.target).closest(el).length && !Ember.$(e.target).closest(btn).length) {
                    Ember.$(el).hide();
                }
            });
        },

        actions: {
            toggleMenu: function toggleMenu() {
                // hide any other menus
                if (this.popover) {
                    this.toggleMenu();
                } else {
                    this.setupMenu();
                }
            }
        }
    });
});