define('huyang/routes/requests/filters', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model() {
            return Ember.RSVP.hash({
                filters: this.store.findAll('filter'),
                buildings: this.store.query('building', { includeInactive: true, withRole: true }),
                users: this.store.findAll('user'),
                requestTypes: this.store.findAll('request-type'),
                groups: this.store.findAll('occupant-group'),
                viewConfigs: this.store.findAll('view-config'),
                requestLabels: this.store.findAll('request-label'),
                requestSchedules: this.store.findAll('request-schedule')
            });
        },

        actions: {
            error: function error(err) {
                if (err) {
                    this.transitionTo('requests.error');
                }
            }
        }
    });
});