define('huyang/adapters/request-type-usage', ['exports', 'huyang/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        pathForType: function pathForType() /*modelName*/{
            return 'requestTypes/usage';
        }
    });
});