define('huyang/routes/assets/new', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model() {
            return Ember.RSVP.hash({
                account: this.store.findRecord('account', this.get('session.data.authenticated.accountId')),
                assetCategories: this.store.findAll('asset-category'),
                assetMakeModels: this.store.findAll('asset-make-model'),
                buildings: this.store.findAll('building'),
                vendors: this.store.findAll('vendor')
            });
        }
    });
});