define('huyang/routes/admin/tracking', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        api: Ember.inject.service(),

        model: function model() {
            return this.get('api').ajax({
                type: 'GET',
                url: '/api/trackedUsers',
                dataType: 'json'
            }).then(function (response) {
                return response;
            }, function (err) {
                console.log(err);
            });
        }
    });
});