define('huyang/routes/requests/search', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        limit: 50,

        queryParams: {
            q: { refreshModel: true },
            from: { refreshModel: true },
            filterAssetCategory: { refreshModel: true },
            filterBuilding: { refreshModel: true },
            filterAssetBuilding: { refreshModel: true },
            filterRequestType: { refreshModel: true },
            filterRequestLabel: { refreshModel: true },
            filterStatus: { refreshModel: true },
            showingAssets: {}
        },

        beforeModel: function beforeModel() {
            // asset search results are somewhat second-class; this extra step is
            // needed to make sure records get cleared correctly on a new search
            this.store.unloadAll('assetSearchResult');
        },


        model: function model(params) {
            params.size = this.get('limit');
            return Ember.RSVP.hash({
                results: params.q ? this.store.query('searchResult', params) : [],
                assets: this.store.peekAll('assetSearchResult'),
                assetMakeModels: this.store.findAll('assetMakeModel'),
                assetCategories: this.store.findAll('assetCategory'),
                buildings: this.modelFor('requests').buildings,
                requestTypes: this.modelFor('requests').requestTypes,
                viewConfig: this.store.queryRecord('view-config', { view_name: 'search' }),
                requestLabels: this.modelFor('requests').requestLabels
            });
        },

        afterModel: function afterModel(model) {
            var meta = model.results.get('meta') || {};
            this.set('total', meta.total || 0);
            this.set('assetTotal', meta.assetTotal || 0);
            this.set('aggregations', meta.aggregations);
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('total', this.get('total'));
            controller.set('assetTotal', this.get('assetTotal'));
            controller.set('limit', this.get('limit'));
            controller.set('aggregations', this.get('aggregations'));
        },

        actions: {
            loading: function loading(transition) {
                var controller = this.controllerFor('requests.search');
                controller.set('loadingResults', true);
                transition.promise.finally(function () {
                    controller.set('loadingResults', false);
                });

                return false;
            },


            error: function error(err) {
                if (err) {
                    this.transitionTo('requests.error');
                }
            }
        }
    });
});