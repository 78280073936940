define('huyang/routes/requests/repeating/new', ['exports', 'huyang/routes/requests/base-new-request'], function (exports, _baseNewRequest) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseNewRequest.default.extend({
        resetController: function () {
            this.controller.set('modalOpened', false);
        }.on('deactivate')
    });
});