define('huyang/routes/requests/request/timer/log', ['exports', 'huyang/routes/base-account', 'huyang/mixins/modal-route'], function (exports, _baseAccount, _modalRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend(_modalRoute.default, {
        modalSelector: '#logTimer',
        returnRoute: 'requests.request.info',

        model: function model() /*params*/{
            return this.modelFor('requests.request.timer');
        },

        afterModel: function afterModel(model) {
            this.set('returnId', model.get('request.id'));
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('loggingChangeset', model.changeset());
        }
    });
});