define('huyang/models/vendor-technician', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        account: _emberData.default.belongsTo('account', { async: true }),
        vendor: _emberData.default.belongsTo('vendor', { async: true }),
        user: _emberData.default.belongsTo('user', { async: true }),
        trade: _emberData.default.belongsTo('trade', { async: true })
    });
});