define('huyang/controllers/requests/request/info', ['exports', 'huyang-common/mixins/modal-helper', 'huyang/mixins/shows-request-modal', 'huyang-common/mixins/creates-followers', 'huyang-common/mixins/shows-gallery', 'huyang/mixins/creates-comments', 'huyang/mixins/finds-recent-channel'], function (exports, _modalHelper, _showsRequestModal, _createsFollowers, _showsGallery, _createsComments, _findsRecentChannel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    exports.default = Ember.Controller.extend(_showsGallery.default, _createsFollowers.default, _createsComments.default, _showsRequestModal.default, _modalHelper.default, _findsRecentChannel.default, {
        store: Ember.inject.service(),
        session: Ember.inject.service(),

        requestController: Ember.inject.controller('requests/request'),

        request: computed.alias('model.request'),
        recentEvents: computed.alias('model.requestEvents'),
        channels: computed.alias('requestsController.model.channels'),
        users: computed.alias('requestsController.model.users'),
        requestLabels: computed.alias('requestsController.model.requestLabels'),
        loadedGalleryImages: [],
        loadedGalleryUrls: [],
        isEditingChecklist: false,

        followersHeading: function () {
            var len = this.get('request.followers.length');

            if (len === 1) {
                return '1 follower';
            } else if (len === 0) {
                return 'No followers';
            }

            return len + ' followers';
        }.property('request.followers.length'),

        hasRecentMessages: function () {
            var primaryComment = this.get('request.comments').findBy('isCreationComment');
            if (primaryComment) {
                var primaryChannel = primaryComment.get('channel');

                var eligibleComments = this.get('request.comments').filter(function (comment) {
                    return !comment.get('isCreationComment') && comment.get('channel.id') === primaryChannel.get('id');
                });

                return eligibleComments.length > 0;
            } else {
                return this.get('request.comments.length') > 0;
            }
        }.property('request.comments.@each.source'),

        buildingStaffUsers: function () {
            var users = this.get('requestsController.model.users');
            var buildingId = this.get('request.building.id');
            var accountId = this.get('session.data.authenticated.accountId');

            return users.filter(function (u) {
                return u.hasStaffRole(accountId, buildingId);
            });
        }.property('requestsController.model.users', 'request.building.id'),

        vendorUsers: function () {
            var vendors = this.get('model.vendors');
            return vendors.map(function (v) {
                return v.get('primaryUser');
            }).sortBy('displayName');
        }.property('model.vendors.[]'),

        startNewTimer: function startNewTimer() {
            var user = this.get('session.currentUser');

            return this.store.createRecord('time-log', {
                account: this.get('request.account'),
                request: this.get('request'),
                logger: user,
                worker: user,
                created: new Date(),
                startTime: new Date(),
                active: true,
                timer: true,
                manualTime: false
            }).save();
        },


        // So this is annoying, but... the list of timers in the modal
        // SHOULD NOT respond to changes triggered by the modal (pause/resume), but it
        // SHOULD respond to changes triggered elsewhere (pause/resume/log/cancel),
        // so it's really easier just to set this manually when necessary
        getOtherActiveTimers: function getOtherActiveTimers() {
            var _this = this;

            return this.get('session.currentUser.activeTimers').filter(function (tl) {
                return tl.get('request.id') !== _this.get('request.id');
            });
        },


        hideAsset: Ember.computed.not('request.account.assets'),

        hideSpinner: Ember.computed('request.images', function () {
            if (!this.get('request.images.length')) {
                return true;
            }
        }),

        editChecklist: Ember.computed('request.isTemplate', 'isEditingChecklist', function () {
            return this.get('request.isTemplate') || this.get('isEditingChecklist');
        }),

        actions: {
            addChecklist: function addChecklist() {
                var _this2 = this;

                var data = {
                    'account': localStorage.getItem('accountId'),
                    'request': this.get('request')
                };

                this.get('store').createRecord('request-checklist', data).save().then(function (checklist) {
                    _this2.set('request.checklist', checklist);
                    _this2.set('editChecklist', true);
                    _this2.get('request').save();
                });
            },
            removeChecklist: function removeChecklist() {
                this.get('store').findRecord('request-checklist', this.get('request.checklist.id')).then(function (checklist) {
                    checklist.destroyRecord();
                });
                this.set('request.checklist', null);
            },
            clickStartTimer: function clickStartTimer() {
                this.startNewTimer();
                // FIXME catch on error

                var otherActive = this.getOtherActiveTimers();

                if (otherActive.length) {
                    this.set('otherActiveTimers', otherActive);
                    this.openModal('manageTimers');
                }
            },
            clearOtherActive: function clearOtherActive() {
                this.set('otherActiveTimers', null);
            },


            goToRequest: function goToRequest(reqId) {
                this.transitionToRoute('requests.request', reqId);
            },
            onUpdateMeta: function onUpdateMeta() {
                var _this3 = this;

                Ember.run.later(this, function () {
                    _this3.get('requestsController').loadRequestCounts();
                }, 500);
            },

            showAddFollowers: function showAddFollowers() {
                this.set('showAddFollowers', true);
                setTimeout(function () {
                    return Ember.$('.add-followers-form .select2-search__field').focus();
                }, 100);
            },
            hideAddFollowers: function hideAddFollowers() {
                this.set('showAddFollowers', false);
            },
            addFollowers: function addFollowers(followerIds, callback) {
                var _this4 = this;

                this.createFollowers(followerIds, this.get('request.building.id'), this.get('request.id')).then(function (result) {
                    _this4.get('request').addFollowers(result.followerIds).then(function () {
                        if (callback) {
                            callback();
                        }
                        _this4.set('showAddFollowers', false);
                        if (result.newUserIds && result.newUserIds.length) {
                            _this4.transitionToRoute('requests.new-occupants', _this4.get('request.id'), {
                                queryParams: {
                                    userIds: result.newUserIds.join(',')
                                }
                            });
                        }
                    });
                });
            },

            deleteRequest: function deleteRequest() {
                this.openModal('confirmDelete');
            },
            editRequest: function editRequest() {
                this.get('request').startEdit();
                this.openModal('editRequest');
            },
            confirmDelete: function confirmDelete() {
                var _this5 = this;

                this.get('request').destroyRecord().then(function () {
                    _this5.get('flashMessages').success('Request deleted');
                    _this5.get('requestController').send('goToPreviousRoute');
                });
            },
            commentClicked: function commentClicked(comment) {
                this.transitionToRoute('requests.request.messages.channel', comment.get('channel.id'));
            },
            markCommentsRead: function markCommentsRead(comments) {
                var _this6 = this;

                if (comments && comments.length > 0 && this.get('request')) {
                    //let channel = comments[0].get('channel');
                    var commentIds = comments.map(function (c) {
                        return parseInt(c.get('id'));
                    });

                    // todo: figure out how to update counter & do this responsibly
                    this.get('request').markCommentsAsRead(commentIds).then(function () {
                        _this6.get('request').reload();
                    });
                }
            },
            assignToVendor: function assignToVendor(user) {
                var _this7 = this;

                // stored as a function since we need to call it in a few different places here
                var transition = function transition() {
                    // if vendor is not already part of this request
                    _this7.transitionToRoute('requests.request.info.send', {
                        queryParams: {
                            assign: true,
                            user: user.get('id')
                        }
                    });
                };

                // if the vendor has already received a service request email, just assign to them.
                // otherwise, transition to the modal route to preview the email

                if (user.get('id')) {
                    var vendor = this.get('model.vendors').findBy('primaryUser.id', user.get('id'));

                    if (vendor) {
                        var request = this.get('request');
                        // see if vendor already has a service request for this request
                        var serviceReq = vendor.get('serviceRequests').findBy('request.id', request.get('id'));

                        if (serviceReq) {
                            request.set('owner', user);
                            request.save().then(function () {
                                _this7.get('flashMessages').success('Reassigned request');
                            });
                        } else {
                            transition();
                        }
                    } else {
                        transition();
                    }
                } else {
                    transition();
                }
            },

            createVendor: function createVendor(email) {
                this.transitionToRoute('requests.request.info.send', {
                    queryParams: {
                        assign: true,
                        email: email
                    }
                });
            }
        }
    });
});