define('huyang/routes/base-account', ['exports', 'huyang/config/environment', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _environment, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('routeName', this.routeName);
        },

        actions: {
            willTransition: function willTransition() {
                // hide mobile nav menu
                Ember.$('.nav-menu').removeClass('open');

                var ac = this.controllerFor('application');
                ac.set('lastRoute', this.routeName);
            },

            goBackToPreviousRoute: function goBackToPreviousRoute(fallbackRoute) {
                var ac = this.controllerFor('application');
                var previousRoute = ac.get('lastRoute');

                if (previousRoute && _environment.default.environment !== 'test') {
                    // the existence of the lastRoute property should ensure that there is an actual history
                    // using history.back to preserve any URL parameters/etc that were set
                    window.history.back();
                } else {
                    this.transitionTo(fallbackRoute);
                }
            }
        }
    });
});