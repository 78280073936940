define('huyang/controllers/settings/customization/request-labels/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            save: function save() {
                this.get('flashMessages').success('Request label added!');
                this.transitionToRoute('settings.customization.request-labels');
            },

            cancel: function cancel() {
                this.transitionToRoute('settings.customization.request-labels');
            }
        }
    });
});