define('huyang/routes/settings/customization/request-types', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return Ember.RSVP.hash({
                baseRequestTypes: this.store.findAll('base-request-type'),
                requestTypes: this.store.query('request-type', { active: true }),
                requestTypeBuildings: this.store.findAll('request-type-building'),
                buildings: this.store.findAll('building')
            });
        }
    });
});