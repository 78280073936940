define('huyang/controllers/requests/request/timer/log', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            confirmLogTimer: function confirmLogTimer() {
                var _this = this;

                var changeset = this.get('loggingChangeset');

                changeset.validate().then(function () {
                    if (!changeset.get('isValid')) {
                        return;
                    }

                    return changeset.save().then(function () {
                        _this.set('loggingChangeset', null);
                        _this.get('flashMessages').success('Time logged!');
                        _this.send('leaveRoute');
                    });
                    // FIXME catch on error
                });
            }
        }
    });
});