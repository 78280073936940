define('huyang/routes/people/staff/timers', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model() {
            return Ember.RSVP.hash({
                timers: this.modelFor('people.staff').openTimers,
                requestLabels: this.store.findAll('request-label')
            });
        }
    });
});