define('huyang/routes/login-vendor/index', ['exports', 'huyang/mixins/vendor-login-destination'], function (exports, _vendorLoginDestination) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_vendorLoginDestination.default, {
        session: Ember.inject.service(),

        beforeModel: function beforeModel(transition) {
            this._super(transition);
            this.controllerFor('application').set('isVendorRoute', true);
        },

        model: function model(params, transition) {
            this.set('destination', transition.params['login-vendor'].destination);

            return this.get('session').authenticate('authenticator:custom', {
                serviceRequest: transition.params['login-vendor'].serviceRequest
            });
        },

        // authenticate succeed: go to destination
        afterModel: function afterModel() {
            var dest = this.get('destinationRoute');

            if (dest.modelId && dest.route !== this.get('destinations')['settings']) {
                this.transitionTo(dest.route, dest.modelId);
            } else {
                this.transitionTo(dest.route);
            }
        },

        actions: {
            // authenticate failed: show error
            error: function error(err /*, transition*/) {
                console.error('error', err);
                this.transitionTo('login-failed');
            }
        }
    });
});