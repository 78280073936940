define('huyang/components/request-checklist-item-modal', ['exports', 'huyang/mixins/creates-comments', 'huyang-common/mixins/shows-gallery'], function (exports, _createsComments, _showsGallery) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_createsComments.default, _showsGallery.default, {
    store: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      // injecting the store service here is not ideal but CreatesComments needs it
      // anyway and this conditional helps the test along
      if (this.get('store')) {
        var staffChannel = this.get('store').peekAll('channel').findBy('name', 'staff');
        this.set('channel', staffChannel);

        var requestLabels = this.get('store').peekAll('requestLabel');
        this.set('requestLabels', requestLabels);
      }
    },


    requestLabelOptions: Ember.computed('requestLabels', function () {
      return (this.get('requestLabels') || []).sortBy('position', 'name').map(function (requestLabel) {
        return {
          id: requestLabel.get('id'),
          name: requestLabel.get('name'),
          color: requestLabel.get('color')
        };
      });
    }),

    labelButtonStyle: Ember.computed('item.requestLabel.color', function () {
      var color = this.get('item.requestLabel.color');
      return Ember.String.htmlSafe('background-color: ' + color);
    }),

    actions: {
      setRequestLabel: function setRequestLabel(requestLabelOption) {
        var flash = this.get('flashMessages');
        flash.clearMessages();

        var optionId = requestLabelOption ? requestLabelOption.id : null;

        if (this.get('item.requestLabel.id') === optionId) {
          return;
        }

        var item = this.get('item');
        var requestLabel = this.get('requestLabels').find(function (requestLabel) {
          return requestLabel.get('id') === optionId;
        });

        item.set('requestLabel', requestLabel);
        item.save().then(function () {
          flash.success('Checklist item label updated!');
        });
      },

      createRequest: function createRequest() {
        // this isn't ideal, but it seems crazy to have to pass an action
        // through the seven layers of the candy cane forest to get here
        var ctrl = this.container.lookup('controller:application');

        Ember.$('#checklistItemModal').modal('hide');
        ctrl.transitionToRoute('requests.new', {
          queryParams: { 'item': this.get('item.id') }
        });
      }
    }
  });
});