define('huyang/serializers/vendor-service-request', ['exports', 'huyang-common/serializers/rest-new'], function (exports, _restNew) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _restNew.default.extend({
        serialize: function serialize(snapshot, options) {
            var data = this._super(snapshot, options);

            // the comment subject & text are getting sent as non-model attributes
            if (snapshot.record.commentSubject) {
                data.commentSubject = snapshot.record.commentSubject;
            }

            if (snapshot.record.commentText) {
                data.commentText = snapshot.record.commentText;
            }

            if (snapshot.record.disableEmail) {
                data.disableEmail = snapshot.record.disableEmail;
            }

            if (snapshot.record.disableComment) {
                data.disableComment = snapshot.record.disableComment;
            }

            return data;
        }
    });
});