define('huyang/components/bootstrap-multiselect', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        keyPath: 'id',
        labelPath: 'label',
        selectedPath: 'selected',
        disabledPath: 'disabled',
        allValue: '--all--',
        showSelectAll: true,

        isMultiple: function () {
            return !this.get('single');
        }.property('single'),

        setup: function () {
            var selectId = '#' + this.get('selectId');
            Ember.$(selectId).multiselect(this.get('optionSet'));
        }.on('didInsertElement'),

        optionSet: function () {
            var self = this;
            var allValue = this.get('allValue');
            var selectId = '#' + this.get('selectId');
            var dropdownShowAction = this.get('onDropdownShow');

            var options = {
                onChange: function onChange(option, checked /*, select*/) {
                    var val = Ember.$(selectId).val();
                    if (self.get('showSelectAll') && self.get('isMultiple')) {
                        var optionVal = Ember.$(option).val();

                        if (checked) {
                            // check all: clear the rest
                            if (optionVal === allValue) {
                                val = [];
                                self.resetSelected();
                            } else {
                                // if not "all" and checked, deselect "all"

                                // set the selected property of the ember object
                                var optionObject = self.get('items').findBy(self.get('keyPath'), optionVal);

                                if (optionObject) {
                                    optionObject.set('selected', checked);
                                }

                                val = _.without(val, allValue);
                            }
                        }
                    }
                    // save filter contents because page might reload
                    self.set('filterValue', Ember.$('.multiselect-search').val());
                    self.sendAction('action', val);
                },
                onDropdownShow: function onDropdownShow() {
                    if (dropdownShowAction) {
                        self.sendAction('onDropdownShow');
                    }
                },
                onDropdownHide: function onDropdownHide() {
                    // clear filter
                    var options = self.get('optionSet');
                    options.preFilter = '';
                    var inputSelector = '#' + self.get('elementId') + ' .multiselect-item.filter input';
                    Ember.$(inputSelector).val('');
                    self.set('filterValue', '');
                    Ember.$(selectId).multiselect('setOptions', options);
                    Ember.$(selectId).multiselect('runFilter', '');
                },
                enableCaseInsensitiveFiltering: true,
                includeSelectAllOption: false,
                allSelectedText: 'All',
                dropRight: this.get('rightAlign')
            };
            var paramOptions = this.get('options');

            if (paramOptions) {
                options = _.extend(options, paramOptions);
            }

            if (this.get('templates')) {
                options.templates = this.get('templates');
            }

            return options;
        }.property('allValue', 'selectId', 'onDropdownShow', 'options', 'templates'),

        resetSelected: function resetSelected() {
            if (this.get('itemsAreGrouped')) {
                this.get('items').forEach(function (group) {
                    group.options.forEach(function (item) {
                        return item.set('selected', false);
                    });
                });
            } else {
                this.get('items').forEach(function (item) {
                    return item.set('selected', false);
                });
            }
        },

        itemList: function () {
            // redraw select on change of items array; each items as |item| doesn't redraw on select change
            var selectId = '#' + this.get('selectId');

            // tell bootstrap multiselect that something changed, but wait until the select is rendered
            Ember.run.scheduleOnce('afterRender', this, function () {
                var filterValue = this.get('filterValue') || '';
                var inputSelector = '#' + this.get('elementId') + ' .multiselect-item.filter input';
                var options = this.get('optionSet');

                // reset filter value so it won't get lost in rebuild
                Ember.$(inputSelector).val(filterValue);
                Ember.$(selectId).multiselect('setOptions', _.extend(options, { preFilter: filterValue }));
                Ember.$(selectId).multiselect('rebuild');
            });

            if (this.get('showSelectAll')) {
                var all = this.itemListAllOption();
                return [all].concat(this.get('items'));
            } else {
                return this.get('items');
            }
        }.property('items.[]', 'items.@each.selected'),

        itemListAllOption: function itemListAllOption() {
            if (this.get('itemsAreGrouped')) {
                var selectedItems = this.get('items').reduce(function (array, group) {
                    var selectedInGroup = group.options.find(function (item) {
                        return item.get('selected');
                    });
                    return selectedInGroup ? array.concat(selectedInGroup) : array;
                }, []);

                return Ember.Object.create({
                    id: this.get('allValue'),
                    label: this.get('allLabel') || 'All',
                    selected: selectedItems.length === 0
                });
            } else {
                var _selectedItems = this.get('items').find(function (item) {
                    return item.get('selected');
                });

                return Ember.Object.create({
                    id: this.get('allValue'),
                    label: this.get('allLabel') || 'All',
                    selected: !_selectedItems
                });
            }
        }
    });
});