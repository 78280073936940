define('huyang/routes/requests/request', ['exports', 'huyang/routes/base-account', 'huyang-common/mixins/header-navigation-route'], function (exports, _baseAccount, _headerNavigationRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend(_headerNavigationRoute.default, {
        // Other locations further up the route hierarchy need to know if a
        // request is being viewed and parent routes cannot get this via
        // params/route, so track it via a service
        viewingRequest: Ember.inject.service('viewing-request'),

        activate: function activate() {
            this._super.apply(this, arguments);
            this.get('viewingRequest').setId(this.paramsFor('requests.request').request_id);
        },
        deactivate: function deactivate() {
            this._super.apply(this, arguments);
            this.get('viewingRequest').clearId();
        },


        parentModel: 'requests',

        model: function model(params, options) {
            var _this = this;

            this.navigationModel(options);

            return this.store.findRecord('request', params.request_id, { reload: true }).then(function (req) {
                var models = {
                    request: req,
                    requestChecklists: _this.store.query('request-checklist', { request: req.get('id') }),
                    requestEvents: _this.store.query('request-event', { request: req.get('id'), limit: 3 }),
                    serviceRequests: _this.store.query('vendor-service-request', { request: req.get('id') }),
                    vendors: _this.store.findAll('vendor'),
                    topOwners: _this.modelFor('requests').topOwners
                };

                if (req.get('isTemplate')) {
                    models.requests = _this.store.query('request', {
                        template_id: req.get('id'),
                        per_page: 5
                    });
                }

                return Ember.RSVP.hash(models);
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);

            this.navigationSetupController(controller);
            var meta = model.request.get('meta');

            if (model.requests) {
                var total = meta && meta.total ? meta.total : model.requests.get('length');
                controller.set('totalRequests', total);
            }

            if (meta) {
                controller.set('notifyUsers', meta.notifyUsers);
            }
        },

        actions: {
            error: function error(err) {
                if (err.errors && err.errors.length && err.errors[0].status === '403') {
                    return this.transitionTo('requests.error', { queryParams: { notfound: true } });
                }
                return this.transitionTo('requests.error');
            }
        }
    });
});