define('huyang/routes/people/vendors/vendor/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() {
            this.transitionTo('people.vendors.vendor.history');
        }
    });
});