define('huyang/services/intl-tel-input', ['exports', 'huyang-common/services/intl-tel-input', 'npm:intl-tel-input', 'npm:libphonenumber-js'], function (exports, _intlTelInput, _npmIntlTelInput, _npmLibphonenumberJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _intlTelInput.default;
    }
  });
});