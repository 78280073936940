define('huyang/controllers/settings/customization/request-types/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        requestTypes: Ember.computed.alias('model.requestTypes'),
        buildings: Ember.computed.alias('model.buildings'),

        tableColumns: [{ key: 'label', label: 'Request type', link: 'settings.customization.request-types.edit', linkParamId: true, iconClass: 'cssClass' }, { key: 'locations', label: 'Locations' }, { key: 'isDefaultLabel', label: 'Default', iconClass: 'isDefaultIcon' }],

        requestTypeLocationMap: function () {
            var map = {};

            this.get('model.requestTypeBuildings').forEach(function (rtb) {
                var requestTypeId = rtb.get('requestType.id');

                if (!map[requestTypeId]) {
                    map[requestTypeId] = [];
                }

                map[requestTypeId].push(rtb.get('building'));
            });

            return map;
        }.property('model.requestTypeBuildings', 'requestTypes.@each.requestTypeBuildings.[]'),

        requestTypesWithLocations: function () {
            var locations = this.get('requestTypeLocationMap');

            return this.get('model.requestTypes').map(function (rt) {
                var loc = locations[rt.get('id')];

                if (loc) {
                    var val = loc[0].get('name');

                    if (loc.length > 1) {
                        val += ' + ' + (loc.length - 1) + ' more';
                    }

                    rt.set('locations', val);
                }

                rt.set('icon', Ember.String.htmlSafe('<i class="request-icon ' + rt.get('cssClass') + '"></i>'));

                if (rt.get('isDefault')) {
                    rt.set('isDefaultIcon', 'fa fa-check');
                }

                return rt;
            }).sortBy('label');
        }.property('requestTypes.[]', 'requestTypeLocationMap', 'requestTypes.@each.isDefault'),

        actions: {
            onEdit: function onEdit(requestType) {
                this.transitionToRoute('settings.customization.request-types.edit', requestType.id);
            }
        }
    });
});