define('huyang/components/settings-sidebar', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),

        isAdmin: function () {
            return this.get('session.data.authenticated.isAdmin');
        }.property('session.currentUser')
    });
});