define('huyang/components/keen-line-chart', ['exports', 'huyang/mixins/keen'], function (exports, _keen) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_keen.default, {
        /*
            this uses the Keen.io SDK to load the query data
            (passed through our proxy in our own API)
        */
        setChartVisibility: function () {
            if (this.get('running')) {
                Ember.$('#chartWrapper').hide();
            } else {
                Ember.$('#chartWrapper').show();
            }
        }.observes('running'),

        drawChart: function () {
            Ember.$('#chartWrapper').show();
            var data = this.get('data');
            if (!data || !data.result) {
                console.log('line-chart: no data yet');
                return;
            }
            var tooltipTitle = this.get('report').tooltipTitle || this.get('title');
            var numberFormat = data.meta && data.meta.numberFormat || '#,###';
            var numberFormatter = new google.visualization.NumberFormat({ pattern: numberFormat });
            var dateFormat = data.meta && data.meta.dateFormat ? data.meta.dateFormat : 'MMM d, yyyy';
            var formatters = [{ formatter: new google.visualization.DateFormat({ pattern: dateFormat }), columnIndex: 0 }];
            var labelMapping = {
                'value': tooltipTitle,
                'Result': tooltipTitle, // for Keen 3.4
                'null': this.get('groupBy') === 'group.id' ? 'Staff' : 'Value'
            };
            var groupLabels = this.get('groupLabels');
            if (this.get('groupBy') && this.get('groupBy.length') && groupLabels) {
                labelMapping = groupLabels;
                labelMapping[null] = 'None';

                // get columns actually present in data (may be fewer than possible columns in groupLabels)
                var groups = [];
                data.result.forEach(function (day) {
                    if (!day.value) {
                        return;
                    }
                    day.value.forEach(function (obj) {
                        var key = _.without(Object.keys(obj), 'result');
                        groups.push(obj[key]);
                    });
                });
                _.uniq(groups).forEach(function (group, idx) {
                    formatters.push({ formatter: numberFormatter, columnIndex: idx + 1 });
                });
            } else {
                formatters.push({ formatter: numberFormatter, columnIndex: 1 });
            }

            // draw a line chart
            var attributes = {
                labelMapping: labelMapping,
                height: 300,
                areaOpacity: 0.07,
                theme: 'maximized',
                fontName: 'museo-sans-rounded, sans-serif',
                fontSize: 12,
                chartArea: {
                    top: 6
                },
                hAxis: {
                    format: 'M/d/yy',
                    gridlines: {
                        color: '#eee',
                        count: 7
                    }
                },
                legend: {
                    alignment: 'end'
                },
                vAxis: {
                    gridlines: {
                        color: '#eee',
                        count: -1 // -1 designates automatic
                    }
                },
                pointSize: 10,
                lineWidth: 3,
                interpolateNulls: true,
                // for tooltip labels
                formatters: formatters,
                title: this.get('title')
            };
            var chart = new Keen.Dataviz().el(this.$('#chartWrapper').get(0)).chartType(this.get('groupBy.length') ? 'linechart' : 'areachart').attributes(attributes).prepare();
            chart.parseRawData(data).title(this.get('title')).render();
        }.observes('data.result', 'reportType')
    });
});