define('huyang/controllers/people/occupants/manage/groups/filter-base', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        parentController: Ember.inject.controller('people/occupants/manage/groups'),

        actions: {
            selectedGroupsChanged: function selectedGroupsChanged(groups) {
                this.get('parentController').set('selectedGroups', groups);
            }
        }
    });
});