define('huyang/components/crosstab-legend', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['crosstab-legend'],

        items: function () {
            var _this = this;

            var colors = this.get('colors');
            var labels = this.get('labels');

            return this.get('keys').map(function (key, idx) {
                return {
                    color: Ember.String.htmlSafe(colors[idx >= colors.length ? idx % colors.length : idx]),
                    label: labels[key] ? labels[key] || key : _this.get('empty')
                };
            });
        }.property('labels', 'keys', 'colors')
    });
});