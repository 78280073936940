define('huyang/components/toggle-switch', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['toggle-switch'],

        actions: {
            clickOption: function clickOption(opt) {
                this.get('options').forEach(function (o) {
                    return Ember.set(o, 'selected', false);
                });
                Ember.set(opt, 'selected', true);

                this.sendAction('choose', opt.id);
            }
        }
    });
});