define('huyang/models/asset-make-model-attachment', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        assetMakeModel: _emberData.default.belongsTo('asset-make-model', { async: true }),
        filename: _emberData.default.attr('string'),
        attachmentType: _emberData.default.attr('string'),
        uuid: _emberData.default.attr('string'),

        iconClassMap: {
            'excel': 'fa-file-excel-o',
            'pdf': 'fa-file-pdf-o',
            'word': 'fa-file-word-o',
            'zip': 'fa-file-zip-o',
            'text': 'fa-file-text-o',
            'other': 'fa-file-o'
        },

        isImage: function () {
            var attType = this.get('attachmentType') || '';
            return attType.indexOf('image/') === 0 || attType.indexOf('img/') === 0;
        }.property('attachmentType'),

        fullUrl: function fullUrl(baseUrl) {
            var filename = this.get('filename') || '';
            var parts = filename.split('.');
            var extension = parts.length > 1 ? parts[parts.length - 1] : '';

            return baseUrl + '/' + this.get('assetMakeModel.account.id') + '/makemodels/' + this.get('uuid') + '.' + extension;
        },

        iconClass: function () {
            if (this.get('isImage')) {
                return 'fa-file-image-o';
            }

            var iconKey = this.get('fileType');
            return this.get('iconClassMap')[iconKey];
        }.property('fileType'),

        downloadUrl: function () {
            var config = Ember.getOwner(this).resolveRegistration('config:environment') || {};
            return this.fullUrl(config.S3_BASE_URL);
        }.property('uuid'),

        url: function () {
            var config = Ember.getOwner(this).resolveRegistration('config:environment') || {};
            return this.fullUrl(config.IMGIX_BASE_URL || config.S3_BASE_URL);
        }.property('uuid'),

        imageUrl: function () {
            return this.get('url') + '?w=2200';
        }.property('url'),

        thumbnailSmallSquareUrl: function () {
            return this.get('url') + '?w=80&h=80&fit=crop';
        }.property('url')
    });
});