define('huyang/components/set-asset-info', ['exports', 'huyang-common/mixins/handles-attachments', 'ember-uuid'], function (exports, _handlesAttachments, _emberUuid) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_handlesAttachments.default, {
        fileUploadId: 'assetFileUpload',

        session: Ember.inject.service(),
        store: Ember.inject.service(),

        validationErrors: [],
        datepickerOptions: {
            format: 'MM/DD/YYYY',
            useCurrent: true
        },

        sortedFields: Ember.computed('asset.customFields', function () {
            if (this.get('asset.customFields')) {
                return this.get('asset.customFields').sortBy('assetCategoryCustomField.position');
            }
            return {};
        }),

        init: function init() {
            this._super.apply(this, arguments);

            if (this.get('asset.assetCategory')) {
                this.getCustomFields();
            }
        },


        activeBuildings: Ember.computed.filterBy('buildings', 'active'),

        getCustomFields: function getCustomFields(reset) {
            var _this = this;

            var asset = this.get('asset');
            if (!asset.get('customFields') || reset) {
                asset.set('customFields', []);
            }

            if (this.get('asset.assetCategory.customFields')) {
                this.get('asset.assetCategory.customFields').forEach(function (accf) {
                    var existingRecord = asset.get('customFields').findBy('assetCategoryCustomField.id', accf.get('id'));
                    if (!existingRecord) {
                        var acf = _this.get('store').createRecord('asset-custom-field', {
                            id: (0, _emberUuid.v4)(),
                            assetCategoryCustomField: accf
                        });
                        asset.get('customFields').pushObject(acf);
                    }
                });
            }
        },


        activeCategories: Ember.computed('assetCategories', function () {
            if (this.get('assetCategories')) {
                return this.get('assetCategories').filter(function (item) {
                    return !item.get('inactive');
                });
            }
        }),

        subspaces: Ember.computed('asset.building', function () {
            if (this.get('asset.building')) {
                return this.get('asset.building.activeSubspaces');
            }
            return false;
        }),

        dateOfAcquisitionDisplay: Ember.computed('asset.dateOfAcquisition', function () {
            return moment(this.get('asset.dateOfAcquisition')).format('MM/DD/YY');
        }),

        makeModel: Ember.computed('asset.assetMakeModel', function () {
            if (this.get('asset.assetMakeModel')) {
                return this.get('asset.assetMakeModel.title');
            }
            return '';
        }),

        showMakeModelAttachment: Ember.computed.and('asset.id', 'makeModel'),

        uploadUrl: function () {
            return '/api/' + this.get('session.data.authenticated.accountId') + '/assetAttachments';
        }.property('model'),

        actions: {
            assetCategoryChanged: function assetCategoryChanged() {
                this.getCustomFields(true);
            },
            addMakeModelAttachments: function addMakeModelAttachments(data) {
                if (data.length) {
                    this.set('asset.makeModelAttachment', data.map(function (file) {
                        return file.get('id');
                    }));
                }
            },
            addDate: function addDate(property) {
                this.set('asset.' + property, new Date());
            },
            removeDate: function removeDate(property) {
                this.set('asset.' + property, null);
            },


            saveButtonClicked: function saveButtonClicked() {
                this.$('.form').submit();
            },

            commitChanges: function commitChanges() {
                var _this2 = this;

                var attachmentsList = this.get('attachmentsList');
                if (attachmentsList) {
                    this.set('asset.attachment', attachmentsList.map(function (file) {
                        return file.get('id');
                    }));
                }

                this.get('asset').validate().then(function () {
                    if (!_this2.get('asset.isValid')) {
                        return;
                    }

                    if (_this2.get('asset.isArchived')) {
                        _this2.set('asset.archived', moment().toDate());
                    }

                    if (_this2.get('makeModel')) {
                        _this2.set('asset.makeModel', _this2.get('makeModel'));
                    }

                    _this2.sendAction('changesSaved', _this2.get('asset'));
                }).catch(function () {
                    _this2.set('showErrors', true);
                });
            },

            cancel: function cancel() {
                this.sendAction('onCancel');
            },

            uploadComplete: function uploadComplete(uuid, data) {
                var self = this;

                if (data.files) {
                    data.files.forEach(function (file) {
                        // find pending file object
                        var pending = self.get('attachmentsList').find(function (f) {
                            return f.get('id') === file.id;
                        });

                        if (pending) {
                            pending.set('id', data.result.assetAttachment.id);
                            pending.set('uuid', uuid);
                        }
                    });
                    this.uploadComplete();
                }
            }

        }
    });
});