define('huyang/controllers/people/occupants/manage', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        tenantPortalUrl: function () {
            return 'https://' + this.get('model.account.subdomain') + '.tikkit.us';
        }.property('model.account.subdomain')
    });
});