define('huyang/routes/settings/buildings/archive', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return this.store.findAll('building').then(function (buildings) {
                return buildings.filter(function (b) {
                    return !b.get('active');
                });
            });
        }
    });
});