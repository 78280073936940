define('huyang/components/subdomain-input', ['exports', 'huyang-common/components/subdomain-input'], function (exports, _subdomainInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _subdomainInput.default;
    }
  });
});