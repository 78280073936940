define('huyang/routes/requests/request/info/edit', ['exports', 'huyang/routes/base-account', 'huyang/mixins/modal-route'], function (exports, _baseAccount, _modalRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend(_modalRoute.default, {
        modalSelector: '.new-request',
        returnRoute: 'requests.request.info',

        model: function model() {
            return this.modelFor('requests.request').request;
        },

        afterModel: function afterModel(model) {
            this.set('returnId', model.get('id'));
            model.startEdit();
        }
    });
});