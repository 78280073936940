define('huyang/routes/requests/preventative-maintenance/program', ['exports', 'huyang-common/mixins/header-navigation-route'], function (exports, _headerNavigationRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_headerNavigationRoute.default, {
    parentModel: 'requests',

    model: function model(params, options) {
      this.navigationModel(options);

      return Ember.RSVP.hash({
        account: this.get('session.currentAccount'),
        assetCategories: this.store.findAll('asset-category'),
        pmProgram: this.store.findRecord('pm-program', params.program_id),
        requestLabels: this.store.findAll('request-label'),
        requestTypes: this.store.findAll('request-type'),
        user: this.get('session.currentUser'),
        requestChecklists: this.store.query('request-checklist', { pmProgram: params.program_id })
      });
    },
    setupController: function setupController(c, m) {
      this._super.apply(this, arguments);

      c.set('allowBalancing', m.pmProgram.get('allowBalancing'));
      c.set('balancingSchedule', m.pmProgram.get('balancingSchedule'));

      this.navigationSetupController(c);
    }
  });
});