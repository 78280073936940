define('huyang/controllers/requests/repeating/new', ['exports', 'huyang/config/environment', 'huyang-common/mixins/modal-helper', 'huyang/mixins/creates-new-requests'], function (exports, _environment, _modalHelper, _createsNewRequests) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalHelper.default, _createsNewRequests.default, {
        goBack: function goBack(request) {
            if (_environment.default.environment === 'test') {
                if (request) {
                    this.transitionToRoute('requests.request', request.get('id'));
                } else {
                    this.transitionToRoute('requests.repeating');
                }
            } else {
                this.send('goBackToPreviousRoute', 'requests.repeating');
            }
        },

        actions: {
            rendered: function rendered() {
                // render-alert is firing twice on didInsertElement, so only open first time
                if (!this.get('modalOpened')) {
                    this.openModal('repeatingModal');
                    this.set('modalOpened', true);
                }
            }
        }
    });
});