define('huyang/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'huyang-common/mixins/version-check', 'huyang/config/environment', 'huyang/mixins/redirects-to-default', 'npm:@sentry/browser'], function (exports, _applicationRouteMixin, _versionCheck, _environment, _redirectsToDefault, _browser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_versionCheck.default, _applicationRouteMixin.default, _redirectsToDefault.default, {
        fullstory: Ember.inject.service(),
        intercom: Ember.inject.service(),
        session: Ember.inject.service(),

        beforeModel: function beforeModel(transition) {
            this._super(transition);
            this.checkVersion();
        },

        model: function model() {
            var accountId = this.get('session.data.authenticated.accountId');
            var userId = this.get('session.data.authenticated.userId');
            // if user is logged in, get their primary building; else generic one
            var primaryBuildingId = this.get('session.data.authenticated.userId') || 0;

            if (this.get('session').get('isAuthenticated') && accountId && userId) {
                var hash = {
                    account: this.store.findRecord('account', accountId),
                    user: this.store.findRecord('user', userId),
                    roles: this.store.findAll('role'),
                    openTimers: this.store.query('time-log', { end_time: null })
                };

                if (this.get('isVendorRoute')) {
                    hash['primaryBuilding'] = this.store.findRecord('primary-building', primaryBuildingId);
                }
                return Ember.RSVP.hash(hash);
            }

            if (this.get('isVendorRoute')) {
                return Ember.RSVP.hash({
                    account: this.store.findRecord('account', accountId),
                    primaryBuilding: this.store.findRecord('primary-building', primaryBuildingId)
                });
            }

            return [];
        },

        afterModel: function afterModel(model) {
            if (model.account && model.account.get('status') === 'inactive') {
                this.transitionTo('inactive');
            }

            if (model.user) {
                if (model.user.get('trackingEnabled')) {
                    this.get('fullstory').track(model.user);
                }

                this.get('intercom').addChat(model.account, model.user);
            }
        },


        getBrowser: function getBrowser() {
            var ua = window.navigator.userAgent;

            var browsers = {
                'CriOS': 'chrome',
                'FxiOS': 'firefox',
                'Safari': 'safari'
            };

            var browser = void 0;

            Object.keys(browsers).forEach(function (key) {
                if (ua.indexOf(key) > -1 && !browser) {
                    browser = browsers[key];
                }
            });

            return browser ? browser : 'webview';
        },

        actions: {
            sessionAuthenticated: function sessionAuthenticated() {
                var ua = window.navigator.userAgent;
                var isVendorRoute = this.controllerFor('application').get('isVendorRoute');

                if (isVendorRoute) {
                    // if vendor route, let the original transition proceed, but reload the app model
                    this.refresh();
                } else if (ua.match(/iPad/i) || ua.match(/iPhone/i)) {
                    /*
                        if user is using an iOS browser that is
                        an embedded UIWebView (Gmail for iOS), then send them to
                        the webview escape page
                    */

                    var browser = this.getBrowser();

                    if (browser === 'webview') {
                        // direct user to a page allowing them to log into their actual device browser
                        var webview = isVendorRoute ? 'login-vendor.webview' : 'login-token.webview';
                        this.transitionTo(webview);
                    } else {
                        this.redirectToDefault();
                    }
                } else {
                    this.redirectToDefault();
                }
            },
            sessionAuthenticationFailed: function sessionAuthenticationFailed() {
                this.controllerFor('login').set('loginErrorMessage', 'The username or password you entered is incorrect.  Please try again');
            },

            error: function error(err /*, transition*/) {
                // redirect to login on permissions error
                if (_environment.default.SENTRY_ENABLED) {
                    _browser.default.captureException(err, {
                        tags: { build: localStorage.getItem('build') },
                        extra: { errors: err.errors }
                    });
                }

                console.error(err);

                if (err && err.status < 500) {
                    if (err.status === 404) {
                        return this.transitionTo('not-found');
                    } else {
                        return this.transitionTo('login');
                    }
                }
                return this.transitionTo('error');
            }
        }
    });
});