define('huyang/components/request-asset-picker', ['exports', 'huyang-common/components/request-asset-picker'], function (exports, _requestAssetPicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _requestAssetPicker.default;
    }
  });
});