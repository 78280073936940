define('huyang/models/request-checklist-item', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        archived: _emberData.default.attr('date'),
        checklist: _emberData.default.belongsTo('request-checklist', { async: true }),
        completed: _emberData.default.attr('date'),
        completedBy: _emberData.default.belongsTo('user'),
        created: _emberData.default.attr('date'),
        createdBy: _emberData.default.belongsTo('user'),
        body: _emberData.default.attr('string'),
        position: _emberData.default.attr('number'),
        comments: _emberData.default.hasMany('request-comment', { async: false }),
        requestLabel: _emberData.default.belongsTo('requestLabel', { async: true }),

        attachments: Ember.computed('comments.[]', function () {
            return this.get('comments').filterBy('attachments.length');
        })
    });
});