define('huyang/controllers/settings/customization/request-labels/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        requestLabels: Ember.computed.alias('model.requestLabels'),

        requestLabelsSorted: function () {
            return this.get('requestLabels').sortBy('position', 'name');
        }.property('requestLabels.@each.position', 'requestLabels.@each.name'),

        actions: {
            reorderItems: function reorderItems(items /*, draggedModel*/) {
                items.forEach(function (item, position) {
                    item.set('position', position);
                    item.save();
                });
            }
        }
    });
});