define('huyang/mixins/creates-comments', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        store: Ember.inject.service(),

        actions: {
            addComment: function addComment(info, attachments, callback) {
                var comment = this.get('store').createRecord('request-comment', info);

                comment.save().then(function () {
                    if (typeof callback === 'function') {
                        callback();
                    }
                });
            }
        }
    });
});