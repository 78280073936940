define('huyang/routes/people/vendors/vendor/technicians/technician', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model(params) {
            return this.store.findRecord('vendorTechnician', params.technician_id);
        }
    });
});