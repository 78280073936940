define('huyang/components/assets/custom-fields/display-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',

    selectedVendor: Ember.computed('field.isVendor', 'field.value', function () {
      if (this.get('field.isVendor')) {
        return this.get('vendors').findBy('id', this.get('field.value'));
      }
    })
  });
});