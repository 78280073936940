define('huyang/components/filter-bar-datepicker', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNameBindings: [':bar-item', 'slim:bar-item-slim'],

        chosenLabel: function () {
            var start = void 0;
            var end = void 0;

            if (typeof this.get('timeframe') === 'string') {
                var opt = this.get('customRangeOptions').findBy('timeframe', this.get('timeframe'));

                if (opt) {
                    return opt.label;
                } else {
                    var parts = this.get('timeframe').split(' ');

                    if (parts.length === 2) {
                        start = moment(parts[0], 'YYYY-MM-DD');
                        end = moment(parts[1], 'YYYY-MM-DD');
                    } else {
                        parts = this.get('timeframe').split('_');
                        if (parts.length === 3) {
                            // this|previous_x_months|days|years
                            if (parts[0] === 'this') {
                                end = moment();
                                start = moment(end).add(-parseInt(parts[1], 10), parts[2]);
                            } else {
                                end = moment().set('date', 1);
                                start = moment(end).add(-parseInt(parts[1], 10), parts[2]);
                                end.add(-1, 'days');
                            }
                        }
                    }
                }
            } else {
                start = moment(this.get('timeframe').start);
                end = moment(this.get('timeframe').end);
            }

            var f = function f(mmt) {
                return mmt.format('M/D/YY');
            };

            return f(start) + ' - ' + f(end);
        }.property('timeframe'),

        setDates: function () {
            if (this.picker && this.get('startDate') && this.get('endDate')) {
                this.picker.setStartDate(this.get('startDate'));
                this.picker.setEndDate(this.get('endDate'));
            }
        }.observes('startDate', 'endDate'),

        parseTimeframe: function () {
            var tf = this.get('timeframe');

            if (typeof tf === 'string') {
                var opt = this.get('customRangeOptions').findBy('timeframe', tf);

                if (opt && opt.range) {
                    this.set('startDate', opt.range[0]);
                    this.set('endDate', opt.range[1]);
                } else {
                    var parts = tf.split(' ');

                    if (parts.length === 2) {
                        this.set('startDate', moment(parts[0], 'YYYY-MM-DD'));
                        this.set('endDate', moment(parts[1], 'YYYY-MM-DD'));
                    }
                }
            } else if (tf.start && tf.end) {
                this.set('startDate', tf.start);
                this.set('endDate', tf.end);
            }
        }.observes('timeframe'),

        setupPicker: function () {
            var _this = this;

            var today = moment('00:00:00', 'HH:mm:ss');
            var tf = this.get('timeframe');
            var ranges = {};

            // format the custom range options in the way that bootstrap-daterangepicker prefers
            this.get('customRangeOptions').forEach(function (opt) {
                return ranges[opt.label] = opt.range;
            });

            var start = void 0,
                end = void 0;
            var opt = this.get('customRangeOptions').findBy('timeframe', tf);

            if (this.get('startDate') && this.get('endDate')) {
                start = this.get('startDate');
                end = this.get('endDate');
            } else if (opt && opt.ranges) {
                start = opt.ranges[0];
                end = opt.ranges[1];
            } else if (tf.start && tf.end) {
                start = tf.start;
                end = tf.end;
            } else {
                start = moment(today).subtract(7, 'days');
                end = today;
            }

            // setup the plugin
            var p = this.$('.dropdown-toggle').daterangepicker({
                startDate: start,
                endDate: end,
                autoApply: true,
                ranges: ranges
            });

            // store the picker object to manipulate later on
            this.picker = Ember.$(p).data('daterangepicker');

            // handle selections make from the plugin
            this.$('.dropdown-toggle').on('apply.daterangepicker', function (e, picker) {
                if (picker.chosenLabel === 'Custom Range') {
                    // custom choices need a custom label & timeframe
                    var _start = picker.startDate;
                    var _end = picker.endDate;

                    _this.set('startDate', _start);
                    _this.set('endDate', _end);

                    // stringRanges is basically for views (filters) that require a string-based range
                    if (_this.get('stringRanges')) {
                        var rangeFmt = 'YYYY-MM-DD';
                        _this.set('timeframe', _start.format(rangeFmt) + ' ' + _end.format(rangeFmt));
                    } else {
                        // otherwise, keen expects start & end values in a dict
                        var f = 'MM/DD/YY';

                        _this.set('timeframe', {
                            start: _start.format(f),
                            end: _end.format(f)
                        });
                    }
                } else {
                    _this.set('timeframe', _this.get('customRangeOptions').findBy('label', picker.chosenLabel).timeframe);
                }
            });
        }.on('didInsertElement')
    });
});