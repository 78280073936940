define('huyang/validators/follower', ['exports', 'ember-validations/validators/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        emailRegExp: new RegExp(/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i),

        call: function call() {
            var _this = this;

            var name = this.property;
            var values = this.model.get('values.' + name) || this.model.get(name);
            if (!values || values.length === 0) {
                return;
            }
            var users = this.options.users || [];
            var errors = this.errors;
            var bad = 0;
            values.forEach(function (follower) {
                // valid email or user id in userIds list
                var userExists = users.find(function (u) {
                    return u.get('id') === follower;
                });
                if (!(_this.emailRegExp.test(follower) || userExists)) {
                    bad += 1;
                }
            });
            if (bad === 0) {
                return;
            }
            if (bad === 1) {
                errors.pushObject('contains an invalid email address, marked in red');
            } else {
                errors.pushObject('contains invalid email addresses, marked in red');
            }
        }
    });
});