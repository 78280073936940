define('huyang/services/viewing-request', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        setId: function setId(requestId) {
            this.set('id', requestId);
        },
        clearId: function clearId() {
            this.set('id', null);
        }
    });
});