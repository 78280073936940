define('huyang/controllers/settings/buildings/building/subspaces', ['exports', 'huyang-common/mixins/modal-helper'], function (exports, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalHelper.default, {
        subspaces: [],
        newSubspaceName: '',
        newSubspaceCommon: false,
        isEditing: [],
        showDragHandle: true,

        saved: function () {
            return this.get('newSubspaceName.length') === 0;
        }.property('newSubspaceName'),

        onComplete: function onComplete() {
            this.get('flashMessages').success('Changes saved!');
        },

        subspacesSorted: function () {
            var isEditing = this.get('isEditing');
            var subspaces = this.get('model.building.subspaces').sortBy('position', 'name');
            subspaces = subspaces.map(function (subspace) {
                subspace.set('cssId', 'subspace' + subspace.get('id'));
                subspace.set('editing', isEditing.includes(subspace.get('id')));
                return subspace;
            });
            return subspaces;
        }.property('model.building.subspaces.@each.position', 'model.building.subspaces.@each.name', 'clickedEdit'),

        actions: {
            editing: function editing(ssid, _editing) {
                // why doesn't adding isEditing.[] or isEditing.length to subspacesSorted property list trigger?
                this.set('clickedEdit', ssid + _editing);
                if (_editing) {
                    this.get('isEditing').push(ssid);
                } else {
                    this.set('isEditing', _.without(this.get('isEditing'), ssid));
                }
                this.set('showDragHandle', this.get('isEditing.length') === 0);
            },

            addSubspace: function addSubspace() {
                var subspace = this.store.createRecord('subspace', {
                    building: this.get('model.building'),
                    name: this.get('newSubspaceName'),
                    common: this.get('newSubspaceCommon'),
                    active: true
                });

                subspace.save();

                this.set('newSubspaceName', '');
                this.set('newSubspaceCommon', false);

                Ember.$('input[name="newSubspaceName"]').focus();
            },

            clickDeleteSubspace: function clickDeleteSubspace(subspace) {
                this.set('subspaceToDelete', subspace);

                var inUseText = '';
                var usage = this.get('model.usage');
                var requests = usage.getRequestCount(subspace.get('id'));

                if (requests) {
                    inUseText = subspace.get('name') + ' is used by ';

                    if (requests) {
                        inUseText += requests + ' ';
                        inUseText += requests === 1 ? 'request' : 'requests';
                    }

                    inUseText += '.';

                    this.set('confirmDeleteTitle', 'Are you sure you wish to deactivate "' + subspace.get('name') + '"?');
                    this.set('confirmLabel', 'Deactivate');
                } else {
                    this.set('confirmDeleteTitle', 'Are you sure you wish to delete "' + subspace.get('name') + '"?');
                    this.set('confirmLabel', 'Delete');
                }

                this.set('inUseText', inUseText);
                this.openModal('confirmDeleteSubspace');
            },

            confirmDeleteSubspace: function confirmDeleteSubspace() {
                var self = this;
                var subspace = this.get('subspaceToDelete');
                if (this.get('model.usage').inUse(subspace.get('id'))) {
                    subspace.set('active', false);
                    subspace.save().then(function () {
                        self.set('subspaceToDelete', null);
                        self.onComplete();
                    });
                } else {
                    subspace.destroyRecord().then(function () {
                        self.set('subspaceToDelete', null);
                        self.onComplete();
                    });
                }
            },

            updateCommon: function updateCommon(subspace, isCommon) {
                subspace.set('common', isCommon);
                subspace.save();
            },

            setNewSubspaceCommon: function setNewSubspaceCommon(value, checked) {
                this.set('newSubspaceCommon', checked);
            },

            reorderItems: function reorderItems(itemModels /*, draggedModel*/) {
                itemModels.forEach(function (ss, idx) {
                    ss.set('position', idx);
                    ss.save();
                });
            }
        }

    });
});