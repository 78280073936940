define('huyang/routes/people/occupants/edit', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model(params) {
            return this.store.findRecord('tenant-user', params.user_id);
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            // for location control
            controller.set('buildings', this.modelFor('people.occupants').buildings);
            controller.set('subspaces', this.modelFor('people.occupants').subspaces);
            controller.set('occupantGroups', this.modelFor('people.occupants').occupantGroups.filterBy('status', 'active'));
        }
    });
});