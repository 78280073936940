define('huyang/controllers/requests/all', ['exports', 'huyang/controllers/requests/list-base'], function (exports, _listBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _listBase.default.extend({
        requests: Ember.computed.alias('model.requests'),

        headerLabel: function () {
            var req = this.get('total');
            return req === 1 ? '1 request' : req + ' requests';
        }.property('requests.length')
    });
});