define('huyang/models/vendor-service-request', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        account: _emberData.default.belongsTo('account', { async: true }),
        vendor: _emberData.default.belongsTo('vendor', { async: true }),
        technician: _emberData.default.belongsTo('vendorTechnician', { async: true }),
        request: _emberData.default.belongsTo('request', { async: true }),
        comment: _emberData.default.belongsTo('requestComment', { async: true }),

        created: _emberData.default.attr('date'),
        responded: _emberData.default.attr('date'),
        response: _emberData.default.attr('string'),

        accepted: Ember.computed.equal('response', 'yes'),
        declined: Ember.computed.equal('response', 'no'),
        needMoreInfo: Ember.computed.equal('response', 'maybe'),

        dateLabel: function () {
            return moment(this.get('created')).format('MMMM Do, YYYY');
        }.property('created'),

        statusLabel: function () {
            var status = this.get('request.status');
            if (status) {
                return status.capitalize();
            }
        }.property('request.status'),

        responseLabel: function () {
            if (this.get('response')) {
                var responses = {
                    'yes': 'Accepted',
                    'no': 'Declined',
                    'maybe': 'Need more info'
                };

                return responses[this.get('response')];
            }

            return 'No response';
        }.property('response'),

        primaryUser: function () {
            if (this.get('technician.id')) {
                return this.get('technician.user');
            }

            return this.get('vendor.primaryUser');
        }.property('vendor.primaryUser.displayName', 'technician.user.displayName')
    });
});