define('huyang/routes/requests/request/messages/channel', ['exports', 'huyang/mixins/updates-vendor-button-route'], function (exports, _updatesVendorButtonRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_updatesVendorButtonRoute.default, {
        documentTitle: 'Messages',

        model: function model(params) {
            return this.store.findRecord('channel', params.channel_id);
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            this.markReadTimeout = setTimeout(function () {
                return controller.markCommentsAsRead();
            }, 1000);
        },

        actions: {
            deactivate: function deactivate() {
                clearTimeout(this.markReadTimeout);
            }
        }
    });
});