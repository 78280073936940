define('huyang/components/request-checklist-item', ['exports', 'ember-sortable/mixins/sortable-item'], function (exports, _sortableItem) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_sortableItem.default, {
        classNames: ['request-checklist-item'],

        isComplete: Ember.computed('checklistItem.completed', function () {
            return this.get('checklistItem.completed') !== null;
        }),

        requestLabelStyle: Ember.computed('checklistItem.requestLabel.color', function () {
            var color = this.get('checklistItem.requestLabel.color');
            return Ember.String.htmlSafe('background-color: ' + color);
        }),

        actions: {
            completeItem: function completeItem() {
                if (!this.get('isComplete')) {
                    this.set('checklistItem.completed', new Date());
                } else {
                    this.set('checklistItem.completed', null);
                }
                this.get('checklistItem').save();
            },

            showEditBox: function showEditBox() {
                var _this = this;

                this.set('isEditingItem', true);

                Ember.run.next(function () {
                    _this.$('.checklist-item-edit-text').focus();

                    // avoid newline flashing on enter keypress
                    _this.$('textarea').keypress(function (event) {
                        if (event.which === '13') {
                            return false;
                        }
                    });
                });
            },

            cancelEditChecklistItem: function cancelEditChecklistItem() {
                this.set('isEditingItem', false);
                this.get('checklistItem').rollbackAttributes();
            },

            deleteChecklistItem: function deleteChecklistItem() {
                this.set('checklistItem.archived', new Date());
                this.get('checklistItem').save();
            },

            editChecklistItem: function editChecklistItem() {
                if (!this.get('checklistItem.body').trim()) {
                    this.get('checklistItem').destroyRecord();
                } else {
                    this.get('checklistItem').save();
                }
                this.set('isEditingItem', false);
            }
        }
    });
});