define('huyang/controllers/inactive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    inactiveMessage: Ember.computed('model.account', function () {
      var account = this.get('model.account');

      if (account.get('inactiveMessageStaff')) {
        return account.get('inactiveMessageStaff');
      }

      return 'This account isn’t active at the moment. We apologize for any inconvenience. For assistance, please reach out directly to facility support staff.';
    })
  });
});