define('huyang/mixins/modal-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        open: function open() {
            Ember.$(this.get('modalSelector')).on('hidden.bs.modal', this.leave.bind(this));
            Ember.$(this.get('modalSelector')).modal('show');
        },

        close: function close() {
            try {
                Ember.$(this.get('modalSelector')).modal('hide');
            } catch (err) {}
        },

        leave: function leave() {
            if (!this.isDestroyed) {
                Ember.$(this.get('modalSelector')).off('hidden.bs.modal');
                if (this.get('returnId')) {
                    this.transitionTo(this.get('returnRoute'), this.get('returnId'));
                } else {
                    this.transitionTo(this.get('returnRoute'));
                }
            }
        },

        actions: {
            rendered: function rendered() {
                this.open();
            },
            leaveRoute: function leaveRoute() {
                this.close();
            }
        }
    });
});