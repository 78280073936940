define('huyang/components/keen-table', ['exports', 'huyang/mixins/keen'], function (exports, _keen) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_keen.default, {
        data: [],
        columns: [],

        setTableVisibility: function () {
            if (this.get('running')) {
                this.set('tableData', null);
            }
        }.observes('running'),

        tableColumns: function () {
            var _this = this;

            // Label and format of the date column depends on the interval level chosen
            var label = 'Date';
            // Let day interval be given default format by display component
            var dateFormat = null;

            var interval = this.get('interval');

            if (interval === 'weekly') {
                label = 'Week';
            } else if (interval === 'monthly') {
                label = 'Month';
                dateFormat = 'MMMM YYYY';
            } else if (interval === 'yearly') {
                label = 'Year';
                dateFormat = 'YYYY';
            }

            var columns = [{
                key: 'timeframe.start',
                isDate: true,
                dateFormat: dateFormat,
                label: label
            }];
            var labels = this.get('groupLabels');

            if (this.get('columns.length') > 0) {
                // add dynamic group columns

                this.get('columns').forEach(function (col) {
                    var colLabel = void 0;
                    if (labels[col]) {
                        colLabel = labels[col];
                    } else {
                        colLabel = 'None';
                    }

                    var column = {
                        key: col + '', // to string
                        label: colLabel,
                        emptyText: '0'
                    };

                    if (_this.get('title') === 'Total cost') {
                        column.currency = true;
                        column.align = 'right';
                    }
                    columns.push(column);
                });
            } else {
                // just add a single column for the ungrouped data
                var column = { key: 'value', label: this.get('title'), emptyText: '0' };
                if (this.get('title') === 'Total cost') {
                    column.currency = true;
                    column.align = 'right';
                }
                columns.push(column);
            }

            return columns;
        }.property('title', 'columns.length', 'columns', 'data'),

        // a kind-of-dumb function to hack values in where they could be null (eg: occupant group grouping)
        replaceNullGroupKey: function replaceNullGroupKey(value, key) {
            var val = value[key];

            if (!val && key === 'group.name') {
                val = 'Staff';
            }

            return val;
        },

        drawTable: function () {
            var _this2 = this;

            var data = this.get('data.result');

            if (!data) {
                return;
            }

            if (!this.get('groupBy.length')) {
                this.set('columns', []);
                this.set('tableData', data.map(function (item) {
                    return Ember.Object.create(item);
                }));
            } else {
                // there's some special magic for displaying grouped columns
                // we want the table to add a column for each grouped object
                var columns = data[0].value.map(function (v) {
                    return _this2.replaceNullGroupKey(v, _this2.get('groupBy'));
                });

                this.set('columns', columns);

                data = data.map(function (d) {
                    var item = { timeframe: d.timeframe };

                    // for each column, add the value to the item dict so it's easy for the table to grab
                    // this is basically a workaround of a strange format by keen
                    // so a column called 'Building 123' with a count of 4 items would be in the data as { 'Building 123': 4 }

                    columns.forEach(function (c) {
                        if (Ember.isArray(d.value)) {
                            d.value.forEach(function (v) {
                                var valKey = _this2.replaceNullGroupKey(v, _this2.get('groupBy'));
                                if (valKey === c) {
                                    item[c] = v.result;
                                }
                            });
                        }
                    });
                    return Ember.Object.create(item);
                });
                this.set('tableData', data);
            }
        }.observes('data')
    });
});