define('huyang/routes/requests/error', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAccount.default.extend({
    model: function model(params, options) {
      return options.queryParams;
    }
  });
});