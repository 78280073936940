define('huyang/controllers/requests/new-occupants', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['userIds', 'buildingId', 'create'],

        emailsList: function () {
            var users = this.get('model.users');
            var count = this.get('model.users.length');
            if (count === 1) {
                return '<span class="email">' + users.get('firstObject.email') + '</span>';
            }
            if (count === 2) {
                return users.map(function (u) {
                    return '<span class="email">' + u.get('email') + '</span>';
                }).join(' and ');
            }
            var emails = users.slice(0, count - 1).map(function (u) {
                return '<span class="email">' + u.get('email') + '</span>';
            }).join(', ');
            return Ember.String.htmlSafe(emails + ', and <span class="email">' + users.get('lastObject.email') + '</span>');
        }.property('model.users'),

        tenantUserId: function () {
            var userId = this.get('model.users.firstObject.id');
            var tenantUser = this.get('model.tenantUsers').find(function (tu) {
                return tu.get('user.id') === userId;
            });
            return tenantUser.get('id');
        }.property('model.users.firstObject.id', 'model.tenantUsers'),

        createdOne: function () {
            return this.get('model.users.length') === 1;
        }.property('model.users.length'),

        followersLabel: function () {
            return this.get('model.users.length') === 1 ? 'follower' : 'followers';
        }.property('model.users.length'),

        occupantsLabel: function () {
            return this.get('model.users.length') === 1 ? 'occupant' : 'occupants';
        }.property('model.users.length')
    });
});