define('huyang/components/open-timers-list', ['exports', 'huyang-common/mixins/modal-helper', 'huyang/mixins/saves-view-config'], function (exports, _modalHelper, _savesViewConfig) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    exports.default = Ember.Component.extend(_modalHelper.default, _savesViewConfig.default, {
        classNames: ['open-timers-list'],
        session: Ember.inject.service(),

        linkToRequest: true,
        hideHeader: false,
        showOtherControls: true,

        // The request page uses this component to display the active timer to
        // reduce the number of components that are using modals to manage logs
        isSingleTimer: false,

        // The component can get destroyed if all open timers are logged,
        // in which case closeModal in the returned promise will happen after
        // component is destroyed, so verify it always happens instead
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            this.closeModal();
        },


        // There might be multiple components on page belonging to same or
        // different users, so make sure modals all have unique IDs
        listLogModalId: computed(function () {
            return this.elementId + 'LogModal';
        }),

        listDeleteModalId: computed(function () {
            return this.elementId + 'DeleteModal';
        }),

        defaultColumns: ['priority', 'requestType.label', 'location', 'requestLabel.name'],

        requestColumns: computed(function () {
            var _this = this;

            var cols = this.get('defaultColumns').map(function (key) {
                return _this.get('columns').findBy('key', key);
            });

            return cols;
        }),

        actions: {
            logTimer: function logTimer(timer) {
                // These can be logged by an admin, so pass currentUser through
                this.set('changeset', timer.changeset({ logger: this.get('session.currentUser') }));
                this.openModal(this.get('listLogModalId'));
            },
            confirmLogTimer: function confirmLogTimer() {
                var _this2 = this;

                var changeset = this.get('changeset');

                changeset.validate().then(function () {
                    if (!changeset.get('isValid')) {
                        return;
                    }

                    return changeset.save().then(function () {
                        _this2.closeModal(_this2.get('listLogModalId'));
                        _this2.get('flashMessages').success('Timer saved!');
                    });
                    // FIXME catch on error
                });
            },
            cancelLogTimer: function cancelLogTimer() {
                this.set('changeset', null);
            },
            deleteTimer: function deleteTimer(timer) {
                this.set('deleteTimer', timer);
                this.openModal(this.get('listDeleteModalId'));
            },
            confirmDeleteTimer: function confirmDeleteTimer() {
                var _this3 = this;

                this.get('deleteTimer').destroyRecord().then(function () {
                    _this3.get('flashMessages').success('Timer canceled!');
                });
            }
        }
    });
});