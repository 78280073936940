define('huyang/controllers/settings/buildings/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        activeBuildings: function () {
            return this.get('model').filter(function (building) {
                return building.get('active');
            }).sortBy('name');
        }.property('model.@each.active', 'model.length'),

        archivedBuildings: function () {
            return this.get('model').filter(function (building) {
                return !building.get('active');
            });
        }.property('model.@each.active', 'model.length'),

        hasArchived: function () {
            return this.get('archivedBuildings.length') > 0;
        }.property('archivedBuildings')
    });
});