define('huyang/models/request-checklist', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        request: _emberData.default.belongsTo('request', { async: true }),
        pmProgram: _emberData.default.belongsTo('pmProgram', { async: true }),
        account: _emberData.default.attr('number'),
        archived: _emberData.default.attr('date'),
        checklistItems: _emberData.default.hasMany('requestChecklistItems', { async: true }),
        created: _emberData.default.attr('date'),
        createdBy: _emberData.default.belongsTo('user'),

        activeChecklistItems: Ember.computed('checklistItems.@each.archived', function () {
            return this.get('checklistItems').rejectBy('archived');
        })
    });
});