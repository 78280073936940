define('huyang/helpers/value-or-default', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.valueOrDefault = valueOrDefault;
    function valueOrDefault(params) {
        var value = params[0];
        var defaultText = '-';

        if (params.length > 1) {
            defaultText = params[1];
        }

        return value ? value : defaultText;
    }

    exports.default = Ember.Helper.helper(valueOrDefault);
});