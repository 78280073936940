define('huyang/controllers/settings/organization/info', ['exports', 'huyang/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        fields: function () {
            return [{
                label: 'Organization name',
                placeholder: 'Vandelay Industries',
                name: 'name',
                validations: { presence: true },
                value: this.get('name')
            }, {
                label: 'Gridium website address',
                name: 'subdomain',
                subdomain: true,
                domain: 'tikkit.gridium.com',
                helpText: 'This is the address you will use to sign in',
                secondaryDomainMessage: 'Occupants will sign in at',
                secondaryDomain: 'tikkit.us',
                includeEmailAddress: true,
                validations: {
                    presence: true,
                    length: { minimum: 3 },
                    subdomain: { current: this.get('model.account.subdomain') }
                },
                value: this.get('subdomain')
            }, {
                name: 'logoUuid',
                label: 'Logo',
                helpText: 'We recommend a white or transparent background for best results',
                photoUpload: true,
                photoUrl: this.get('photoUrl'),
                uploadUrl: this.get('uploadUrl')
            }];
        }.property('name', 'subdomain', 'photoUrl'),

        uploadUrl: function () {
            return '/api/' + this.get('model.account.id') + '/accounts/' + this.get('model.account.id') + '/logo';
        }.property('model.account.id'),

        showError: function () {
            return this.get('isInvalid') && this.get('clickedSave');
        }.property('isInvalid', 'clickedSave'),

        // for validations
        name: function () {
            return this.get('model.account.name');
        }.property('model.account.name'),

        subdomain: function () {
            return this.get('model.account.subdomain');
        }.property('model.account.subdomain'),

        subdomainUpdated: function () {
            return this.get('subdomain') !== this.get('model.account.subdomain');
        }.property('subdomain', 'model.account.subdomain'),

        saved: function () {
            return this.get('name') === this.get('model.account.name') && this.get('subdomain') === this.get('model.account.subdomain');
        }.property('name', 'model.account.name', 'subdomain', 'model.account.subdomain'),

        photoUrl: function () {
            if (this.get('model.account.logoUuid')) {
                return this.get('model.account.logoUrl');
            }

            return undefined;
        }.property('model.account.logoUrl', 'model.account.logoUuid'),

        actions: {
            changesSaved: function changesSaved(fv, changed) {
                if (changed.includes('subdomain')) {
                    window.location = _environment.default.DOMAIN_PATTERN.replace('%@', this.get('model.account.subdomain')) + '/logout';
                }
            },

            fileUploaded: function fileUploaded(uuid) {
                console.log('uuid', uuid);
                this.get('model.account').set('logoUuid', uuid);
            },

            uploadFailed: function uploadFailed(err) {
                console.log('failed result=', err);
                // TODO: notify
            }
        }
    });
});