define('huyang/controllers/login-token/webview', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        token: Ember.computed.alias('model'),

        loginUrl: function () {
            var loc = window.location;
            var url = loc.protocol + '//' + loc.host + '/token/' + this.get('token');

            return url;
        }.property('token'),

        actions: {
            select: function select() {
                document.getElementById('loginInput').selectionStart = 0;
                document.getElementById('loginInput').selectionEnd = 999;
            }
        }
    });
});