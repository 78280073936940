define('huyang/controllers/requests', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        api: Ember.inject.service(),
        session: Ember.inject.service(),

        filtersLoaded: function () {
            return Ember.isPresent(this.get('model.filters'));
        }.property('model.filters', 'model.filters.length'),

        byLocation: function () {
            var bids = this.get('model.user').get('staffBuildingIds');
            var buildings = bids.length ? this.get('model.buildings').filter(function (b) {
                return bids.includes(b.get('id'));
            }) : this.get('model.buildings');

            // If search is working, return only buildings with requests,
            // otherwise return all buildings to provide usable sidebar links
            if (this.get('countsLoaded')) {
                var locations = [];
                var counts = this.get('counts.buildings');
                buildings.forEach(function (building) {
                    var count = counts && counts[building.get('id')] ? counts[building.get('id')] : 0;
                    if (count) {
                        locations.pushObject({
                            id: building.get('id'),
                            name: building.get('name'),
                            count: count
                        });
                    }
                });
                return locations.sortBy('name');
            }

            return buildings.sortBy('name');
        }.property('model.buildings.[]', 'countsLoaded'),

        sortedFilters: function () {
            if (!this.get('filtersLoaded')) {
                return [];
            }

            var filters = this.get('model.filters').reject(function (f) {
                return f.get('id') === 'new';
            });

            var counts = this.get('counts.filters');
            filters.forEach(function (filter) {
                var count = counts && counts[filter.get('id')] ? counts[filter.get('id')] : 0;
                filter.set('count', count);
            });
            return filters.sortBy('name');
        }.property('model.filters.[]', 'model.filters.length', 'countsLoaded', 'filtersLoaded'),

        loadRequestCounts: function loadRequestCounts() {
            var _this = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                _this.get('api').ajax({
                    type: 'GET',
                    url: '/api/' + _this.get('session.data.authenticated.accountId') + '/requests/counts',
                    dataType: 'json'
                }).then(function (response) {
                    var props = {};
                    Object.keys(response).forEach(function (key) {
                        props[key] = response[key];
                    });
                    Ember.run(function () {
                        _this.set('counts', props);
                        _this.set('countsLoaded', new Date().getTime());
                        resolve();
                    });
                }, function () {
                    reject();
                });
            });
            this.set('requestCountPromise', promise);
        },

        actions: {
            search: function search(q) {
                this.transitionToRoute('requests.search', { queryParams: { q: q } });
            }
        }
    });
});