define('huyang/components/pm-program/set-info', ['exports', 'huyang/templates/components/pm-program/set-info', 'huyang-common/mixins/request-info'], function (exports, _setInfo, _requestInfo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend(_requestInfo.default, {
    layout: _setInfo.default,
    setDueAfter: true,
    allowBalancing: false,
    session: Ember.inject.service(),
    varyAssignment: Ember.computed.equal('assignToStaff', 'individual'),

    showAlert: Ember.computed('request.assetCategory', 'edit', function () {
      return this.get('request.assetCategory') && !this.get('edit');
    }),

    activeAssets: Ember.computed('request.assetCategory.activeAssets', function () {
      return this.get('request.assetCategory.activeAssets.length') || 0;
    }),

    validations: {
      'request.title': { presence: true },
      'request.priority': { presence: true },
      'request.assetCategory': { presence: true },
      'request.requestType': { presence: true },
      'request.estimatedHours': { presence: true },
      'assignToStaff': { presence: true }
    },

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('edit')) {
        var dueAfter = this.get('request.schedule.dueAfter');
        if (dueAfter) {
          var _dueAfter$split = dueAfter.split(' '),
              _dueAfter$split2 = _slicedToArray(_dueAfter$split, 2),
              interval = _dueAfter$split2[0],
              unit = _dueAfter$split2[1];

          this.set('dueAfterInterval', interval);
          this.set('dueAfterUnit', unit);
          this.set('setDueAfter', true);
        }
        if (!this.get('assignToStaff')) {
          this.set('assignToStaff', 'none');
        }
      }

      var buildingOwners = this.get('request.pmProgramBuildingOwners') || [];
      if (buildingOwners.get('length') > 0) {
        var allAssignment = buildingOwners.findBy('building.id', undefined);
        if (allAssignment) {
          this.set('assignToStaff', 'all');
          this.set('assignToStaffMember', allAssignment.get('owner'));
        } else {
          this.set('assignToStaff', 'individual');
          buildingOwners.forEach(function (bo) {
            var building = _this.get('programBuildings').findBy('id', bo.get('building.id'));
            building.assignToStaffMember = bo.get('owner');
          });
        }
      }
    },


    programBuildings: Ember.computed('buildings', function () {
      var _this2 = this;

      if (!this.get('buildings')) {
        return;
      }

      return this.get('buildings').sortBy('name').map(function (b) {
        return {
          id: b.get('id'),
          name: b.get('name'),
          users: _this2.get('users').filter(function (u) {
            return u.hasStaffRole(_this2.get('session.data.authenticated.accountId'), b.get('id'));
          })
        };
      });
    }),

    staffUsers: Ember.computed('users', function () {
      var _this3 = this;

      if (!this.get('users')) {
        return;
      }
      return this.get('users').filter(function (u) {
        return u.hasAnyRole(_this3.get('session.data.authenticated.accountId'), ['staff-all', 'staff', 'admin']);
      });
    }),

    showRepeatText: Ember.computed('edit', 'repeatText', function () {
      return this.get('repeatText') && !this.get('edit');
    }),

    setAssignment: function setAssignment() {
      var assignment = void 0;
      if (this.get('assignToStaff') === 'none') {
        assignment = null;
      } else if (this.get('assignToStaff') === 'all') {
        assignment = { 'All': this.get('assignToStaffMember.id') };
      } else {
        // the "all" assignment record will stick around until refresh if not
        // manually removed
        var allAssignment = (this.get('request.pmProgramBuildingOwners') || []).findBy('building.id', undefined);
        if (allAssignment) {
          this.set('request.pmProgramBuildingOwners', []);
          allAssignment.deleteRecord();
        }

        assignment = {};
        this.get('programBuildings').forEach(function (b) {
          if (b.assignToStaffMember) {
            assignment[b.id] = b.assignToStaffMember.get('id');
          }
        });
      }

      return assignment;
    },

    saveRequest: function saveRequest() {
      var _this4 = this;

      this.set('isLoading', true);
      var attachmentsList = this.get('attachmentsList');
      var modelName = this.get('request.constructor.modelName');

      if (modelName) {
        // it's an existing model that we just need to update
        var request = this.get('request');

        this.set('request.pmAssignee', this.setAssignment());

        this.set('request.updated', new Date());
        this.set('request.schedule.dueAfter', this.get('dueAfter'));
        this.get('request.schedule').save();

        if (attachmentsList) {
          this.set('request.attachment', attachmentsList.map(function (file) {
            return file.get('id');
          }));
        }

        request.save().then(function () {
          if (!_this4.get('assignModeStaff')) {
            _this4.saveServiceRequest(request).then(function () {
              _this4.set('isLoading', false);
              _this4.sendAction('requestSaved', request);
            });
          } else {
            _this4.sendAction('requestSaved', request);
          }
        });
      } else {
        // this is a brand new request
        var info = this.get('request');

        // info.account = this.get('request.building.account');
        info.account = this.get('session.currentAccount');

        // set a bunch of default data for the new request
        info.created = new Date();
        info.updated = info.created;
        info.opened = info.created;
        info.secondsOpen = 0;
        info.status = 'open';
        info.originator = this.get('session.currentUser');
        info.dueAfter = this.get('dueAfter');
        info.allowBalancing = this.get('allowBalancing');

        info.pmAssignee = this.setAssignment();

        if (this.get('checklist')) {
          info.checklist = this.get('checklist');
        }
        // if dates are moments, convert so Ember can serialize
        if (info.dueAfter && info.dueAfter.toDate) {
          info.dueAfter = info.dueAfter.toDate();
        }
        if (info.createDate && info.createDate.toDate) {
          info.createDate = info.createDate.toDate();
        }

        if (attachmentsList && attachmentsList.length) {
          info.attachment = attachmentsList.map(function (file) {
            return file.get('id');
          });
        }

        var newRequest = this.get('store').createRecord('pm-program', info);

        newRequest.save().then(function () {
          _this4.set('isLoading', false);
          _this4.sendAction('requestSaved', newRequest, []);
        });
      }
    },

    actions: {
      addChecklist: function addChecklist() {
        var _this5 = this;

        var data = {
          'account': localStorage.getItem('accountId')
        };

        this.get('store').createRecord('request-checklist', data).save().then(function (checklist) {
          _this5.set('checklist', checklist);
        });
      },
      setRepeatRule: function setRepeatRule(props) {
        this.set('afterCloseTemp', {});
        this.set('repeatTemp', props);

        // Fields have default values, but temp props need to be updated for display
        if (!this.get('initialRepeatSet')) {
          this.set('repeatRule', props.repeatRule);
          this.set('createDate', props.createDate);
          this.set('allowBalancing', props.allowBalancing);
          this.set('balancingSchedule', props.balancingSchedule);
          this.set('initialRepeatSet', true);
        }
      },

      updateAssignToStaff: function updateAssignToStaff(value) {
        this.set('assignToStaff', value);
      },
      removeChecklist: function removeChecklist() {
        this.get('store').findRecord('request-checklist', this.get('checklist.id')).then(function (checklist) {
          checklist.destroyRecord();
        });
        this.set('checklist', null);
      }
    }
  });
});