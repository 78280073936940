define('huyang/controllers/dashboard', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),

        myRequests: function () {
            var userId = parseInt(this.get('session.data.authenticated.userId'));
            return this.get('model.requests').filter(function (req) {
                return parseInt(req.get('owner.id')) === userId;
            });
        }.property('model.requests'),

        unassignedRequests: function () {
            return this.get('model.requests').filter(function (req) {
                return !req.get('owner');
            });
        }.property('model.requests')
    });
});