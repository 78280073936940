define('huyang/mixins/uses-multiselect-menu', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        multiselectOptions: {
            allSelectedText: 'All',
            nonSelectedText: 'All',
            enableCaseInsensitiveFiltering: true,
            enableClickableOptGroups: true,
            includeSelectAllOption: false,
            includeFilterClearBtn: false, // doesn't seem to work
            buttonText: function buttonText(options, select) {
                var first = options[0];

                if (options.length === 0) {
                    return this.nonSelectedText;
                }

                if (select.find('option').length === options.length || Ember.$(first).val() === '--all--') {
                    return this.allSelectedText;
                }

                if (this.slim && this.multiple !== false) {
                    return options.length;
                } else {
                    if (options.length === 1) {
                        // show name of first option
                        var label = Ember.$(first).text().trim();
                        return label;
                    }

                    return options.length + ' selected';
                }
            }
        }
    });
});