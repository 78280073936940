define('huyang/routes/admin/account', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    api: Ember.inject.service(),

    model: function model() {
      return this.get('api').ajax({
        type: 'GET',
        url: '/api/accounts',
        dataType: 'json'
      });
    }
  });
});