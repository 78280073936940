define('huyang/components/view-filters', ['exports', 'huyang-common/mixins/displays-priority', 'huyang/mixins/uses-multiselect-menu'], function (exports, _displaysPriority, _usesMultiselectMenu) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_displaysPriority.default, _usesMultiselectMenu.default, {
        timeframe: '1 w',
        startDate: '',
        endDate: '',

        slimOptions: function () {
            var options = Ember.copy(this.get('multiselectOptions'));

            return Ember.merge(options, {
                slim: true
            });
        }.property('multiselectOptions'),

        // search and select all not needed with only two options
        statusMultiselectOptions: function () {
            var options = Ember.copy(this.get('multiselectOptions'));

            return Ember.merge(options, {
                enableCaseInsensitiveFiltering: false,
                includeSelectAllOption: false
            });
        }.property('multiselectOptions'),

        recurringMultiselectOptions: function () {
            var options = Ember.copy(this.get('multiselectOptions'));

            return Ember.merge(options, {
                enableCaseInsensitiveFiltering: false,
                includeSelectAllOption: false
            });
        }.property('multiselectOptions'),

        requestLabelMultiselectOptions: function () {
            var options = Ember.copy(this.get('multiselectOptions'));

            return Ember.merge(options, {
                enableCaseInsensitiveFiltering: false
            });
        }.property('multiselectOptions'),

        priorityMultiselectOptions: function () {
            var options = Ember.copy(this.get('multiselectOptions'));

            return Ember.merge(options, {
                enableCaseInsensitiveFiltering: false
            });
        }.property('multiselectOptions'),

        ownerMultiselectOptions: function () {
            var options = Ember.copy(this.get('multiselectOptions'));

            return Ember.merge(options, {
                enableHTML: true,
                optionLabel: function optionLabel(element) {
                    return Ember.$(element).html();
                }
            });
        }.property('multiselectOptions'),

        dateSelectOptions: function () {
            var options = Ember.copy(this.get('multiselectOptions'));

            return Ember.merge(options, {
                enableCaseInsensitiveFiltering: false
            });
        }.property('multiselectOptions'),

        customRangeOptions: Ember.computed(function () {
            var today = moment('00:00:00', 'HH:mm:ss');
            var quarters = [0, 3, 6, 9]; // 0-based months
            var quarter = Math.floor(today.get('month') / 3);

            return [{
                label: 'Past 24 hours',
                timeframe: '1 d',
                range: [moment(today).subtract(1, 'days'), today]
            }, {
                label: 'Past week',
                timeframe: '1 w',
                range: [moment(today).subtract(7, 'days'), today]
            }, {
                label: 'Past two weeks',
                timeframe: '2 w',
                range: [moment(today).subtract(14, 'days'), today]
            }, {
                label: 'Past 30 days',
                timeframe: '30 d',
                range: [moment(today).subtract(30, 'days'), today]
            }, {
                label: 'Past 90 days',
                timeframe: '90 d',
                range: [moment(today).subtract(90, 'days'), today]
            }, {
                label: 'Past year',
                timeframe: '1 y',
                range: [moment(today).subtract(1, 'year'), today]
            }, {
                label: 'Current month to date',
                timeframe: 'this month',
                range: [moment(today).set('date', 1), today]
            }, {
                label: 'Previous month',
                timeframe: 'previous month',
                range: [moment().subtract(1, 'month').set('date', 1), moment().set('date', 1).subtract(1, 'day')]
            }, {
                label: 'Current quarter to date',
                timeframe: 'this quarter',
                range: [moment().set({ month: quarters[quarter], date: 1 }), today]
            }, {
                label: 'Previous quarter',
                timeframe: 'previous quarter',
                range: [moment().set({ month: quarters[quarter], date: 1 }).subtract(3, 'months'), moment().set({ month: quarters[quarter], date: 1 }).subtract(1, 'day')]
            }, {
                label: 'Current year to date',
                timeframe: 'this year',
                range: [moment(today).set({ 'date': 1, 'month': 0 }), today]
            }, {
                label: 'Previous year',
                timeframe: 'previous year',
                range: [moment(today).subtract(1, 'year').set({ month: 0, date: 1 }), moment(today).set({ month: 0, date: 1 }).subtract(1, 'day')]
            }, {
                label: 'Entire date range',
                timeframe: 'any',
                range: [moment(today).subtract(10, 'years'), today]
            }, {
                label: 'Next 7 days (pending)',
                timeframe: '+7 d',
                range: [today, moment(today).add(7, 'days')]
            }, {
                label: 'Next 30 days (pending)',
                timeframe: '+30 d',
                range: [today, moment(today).add(30, 'days')]
            }];
        }),

        filtersToString: function filtersToString(name) {
            // search returns filters as ints but Ember and form use strings
            var filters = this.get('filters.' + name) || [];
            // dueDateRange is single value ("3 d")
            if (Array.isArray(filters)) {
                return filters.map(function (id) {
                    return id + '';
                });
            } else {
                return filters;
            }
        },

        statusOptions: function () {
            var statusFilter = this.get('filters.status');

            return ['open', 'closed', 'pending', 'template'].map(function (status) {
                return Ember.Object.create({
                    id: status,
                    label: status.capitalize(),
                    selected: statusFilter.includes(status)
                });
            });
        }.property('filters.status'),

        recurringOptions: function () {
            // recurring true/false used to mean template/non-template
            // now we have an additional value (repeating) for requests created from repeating template
            var recurFilter = this.get('filters.recurring') || [];

            return [Ember.Object.create({ // aren't associated with a template
                id: 'false',
                label: 'Non-repeating requests',
                selected: recurFilter.includes('false')
            }), Ember.Object.create({ // created from a template
                id: 'repeating',
                label: 'Repeating requests',
                selected: recurFilter.includes('repeating')
            }), Ember.Object.create({ // is a template
                id: 'true',
                label: 'Repeating request templates',
                selected: recurFilter.includes('true')
            })];
        }.property('filters.recurring'),

        priorityOptions: function () {
            var priorities = this.get('priorityMap');
            var priorityFilter = this.filtersToString('priority');

            return Object.keys(priorities).map(function (priority) {
                var priorityStr = priority + '';

                return Ember.Object.create({
                    id: priorityStr,
                    label: priorities[priority].label,
                    selected: priorityFilter.includes(priorityStr)
                });
            });
        }.property('filters.priority'),

        // helper for list of objects that have id and name
        objOptions: function objOptions(filter, list) {
            if (!list) {
                return [];
            }

            return list.map(function (obj) {
                return Ember.Object.create({
                    id: obj.get('id'),
                    label: obj.get('name'),
                    selected: filter.includes(obj.get('id'))
                });
            }).sortBy('label');
        },

        requestLabelOptions: function () {
            var requestLabels = this.get('requestLabels');
            if (!requestLabels) {
                return [];
            }

            var filter = this.filtersToString('requestLabel');
            return requestLabels.sortBy('position', 'name').map(function (label) {
                return Ember.Object.create({
                    id: label.get('id'),
                    label: label.get('name'),
                    selected: filter.includes(label.get('id'))
                });
            });
        }.property('requestLabels', 'filters.requestLabel'),

        requestTypeOptions: function () {
            var reqTypes = this.get('requestTypes');

            if (!reqTypes) {
                return [];
            }

            var filter = this.filtersToString('requestType');
            return reqTypes.sortBy('label').map(function (reqType) {
                return Ember.Object.create({
                    id: reqType.get('id'),
                    label: reqType.get('label'),
                    selected: filter.includes(reqType.get('id'))
                });
            });
        }.property('requestTypes', 'filters.requestType'),

        ownerOptions: function () {
            var users = this.get('owners');
            var filter = this.filtersToString('owner');

            if (!users) {
                return [];
            }

            var options = users.map(function (obj) {
                return Ember.Object.create({
                    id: obj.get('id'),
                    label: obj.get('displayName'),
                    user: obj,
                    selected: filter.includes(obj.get('id'))
                });
            });

            options.push(Ember.Object.create({
                id: 'unassigned',
                label: 'Unassigned',
                selected: filter.includes('unassigned')
            }));

            return options.sortBy('label');
        }.property('owners', 'filters.owner'),

        creatorOptions: function () {
            var users = this.get('creators');
            var filter = this.filtersToString('originator');

            if (!users) {
                return [];
            }

            var options = users.map(function (obj) {
                return Ember.Object.create({
                    id: obj.get('id'),
                    label: obj.get('displayName'),
                    user: obj,
                    selected: filter.includes(obj.get('id'))
                });
            });

            return options.sortBy('label');
        }.property('creators', 'filters.originator'),

        buildingOptions: function () {
            var buildingList = this.get('buildings').map(function (building) {
                var name = building.get('name');
                if (!building.get('active')) {
                    name += ' (closed)';
                }
                var bldg = Ember.Object.create({
                    'id': building.get('id'),
                    'name': name
                });
                return bldg;
            });
            return this.objOptions(this.filtersToString('building'), buildingList);
        }.property('buildings', 'filters.building'),

        filteredBuildings: function () {
            var _this = this;

            var buildings = this.get('filters.building').map(function (buildingId) {
                return _this.get('buildings').find(function (building) {
                    // Saved views have building IDs stored as Numbers
                    return building.id === String(buildingId);
                });
            });
            // filter might have building that no longer exists
            return buildings.filter(function (b) {
                return b;
            });
        }.property('filters.building', 'filters.building.@each', 'buildings.@each'),

        hasSubspaces: function () {
            if (!this.get('filteredBuildings.length')) {
                return false;
            }
            return Boolean(this.get('filteredBuildings').find(function (building) {
                return building.get('subspaces.length');
            }));
        }.property('filteredBuildings.length'),

        subspaceOptions: function subspaceOptions(filter, list) {
            if (!list) {
                return [];
            }

            return list.map(function (obj) {
                return Ember.Object.create({
                    id: obj.get('id'),
                    label: obj.get('name'),
                    selected: filter.includes(obj.get('id'))
                });
            });
        },

        subspaceOptionGroups: function () {
            var _this2 = this;

            var groups = [];
            this.get('filteredBuildings').map(function (building) {
                var subspaces = building.get('subspaces').sortBy('position', 'name');
                if (subspaces.length) {
                    groups.push({
                        optionLabel: building.get('name'),
                        options: _this2.subspaceOptions(_this2.filtersToString('subspace'), subspaces)
                    });
                }
            });
            return groups;
        }.property('filteredBuildings'),

        groupOptions: function () {
            return this.objOptions(this.filtersToString('group'), this.get('groups'));
        }.property('groups', 'filters.group'),

        dueDateMultiselectOptions: function () {
            var options = Ember.copy(this.get('multiselectOptions'));

            return Ember.merge(options, {
                enableCaseInsensitiveFiltering: false,
                includeSelectAllOption: false,
                allSelectedText: 'Any date (or none)',
                nonSelectedText: 'Any date (or none)'
            });
        }.property('multiselectOptions'),

        dueDateOptions: function () {
            var dueDateFilter = this.filtersToString('dueDateRange');
            var options = [{ id: '-1 d', label: 'Overdue' }, { id: '1 d', label: 'Within 1 day' }, { id: '2 d', label: 'Within 2 days' }, { id: '3 d', label: 'Within 3 days' }, { id: '4 d', label: 'Within 4 days' }, { id: '5 d', label: 'Within 5 days' }, { id: '1 w', label: 'Within 1 week' }, { id: '2 w', label: 'Within 2 weeks' }, { id: '1 m', label: 'Within 1 month' }];
            return options.map(function (filter) {
                filter.selected = dueDateFilter === filter.id;
                return Ember.Object.create(filter);
            });
        }.property('filters.dueDateRange'),

        updateMultiFilter: function updateMultiFilter(name, values) {
            this.set('filters.' + name, values);
            this.sendAction('action', this.get('filters'));
        },

        filterDate: function () {
            var tf = this.get('timeframe');
            var prevDateRange = this.get('filters.dateRange');

            if (typeof tf === 'string') {
                this.set('filters.dateRange', tf);
            } else if (tf.start && tf.end) {
                var start = moment(tf.start, 'YYYY-MM-DD').format('YYYY-MM-DD');
                var end = moment(tf.end, 'YYYY-MM-DD').format('YYYY-MM-DD');

                var range = start + ' ' + end;
                this.set('filters.dateRange', range);
            }

            if (this.get('filters.dateRange') !== prevDateRange) {
                this.sendAction('action', this.get('filters'));
            }
        }.observes('timeframe'),

        moreCount: function () {
            var _this3 = this;

            var props = ['priority', 'owner', 'originator', 'status', 'group', 'requestLabel', 'dueDateRange', 'recurring'];
            var count = 0;

            props.forEach(function (prop) {
                if (_this3.get('filters.' + prop + '.length') > 0) {
                    count++;
                }
            });

            return count;
        }.property('filters.priority', 'filters.owner', 'filters.originator', 'filters.status', 'filters.group', 'filters.requestLabel', 'filters.priority.[]', 'filters.owner.[]', 'filters.originator.[]', 'filters.status.[]', 'filters.group.[]', 'filters.requestLabel.[]'),

        setupTimeframe: function () {
            var range = this.get('filters.dateRange');

            if (range) {
                if (range === '1 m') {
                    range = '30 d';
                }
                if (range === '3 m') {
                    range = '90 d';
                }
                this.set('timeframe', range);
            }
        }.on('didInsertElement').observes('filters.dateRange'),

        clickHandler: function clickHandler(ev) {
            // in phantomjs: ev.target.closest causes TypeError: undefined is not a constructor
            if (!ev.target.closest) {
                return;
            }
            // do nothing if click is in bar-more-filters or more-filters
            if (ev.target.closest('.more-filters') || ev.target.closest('.bar-more-filters')) {
                return;
            }
            // if .more-filters is visible and click is outside it, close it
            if (Ember.$('.more-filters.tether-element:visible').length) {
                Ember.$('.more-filters').toggle();
            }
        },

        setupListener: function () {
            this.set('clickHandler', Ember.run.bind(this, this.clickHandler));
            Ember.$(document).click(this.get('clickHandler'));
        }.on('didRender'),

        removeListener: function () {
            Ember.$(document).off('click', this.get('clickHandler'));
        }.on('willDestroyElement'),

        actions: {
            filterStatus: function filterStatus(values) {
                this.updateMultiFilter('status', values);
            },

            filterPriority: function filterPriority(values) {
                this.updateMultiFilter('priority', values);
            },

            filterBuilding: function filterBuilding(values, targetValue) {
                if (values === null || values.length === 0) {
                    // Buildings were cleared, so clear subspaces
                    this.set('filters.building', []);
                    this.set('filters.subspace', []);
                    this.sendAction('action', this.get('filters'));
                } else if (values.length >= this.get('filters.building').length) {
                    // Building was added, no subspaces to change
                    this.updateMultiFilter('building', values);
                } else {
                    // Check filtered subspaces against those belonging to removed building
                    var subspacesToClear = this.get('filteredBuildings').find(function (building) {
                        return building.id === targetValue;
                    }).get('subspaces').map(function (subspace) {
                        return subspace.id;
                    });

                    var subspacesToKeep = this.get('filters.subspace').filter(function (subspaceId) {
                        return !subspacesToClear.includes(subspaceId);
                    });

                    this.set('filters.building', values);
                    this.set('filters.subspace', subspacesToKeep);
                    this.sendAction('action', this.get('filters'));
                }
            },

            filterSubspace: function filterSubspace(values) {
                this.updateMultiFilter('subspace', values);
            },

            filterOwner: function filterOwner(values) {
                this.updateMultiFilter('owner', values);
            },

            filterCreator: function filterCreator(values) {
                this.updateMultiFilter('originator', values);
            },

            filterGroup: function filterGroup(values) {
                this.updateMultiFilter('group', values);
            },

            filterRequestType: function filterRequestType(values) {
                this.updateMultiFilter('requestType', values);
            },

            filterRequestLabel: function filterRequestLabel(values) {
                this.updateMultiFilter('requestLabel', values);
            },

            filterDueDate: function filterDueDate(value) {
                if (value === '--all--') {
                    value = null;
                }
                this.set('filters.dueDateRange', value);
                this.sendAction('action', this.get('filters'));
            },

            filterRecurring: function filterRecurring(values) {
                this.updateMultiFilter('recurring', values);
            },

            closeMoreFilters: function closeMoreFilters() {
                if (Ember.$('.more-filters.tether-element:visible').length) {
                    Ember.$('.more-filters').toggle();
                }
            },

            toggleMoreFilters: function toggleMoreFilters() {
                if (this.popover) {
                    // just show/hide it if it already has been tethered
                    Ember.$('.more-filters').toggle();
                } else {
                    // tether the top right corner of the popover
                    // to the bottom right corner of the button

                    this.popover = new Tether({
                        element: '.more-filters',
                        target: '.bar-more-filters',
                        attachment: 'top right',
                        targetAttachment: 'bottom right'
                    });
                }
            }
        }
    });
});