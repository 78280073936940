define('huyang/controllers/people/vendors/vendor', ['exports', 'ember-validations', 'huyang-common/mixins/modal-helper', 'huyang-common/mixins/validates-email'], function (exports, _emberValidations, _modalHelper, _validatesEmail) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalHelper.default, _validatesEmail.default, {
        intlTelInput: Ember.inject.service(),
        vendor: Ember.computed.alias('model.vendor'),
        trades: Ember.computed.alias('model.trades'),

        hideDelete: function () {
            return this.get('vendor.serviceRequests.length') || this.get('vendor.technicians.length') || this.get('vendor.certificates.length');
        }.property('vendor.serviceRequests.@each', 'vendor.technicians.@each', 'vendor.certificates.@each'),

        confirmDeleteTitle: function () {
            return 'Delete \'' + this.get('vendor.name') + '\'';
        }.property('vendor.name'),

        editTitle: function () {
            return 'Edit \'' + this.get('vendor.name') + '\'';
        }.property('vendor.name'),

        fields: function () {
            var controller = this;
            return [{
                name: 'name',
                value: this.get('vendor.name'),
                label: 'Vendor Name',
                placeholder: 'ACME Plumbers',
                validations: {
                    presence: { message: 'Name must be present' }
                }
            }, {
                name: 'tradeId',
                value: this.get('vendor.primaryTrade.id'),
                label: 'Primary Trade',
                prompt: 'Choose a trade...',
                select: true,
                data: this.get('model.trades'),
                keyPath: 'id',
                labelPath: 'label'
            }, {
                name: 'mobilePhone',
                label: 'Mobile phone',
                component: 'huyang-form-phone',
                properties: Ember.copy({
                    phone: this.get('vendor.phone')
                }),
                validations: {
                    inline: (0, _emberValidations.validator)(function () {
                        var validation = controller.get('intlTelInput').validate();

                        var errors = {};
                        if (validation.error) {
                            errors.mobilePhone = validation.error;
                            this.errors.pushObject(errors.mobilePhone);
                        }
                    })
                }
            }, {
                name: 'email',
                value: this.get('vendor.primaryUser.email'),
                label: 'Service Email Address',
                placeholder: 'service@plumbers.com',
                validations: this.get('emailValidations')
            }];
        }.property('vendor.name', 'vendor.primaryTrade', 'vendor.phone', 'vendor.primaryUser', 'model.trades'),

        actions: {
            openModal: function openModal(id) {
                this.openModal(id);
            },

            openDelete: function openDelete() {
                this.openModal('confirmDelete');
            },

            confirmDelete: function confirmDelete() {
                var _this = this;

                this.get('vendor').destroyRecord().then(function () {
                    _this.closeModal();
                    _this.get('flashMessages').success('Vendor deleted!');
                    _this.transitionToRoute('people.vendors');
                });
            },

            cancel: function cancel() {
                this.closeModal();
            },

            saveChanges: function saveChanges(values, onComplete) {
                var _this2 = this;

                var vendor = this.get('vendor');

                if (vendor.get('name') !== values.name) {
                    vendor.set('name', values.name);
                    vendor.get('primaryUser').then(function (user) {
                        user.set('name', values.name);
                        user.save();
                    });
                }

                if (vendor.get('phone') !== values.phone) {
                    vendor.set('phone', this.get('intlTelInput.iti').getNumber());
                }

                if (vendor.get('primaryTrade.id') !== values.tradeId) {
                    vendor.set('primaryTrade', this.get('model.trades').find(function (trade) {
                        return trade.get('id') === values.tradeId;
                    }));
                }

                if (vendor.get('primaryUser.email') !== values.email) {
                    // Create new user and save it first
                    var user = this.get('store').createRecord('user', {
                        name: values.name,
                        email: values.email
                    });
                    user.save().then(function () {
                        // add vendor role to user
                        user.addRole(_this2.get('store').peekRecord('account', localStorage.getItem('accountId')), 'vendor');
                        vendor.set('primaryUser', user);
                        _this2.saveVendor(vendor, onComplete);
                    });
                } else {
                    this.saveVendor(vendor, onComplete);
                }
            }
        },

        // actions helpers

        saveVendor: function saveVendor(vendor, onSuccess) {
            var _this3 = this;

            vendor.save().then(function () {
                if (typeof onSuccess === 'function') {
                    onSuccess();
                }
                _this3.send('cancel');
            });
        }
    });
});