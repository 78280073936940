define('huyang/controllers/requests/preventative-maintenance/program/info', ['exports', 'huyang-common/mixins/modal-helper'], function (exports, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalHelper.default, {
        loadedGalleryImages: [],
        loadedGalleryUrls: [],

        actions: {
            addChecklist: function addChecklist() {
                var _this = this;

                var data = {
                    'account': localStorage.getItem('accountId'),
                    'pmProgram': this.get('model.pmProgram')
                };

                this.get('store').createRecord('request-checklist', data).save().then(function (checklist) {
                    _this.set('model.pmProgram.checklist', checklist);
                    _this.get('model.pmProgram').save();
                });
            },
            deleteProgram: function deleteProgram() {
                this.openModal('confirmDelete');
            },
            editProgram: function editProgram() {
                this.get('request').startEdit();
                this.openModal('editProgram');
            },
            confirmDelete: function confirmDelete() {
                var _this2 = this;

                this.get('model.pmProgram').destroyRecord().then(function () {
                    _this2.get('flashMessages').success('Program deleted');
                    _this2.transitionToRoute('requests.preventative-maintenance');
                });
            },
            removeChecklist: function removeChecklist() {
                this.get('store').findRecord('request-checklist', this.get('model.pmProgram.checklist.id')).then(function (checklist) {
                    checklist.destroyRecord();
                });
                this.set('model.pmProgram.checklist', null);
            }
        }
    });
});