define('huyang/components/request-table-asset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // use a computed property so that Ember will notice when an asset loads async
    value: Ember.computed('item.asset.tag', 'item.asset.description', 'item.assetCategory.title', function val() {
      return this.get('item.' + this.get('key'));
    })
  });
});