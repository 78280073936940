define('huyang/routes/requests/filters/filter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        limit: 50,

        queryParams: {
            dateRange: { refreshModel: true },
            dueDateRange: { refreshModel: true },

            sort: { refreshModel: true },
            from: { refreshModel: true },

            // multi-valued
            building: { refreshModel: true },
            subspace: { refreshModel: true },
            owner: { refreshModel: true },
            originator: { refreshModel: true },
            priority: { refreshModel: true },
            requestType: { refreshModel: true },
            requestLabel: { refreshModel: true },
            status: { refreshModel: true },
            group: { refreshModel: true },
            recurring: { refreshModel: true },

            buildingAll: { refreshModel: true },
            dueDateRangeAll: { refreshModel: true },
            subspaceAll: { refreshModel: true },
            ownerAll: { refreshModel: true },
            originatorAll: { refreshModel: true },
            priorityAll: { refreshModel: true },
            requestTypeAll: { refreshModel: true },
            requestLabelAll: { refreshModel: true },
            statusAll: { refreshModel: true },
            groupAll: { refreshModel: true },
            recurringAll: { refreshModel: true }
        },

        model: function model(params) {
            var parent = this.modelFor('requests/filters');
            return Ember.RSVP.hash({
                filter: this.store.findRecord('filter', params.filter_id),
                results: this.store.query('filter-search-result', params),
                buildings: parent.buildings,
                users: parent.users,
                requestTypes: parent.requestTypes,
                groups: parent.groups,
                viewConfig: this.store.queryRecord('view-config', { view_name: params.filter_id }),
                requestLabels: parent.requestLabels,
                requestSchedules: parent.requestSchedules
            });
        },

        afterModel: function afterModel(model) {
            var meta = model.results.get('meta');
            this.set('total', meta.total || 0);
            this.set('aggregations', meta.aggregations);
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('total', this.get('total'));
            controller.set('limit', this.get('limit'));
            controller.set('aggregations', this.get('aggregations'));
        },

        resetController: function resetController(controller, isExiting, transition) {
            this._super(controller, isExiting, transition);
            controller.clearQueryParams();
        },

        clearMenus: function () {
            Ember.$('.more-filters.tether-element').remove();
        }.on('deactivate'),

        actions: {
            loading: function loading() /*transition, originRoute*/{
                var controller = this.controllerFor('requests.filters.filter');
                controller.set('loading', true);
                this.router.on('didTransition', function () {
                    controller.set('loading', false);
                });
            },


            didTransition: function didTransition() {
                var controller = this.controllerFor('requests.filters.filter');

                if (controller.get('newFilterId')) {
                    var count = controller.get('newFilterCount') || 0;
                    Ember.$('#filterCount' + controller.get('newFilterId')).text(count);
                    controller.set('newFilterId', null);
                }
            }
        }
    });
});