define('huyang/mixins/keen-query', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        session: Ember.inject.service(),

        /*
            we only want the runQuery function to run once for each time the properties change
            so we will observe it here and delegate to the run loop.
        */
        updateQuery: function () {
            Ember.run.once(this, this.createQuery);
        }.observes('reportType', 'timeframe', 'groupBy', 'interval', 'filteredIds', 'groupLabels', 'startDate', 'endDate', 'category1', 'category2', 'repeating'),

        createQuery: function createQuery() {
            var _this = this;

            if (!this.keen) {
                this.setup();
            }
            Keen.ready(function () {
                var filters = [];

                if (_this.get('groupBy.length') && _this.get('filteredIds.length') > 0) {
                    filters.push({
                        operator: 'in',
                        property_name: _this.get('groupBy'),
                        property_value: _this.get('filteredIds').map(function (id) {
                            return parseInt(id, 10);
                        })
                    });
                }

                if (_this.get('groupBy') === 'group.id' || _this.get('category1') === 'group.id' || _this.get('category2') === 'group.id') {
                    // filter out null groups (staff users)
                    filters.push({
                        operator: 'exists',
                        property_name: 'group.id',
                        property_value: true
                    });
                }

                if (_this.get('repeating') !== '--all--') {
                    var f = { property_name: 'fromRepeatingTemplate', property_value: true };

                    if ('true' === _this.get('repeating')) {
                        f.operator = 'eq';
                    } else {
                        // include requests that don't have fromRepeatingTemplate set at all
                        // so that we only need to set the property for fromRepeatingTemplate requests
                        f.operator = 'ne';
                    }
                    filters.push(f);
                }

                var groupBy = _this.get('viz') === 'crosstab' ? [_this.get('category1'), _this.get('category2')] : _this.get('groupBy') || [];
                var params = {
                    eventCollection: _this.get('eventCollection'),
                    groupBy: groupBy,
                    timeframe: _this.get('timeframe'),
                    filters: filters,
                    viz: _this.get('viz')
                };

                // FIXME: It would be MORE than ideal to supply a timezone,
                // as this would allow Keen to adjust returned timeframe dates
                // according to DST, but (1) all existing reports would still
                // not be adjusted, and (2) it does not seem like DST works on
                // yearly intervals on Keen
                if (false) {
                    params.timezone = _this.get('session.currentUser.timezone');
                }

                if (_this.get('viz') === 'trend') {
                    params.interval = _this.get('interval');
                }
                if (_this.get('report').targetProperty) {
                    params.targetProperty = _this.get('report').targetProperty;
                }
                var qs = _this.get('report').analysis + ':' + JSON.stringify(params);
                console.log('keen-query: qs=\t', qs, '\nkeenQueryString=\t', _this.get('keenQueryString'));
                if (_this.get('keenQueryString') !== qs) {
                    Ember.run(function () {
                        _this.set('query', new Keen.Query(_this.get('report').analysis, params));
                        // use this for observers since query is a new object
                        _this.set('keenQueryString', _this.get('report').analysis + ':' + JSON.stringify(params));
                    });
                }
            });
        },

        runQuery: function () {
            var _this2 = this;

            console.log('keen-query: runQuery: ', this.get('query'));
            if (!this.get('query') || !this.get('keenQueryString')) {
                console.log('keen-query: no query');
                return;
            }

            this.set('running', true);

            // this promise is so tests can tell when the keen.run results are ready
            // but keen doesn't get along with phantomjs so it's not used
            //var promise = new Ember.RSVP.Promise((resolve, reject) => {
            this.keen.run(this.get('query'), function (err, response) {
                Ember.run(function () {
                    _this2.set('running', false);
                    console.log('keen-query: got data from query ', _this2.get('query'), response);
                    if (err) {
                        console.error(err);
                        //reject();
                    } else {
                        var postprocess = _this2.get('report').postprocess;
                        var data = response;

                        if (postprocess && _this2.get('postprocess')) {
                            var pp = postprocess(data.result);
                            data = { result: pp.results };

                            if (pp.meta && pp.meta.title) {
                                data.title = pp.meta.title;
                            }

                            data.meta = pp.meta;
                        }

                        _this2.set('data', data);
                        //resolve();
                    }
                });
                //});
            });

            //this.set('keenQueryPromise', promise);
        }.observes('keenQueryString')

    });
});