define('huyang/components/request-event', ['exports', 'huyang-common/utils/time', 'accounting/format-money'], function (exports, _time, _formatMoney) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var computed = Ember.computed;

    var et = 'eventType';

    exports.default = Ember.Component.extend({
        classNames: ['request-event', 'feed-panel'],

        store: Ember.inject.service(),

        priorityColors: { 1: '#fc331d', 2: '#fd8f25', 3: '#fbbd3f', 4: '#bbb' },

        info: computed.alias('event.info'),
        eventType: computed.alias('event.eventType'),
        eventDate: computed.alias('event.eventDate'),

        isOpen: computed.equal(et, 'open'),
        isClose: computed.equal(et, 'close'),
        isReopen: computed.equal(et, 'reopen'),
        isEdit: computed.equal(et, 'edit'),
        isRelabel: computed.equal(et, 'relabel'),
        isPriority: computed.equal(et, 'reprioritize'),
        isReassign: computed.equal(et, 'reassign'),
        isFollow: computed.equal(et, 'follow'),
        isUnfollow: computed.equal(et, 'unfollow'),

        isTimeLogAdd: computed.equal(et, 'time-log-add'),
        isTimeLogEdit: computed.equal(et, 'time-log-edit'),
        isTimeLogDelete: computed.equal(et, 'time-log-delete'),

        isTimerStart: computed.equal(et, 'timer-start'),
        isTimerPause: computed.equal(et, 'timer-pause'),
        isTimerResume: computed.equal(et, 'timer-resume'),
        isTimerCancel: computed.equal(et, 'timer-cancel'),

        isCostLogAdd: computed.equal(et, 'cost-log-add'),
        isCostLogEdit: computed.equal(et, 'cost-log-edit'),
        isCostLogDelete: computed.equal(et, 'cost-log-delete'),

        isChecklistAdd: computed.equal(et, 'checklist-add'),
        isChecklistDelete: computed.equal(et, 'checklist-delete'),
        isChecklistItemAdd: computed.equal(et, 'checklist-item-add'),
        isChecklistItemDelete: computed.equal(et, 'checklist-item-delete'),
        isChecklistItemEdit: computed.equal(et, 'checklist-item-edit'),
        isChecklistItemMarked: computed.equal(et, 'checklist-item-marked'),

        isFileUpload: computed.equal(et, 'file-upload'),
        isFileDelete: computed.equal(et, 'file-delete'),

        isCommentAdd: computed.equal(et, 'comment-add'),
        isCommentDelete: computed.equal(et, 'comment-delete'),

        isPendingOpen: computed.equal(et, 'pending-open'),
        isPreviousRepeating: computed.equal(et, 'previous-repeating'),

        isSingleEdit: function () {
            if (this.get(et) === 'edit' && this.get('info')) {
                return Object.keys(this.get('info')).length === 1;
            }
        }.property(),

        isMultiEdit: function () {
            return this.get('isEdit') && !this.get('isSingleEdit');
        }.property(),

        isComment: function () {
            return this.get(et).indexOf('comment') !== -1;
        }.property(),

        isAnyFollow: function () {
            return this.get(et).indexOf('follow') !== -1;
        }.property(),

        isOtherFollower: function () {
            // user id is a string, follower id in json is an int
            return this.get('isAnyFollow') && this.get('info.follower') !== Number(this.get('event.user.id'));
        }.property(),

        isOtherTimeLogger: function () {
            return (this.get('isTimeLog') || this.get('isTimer')) && Number(this.get('event.user.id')) !== Number(this.get('event.assignee.id'));
        }.property(),

        isAnyDelete: function () {
            return this.get(et).indexOf('delete') !== -1;
        }.property(),

        isTextBlock: function () {
            if (!this.get('isSingleEdit')) {
                return false;
            }

            var info = this.get('info');
            return !!(info.title || info.description);
        }.property(),

        isTimeLog: function () {
            return this.get(et).indexOf('time-log') === 0;
        }.property(),

        isTimer: function () {
            return this.get(et).indexOf('timer') === 0;
        }.property(),

        isCostLog: function () {
            return this.get(et).indexOf('cost-log') === 0;
        }.property(),

        isVendorResponse: function () {
            return this.get(et) === 'vendor-response';
        }.property(),

        isVendorAccept: function () {
            return this.get('isVendorResponse') && this.get('info.response') === 'yes';
        }.property('isVendorResponse', 'info.response'),

        isVendorDecline: function () {
            return this.get('isVendorResponse') && this.get('info.response') === 'no';
        }.property(),

        isVendorMaybe: function () {
            return this.get('isVendorResponse') && this.get('info.response') === 'maybe';
        }.property(),

        isPreviousRelabel: function () {
            return this.get('isPreviousRepeating') && 'requestLabel' in this.get('info');
        }.property(),

        eventActionText: function () {
            var user = this.get('event.user');
            var pending = this.get('info.status') === 'pending' ? 'pending ' : '';
            var template = this.get('info.status') === 'template' ? ' template' : '';

            if (!user.get('id')) {
                // There should only be a few kinds of events without users
                //   - Legacy status changes (open/close/reopen) prior to users being stored
                //   - Automated events, for repeating and pending
                var cachedTemplate = this.get('info.template.workOrder');
                switch (true) {
                    case this.get('isOpen') && !!cachedTemplate:
                        return 'The request was filed in the repeating series #' + cachedTemplate;
                    case this.get('isOpen'):
                        return 'The ' + pending + 'request' + template + ' was filed';
                    case this.get('isClose'):
                        return 'The request was closed';
                    case this.get('isReopen'):
                        return 'The request was reopened';
                    case this.get('isPendingOpen'):
                        return 'The pending request has transitioned to open';
                    case this.get('isPreviousRepeating'):
                        return this.get('previousRepeatingText');
                }
            }

            switch (true) {
                case this.get('isOpen'):
                    return 'filed the ' + pending + 'request' + template;
                case this.get('isClose'):
                    return 'closed the request';
                case this.get('isReopen'):
                    return 'reopened the request';
                case this.get('isRelabel'):
                    return 'set the label to';
                case this.get('isPriority'):
                    return 'set the priority to';
                case this.get('isReassign'):
                    return 'assigned the request to';

                case this.get('isTextBlock'):
                    return 'edited the ' + this.get('textBlockLabel');
                case this.get('isSingleEdit'):
                    return 'set the ' + this.get('singleEditLabel') + ' to';

                case this.get('isFileUpload'):
                    return 'uploaded';
                case this.get('isFileDelete'):
                    return 'deleted';

                case this.get('isCommentAdd'):
                    return this.get('commentAddText');
                case this.get('isCommentDelete'):
                    return 'deleted a message to';

                case this.get('isFollow') && this.get('isOtherFollower'):
                    return 'added';
                case this.get('isUnfollow') && this.get('isOtherFollower'):
                    return 'removed';
                case this.get('isFollow'):
                    return 'followed the request';
                case this.get('isUnfollow'):
                    return 'unfollowed the request';

                case this.get('isTimeLogAdd'):
                    return this.get('info.manualTime') ? 'logged' : 'tracked';
                case this.get('isTimeLogEdit'):
                    return 'updated';
                case this.get('isTimeLogDelete'):
                    return 'removed';

                case this.get('isTimerStart'):
                    return 'began tracking time';
                case this.get('isTimerPause'):
                    return 'paused a timer';
                case this.get('isTimerResume'):
                    return 'resumed a timer';
                case this.get('isTimerCancel'):
                    return 'canceled a timer';

                case this.get('isCostLogAdd'):
                    return 'logged';
                case this.get('isCostLogEdit'):
                    return 'updated';
                case this.get('isCostLogDelete'):
                    return 'removed';

                case this.get('isChecklistAdd'):
                    return 'added a checklist';
                case this.get('isChecklistDelete'):
                    return 'removed a checklist';
                case this.get('isChecklistItemAdd'):
                    return 'added "' + this.get('info.body') + '" checklist item';
                case this.get('isChecklistItemDelete'):
                    return 'removed "' + this.get('info.body') + '" checklist item';
                case this.get('isChecklistItemEdit'):
                    return 'changed checklist item from "' + this.get('info.old_body') + '" to "' + this.get('info.body') + '" ';
                case this.get('isChecklistItemMarked'):
                    return 'marked "' + this.get('info.body') + '" as ' + (this.get('info.completed') ? 'complete' : 'not complete');

                case this.get('isVendorResponse'):
                    return this.get('vendorResponseText');

                default:
                    return 'edited the request';
            }
        }.property(),

        eventSubjectText: function () {
            switch (true) {
                case this.get('isPreviousRelabel'):
                case this.get('isRelabel'):
                    return this.get('requestLabelText');
                case this.get('isReassign'):
                    return this.get('assigneeText');
                case this.get('isPriority'):
                    return this.get('priorityText');
                case this.get('isComment'):
                    return true;
                case this.get('isOtherFollower'):
                    return this.get('followerText');
                case this.get('isTimeLog'):
                    return this.get('timeLogText');
                case this.get('isCostLog'):
                    return this.get('costLogText');

                case !this.get('isTextBlock') && this.get('isSingleEdit'):
                    return this.get('singleEditText');

                case this.get('isFileUpload'):
                case this.get('isFileDelete'):
                    return this.get('info').file;
            }
        }.property(),

        afterSubjectText: function () {
            var templateId = this.get('event.template.id');
            var workOrder = this.get('info.template.workOrder');
            switch (true) {
                case this.get('isOtherFollower'):
                    return ' as a follower';
                case this.get('isOtherTimeLogger'):
                    return 'for ' + this.get('event.assignee.displayName');
                case this.get('isOpen') && !!this.get('event.template.id'):
                    return ' in the';
                case this.get('isPreviousRepeating'):
                    var text = ' when the next request was created in the';
                    if (templateId) {
                        return text;
                    } else if (workOrder) {
                        return text + ' repeating series #' + workOrder;
                    }
                    return text + ' repeating series';
            }
        }.property(),

        afterSubjectLink: function () {
            var templateId = this.get('event.template.id');
            switch (true) {
                case this.get('isOpen') && !!templateId:
                case this.get('isPreviousRepeating') && !!templateId:
                    return {
                        text: 'repeating series #' + this.get('event.template.workOrder'),
                        path: 'requests.from-template',
                        id: templateId
                    };
            }
        }.property(),

        icon: function () {
            switch (true) {
                case this.get('isVendorAccept'):
                    return 'check';
                case this.get('isVendorDecline'):
                    return 'times';
                case this.get('isTimeLog'):
                    return 'clock-o';
                case this.get('isCostLog'):
                    return 'usd';
            }

            switch (this.get(et)) {
                case 'open':
                    return 'plus';

                case 'checklist-add':
                case 'checklist-item-add':
                case 'checklist-delete':
                case 'checklist-item-delete':
                case 'checklist-item-edit':
                case 'checklist-item-marked':
                    return 'check-square';

                case 'close':
                    return 'check';
                case 'reopen':
                    return 'undo';
                case 'relabel':
                    return 'tag';
                case 'file-upload':
                    return 'upload';
                case 'comment-add':
                    return 'comment';
                case 'previous-repeating':
                    return 'calendar';

                case 'comment-delete':
                case 'file-delete':
                case 'delete':
                    return 'times';

                case 'follow':
                case 'unfollow':
                case 'reassign':
                    return 'user';

                case 'vendor-response':
                    return 'question';

                default:
                    return 'pencil';
            }
        }.property(),

        iconStyle: function () {
            var color = void 0;
            switch (true) {
                case this.get('isAnyDelete') || this.get('isVendorDecline'):
                    color = '#e75854';
                    break;
                case this.get('isVendorAccept'):
                    color = '#5fb760';
                    break;
                case this.get('isVendorMaybe'):
                    color = '#337AB7';
                    break;
                case this.get('isPriority'):
                    color = this.priorityColors[this.get('info.priority')];
                    break;
                case this.get('isClose'):
                    color = '#33a02c';
                    break;
                case this.get('isRelabel') && this.get('info.requestLabel.color'):
                    color = this.get('info.requestLabel.color');
                    break;
                default:
                    color = '#ccc';
            }
            return 'background-color: ' + color + ';';
        }.property(),

        channel: function () {
            return this.get('store').peekRecord('channel', this.get('info.comment.channel'));
        }.property(),

        singleEditLabel: function () {
            if (!this.get('isSingleEdit')) {
                return;
            }

            for (var prop in this.get('info')) {
                switch (prop) {
                    case 'requestLabel':
                        return 'label';
                    case 'requestType':
                        return 'type';
                    case 'dueDate':
                        return 'due date';
                    case 'building':
                        return 'location';
                    case 'subspace':
                        return 'area';
                    default:
                        return prop;
                }
            }
        }.property(),

        singleEditText: function () {
            var info = this.get('info');
            if (info.dueDate) {
                return moment(info.dueDate).format('ddd, MMM D, YYYY [at] h:mm a');
            }
            for (var prop in info) {
                return info[prop] || 'None';
            }
        }.property(),

        textBlockLabel: function () {
            if (!this.get('isTextBlock')) {
                return;
            }
            for (var prop in this.get('info')) {
                return prop;
            }
        }.property(),

        textBlockText: function () {
            if (!this.get('isTextBlock')) {
                return;
            }
            return this.get('info')[this.get('textBlockLabel')];
        }.property(),

        requestLabelText: function () {
            var label = this.get('info.requestLabel.name');
            return label ? label : 'None';
        }.property(),

        subspaceText: function () {
            var subspace = this.get('info.subspace');
            return subspace ? subspace : 'None';
        }.property(),

        followerText: function () {
            var follower = this.get('store').peekRecord('user', this.get('info.follower'));
            if (follower) {
                return follower.get('displayName');
            }
        }.property(),

        priorityText: function () {
            return ['Critical', 'High', 'Medium', 'Low'][this.get('info.priority') - 1];
        }.property(),

        assigneeText: function () {
            var assignee = this.get('event.assignee.displayName');
            return assignee ? assignee : 'Nobody';
        }.property(),

        timeLogText: function () {
            var _elapsedToTimer = (0, _time.elapsedToTimer)(this.get('info.elapsed')),
                _elapsedToTimer2 = _slicedToArray(_elapsedToTimer, 2),
                hours = _elapsedToTimer2[0],
                minutes = _elapsedToTimer2[1];

            var text = '';

            if (hours) {
                text += hours + 'hr';
            }
            if (minutes) {
                text += ' ' + minutes + 'm';
            }

            text += ' <span class="normal-weight">of</span> ';

            if (!this.get('info.billable')) {
                text += 'non-';
            }
            text += 'billable work';

            return text;
        }.property(),

        costLogText: function () {
            var text = '<span class="normal-weight">a</span> ';
            if (!this.get('info.billable')) {
                text += 'non-';
            }
            text += 'billable ';
            if (this.get('info.category')) {
                // const cat = this.get('store')
                //                 .peekRecord('costCategory', this.get('info.category'));
                text += this.get('info.category') + ' ';
            }
            text += 'cost <span class="normal-weight">of</span> ';
            text += '' + (0, _formatMoney.default)(this.get('info.value'));
            if (this.get('info.description')) {
                text += ': <i class="normal-weight">' + this.get('info.description') + '</i>';
            }

            return text;
        }.property(),

        previousRepeatingText: function () {
            var info = this.get('info');
            if (!info) {
                return;
            }

            var action = void 0;

            if (info.closed) {
                action = 'The request was closed';
                if (this.get('isPreviousRelabel')) {
                    action += ' and relabeled to';
                }
            } else if (this.get('isPreviousRelabel')) {
                action = 'The label was set to';
            }

            return action;
        }.property(),

        commentAddText: function () {
            var actionText = 'sent a message';

            if (this.get('channel')) {
                actionText = actionText + ' to ';
            }

            return actionText;
        }.property(),

        vendorResponseText: function () {
            var resp = this.get('info.response');
            var msg = ' your request for service';

            switch (resp) {
                case 'yes':
                    return 'accepted' + msg;
                case 'no':
                    return 'declined' + msg;
                case 'maybe':
                    return 'needs more info about' + msg;
                default:
                    return 'responded to' + msg;
            }
        }.property(),

        textFields: function () {
            var info = this.get('info');
            var fields = [];
            ['title', 'description'].forEach(function (field) {
                if (info[field]) {
                    fields.push({
                        label: field.capitalize(),
                        text: info[field]
                    });
                }
            });
            return fields;
        }.property(),

        metaFields: function () {
            var info = this.get('info');
            var map = {
                building: {
                    label: 'Location changed to',
                    icon: 'fa fa-map-marker'
                },
                subspace: {
                    label: 'Area changed to',
                    icon: 'fa fa-location-arrow',
                    value: this.get('subspaceText')
                },
                requestType: {
                    label: 'Type changed to',
                    icon: 'huyang-question'
                },
                owner: {
                    label: 'Assigned to',
                    icon: 'fa fa-user',
                    value: this.get('assigneeText')
                },
                priority: {
                    label: 'Prioritized as',
                    icon: 'fa fa-exclamation-circle',
                    value: this.get('priorityText')
                },
                requestLabel: {
                    label: 'Labeled as',
                    icon: 'fa fa-tag',
                    value: this.get('requestLabelText')
                }
            };

            var fields = [];
            for (var prop in map) {
                if (prop in info) {
                    fields.push({
                        label: map[prop].label,
                        icon: map[prop].icon,
                        value: map[prop].value || info[prop]
                    });
                }
            }
            return fields;
        }.property(),

        actions: {
            toggleDetails: function toggleDetails() {
                this.set('showDetails', !this.get('showDetails'));
            }
        }
    });
});