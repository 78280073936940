define('huyang/routes/people/occupants/edit-group', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),

        model: function model(params) {
            return Ember.RSVP.hash({
                account: this.store.findRecord('account', this.get('session.data.authenticated.accountId')),
                group: this.store.findRecord('occupant-group', params.group_id),
                buildings: this.store.findAll('building'),
                subspaces: this.store.findAll('subspace'),
                occupants: this.store.findAll('tenant-user')
            });
        }
    });
});