define('huyang/validations/asset', ['exports', 'ember-changeset-validations/validators/index'], function (exports, _index) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        assetCategory: (0, _index.validatePresence)(true),
        tag: (0, _index.validatePresence)(true),
        building: (0, _index.validatePresence)(true)
    };
});