define('huyang/controllers/assets/asset-list-base', ['exports', 'huyang-common/mixins/modal-helper', 'huyang/mixins/saves-view-config', 'huyang-common/mixins/sorts-multiple-columns'], function (exports, _modalHelper, _savesViewConfig, _sortsMultipleColumns) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_modalHelper.default, _savesViewConfig.default, _sortsMultipleColumns.default, {
    queryParams: ['sortColumns', 'archived'],
    archived: '',
    applicationController: Ember.inject.controller('application'),

    store: Ember.inject.service(),

    viewName: Ember.computed(function () {
      return this.get('applicationController.currentRouteName');
    }),

    viewConfig: Ember.computed('viewName', 'model.viewConfig', function () {
      var viewConfigs = this.get('store').peekAll('viewConfig');

      return viewConfigs.findBy('viewName', this.get('viewName'));
    }),

    routeModelId: Ember.computed('model', function () {
      return this.get('model.id');
    }),

    assets: Ember.computed.alias('model.assets'),
    hasAssets: Ember.computed.or('totalActive', 'totalArchived'),

    defaultColumns: ['assetCategory.title', 'tag', 'description', 'assetMakeModel.title', 'building.name'],

    columns: [Ember.Object.create({ key: 'assetCategory.title', label: 'Category' }), Ember.Object.create({ key: 'tag', label: 'Tag', link: 'assets.asset', disableLinkAction: true }), Ember.Object.create({ key: 'description', label: 'Description' }), Ember.Object.create({ key: 'assetMakeModel.title', label: 'Make / model' }), Ember.Object.create({ key: 'building.name', label: 'Location / area' }), Ember.Object.create({ key: 'cost', label: 'Cost', currency: true, align: 'right' }), Ember.Object.create({
      key: 'dateOfAcquisition',
      label: 'Date of acquisition',
      isDate: true,
      relativeDate: true,
      nowrap: false
    }), Ember.Object.create({
      key: 'dateOfDecommissioning',
      label: 'Date of decommissioning',
      isDate: true,
      relativeDate: true,
      nowrap: false
    }), Ember.Object.create({
      key: 'warrantyExpiration',
      label: 'Warranty expiration',
      isDate: true,
      relativeDate: true,
      nowrap: false
    }), Ember.Object.create({ key: 'maintenanceCost', label: 'Total maintenance cost', currency: true, align: 'right' }), Ember.Object.create({ key: 'totalMaintenanceHours', label: 'Total maintenance hours' }), Ember.Object.create({
      key: 'lastMaintenanceDate',
      label: 'Last maintenance date',
      isDate: true,
      relativeDate: true,
      nowrap: false
    }), Ember.Object.create({
      key: 'nextMaintenanceDate',
      label: 'Next maintenance date',
      isDate: true,
      relativeDate: true,
      nowrap: false
    })],

    // Not quite sure what is going on here, but this does not work. The pages
    // are loaded correctly but it doesn't seem to hit here when it should.
    // Needs some more investigation.
    //
    // scrollTo: function() {
    //   Ember.run.next(() => {
    //     let scrollTo = this.get('scrollToId');
    //     if (scrollTo) {
    //       let req = Ember.$(`#row${scrollTo}`);
    //       if (req && req.length) {
    //         Ember.$('html, body').animate({
    //           scrollTop: req.offset().top-130  // for fixed header
    //         }, 0);
    //       }
    //     }
    //   });
    // }.property('scrollToId'),

    userColumns: function () {
      var viewConfig = this.get('viewConfig');
      if (!viewConfig || !viewConfig.get('columnList')) {
        return [];
      }
      return viewConfig.get('columnList');
    }.property('viewConfig.columnList.[]'),

    tableColumns: function () {
      var _this = this;

      var userColumns = this.get('userColumns');
      var displayedColumns = userColumns.length > 0 ? userColumns : this.get('defaultColumns');

      return displayedColumns.map(function (key) {
        return _this.get('columns').findBy('key', key);
      });
    }.property('columns', 'defaultColumns', 'userColumns.[]'),

    showingActiveAssets: Ember.computed.not('archived'),

    actions: {
      goToAsset: function goToAsset(id, routeName) {
        var _this2 = this;

        var assets = this.get('model.assets');
        var assetIds = assets.map(function (a) {
          return a.get('id');
        });
        var idx = assetIds.indexOf(id);
        var page = 1;
        if (idx >= 0) {
          page = Math.floor(idx / this.get('perPage')) + 1; // 1-based
        }

        /*
            ember only allows passing extra data to a route through the
            queryParams property (afaik). they won't show up in the URL though.
             we're passing an array of request IDs and the route name to give context
            for navigation between requests and back to the given index
        */

        var queryParams = {
          requestIds: assetIds,
          previousRoute: this.get('viewName'),
          previousModelId: this.get('routeModelId'),
          fromId: id,
          currentPage: page,
          totalPages: this.get('totalPages'),
          filter: this.get('filter'),
          perPage: this.get('perPage'),
          buildingId: this.get('buildingId')
        };

        // add any other query params
        var params = this.get('queryParams') || [];
        var paramNames = [];

        params.forEach(function (param) {
          if (_this2.get(param)) {
            queryParams[param] = _this2.get(param);
            paramNames.push(param);
          }
        });

        queryParams.paramNames = paramNames.join(',');

        var route = routeName ? routeName : 'assets.asset';

        this.transitionToRoute(route, id, {
          queryParams: queryParams
        });
      },

      saveColumns: function saveColumns() {
        var cols = this.get('orderedColumns') || this.get('columns');
        var selected = cols.filter(function (col) {
          return col.selected;
        });

        this.saveViewConfig(selected.map(function (col) {
          return col.key;
        }), this.get('viewName'));
      },

      showActiveAssets: function showActiveAssets() {
        this.set('archived', '');
      },
      showArchivedAssets: function showArchivedAssets() {
        this.set('archived', true);
      }
    }
  });
});