define('huyang/components/occupant-signup-settings', ['exports', 'huyang/components/dynamic-component'], function (exports, _dynamicComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _dynamicComponent.default.extend({
        signupType: 'staff',
        autoCreate: Ember.computed.equal('signupType', 'self'),
        approvalRequired: true,
        createFromEmail: false,
        users: [],
        approvers: [],
        approvedDomains: '',
        hasCustomApprovers: false,

        propertiesToSend: ['autoCreate', 'approvalRequired', 'approvedDomains', 'hasCustomApprovers', 'approvers', 'createFromEmail'],

        watchForChanges: function () {
            if (!this.get('autoCreate')) {
                this.set('createFromEmail', false);
            }
            Ember.run.once(this, 'notifyOfChanges');
        }.observes('signupType', 'approvalRequired', 'approvedDomains', 'hasCustomApprovers', 'approvers.[]', 'createFromEmail').on('init'),

        actions: {
            radioChanged: function radioChanged(value) {
                this.set('signupType', value);
            },
            membersChanged: function membersChanged(data) {
                this.set('approvers', data.addedUsers);
            }
        }
    });
});