define('huyang/routes/settings/user', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model() {
            return this.modelFor('settings').account;
        }
    });
});