define('huyang/components/open-timers-banner', ['exports', 'huyang-common/mixins/modal-helper'], function (exports, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var computed = Ember.computed;


    var _listener = 'windowListener';

    exports.default = Ember.Component.extend(_modalHelper.default, {
        classNames: ['open-timers-banner'],

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);

            var setCount = this.setDisplayedCount.bind(this);

            setCount();
            this.set(_listener, setCount);
            window.addEventListener('resize', setCount);
        },
        didDestroyElement: function didDestroyElement() {
            this._super.apply(this, arguments);
            window.removeEventListener('resize', this.get(_listener));
        },
        setDisplayedCount: function setDisplayedCount() {
            var config = Ember.getOwner(this).resolveRegistration('config:environment') || {};

            // This is really dumb, but... to be able to test this component well,
            // browser/headless runtimes need to have access to a consistent number
            var width = config.environment === 'test' ? 1024 : document.body.clientWidth;

            // Updating timerWidth necessitates changes to open-timers-banner.less...
            // Also, 275px is a good min to show both timer AND request info, but a little
            // needs to be shaved off on mobile to make room for an "All" link
            var timerWidth = width > 575 ? 275 : 255;
            var linkWidth = width > 575 ? 130 : 55;

            var displayedCount = Math.floor((width - linkWidth) / timerWidth);

            this.setProperties({ displayedCount: displayedCount });
        },


        hasMoreTimers: computed('timers.length', 'displayedCount', function hasMoreTimers() {
            return this.get('displayedCount') < this.get('timers.length');
        }),

        displayedTimers: computed('timers.@each.active', 'displayedCount', function displayedTimers() {
            // Sort by active/paused first then work order number.. sortBy('active') shows
            // paused first, which we do not want, so need custom sort.
            // Spread into new array so .sort does not mutate original array/collection
            return [].concat(_toConsumableArray(this.get('timers'))).sort(function (a, b) {
                var active = function active(obj) {
                    return obj.get('active');
                };

                if (active(a) === active(b)) {
                    var workOrder = function workOrder(obj) {
                        return Number(obj.get('request.workOrder'));
                    };

                    // Ascending "work order" order, so a -> b
                    return workOrder(a) - workOrder(b);
                }

                // Descending "active" order, so b -> a
                return active(b) - active(a);
            }).slice(0, this.get('displayedCount'));
        }),

        actions: {
            clickLog: function clickLog(log) {
                this.set('changeset', log.changeset());
                this.openModal('quickLogTimer');
            },
            confirmLogTimer: function confirmLogTimer() {
                var _this = this;

                this.get('changeset').save().then(function () {
                    return _this.set('changeset', null);
                });
                // FIXME catch on error
            },
            cancelLogTimer: function cancelLogTimer() {
                this.set('changeset', null);
            },
            clickCancel: function clickCancel(timer) {
                this.set('deleteTimer', timer);
                this.openModal('quickDeleteTimer');
            },
            confirmDeleteTimer: function confirmDeleteTimer() {
                var _this2 = this;

                this.get('deleteTimer').destroyRecord().then(function () {
                    _this2.get('flashMessages').success('Timer canceled!');
                });
            }
        }
    });
});