define('huyang/components/pending-attachments-list', ['exports', 'huyang-common/components/pending-attachments-list'], function (exports, _pendingAttachmentsList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _pendingAttachmentsList.default;
    }
  });
});