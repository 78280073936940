define('huyang/components/vendor-certificates', ['exports', 'huyang-common/mixins/modal-helper'], function (exports, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_modalHelper.default, {
        classNames: ['vendor-certificate-list'],

        confirmDeleteTitle: function () {
            return 'Delete \'' + this.get('deletedCoi.name') + '\'';
        }.property('deletedCoi.name'),

        actions: {
            deleteCoi: function deleteCoi(coi) {
                this.set('deletedCoi', coi);
                this.openModal('confirmDeleteCoi');
            },

            confirmDeleteCoi: function confirmDeleteCoi() {
                var _this = this;

                var coi = this.get('deletedCoi');
                coi.destroyRecord().then(function () {
                    _this.set('deletedCoi', null);
                    _this.get('flashMessages').success(coi.get('name') + ' deleted!');
                    _this.closeModal();
                });
            }
        }
    });
});