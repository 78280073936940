define('huyang/models/vendor-coi', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        vendor: _emberData.default.belongsTo('vendor', { async: true }),

        created: _emberData.default.attr('date'),
        expiration: _emberData.default.attr('date'),
        name: _emberData.default.attr('string'),
        filename: _emberData.default.attr('string'),
        attachmentType: _emberData.default.attr('string'),
        uuid: _emberData.default.attr('string'),

        expirationLabel: function () {
            var exp = this.get('expiration');
            if (exp) {
                return moment(exp).format('MMMM Do, YYYY');
            }
        }.property('expiration'),

        fileUrl: function () {
            var uuid = this.get('uuid');

            if (uuid) {
                var base = Ember.getOwner(this).resolveRegistration('config:environment').S3_BASE_URL;
                var accountId = this.get('vendor.account.id');
                var vendorId = this.get('vendor.id');
                return base + '/' + accountId + '/vendors/' + vendorId + '/cois/' + uuid;
            }
        }.property('uuid')
    });
});