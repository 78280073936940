define('huyang/controllers/requests/list-base', ['exports', 'huyang/mixins/shows-request-modal', 'huyang/mixins/request-list', 'huyang/mixins/saves-view-config', 'huyang-common/mixins/sorts-multiple-columns'], function (exports, _showsRequestModal, _requestList, _savesViewConfig, _sortsMultipleColumns) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_requestList.default, _showsRequestModal.default, _savesViewConfig.default, _sortsMultipleColumns.default, {
        queryParams: ['sortColumns'],
        requestsController: Ember.inject.controller('requests'),
        sortedFilters: Ember.computed.alias('requestsController.sortedFilters'),

        actions: {
            saveColumns: function saveColumns(columns) {
                this.saveViewConfig(columns, this.get('viewName'));
            }
        }
    });
});