define('huyang/controllers/admin/token-lookup', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        api: Ember.inject.service(),

        hasUrls: function () {
            return this.get('staffUrls.length') > 0 || this.get('occUrls.length') > 0;
        }.property('staffUrls.length', 'occUrls.length'),

        actions: {
            lookup: function lookup() {
                var _this = this;

                this.set('token', null);
                this.set('notFound', null);
                this.set('tokenEmail', this.get('email'));
                this.set('staffUrls', []);
                this.set('occUrls', []);
                this.get('api').ajax({
                    type: 'GET',
                    url: '/api/admin/tokenLookup?email=' + this.get('email'),
                    dataType: 'json'
                }).then(function (response) {
                    _this.set('staffUrls', response.accounts.staff.map(function (acct) {
                        return 'https://' + acct + '.tikkit.gridium.com/token/' + response.token;
                    }));
                    _this.set('occUrls', response.accounts.occupant.map(function (acct) {
                        return 'https://' + acct + '.tikkit.us/token/' + response.token;
                    }));
                }, function () {
                    _this.set('notFound', true);
                });
            }
        }
    });
});