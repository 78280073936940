define('huyang/routes/assets/category', ['exports', 'huyang/routes/assets/asset-filter-base'], function (exports, _assetFilterBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _assetFilterBase.default.extend({
        templateName: 'assets/asset-list-base',

        model: function model(params) {
            var _this = this;

            var modelParams = this.getInfinityModelParams('category', params);

            modelParams.category = params.category_id;

            return Ember.RSVP.hash({
                assets: this.infinityModel('asset', modelParams),
                category: this.store.findRecord('assetCategory', params.category_id).then(function (c) {
                    _this.set('documentTitle', c.get('name'));
                    return c;
                }),
                viewConfig: this.store.queryRecord('view-config', { view_name: this.get('viewName') })
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('filter', 'category');
            controller.set('categoryId', model.category.get('id'));
        }
    });
});