define('huyang/controllers/admin/account/info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    accountController: Ember.inject.controller('admin.account'),
    selectedAccount: Ember.computed.alias('accountController.selectedAccount'),
    // assetCategories: Ember.computed.alias('account.assetCategories'),
    // requestTypes: Ember.computed.alias('account.selectedAccount.requestTypes'),
    // requestLabels: Ember.computed.alias('account.requestLabels'),

    accountStatuses: Ember.computed('selectedAccount', function () {
      return [{ status: 'trial', selected: this.get('selectedAccount.status') === 'trial' }, { status: 'inactive', selected: this.get('selectedAccount.status') === 'inactive' }, { status: 'paid', selected: this.get('selectedAccount.status') === 'paid' }, { status: 'demo', selected: this.get('selectedAccount.status') === 'demo' }];
    }),

    statusSelectConfig: {
      multiple: false
    },

    accountIsInactive: Ember.computed.equal('selectedAccount.status', 'inactive'),
    staffMessage: Ember.computed('selectedAccount', function () {
      if (this.get('selectedAccount.inactiveMessageStaff')) {
        return this.get('selectedAccount.inactiveMessageStaff');
      }

      return 'This account isn’t active at the moment. We apologize for any inconvenience. For assistance, please reach out directly to facility support staff.';
    }),
    tenantsMessage: Ember.computed('selectedAccount', function () {
      if (this.get('selectedAccount.inactiveMessageTenants')) {
        return this.get('selectedAccount.inactiveMessageTenants');
      }

      return 'This account isn’t active at the moment. We apologize for any inconvenience. For assistance, please reach out directly to facility support staff.';
    }),
    vendorsMessage: Ember.computed('selectedAccount', function () {
      if (this.get('selectedAccount.inactiveMessageVendors')) {
        return this.get('selectedAccount.inactiveMessageVendors');
      }

      return 'This account isn’t active at the moment. We apologize for any inconvenience. For assistance, please reach out directly to facility support staff.';
    }),

    actions: {
      onSelect: function onSelect(selectedValue) {
        var _this = this;

        this.get('store').findRecord('account', this.get('selectedAccount.id')).then(function (a) {
          _this.set('selectedAccount.status', selectedValue);
          a.set('status', selectedValue);
          a.save().then(function () {
            _this.get('flashMessages').success('Account status updated');
          });
        });
      },
      saveInactiveMessages: function saveInactiveMessages() {
        var _this2 = this;

        this.set('isLoading', true);
        this.get('store').findRecord('account', this.get('selectedAccount.id')).then(function (a) {
          a.set('inactiveMessageStaff', _this2.get('staffMessage'));
          a.set('inactiveMessageTenants', _this2.get('tenantsMessage'));
          a.set('inactiveMessageVendors', _this2.get('vendorsMessage'));
          a.save().then(function () {
            _this2.get('flashMessages').success('Inactive messages saved');
            _this2.set('isLoading', false);
          });
        });
      }

      // toggleAssetCategories() {
      //   this.set('showAssetCategories', !this.get('showAssetCategories'));
      // },

      // toggleRequestTypes() {
      //   this.set('showRequestTypes', !this.get('showRequestTypes'));
      // },

      // toggleRequestLabels() {
      //   this.set('showRequestLabels', !this.get('showRequestLabels'));
      // },

    }
  });
});