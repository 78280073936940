define('huyang/components/open-timer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    exports.default = Ember.Component.extend({
        classNames: ['open-request-timer'],

        showOtherControls: true,

        clickLog: function clickLog() {},
        clickDelete: function clickDelete() {},


        isActive: computed('timer.active', function () {
            return this.get('timer.active');
        }),

        calloutClass: computed('isActive', function () {
            return this.get('isActive') ? 'green' : 'grey';
        }),

        actions: {
            clickCallout: function clickCallout() {
                this.get('timer').toggleActive();
            },
            clickDelete: function clickDelete() {
                this.get('clickDelete')(this.get('timer'));
            },
            clickLog: function clickLog() {
                this.get('clickLog')(this.get('timer'));
            }
        }
    });
});