define('huyang/routes/admin', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAccount.default.extend({
    afterModel: function afterModel(model) {
      if (model.account.get('id') !== '1041') {
        this.transitionTo('application');
      }
    }
  });
});