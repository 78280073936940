define('huyang/controllers/settings/user/mobile-password', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        password: '',
        confirmPassword: '',

        fields: Ember.computed(function () {
            return [{
                name: 'password',
                label: 'New password',
                example: null,
                type: 'password',
                validations: {
                    presence: true,
                    length: { minimum: 8 },
                    confirmation: true
                }
            }, {
                name: 'passwordConfirmation',
                label: 'Confirm new password',
                example: null,
                type: 'password',
                validations: {
                    presence: true,
                    length: { minimum: 8 }
                }
            }];
        }),

        actions: {
            updatePasswordProps: function updatePasswordProps(values) {
                var _this = this;

                Object.keys(values).forEach(function (key) {
                    _this.set(key, values[key]);
                });
            }
        }
    });
});