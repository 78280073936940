define('huyang/routes/settings/customization/cost-categories', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        account: this.store.peekRecord('account', localStorage.getItem('accountId')),
        categories: this.store.findAll('cost-category')
      });
    },

    afterModel: function afterModel(model) {
      model.categories.setEach('canBeDeleted', true);
    }
  });
});