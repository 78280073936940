define('huyang/components/request-attachments', ['exports', 'huyang-common/components/request-attachments'], function (exports, _requestAttachments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _requestAttachments.default;
    }
  });
});