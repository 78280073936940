define('huyang/models/request-comment', ['exports', 'ember-data', 'huyang-common/models/request-comment'], function (exports, _emberData, _requestComment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _requestComment.default.extend({
        serviceRequest: _emberData.default.belongsTo('vendorServiceRequest', { async: true }),
        checklistItem: _emberData.default.belongsTo('RequestChecklistItem', { async: false }),

        hasVendorResponse: function () {
            return this.get('serviceRequest.response');
        }.property('serviceRequest.response')
    });
});