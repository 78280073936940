define('huyang/routes/requests/preventative-maintenance/index', ['exports', 'huyang/routes/requests/preventative-maintenance/pm-filter-base'], function (exports, _pmFilterBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _pmFilterBase.default.extend({
        documentTitle: 'Preventative maintenance programs',
        filter: 'all'
    });
});