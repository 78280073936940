define('huyang/controllers/people/staff/timers', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    exports.default = Ember.Controller.extend({
        timerUsers: computed('model.timers.@each', function () {
            var timers = this.get('model.timers');
            var byUser = {};

            // Convert timers into a list of users
            timers.forEach(function (timer) {
                byUser[timer.get('worker.id')] = timer.get('worker');
            });

            return Object.keys(byUser).map(function (u) {
                return byUser[u];
            });
        })
    });
});