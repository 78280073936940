define('huyang/controllers/settings/buildings/add', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            save: function save(data, onComplete, onError) {
                var _this = this;

                data.active = true;

                var building = this.store.createRecord('building', data);
                building.set('account', this.get('model.account'));

                building.save().then(function () {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }

                    _this.transitionToRoute('settings.buildings.building.info', building.get('id'));
                }).catch(onError);
            },
            cancel: function cancel() {
                this.transitionToRoute('settings.buildings.index');
            }
        }
    });
});