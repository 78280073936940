define('huyang/routes/settings/buildings/add', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),

    // make sure account is loaded
    model: function model() {
      return Ember.RSVP.hash({
        account: this.store.findRecord('account', this.get('session.data.authenticated.accountId')),
        users: this.store.findAll('user')
      });
    }
  });
});