define('huyang/routes/settings/customization/trades', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return Ember.RSVP.hash({
                account: this.store.peekRecord('account', localStorage.getItem('accountId')),
                trades: this.store.findAll('trade'),
                baseTrades: this.store.findAll('baseTrade')
            });
        },

        actions: {
            didTransition: function didTransition() /*transition*/{
                this.controller.set('tradeLabel', null);
            }
        }
    });
});