define('huyang/controllers/people/occupants/manage/groups/archive', ['exports', 'huyang/controllers/people/occupants/manage/groups/filter-base'], function (exports, _filterBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _filterBase.default.extend({
        actions: {
            selectedGroupsChanged: function selectedGroupsChanged(groups) {
                this.get('parentController').set('selectedArchivedGroups', groups);
            }
        }
    });
});