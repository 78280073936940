define('huyang/routes/index', ['exports', 'huyang/routes/base-account', 'huyang/mixins/redirects-to-default'], function (exports, _baseAccount, _redirectsToDefault) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend(_redirectsToDefault.default, {
        beforeModel: function beforeModel(transition) {
            this._super(transition);
            return this.redirectToDefault();
        }
    });
});