define('huyang/models/asset-make-model', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        title: _emberData.default.attr('string'),
        account: _emberData.default.belongsTo('account', { async: true }),
        attachments: _emberData.default.hasMany('asset-make-model-attachment', { async: true })
    });
});