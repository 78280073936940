define('huyang/routes/requests/all', ['exports', 'huyang/routes/requests/request-filter-base'], function (exports, _requestFilterBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _requestFilterBase.default.extend({
        documentTitle: 'All requests',
        filter: 'all'
    });
});