define('huyang/routes/requests/new-occupants', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        beforeModel: function beforeModel(transition) {
            this._super(transition);
            var params = transition.queryParams;
            // missing query params; go to requests list
            if (!params.userIds) {
                this.transitionTo('requests');
            }
        },

        model: function model(params, options) {
            var userIdParam = options.queryParams.userIds || '';
            var userIds = userIdParam.split(',');
            this.set('userIds', userIds);
            this.set('isCreate', options.queryParams.create);
            return Ember.RSVP.hash({
                request: this.store.findRecord('request', params.request_id),
                users: this.modelFor('requests').users.filter(function (user) {
                    return userIds.includes(user.get('id'));
                }),
                tenantUsers: this.store.findAll('tenant-user')
            });
            // TODO: save userIds in localstorage? context like requests?
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('isCreate', this.get('isCreate'));
            controller.set('userIds', this.get('userIds'));
        }
    });
});