define('huyang/components/trade-list', ['exports', 'huyang-common/mixins/modal-helper'], function (exports, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_modalHelper.default, {
        classNames: ['trade-list'],

        confirmDeleteTitle: function () {
            return 'Delete \'' + this.get('deletedTrade.label') + '\'';
        }.property('deletedTrade'),

        sortedTrades: function () {
            return this.get('trades').sortBy('label');
        }.property('trades.@each.label'),

        actions: {
            editTrade: function editTrade(trade) {
                if (this.get('editedTrade')) {
                    this.get('editedTrade').set('isEditing', false);
                }

                this.set('editedTrade', trade);
                this.set('editedTradeLabel', trade.get('label'));
                trade.set('isEditing', true);
            },

            cancelEdit: function cancelEdit(trade) {
                this.set('editedTrade', null);
                trade.set('isEditing', false);
                trade.set('error', null);
                trade.rollbackAttributes();
            },

            saveEdit: function saveEdit() {
                var _this = this;

                if (this.validateTrade()) {
                    var trade = this.get('editedTrade');

                    trade.set('label', this.get('editedTradeLabel'));
                    trade.save().then(function () {
                        _this.get('flashMessages').success('Trade saved!');
                        trade.set('isEditing', false);
                    });
                }
            },

            deleteTrade: function deleteTrade(trade) {
                this.set('deletedTrade', trade);
                this.openModal('confirmDeleteTrade');
            },

            confirmDeleteTrade: function confirmDeleteTrade() {
                var _this2 = this;

                var trade = this.get('deletedTrade');
                trade.destroyRecord().then(function () {
                    _this2.set('deletedTrade', null);
                    _this2.get('flashMessages').success('\'' + trade.get('label') + '\' has been deleted!');
                });
            }
        },

        // actions helpers

        validateTrade: function validateTrade() {
            var format = function format(str) {
                // Convert to lowercase and trim whitespace
                if (str) {
                    return str.toLowerCase().trim();
                }
            };

            var trade = this.get('editedTrade');
            var label = format(this.get('editedTradeLabel'));

            if (!label) {
                trade.set('error', 'Must be present');
                return;
            }

            var duplicate = this.get('trades').find(function (t) {
                return t.get('id') !== trade.get('id') && format(t.get('label')) === label;
            });

            if (duplicate) {
                trade.set('error', 'Must be unique');
                return;
            }

            return true;
        }
    });
});