define('huyang/routes/vendor/request', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        model: function model(params) {
            var _this = this;

            return this.store.findRecord('vendor-service-request', params.request_id).then(function (sr) {
                return Ember.RSVP.hash({
                    serviceRequest: sr,
                    request: _this.store.findRecord('request', sr.get('request.id'), { reload: true })
                });
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);

            var response = model.serviceRequest.get('response');
            var comments = model.request.get('comments');

            if (!response || comments.length <= 1) {
                controller.set('showVendorPrompt', true);
            } else {
                controller.set('autoHideDetails', true);
            }
        },

        actions: {
            // error loading model: user probably doesn't have permission for this request
            error: function error(_error /*, transition*/) {
                console.error('vendor.request error=', _error);
                this.get('session').invalidate();
                this.transitionTo('login-failed');
            }
        }
    });
});