define('huyang/controllers/people/occupants/group-base', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        activeOccupants: Ember.computed.filterBy('model.occupants', 'isActive'),

        actions: {
            saveGroup: function saveGroup(info) {
                var _this = this;

                var locationAccess = info.locationAccess;
                var at = locationAccess.accessType;

                var groupInfo = {
                    account: this.get('model.account'),
                    name: info.name,
                    grantAll: at === 'all'
                };

                var group = this.get('model.group') || this.store.createRecord('occupant-group');
                group.setProperties(groupInfo);

                group.save().then(function (group) {
                    var members = info.members,
                        membersToRemove = info.membersToRemove;

                    if (!members && at === 'all') {
                        return true;
                    } else {
                        var promises = [];

                        members.forEach(function (member) {
                            member.set('occupantGroup', group);
                            promises.push(member.save());
                        });

                        membersToRemove.forEach(function (member) {
                            member.set('occupantGroup', null);
                            promises.push(member.save());
                        });

                        if (at === 'specific') {
                            // Only save/remove locations if not set to "all"
                            locationAccess.addedLocations.forEach(function (_ref) {
                                var building = _ref.building,
                                    subspace = _ref.subspace;

                                var grant = _this.store.createRecord('occupant-group-grant', {
                                    group: group,
                                    building: building,
                                    subspace: subspace
                                });
                                promises.push(grant.save());
                            });

                            locationAccess.removedLocations.forEach(function (_ref2) {
                                var building = _ref2.building,
                                    subspace = _ref2.subspace;

                                var subspaceId = subspace ? subspace.get('id') : undefined,
                                    grant = group.get('grants').find(function (g) {
                                    return g.get('building.name') === building.get('name') && g.get('subspace.id') === subspaceId;
                                });
                                promises.push(grant.destroyRecord());
                            });
                        }

                        return Ember.RSVP.all(promises);
                    }
                }).then(function () {
                    _this.transitionToRoute('people.occupants.manage.groups.index');
                }); // End group.save().then()
            }
        }
    });
});