define('huyang/controllers/requests/preventative-maintenance/new', ['exports', 'huyang/config/environment', 'huyang/mixins/creates-new-requests', 'huyang-common/mixins/modal-helper'], function (exports, _environment, _createsNewRequests, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_createsNewRequests.default, _modalHelper.default, {
        isProgram: true,

        assetCategories: Ember.computed('model.assetCategories', function () {
            return this.get('model.assetCategories').rejectBy('inactive');
        }),

        goBack: function goBack(request) {
            if (_environment.default.environment === 'test') {
                if (request) {
                    this.transitionToRoute('requests.request', request.get('id'));
                } else {
                    this.transitionToRoute('requests.preventative-maintenance');
                }
            } else {
                this.send('goBackToPreviousRoute', 'requests.preventative-maintenance');
            }
        },

        actions: {
            rendered: function rendered() {
                // render-alert is firing twice on didInsertElement, so only open first time
                if (!this.get('modalOpened')) {
                    this.openModal('repeatingModal');
                    this.set('modalOpened', true);
                }
            }
        }
    });
});