define('huyang/routes/settings/customization/request-labels/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return this.modelFor('settings.customization.request-labels');
        }
    });
});