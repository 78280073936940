define('huyang/controllers/assets/asset/index', ['exports', 'huyang-common/mixins/modal-helper', 'huyang-common/mixins/shows-gallery'], function (exports, _modalHelper, _showsGallery) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_modalHelper.default, _showsGallery.default, {
    sortedFields: Ember.computed('model.asset.customFields', function () {
      if (this.get('model.asset.customFields')) {
        return this.get('model.asset.customFields').sortBy('assetCategoryCustomField.position');
      }
      return {};
    }),

    loadedGalleryImages: [],
    loadedGalleryUrls: [],

    hasDates: Ember.computed.or('model.asset.dateOfAcquisition', 'model.asset.dateOfDecommissioning', 'model.asset.warrantyExpiration'),

    allAttachments: Ember.computed('model.asset.attachments', 'model.asset.assetMakeModel.attachments', function () {
      var allAssets = [];

      if (this.get('model.asset.attachments')) {
        this.get('model.asset.attachments').forEach(function (a) {
          allAssets.pushObject(a);
        });
      }

      if (this.get('model.asset.assetMakeModel.attachments')) {
        this.get('model.asset.assetMakeModel.attachments').forEach(function (a) {
          allAssets.pushObject(a);
        });
      }

      return allAssets;
    }),

    requests: Ember.computed('model.asset.requests', function () {
      return this.get('model.asset.requests').filterBy('isTemplate', false).sortBy('updated').reverse();
    }),

    repeatingRequests: Ember.computed('model.asset.requests', function () {
      return this.get('model.asset.requests').filterBy('isTemplate', true).sortBy('updated').reverse();
    }),

    actions: {
      archiveAsset: function archiveAsset() {
        var _this = this;

        this.set('model.asset.archived', new Date());
        this.get('model.asset').save().then(function () {
          _this.transitionToRoute('assets.index');
        });
      },
      unarchiveAsset: function unarchiveAsset() {
        var _this2 = this;

        this.set('model.asset.archived', null);
        this.get('model.asset').save().then(function () {
          _this2.transitionToRoute('assets.index');
        });
      },
      deleteAttachment: function deleteAttachment(attachment) {
        this.set('deletingAttachment', attachment);
        if (attachment.constructor.modelName === 'asset-make-model-attachment') {
          this.set('deleteAttachmentExtraBody', '          <strong>This attachment is attached to the make/model and will be           removed from every asset marked as          ' + attachment.get('assetMakeModel.title') + '.</strong>');
        } else {
          this.set('deleteAttachmentExtraBody', null);
        }
        this.openModal('confirmAssetAttachmentDelete');
      },
      confirmAssetAttachmentDelete: function confirmAssetAttachmentDelete() {
        this.get('deletingAttachment').destroyRecord();
      },
      goToRequest: function goToRequest(request) {
        this.transitionToRoute('requests.request', request.id);
      }
    }
  });
});