define('huyang/routes/timer/start', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model() {
            return this.modelFor('timer');
        },

        gotoRequest: function gotoRequest(requestId) {
            this.get('flashMessages').success('Timer started!');
            this.transitionTo('requests.request.info', requestId);
        },

        afterModel: function afterModel(model, transition) {
            var _this = this;

            this._super(model, transition);
            if (!model.get('active')) {
                return model.resumeTimer().then(function () {
                    return _this.gotoRequest(model.get('request.id'));
                });
            }
            return this.gotoRequest(model.get('request.id'));
        }
    });
});