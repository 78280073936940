define('huyang/components/gallery-thumb', ['exports', 'huyang-common/components/gallery-thumb'], function (exports, _galleryThumb) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _galleryThumb.default;
    }
  });
});