define('huyang/models/trade', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        account: _emberData.default.belongsTo('account', { async: true }),
        vendors: _emberData.default.hasMany('vendor', { async: true }),

        label: _emberData.default.attr('string'),
        vendorCount: _emberData.default.attr('number'),

        canBeDeleted: function () {
            return this.get('vendorCount') === 0;
        }.property('vendorCount')
    });
});