define('huyang/components/request-header', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        listen: function () {
            var _this = this;

            var LEFT_ARROW = 37;
            var RIGHT_ARROW = 39;

            Ember.$(window).on('keyup.requestKeyup', function (e) {
                if (e.keyCode === LEFT_ARROW && !window.preventArrowNav) {
                    _this.sendAction('previous');
                } else if (e.keyCode === RIGHT_ARROW && !window.preventArrowNav) {
                    _this.sendAction('next');
                }

                _this.$('.left-arrow, .right-arrow').removeClass('pressed');
            });

            Ember.$(window).on('keydown.requestKeydown', function (e) {
                if (!window.preventArrowNav) {
                    if (e.keyCode === LEFT_ARROW) {
                        _this.$('.left-arrow').addClass('pressed');
                    } else if (e.keyCode === RIGHT_ARROW) {
                        _this.$('.right-arrow').addClass('pressed');
                    }
                }
            });

            // set a naughty global to prevent nav whenever elements are focused
            Ember.$('body').on('focus', 'textarea, input', function () {
                return window.preventArrowNav = true;
            });
            Ember.$('body').on('blur', 'textarea, input', function () {
                return window.preventArrowNav = false;
            });
        }.on('didInsertElement'),

        unlisten: function () {
            try {
                Ember.$(window).off('keydown.requestKeydown');
                Ember.$(window).off('keyup.requestKeyup');
                Ember.$('body').off('focus', 'textarea, input');
                Ember.$('body').off('blur', 'textarea, input');
            } catch (err) {}
        }.on('willDestroyElement'),

        actions: {
            previousRoute: function previousRoute() {
                this.sendAction('previousRoute');
            },
            previous: function previous() {
                this.sendAction('previous');
            },
            next: function next() {
                this.sendAction('next');
            },
            closeRequest: function closeRequest() {
                this.sendAction('closeRequest');
            },
            reopenRequest: function reopenRequest() {
                this.sendAction('reopenRequest');
            }
        }
    });
});