define('huyang/controllers/requests/new', ['exports', 'huyang/config/environment', 'huyang/mixins/creates-new-requests'], function (exports, _environment, _createsNewRequests) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_createsNewRequests.default, {
        queryParams: ['item'],
        item: null,

        hideAsset: Ember.computed.not('account.assets'),

        goBack: function goBack(request) {
            if (_environment.default.environment === 'test') {
                if (request) {
                    this.transitionToRoute('requests.request', request.get('id'));
                } else {
                    this.transitionToRoute('requests');
                }
            } else {
                this.send('goBackToPreviousRoute', 'requests.mine');
            }
        },

        hintText: function () {
            var subdomain = this.get('account.subdomain');
            return 'You can submit requests via email to <a href="mailto:' + subdomain + '@tikkit.us">' + subdomain + '@tikkit.us</a>';
        }.property('account.subdomain')
    });
});