define('huyang/components/pm-program/schedule', ['exports', 'huyang/templates/components/pm-program/schedule', 'huyang-common/mixins/repeats'], function (exports, _schedule, _repeats) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_repeats.default, {
    layout: _schedule.default,
    classNames: ['repeat-schedule-picker'],

    timePickerOptions: function () {
      return {
        inline: true,
        sideBySide: false,
        defaultDate: this.get('defaultDate'),
        stepping: 5,
        format: 'LT'
      };
    }.property(),

    startTimeFormatted: function () {
      var startDate = this.get('startDate');
      var timezone = this.get('session.currentUser.timezone');
      if (timezone) {
        startDate.tz(timezone);
      }
      return this.get('startDate').format('h:mm a');
    }.property('startDate'),

    months: Ember.computed(function () {
      return moment.months();
    }),

    days: Ember.computed(function () {
      var days = [];
      for (var i = 1; i < 32; i++) {
        days.push(i);
      }
      return days;
    }),

    observeAlwaysIncludePickers: Ember.observer('alwaysIncludeMonth', 'alwaysIncludeDay', 'schedule.startDate', function () {
      // From the requirements doc:
      //  > It is easy to generate these dates by creating a recurrence rule
      //  > that assumes a start date of the specified series date in the
      //  > previous year.
      var month = this.get('alwaysIncludeMonth');
      var day = this.get('alwaysIncludeDay');

      var startDate = moment(month + '/' + day + '/' + moment().year() + ' ' + this.get('startTimeFormatted'));
      var timezone = this.get('session.currentUser.timezone');
      if (timezone) {
        startDate.tz(timezone);
      }

      this.send('setPreviewStartDate', startDate.add(-1, 'year'));
      this.send('setStartDate', startDate);
    })
  });
});