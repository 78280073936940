define('huyang/routes/settings/user/index', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        beforeModel: function beforeModel() {
            this.transitionTo('settings.user.info');
        }
    });
});