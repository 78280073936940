define('huyang/routes/settings/buildings/building/subspaces', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            var building = this.modelFor('settings.buildings.building').building;
            return Ember.RSVP.hash({
                building: building,
                // reload in case the counts have changed (ie new request)
                usage: this.store.findRecord('subspaceUsage', building.get('id'), { reload: true })
            });
        }
    });
});