define('huyang/models/building', ['exports', 'huyang-common/models/building', 'ember-data'], function (exports, _building, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _building.default.extend({
    assets: _emberData.default.hasMany('asset', { async: true }),
    pmAssignee: _emberData.default.attr(),
    pmProgramBuildingOwners: _emberData.default.hasMany('pm-program-building-owner', { async: false }),

    activeAssets: Ember.computed('assets', function () {
      return this.get('assets').filter(function (asset) {
        return !asset.get('isArchived');
      });
    })
  });
});