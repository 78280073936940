define('huyang/controllers/requests/pending', ['exports', 'huyang/controllers/requests/list-base'], function (exports, _listBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _listBase.default.extend({
        requests: Ember.computed.alias('model.requests'),

        headerLabel: function () {
            var pending = this.get('total');
            return pending === 1 ? '1 pending request' : pending + ' pending requests';
        }.property('requests.length')
    });
});