define('huyang/routes/requests/preventative-maintenance/pm-filter-base', ['exports', 'huyang/routes/base-account', 'ember-infinity/mixins/route'], function (exports, _baseAccount, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend(_route.default, {
        queryParams: {
            sortColumns: { refreshModel: true }
        },

        sortSaveKey: 'pmSorting',
        pageSize: 50,
        perPage: 50,

        filter: 'all', // this prop should get overridden by child classes

        /*
            if user had multiple pages loaded, clicks a pm program, and comes back to pm program list,
            load the first page as usual, then load the rest of the previously-loaded pages in afterModel
        */
        beforeModel: function beforeModel(transition) {
            var superVal = this._super(transition);
            // if currentPage is set in query params, user is coming back from a request list
            if (transition.queryParams.currentPage) {
                console.log('pm-filter-base: currentPage=', transition.queryParams.currentPage);
                this.set('loadToPage', parseInt(transition.queryParams.currentPage));
            }
            if (transition.queryParams && transition.queryParams.fromId) {
                console.log('pm-filter-base: scrollToId=', transition.queryParams.fromId);
                this.set('scrollToId', transition.queryParams.fromId);
            }
            // get route name
            this.set('viewName', transition.targetName);
            return superVal;
        },

        afterModel: function afterModel(model, transition) {
            var _this = this;

            var superVal = this._super(model, transition);
            var loadTo = this.get('loadToPage') || 1;
            if (!model.pmPrograms || loadTo <= this.get('currentPage')) {
                return superVal;
            }
            // load the rest of the pages that were previously loaded
            var pages = _.range(2, loadTo + 1);
            pages.reduce(function (cur /*, next*/) {
                return cur.then(function () {
                    console.log('pm-filter-base: loading page');
                    return _this._loadNextPage();
                });
            }, Ember.RSVP.resolve());
            return superVal;
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            var total = 0,
                totalPages = 1;

            if (model.pmPrograms && model.pmPrograms.meta) {
                var meta = model.pmPrograms.meta;
                total = meta && meta.total ? meta.total : model.pmPrograms.get('length');
                totalPages = meta && meta.total_pages ? meta.total_pages : 1;
            }

            controller.set('total', total);
            controller.set('totalPages', totalPages);
            controller.set('scrollToId', this.get('scrollToId'));
            controller.set('currentPage', this.get('currentPage'));
            controller.set('perPage', this.get('perPage'));

            controller.set('documentTitle', this.get('documentTitle'));
            controller.set('viewName', this.get('viewName'));
            controller.set('filter', this.get('filter'));
        },

        getInfinityModelParams: function getInfinityModelParams(filter) {
            var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            var sort = params.sortColumns;

            if (!Array.isArray(params.sortColumns)) sort = JSON.parse(sort);

            var modelParams = {
                perPage: this.get('perPage'),
                startingPage: 1,
                modelPath: 'controller.model.pmPrograms',
                filter: filter
            };

            if (sort && sort.length > 0) {
                modelParams.sortColumns = sort;
            }

            return modelParams;
        },
        model: function model(params) {
            var modelParams = this.getInfinityModelParams(this.get('filter'), params);

            return Ember.RSVP.hash({
                pmPrograms: this.infinityModel('pmProgram', modelParams),
                viewConfig: this.store.queryRecord('view-config', { view_name: this.get('viewName') })
            });
        }
    });
});