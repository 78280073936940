define('huyang/routes/requests/request/files', ['exports', 'huyang/mixins/updates-vendor-button-route'], function (exports, _updatesVendorButtonRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_updatesVendorButtonRoute.default, {
        actions: {
            didTransition: function didTransition() /*transition*/{
                this.controller.set('filter', null);
            }
        }
    });
});