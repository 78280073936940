define('huyang/controllers/vendor/request', ['exports', 'huyang-common/mixins/shows-gallery'], function (exports, _showsGallery) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_showsGallery.default, {
        serviceRequest: Ember.computed.alias('model.serviceRequest'),
        request: Ember.computed.alias('model.request'),
        channel: Ember.computed.alias('serviceRequest.comment.channel'),

        showVendorPrompt: false,
        autoHideDetails: false,

        loadedGalleryImages: [],
        loadedGalleryUrls: [],

        toggleOptions: function () {
            var response = this.get('serviceRequest.response');

            var opts = [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'Not this time' }, { id: 'maybe', label: 'Need more info' }];

            if (response) {
                var selectedOpt = opts.findBy('id', response);

                if (selectedOpt) {
                    selectedOpt.selected = true;
                }
            }

            return opts;
        }.property('serviceRequest.response'),

        showNotesField: function () {
            var response = this.get('serviceRequest.response');

            if (this.get('hasAdditionalReplies')) {
                return false;
            } else if (response) {
                return true;
            }

            return false;
        }.property('serviceRequest.response', 'hasAdditionalReplies'),

        hasAdditionalReplies: function () {
            return this.get('request.comments.length') > 1;
        }.property('request.comments.length'),

        saveComment: function saveComment(info, attachments, callback) {
            var request = this.get('request');
            info.request = request;

            if (attachments && attachments.length) {
                info.attachment = attachments.map(function (file) {
                    return file.get('id');
                });
            }
            // set body to unrendered version so it'll be visible immediately
            info.renderedBody = info.body;
            info.channel = this.get('channel');
            var comment = this.store.createRecord('request-comment', info);

            comment.save().then(function () /*commentObject*/{
                if (typeof callback === 'function') {
                    callback();
                }
            });
        },

        replyLabel: function () {
            return 'Respond to ' + this.get('serviceRequest.comment.author.displayName');
        }.property('serviceRequest.comment.author.displayName'),

        directionsUrl: function () {
            var mapBase = 'https://www.google.com/maps/place/';
            return mapBase + encodeURIComponent(this.get('request.building.addressString'));
        }.property('request.building'),

        actions: {
            addComment: function addComment(info, attachments, callback) {
                this.saveComment(info, attachments, callback);
            },
            responseChanged: function responseChanged(response) {
                var serviceRequest = this.get('serviceRequest');
                serviceRequest.set('responded', new Date());
                serviceRequest.set('response', response);
                serviceRequest.save();
            },
            toggleDetails: function toggleDetails() {
                Ember.$('.request-info-drawer').slideToggle();
                this.toggleProperty('detailsVisible');
            },
            editDecision: function editDecision() {
                this.set('showVendorPrompt', true);
            }
        }

    });
});