define('huyang/controllers/people/staff/member', ['exports', 'huyang-common/mixins/modal-helper'], function (exports, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalHelper.default, {
        peopleStaffController: Ember.inject.controller('people/staff'),
        buildings: Ember.computed.alias('peopleStaffController.model.buildings'),
        account: Ember.computed.alias('peopleStaffController.model.account'),

        session: Ember.inject.service(),

        enableSms: function () {
            var acct = this.store.peekRecord('account', localStorage.getItem('accountId'));
            return acct && acct.hasFeature('sms');
        }.property(),

        isAdmin: function () {
            var accountId = this.get('session.data.authenticated.accountId');
            return this.get('model').hasRole(accountId, 'admin');
        }.property('model.accountRoles'),

        roleBuildingIds: function () {
            var buildingIds = [];
            var accountId = parseInt(this.get('session.data.authenticated.accountId'));
            this.get('model.accountRoles').forEach(function (ar) {
                if (parseInt(ar.get('account.id')) !== accountId || ar.get('role.name') !== 'staff') {
                    return;
                }
                var building = ar.get('building');
                if (building && !buildingIds.includes(building.get('id'))) {
                    buildingIds.push(building.get('id'));
                }
            });
            return buildingIds;
        }.property('model.accountRoles'),

        updateRoles: function updateRoles(_ref) {
            var isAdmin = _ref.isAdmin,
                hasAllAccess = _ref.hasAllAccess,
                buildingIds = _ref.buildingIds;

            // FIXME: Optimize number of API calls
            //
            // There should be an API endpoint to take this information
            // and handle all the creation/deletion on the backend in one request,
            // rather than a (potentially large) series of promises

            var user = this.get('model');
            var account = this.get('account');
            var accountId = parseInt(this.get('session.data.authenticated.accountId'));
            var promises = [];

            // Update admin status if changed
            var userAlreadyAdmin = user.hasRole(this.get('session.data.authenticated.accountId'), 'admin');
            if (isAdmin && !userAlreadyAdmin) {
                promises.push(user.addRole(account, 'admin'));
            } else if (!isAdmin && userAlreadyAdmin) {
                promises.push(user.removeRole(account, 'admin'));
            }

            // Update all-access if changed
            var userAlreadyHasAll = user.hasRole(accountId, 'staff-all');
            if (hasAllAccess && !userAlreadyHasAll) {
                promises.push(user.addRole(account, 'staff-all'));
            } else if (!hasAllAccess && userAlreadyHasAll) {
                promises.push(user.removeRole(account, 'staff-all'));
            }

            // get list of roles to add and remove
            user.get('accountRoles').forEach(function (role) {
                // only staff roles for this account, with a building
                if (parseInt(role.get('account.id')) !== accountId || !role.get('building') || role.get('role.name') !== 'staff') {
                    return;
                }
                if (buildingIds.includes(role.get('building.id'))) {
                    // selected building already has role; remove from buildingsIds and ignore
                    buildingIds = _.without(buildingIds, role.get('building.id'));
                } else {
                    // this building role is not selected
                    promises.push(user.removeAccountRole(role));
                }
            });

            // any ids left in buildingIds are new
            var buildings = this.get('buildings');
            buildingIds.forEach(function (bid) {
                var building = buildings.find(function (bldg) {
                    return bldg.get('id') === bid;
                });
                promises.push(user.addRole(account, 'staff', building));
            });

            return Ember.RSVP.all(promises);
        },

        confirmDeleteTitle: function () {
            return 'Remove staff ' + this.get('model.name') + '?';
        }.property('model.name'),

        actions: {
            saveEdit: function saveEdit(info, onComplete, onError) {
                var _this = this;

                var user = this.get('model');

                // Assign info to user, 'isAdmin', 'buildingIds', etc will be
                // filtered out on serialization
                user.setProperties(info);

                user.save().then(function () {
                    return _this.updateRoles(info);
                }).then(function () {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                    _this.get('flashMessages').success('Staff member updated!');
                }, function () {
                    if (typeof onError === 'function') {
                        onError();
                    }
                });
            },

            clickRemoveStaff: function clickRemoveStaff() {
                this.openModal('confirmDelete');
            },

            confirmRemoveStaff: function confirmRemoveStaff() {
                this.closeModal();
                var self = this;
                var user = this.get('model');
                var remove = [];
                this.store.findAll('role').then(function (roles) {
                    user.get('accountRoles').forEach(function (ar) {
                        var r = roles.find(function (role) {
                            return role.get('id') === ar.get('role.id');
                        });
                        var roleName = r.get('name');
                        if (roleName === 'staff' || roleName === 'admin' || roleName === 'staff-all') {
                            remove.push(ar);
                        }
                    });
                    var promises = [];
                    remove.forEach(function (ar) {
                        promises.push(user.removeAccountRole(ar));
                    });
                    Ember.RSVP.all(promises).then(function () {
                        self.transitionToRoute('people.staff.index');
                    });
                });
            },

            cancel: function cancel() {
                this.transitionToRoute('people.staff.index');
            }
        }
    });
});