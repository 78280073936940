define('huyang/controllers/login-vendor/webview', ['exports', 'huyang/mixins/vendor-login-destination'], function (exports, _vendorLoginDestination) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_vendorLoginDestination.default, {
        destination: Ember.computed.alias('model.destination'),

        loginUrl: function () {
            var loc = window.location;
            return loc.protocol + '//' + loc.host + '/vendor-token/' + this.get('model.serviceRequestHash') + '/' + this.get('model.destination');
        }.property('model.serviceRequestHash', 'model.destination'),

        actions: {
            select: function select() {
                document.getElementById('loginInput').selectionStart = 0;
                document.getElementById('loginInput').selectionEnd = 999;
            }
        }
    });
});