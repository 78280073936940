define('huyang/controllers/requests/mine', ['exports', 'huyang/controllers/requests/list-base'], function (exports, _listBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _listBase.default.extend({
        requests: Ember.computed.alias('model.requests'),

        headerLabel: function () {
            var open = this.get('total');
            var label = open === 1 ? '1 open request' : open + ' open requests';
            return label + ' assigned to me';
        }.property('requests.length')
    });
});