define('huyang/components/vendor-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['vendor-list'],

        sortedVendors: function () {
            return this.get('vendors').sortBy('name');
        }.property('vendors.@each.name'),

        displayedVendors: function () {
            if (this.get('filter')) {
                var filter = this.get('filter').toLowerCase();

                return this.get('sortedVendors').filter(function (vendor) {
                    var tradeIncludes = false;
                    if (vendor.get('primaryTrade.label')) {
                        var label = vendor.get('primaryTrade.label');
                        tradeIncludes = label.toLowerCase().indexOf(filter) > -1;
                    }

                    return tradeIncludes || vendor.get('name').toLowerCase().indexOf(filter) > -1;
                });
            } else {
                return this.get('sortedVendors');
            }
        }.property('sortedVendors', 'filter'),

        actions: {
            addVendor: function addVendor() {
                this.sendAction('addVendor');
            }
        }
    });
});