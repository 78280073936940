define('huyang/routes/settings/staff/index', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        model: function model() {
            var store = this.store;
            var accountId = this.get('session.data.authenticated.accountId');

            return store.fetchAll('user').then(function (users) {
                return users.filter(function (user) {
                    return user.hasAnyRole(accountId, ['staff', 'staff-all', 'admin']);
                });
            });
        }
    });
});