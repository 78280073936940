define('huyang/routes/requests/building', ['exports', 'huyang/routes/requests/request-filter-base'], function (exports, _requestFilterBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _requestFilterBase.default.extend({
        model: function model(params) {
            var _this = this;

            var modelParams = this.getInfinityModelParams('building', params);

            modelParams.building = params.building_id;
            modelParams.latestComment = true;

            return Ember.RSVP.hash({
                requests: this.infinityModel('request', modelParams),
                building: this.store.findRecord('building', params.building_id).then(function (b) {
                    _this.set('documentTitle', b.get('name'));
                    return b;
                }),
                viewConfig: this.store.queryRecord('view-config', { view_name: this.get('viewName') })
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('filter', 'building');
            controller.set('buildingId', model.building.get('id'));
        }
    });
});