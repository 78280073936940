define('huyang/controllers/requests/from-template', ['exports', 'huyang/controllers/requests/list-base'], function (exports, _listBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _listBase.default.extend({
        requests: Ember.computed.alias('model.requests'),

        headerLabel: function () {
            var title = Ember.String.htmlSafe(this.get('model.template.title')),
                id = this.get('model.template.id'),
                count = this.get('total'),
                header = count + ' request' + (count === 1 ? '' : 's');

            return header + ' in the series <a href="/requests/' + id + '">' + title + '</a>';
        }.property('requests.length', 'model.schedule.request.title')
    });
});