define('huyang/mixins/vendor-login-destination', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        destinations: {
            'response-yes': 'vendor.response.yes',
            'response-no': 'vendor.response.no',
            'request': 'vendor.request',
            'settings': 'vendor.settings'
        },

        session: Ember.inject.service(),

        destinationRoute: function () {
            var serviceReqId = this.get('session.data.authenticated.serviceRequestId');
            var destination = this.get('destination');
            var destinations = this.get('destinations');
            var route = destinations[destination];

            if (!serviceReqId) {
                // if missing service request, go to default route
                route = null;
            }

            if (!route) {
                route = serviceReqId ? destinations['request'] : destinations['settings'];
            }

            return { route: route, modelId: serviceReqId };
        }.property('destination', 'session.data.authenticated.serviceRequestId')
    });
});