define('huyang/routes/people/vendors/index', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        model: function model() {
            return Ember.RSVP.hash({
                account: this.store.findRecord('account', this.get('session.data.authenticated.accountId')),
                vendors: this.store.findAll('vendor'),
                trades: this.store.findAll('trade'),
                technicians: this.store.findAll('vendorTechnician')
            });
        },

        actions: {
            didTransition: function didTransition() /*transition*/{
                this.controller.set('filter', null);
            }
        }
    });
});