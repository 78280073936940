define('huyang/controllers/requests/building', ['exports', 'huyang/controllers/requests/list-base'], function (exports, _listBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _listBase.default.extend({
        requests: Ember.computed.alias('model.requests'),

        headerLabel: function () {
            var req = this.get('total');
            var label = req === 1 ? '1 request' : req + ' requests';

            return this.get('model.building.name') + ', ' + label;
        }.property('model.requests', 'model.building.name'),

        actions: {
            goToRequest: function goToRequest(id) {
                var requestIds = this.get('requests').map(function (r) {
                    return r.get('id');
                });

                this.transitionToRoute('requests.request', id, {
                    queryParams: {
                        requestIds: requestIds,
                        previousRoute: this.get('routeName'),
                        previousModelId: this.get('model.building.id')
                    }
                });
            }
        }
    });
});