define('huyang/models/vendor', ['exports', 'ember-data', 'huyang-common/mixins/formats-list-strings', 'huyang-common/mixins/formats-phone'], function (exports, _emberData, _formatsListStrings, _formatsPhone) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(_formatsPhone.default, _formatsListStrings.default, {
        account: _emberData.default.belongsTo('account', { async: true }),
        primaryUser: _emberData.default.belongsTo('user', { async: true }),
        primaryTrade: _emberData.default.belongsTo('trade', { async: true }),

        serviceRequests: _emberData.default.hasMany('vendorServiceRequests', { async: true }),
        technicians: _emberData.default.hasMany('vendorTechnicians', { async: true }),
        certificates: _emberData.default.hasMany('vendorCois', { async: true }),

        name: _emberData.default.attr('string'),
        address1: _emberData.default.attr('string'),
        address2: _emberData.default.attr('string'),
        city: _emberData.default.attr('string'),
        addressState: _emberData.default.attr('string'),
        zipCode: _emberData.default.attr('string'),
        phone: _emberData.default.attr('string'),

        displayName: function () {
            if (this.get('name')) {
                return this.get('name');
            }

            return this.get('primaryUser.email');
        }.property('primaryUser.email', 'name'),

        contactString: function () {
            var name = this.get('name');
            var email = this.get('primaryUser.email').toLowerCase();

            if (name) {
                return name + ' &lt;' + email + '&gt;';
            }

            return email;
        }.property('name', 'primaryUser.email'),

        formattedPhone: Ember.computed('phone', function () {
            return this.formatPhoneDisplay(this.get('phone'));
        }),

        techniciansString: function () {
            return this.prepareListString(this.get('technicians'), 'user.displayName');
        }.property('technicians.[]')
    });
});