define('huyang/controllers/admin/account/upload-pm', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        accountController: Ember.inject.controller('admin.account'),
        selectedAccount: Ember.computed.alias('accountController.selectedAccount'),

        inputId: 'csvUploadPM',
        uploadUrl: function () {
            return '/api/' + this.get('selectedAccount.id') + '/import/pm_programs';
        }.property('selectedAccount'),

        // set one of these to an array of either errors or
        // success output items (5 PM programs created, etc.)
        requestSuccess: [],
        requestFailure: [],
        requestMessage: '',
        fields: [{
            'name': 'title',
            'required': true
        }, {
            'name': 'description',
            'required': false
        }, {
            'name': 'priority',
            'required': false
        }, {
            'name': 'status',
            'required': false
        }, {
            'name': 'estimatedHours',
            'required': true
        }, {
            'name': 'created',
            'required': false
        }, {
            'name': 'updated',
            'required': false
        }, {
            'name': 'originator',
            'required': true
        }, {
            'name': 'label',
            'required': false
        }, {
            'name': 'checklistItems',
            'required': false
        }, {
            'name': 'assetCategory',
            'required': true
        }, {
            'name': 'allowBalancing',
            'required': false
        }, {
            'name': 'balancingSchedule',
            'required': false
        }, {
            'name': 'active',
            'required': false
        }, {
            'name': 'start',
            'required': false
        }, {
            'name': 'repeatRule',
            'required': false
        }, {
            'name': 'dueAfter',
            'required': false
        }, {
            'name': 'previousClose',
            'required': false
        }, {
            'name': 'previousRelabel',
            'required': false
        }, {
            'name': 'previousLabel',
            'required': false
        }, {
            'name': 'afterClose',
            'required': false
        }],

        actions: {
            uploadComplete: function uploadComplete(id, response) {
                if (response.result.ok) {
                    this.set('requestSuccess', response.result.ok);
                    this.set('requestFailure', response.result.error);
                } else {
                    // Some uploads return a URL to view status
                    this.set('requestMessage', response.result);
                }
            },
            getTemplate: function getTemplate() {
                var data = this.get('fields').map(function (field) {
                    return field.name;
                });
                this.get('csv').export([data], { fileName: 'pm-template.csv' });
            }
        }
    });
});