define('huyang/controllers/people/vendors/index', ['exports', 'huyang-common/mixins/modal-helper'], function (exports, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalHelper.default, {
        tableColumns: [{ key: 'name', label: 'Name', link: 'people.vendors.vendor', linkParamId: true }, { key: 'primaryUser.email', label: 'Email' }, { key: 'primaryTrade.label', label: 'Trade' }, { key: 'techniciansString', label: 'Technicians' }],

        displayedVendors: function () {
            if (this.get('filter')) {
                var filter = this.get('filter').toLowerCase();

                return this.get('model.vendors').filter(function (vendor) {
                    var tradeIncludes = false;

                    if (vendor.get('primaryTrade.label')) {
                        var label = vendor.get('primaryTrade.label');
                        tradeIncludes = label.toLowerCase().indexOf(filter) > -1;
                    }

                    return tradeIncludes || vendor.get('name').toLowerCase().indexOf(filter) > -1;
                });
            } else {
                return this.get('model.vendors');
            }
        }.property('model.vendors.[]', 'filter'),

        actions: {
            openWizard: function openWizard() {
                this.openModal('newVendor');
            },

            cancel: function cancel() {
                this.closeModal();
            },

            goToVendorPage: function goToVendorPage(pageName, vendorId) {
                this.transitionToRoute('people.vendors.vendor.' + pageName, vendorId);
            }
        }
    });
});