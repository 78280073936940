define('huyang/models/report-query', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        account: _emberData.default.attr('number'),
        user: _emberData.default.attr('number'),
        name: _emberData.default.attr(),
        // as in https://api.keen.io/3.0/projects/PROJECT_ID/queries/<analysisType>
        // see https://keen.io/docs/api/#analyses for options
        analysis: _emberData.default.attr('string'),
        params: _emberData.default.attr(),
        viz: _emberData.default.attr('string'), // trend or crosstab

        // TODO: get keen as a service?
        results: function results(keen, callback) {
            // must be ready
            var query = new Keen.Query(this.get('analysis'), this.get('params'));
            query.run(query, callback);
        },

        route: function () {
            return 'reports.saved.' + (this.get('viz') || 'trend');
        }.property('viz')
    });
});