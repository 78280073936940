define('huyang/utils/has-feature', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = hasFeature;
    function hasFeature(account, feature) {
        if (!account || !feature) {
            return false;
        }

        // So this is just really stupid, but passing in a model directly means we can
        // just do account.hasFeature, but passing in a relationship (like request.account)
        // means we have to extract it from .content..... Because Ember data sucks
        if (account.hasFeature) {
            return account.hasFeature(feature);
        }

        if (account.get('content').hasFeature) {
            return account.get('content').hasFeature(feature);
        }
    }
});