define('huyang/routes/profiles/user', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        model: function model(params) {
            var store = this.store;
            var currentUserId = parseInt(this.get('session.data.authenticated.userId'));
            var requests = store.query('request', { owner_id: currentUserId });

            return Ember.RSVP.hash({
                'user': store.findRecord('user', params.user_id),
                'requests': requests.filter(function (request) {
                    return parseInt(request.get('owner.id')) === currentUserId;
                })
            });
        }
    });
});