define('huyang/routes/settings/customization/request-types/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('baseRequestTypes', this.modelFor('settings.customization.request-types').baseRequestTypes);
            controller.set('requestTypes', this.modelFor('settings.customization.request-types').requestTypes);
            controller.set('requestTypeBuildings', this.modelFor('settings.customization.request-types').requestTypeBuildings);
            controller.set('buildings', this.modelFor('settings.customization.request-types').buildings);
        }
    });
});