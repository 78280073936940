define('huyang/components/report-header', ['exports', 'huyang/mixins/keen'], function (exports, _keen) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_keen.default, {
        reportTypeLabel: 'Metric',
        groupByFilter: true,
        groupByCategory: false,

        saveButtonLabel: function () {
            return this.get('isSaved') ? 'Save changes' : 'Save report';
        }.property('isSaved'),

        selectConfig: {
            allSelectedText: 'All',
            nonSelectedText: 'All',
            enableCaseInsensitiveFiltering: false,
            includeSelectAllOption: false,
            includeFilterClearBtn: false, // doesn't seem to work
            buttonText: function buttonText(options, select) {
                if (options.length === 0) {
                    return this.nonSelectedText;
                }

                if (select.find('option').length === options.length) {
                    return 'All';
                }

                // first option (+x more)
                var first = options[0];
                var label = Ember.$(first).text().trim();

                if (options.length === 1) {
                    return label;
                }

                return options.length + ' selected';
            },
            buttonTitle: function buttonTitle(options, select) {
                return this.buttonText(options, select);
            }
        },

        groupByOwner: Ember.computed.equal('groupBy', 'owner.id'),
        groupByWorker: Ember.computed.equal('groupBy', 'worker.id'),
        groupByBuildings: Ember.computed.equal('groupBy', 'building.id'),
        groupByRequestTypes: Ember.computed.equal('groupBy', 'request_type.id'),
        groupByRequestLabels: Ember.computed.equal('groupBy', 'request_label.id'),
        groupByGroups: Ember.computed.equal('groupBy', 'group.id'),
        groupByCostCategory: Ember.computed.equal('groupBy', 'category.id'),

        timeframeOptions: Ember.computed(function () {
            return this.toEmberObjects('timeframe', [{ id: 'this_7_days', label: 'Past 7 days' }, { id: 'this_14_days', label: 'Past 2 weeks' }, { id: 'this_30_days', label: 'Past 30 days' }, { id: 'this_90_days', label: 'Past 90 days' }, { id: 'this_12_months', label: 'Past year' }, { id: 'this_month', label: 'Current month to date' }, { id: 'previous_month', label: 'Previous month' }, { id: 'this_quarter', label: 'Current quarter to date' }, { id: 'previous_quarter', label: 'Previous quarter' }, { id: 'this_year', label: 'Current year to date' }, { id: 'previous_year', label: 'Previous year' }, { id: 'all', label: 'Entire date range' }]);
        }),

        ownerOptions: Ember.computed(function () {
            return this.get('owners').sortBy('label');
        }),

        requestTypeOptions: Ember.computed(function () {
            return this.get('requestTypes').filterBy('active').sortBy('label');
        }),

        requestLabelOptions: Ember.computed(function () {
            return this.get('requestLabels').sortBy('position');
        }),

        costCategoryOptions: Ember.computed(function () {
            return this.get('costCategories').sortBy('label');
        }),

        reportTypeOptions: Ember.computed('reportType', function () {
            var reportTypes = this.get('reportTypes');
            var reports = Object.keys(reportTypes).map(function (key) {
                var report = reportTypes[key];
                return {
                    id: key,
                    label: report.label,
                    index: report.index
                };
            });
            return this.toEmberObjects('reportType', _.sortBy(reports, 'index'));
        }),

        categoryOptions: Ember.computed('requestLabels.length', 'groupByCategory', 'reportType', function () {
            var options = [{ id: 'building.id', label: 'Location' }, { id: 'request_type.id', label: 'Request type' }, { id: 'group.id', label: 'Occupant group' }];

            if (this.get('reportType') === 'time_log:average') {
                options.insertAt(1, { id: 'worker.id', label: 'Staff' });
            } else {
                options.insertAt(1, { id: 'owner.id', label: 'Assignee' });
            }
            if (this.get('requestLabels.length')) {
                options.insertAt(3, { id: 'request_label.id', label: 'Request label' });
            }
            if (this.get('reportType') === 'cost_log:sum') {
                options.insertAt(4, { id: 'category.id', label: 'Cost category' });
            }

            return options;
        }),

        category1Options: Ember.computed('category1', 'category2', 'reportType', function () {
            if (!this.get('groupByCategory')) {
                return null;
            }
            // always show all options for category1
            return this.toEmberObjects('category1', this.get('categoryOptions'));
        }),

        category2Options: Ember.computed('category1', 'category2', 'reportType', function () {
            var _this = this;

            if (!this.get('groupByCategory')) {
                return null;
            }
            var options = this.get('categoryOptions').filter(function (option) {
                return !_this.get('category1') || option.id !== _this.get('category1');
            });
            return this.toEmberObjects('category2', options);
        }),

        intervalOptions: Ember.computed('interval', function () {
            if (!this.get('interval')) {
                return null;
            }

            return this.toEmberObjects('interval', [{ id: 'daily', label: 'Daily' }, { id: 'weekly', label: 'Weekly' }, { id: 'monthly', label: 'Monthly' }, { id: 'yearly', label: 'Yearly' }]);
        }),

        metricOptions: Ember.computed('metric', function () {
            if (!this.get('metric')) {
                return null;
            }

            return this.toEmberObjects('metric', [{ id: 'daily', label: 'Daily' }, { id: 'weekly', label: 'Weekly' }, { id: 'monthly', label: 'Monthly' }, { id: 'yearly', label: 'Yearly' }]);
        }),

        customRangeOptions: Ember.computed(function () {
            var today = moment('00:00:00', 'HH:mm:ss');
            var thisQuarter = Math.floor(today.get('month') / 3);
            var quarters = [0, 3, 6, 9]; // 0-based months
            var quarterStart = moment(today).set('month', quarters[thisQuarter]).set('date', 1);

            return [{
                label: 'Past week',
                timeframe: 'this_7_days',
                range: [moment(today).subtract(7, 'days'), today]
            }, {
                label: 'Past 2 weeks',
                timeframe: 'this_14_days',
                range: [moment(today).subtract(14, 'days'), today]
            }, {
                label: 'Past month',
                timeframe: 'this_30_days',
                range: [moment(today).subtract(30, 'days'), today]
            }, {
                label: 'Past 90 days',
                timeframe: 'this_90_days',
                range: [moment(today).subtract(90, 'days'), today]
            }, {
                label: 'Past year',
                timeframe: 'this_12_months',
                range: [moment(today).subtract(1, 'year'), today]
            }, {
                label: 'Current month to date',
                timeframe: 'this_month',
                range: [moment(today).date(1), today]
            }, {
                label: 'Previous month',
                timeframe: 'previous_month',
                range: [moment(today).subtract(1, 'month').date(1), moment(today).date(1)]
            }, {
                label: 'Current quarter to date',
                timeframe: 'this_quarter',
                range: [quarterStart, today]
            }, {
                label: 'Previous quarter',
                timeframe: 'previous_quarter',
                range: [moment(quarterStart).subtract(3, 'month'), quarterStart]
            }, {
                label: 'Current year to date',
                timeframe: 'this_year',
                range: [moment(today).date(1).month(1), moment(today)]
            }, {
                label: 'Previous year',
                timeframe: 'previous_year',
                range: [moment(today).subtract(1, 'year').date(1).month(1), moment(today).date(1).month(1)]
            }, {
                label: 'Entire date range',
                timeframe: 'all',
                range: [moment('2000-01-01', 'YYYY-MM-DD'), today]
            }];
        }),

        repeatingOptions: Ember.computed('repeating', function () {
            if (!this.get('repeating')) {
                return null;
            }

            return this.toEmberObjects('repeating', [{ id: 'true', label: 'Repeating requests' }, { id: 'false', label: 'Non-repeating requests' }]);
        }),

        resetFilterIds: function () {
            if (Ember.isArray(this.get('filteredIds'))) {
                this.get('filteredIds').clear();
            }
        }.observes('groupBy'),

        actions: {
            chooseDate: function chooseDate(timeframe) {
                this.set('timeframe', timeframe);
            },
            chooseReportType: function chooseReportType(reportType) {
                this.set('reportType', reportType);
            },
            chooseGroupBy: function chooseGroupBy(group) {
                this.set('groupBy', group);
                this.set('filteredIds', []);
            },
            chooseFirstCategory: function chooseFirstCategory(category) {
                this.set('category1', category);
                // if same as category2, select the first category2 option instead
                if (category === this.get('category2')) {
                    this.set('category2', this.get('categoryOptions').find(function (opt) {
                        return category !== opt.id;
                    }).id);
                }
            },
            chooseSecondCategory: function chooseSecondCategory(category) {
                this.set('category2', category);
            },
            chooseInterval: function chooseInterval(interval) {
                this.set('interval', interval);
            },
            chooseRepeating: function chooseRepeating(repeating) {
                this.set('repeating', repeating);
            },
            chooseMetric: function chooseMetric(metric) {
                this.set('metric', metric);
            },
            chooseFilters: function chooseFilters(id) {
                var filter = id === '--all--' ? [] : [id];

                this.set('filteredIds', filter);
            },
            saveButtonClicked: function saveButtonClicked() {
                this.sendAction('saveClicked');
            },
            deleteButtonClicked: function deleteButtonClicked() {
                this.sendAction('deleteClicked');
            },
            renameButtonClicked: function renameButtonClicked() {
                this.sendAction('renameClicked');
            },
            downloadClicked: function downloadClicked() {
                this.sendAction('downloadClicked');
            }
        }
    });
});