define('huyang/components/set-group-info', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        membersToRemove: [],

        fields: function () {
            var at = 'all';
            var addedUsers = [];

            if (this.get('group')) {
                at = this.get('group.grantAll') ? 'all' : 'specific';

                if (this.get('group.occupants')) {
                    addedUsers = this.get('group.occupants').map(function (o) {
                        return o.get('user');
                    });
                }
            }

            return [{
                name: 'name',
                label: 'Name',
                validations: { presence: true },
                placeholder: 'Accounting, ACME Tenant, etc.',
                value: this.get('group.name')
            }, {
                name: 'locationAccess',
                section: {
                    label: 'Location access',
                    icon: 'fa-building-o'
                },
                component: 'occupant-location-access',
                properties: {
                    includeGroupOption: false,
                    noun: 'Members',
                    accessType: at,
                    buildings: this.get('buildings'),
                    subspaces: this.get('subspaces'),
                    selectedLocations: this.get('locations')
                },
                action: 'locationAccessChanged'
            }, {
                name: 'members',
                section: {
                    label: 'Members',
                    icon: 'fa-users'
                },
                component: 'user-search-select',
                properties: {
                    users: this.get('activeOccupants').map(function (o) {
                        return o.get('user');
                    }),
                    addedUsers: addedUsers
                },
                action: 'membersChanged'
            }];
        }.property('buildings.[]', 'subspaces.[]', 'activeOccupants.[]'),

        locations: function () {
            var grants = this.get('group.grants') || [];

            return grants.map(function (grant) {
                return {
                    building: grant.get('building.id'),
                    subspace: grant.get('subspace.id')
                };
            });
        }.property('occupantGrants.[]'),

        actions: {
            locationAccessChanged: function locationAccessChanged(la) {
                this.set('locationAccess', la);
            },
            membersChanged: function membersChanged(data) {
                var userIds = data.addedUsers.map(function (user) {
                    return user.get('id');
                });

                var members = this.get('activeOccupants').filter(function (occupant) {
                    return userIds.includes(occupant.get('user.id'));
                });

                this.set('members', members);

                if (this.get('group.occupants')) {
                    var membersToRemove = this.get('group.occupants').filter(function (o) {
                        return !userIds.includes(o.get('user.id'));
                    });
                    this.set('membersToRemove', membersToRemove);
                }
            },
            saveGroup: function saveGroup(info) {
                info.locationAccess = this.get('locationAccess');
                info.members = this.get('members');
                info.membersToRemove = this.get('membersToRemove');

                this.sendAction('saveGroup', info);
            },
            cancel: function cancel() {
                this.sendAction('cancel');
            }
        }
    });
});