define('huyang/controllers/people/occupants/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        saveOccupant: function saveOccupant(user, info, onComplete) {
            var _this = this;

            var locationAccess = info.locationAccess;
            var at = locationAccess.accessType;

            var occupant = this.store.createRecord('tenant-user', {
                user: user,
                grantAll: at === 'all',
                status: 'active',
                occupantGroup: at === 'group' ? locationAccess.selectedGroupObject : null
            });

            if (at === 'specific') {
                var grants = [];
                locationAccess.selectedBuildings.forEach(function (b) {
                    var selectedSubspaces = b.get('subspaces').filterBy('selected') || [];
                    if (selectedSubspaces.length > 0) {
                        selectedSubspaces.forEach(function (sub) {
                            grants.push({
                                building: b.get('id'),
                                subspace: sub.get('id')
                            });
                        });
                    } else {
                        grants.push({
                            building: b.get('id')
                        });
                    }
                });
                occupant.grants = grants;
            }

            occupant.save().then(function () {
                onComplete();
                _this.transitionToRoute('people.occupants.manage.users.active');
            });
        },

        actions: {
            saveOccupant: function saveOccupant(info, onComplete /*, onError*/) {
                var _this2 = this;

                var users = this.store.peekAll('user');

                var existingUsers = users.filter(function (user) {
                    return user.get('email') === info.email;
                });
                var user = existingUsers.get('firstObject');

                if (user) {
                    this.saveOccupant(user, info, onComplete);
                } else {
                    var userInfo = {
                        email: info.email,
                        name: info.name,
                        mobilePhone: info.mobilePhone
                    };
                    user = this.store.createRecord('user', userInfo);
                    user.save().then(function () {
                        return _this2.saveOccupant(user, info, onComplete);
                    });
                }
            },
            cancel: function cancel() {
                this.transitionToRoute('people.occupants.manage.users');
            }
        }
    });
});