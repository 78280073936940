define('huyang/routes/settings/organization', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        api: Ember.inject.service(),
        session: Ember.inject.service(),

        model: function model() {
            return Ember.RSVP.hash({
                account: this.store.findRecord('account', this.get('session.data.authenticated.accountId'))
            });
        }
    });
});