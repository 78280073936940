define('huyang/controllers/requests/request/messages/channel', ['exports', 'huyang-common/mixins/shows-gallery', 'huyang/mixins/creates-comments', 'huyang-common/mixins/modal-helper'], function (exports, _showsGallery, _createsComments, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_showsGallery.default, _createsComments.default, _modalHelper.default, {
        requestController: Ember.inject.controller('requests/request'),
        request: Ember.computed.alias('requestController.model.request'),

        loadedGalleryImages: [],
        loadedGalleryUrls: [],

        notifyUsers: function () {
            var _this = this;

            var userIds = this.get('requestController.notifyUsers');
            var channel = this.get('model.name');

            if (userIds && userIds[channel]) {
                return userIds[channel].map(function (userId) {
                    return _this.store.peekRecord('user', userId);
                });
            }

            return [];
        }.property('requestController.notifyUsers', 'model.name'),

        markCommentsAsRead: function markCommentsAsRead() {
            if (this.get('request')) {
                this.get('request').markChannelAsRead(this.get('model'), 'all');
            }
        },

        hasChannelComments: function () {
            var channel = this.get('model');
            var comments = this.get('request.comments');

            return comments.filter(function (c) {
                return c.get('channel.id') === channel.get('id');
            }).length > 0;
        }.property('request.comments.[]', 'model'),

        commentImages: function () {
            var comments = this.get('request.comments');

            return comments.reduce(function (acc, value) {
                value.get('images').forEach(function (i) {
                    acc.push(i);
                });
                return acc;
            }, []);
        }.property('request.comments.[]'),

        actions: {
            deleteComment: function deleteComment(comment) {
                this.set('commentToDelete', comment);
                this.openModal('confirmDelete');
            },
            confirmDeleteComment: function confirmDeleteComment() {
                var _this2 = this;

                var comment = this.get('commentToDelete');

                if (comment) {
                    comment.destroyRecord().then(function () {
                        _this2.get('flashMessages').success('Comment deleted');
                    });
                }
            }
        }
    });
});