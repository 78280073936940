define('huyang/routes/print/request', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        model: function model(params) {
            return Ember.RSVP.hash({
                account: this.get('session.currentAccount'),
                buildings: this.store.findAll('building', { withRole: true }),
                channels: this.store.findAll('channel'),
                request: this.store.findRecord('request', params.request_id, { reload: true }),
                requestChecklists: this.store.query('request-checklist', { request: params.request_id }),
                technicians: this.store.findAll('vendor-technician'),
                users: this.store.findAll('user'),
                vendors: this.store.findAll('vendor')
            });
        }
    });
});