define('huyang/routes/assets', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAccount.default.extend({
    model: function model() {
      return Ember.RSVP.hash({
        account: this.store.findRecord('account', this.get('session.data.authenticated.accountId')),
        buildings: this.store.findAll('building', { withRole: true }),
        categories: this.store.findAll('asset-category'),
        viewConfigs: this.store.findAll('view-config')
      });
    },


    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.store.findAll('filter').then(function (filters) {
        controller.set('model.filters', filters);
        controller.loadAssetCounts();
      }).catch(function () /* error */{
        console.log('Error connecting to search');
      });
    }
  });
});