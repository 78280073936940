define('huyang/routes/requests/request/activity', ['exports', 'huyang/mixins/updates-vendor-button-route'], function (exports, _updatesVendorButtonRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_updatesVendorButtonRoute.default, {
        model: function model() {
            var req = this.modelFor('requests.request').request;
            return Ember.RSVP.hash({
                request: req,
                requestEvents: this.store.query('request-event', { request: req.get('id') })
            });
        }
    });
});