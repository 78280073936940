define('huyang/controllers/people/occupants/edit-group', ['exports', 'huyang/controllers/people/occupants/group-base'], function (exports, _groupBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _groupBase.default.extend({
        actions: {
            cancel: function cancel() {
                this.transitionToRoute('people.occupants.manage.groups');
            }
        }
    });
});