define('huyang/controllers/requests/request/files', ['exports', 'huyang-common/mixins/shows-gallery', 'huyang-common/mixins/modal-helper', 'npm:@sentry/browser'], function (exports, _showsGallery, _modalHelper, _browser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_showsGallery.default, _modalHelper.default, {
        files: Ember.computed.alias('model.request.attachments'),
        loadedGalleryImages: [],
        loadedGalleryUrls: [],

        fileCountLabel: function () {
            var count = this.get('files.length');
            return count + ' file' + (count !== 1 ? 's' : '');
        }.property('files.[]'),

        filteredFiles: function () {
            var filter = (this.get('filter') || '').toLowerCase();
            return this.get('files').filter(function (file) {
                return file.get('filename').toLowerCase().indexOf(filter) !== -1;
            });
        }.property('files.[]', 'filter'),

        filteredImages: function () {
            return this.get('filteredFiles').filter(function (file) {
                return file.get('canPreview');
            });
        }.property('filteredFiles'),

        confirmDeleteTitle: function () {
            return 'Delete \'' + this.get('deletedFile.filename') + '\'';
        }.property('deletedFile'),

        uploadUrl: function () {
            return '/api/' + this.get('model.request.account.id') + '/requestAttachments';
        }.property('model.request.account.id'),

        actions: {
            deleteFile: function deleteFile(file) {
                this.set('deletedFile', file);
                this.openModal('confirmDeleteFile');
            },

            confirmDeleteFile: function confirmDeleteFile() {
                var _this = this;

                var file = this.get('deletedFile');
                if (!file) {
                    return;
                }

                this.set('deletedFile', null);
                file.destroyRecord().then(function () {
                    _this.get('flashMessages').success('File has been deleted!');
                });
            },

            openFileBrowser: function openFileBrowser() {
                Ember.$('#fileUpload').click();
            },

            uploadAdded: function uploadAdded() {
                this.set('isUploading', true);
            },

            uploadComplete: function uploadComplete(uuid, data) {
                var _this2 = this;

                this.set('isUploading', false);

                var file = this.store.push('request-attachment', data.result.requestAttachment);

                // Date has not been serialized, so it's still a string
                var created = moment(file.get('created')).toDate();
                var user = this.store.peekRecord('user', localStorage.getItem('userId'));

                file.setProperties({
                    request: this.get('model.request'),
                    created: created,
                    user: user
                });
                file.save().then(function () {
                    _this2.set('filter', null);
                    _this2.get('flashMessages').success('File has been saved!');
                });
            },

            uploadFailed: function uploadFailed(error) {
                console.log(error);
                this.set('isUploading', false);
                this.get('flashMessages').danger('There was an error, please try again.');
                _browser.default.captureMessage('Error uploading file: ' + error);
            }
        }
    });
});