define('huyang/routes/people/occupants/manage/groups/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            var og = this.store.peekAll('occupant-group');

            return og.filter(function (group) {
                return group.get('isActive');
            });
        }
    });
});