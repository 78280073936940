define('huyang/components/icon-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['icon-button-container'],

        preventDefaultLink: true,

        setupTooltip: function () {
            this.$('.btn[data-toggle="tooltip"]').tooltip({ container: 'body' });
        }.on('didInsertElement'),

        cssClass: function () {
            var className = '';

            if (this.get('fullButton')) {
                className = 'full';
            }

            if (this.get('buttonClass')) {
                className += ' btn-' + this.get('buttonClass');
                return className;
            }

            return className += ' btn-default';
        }.property('buttonClass'),

        actions: {
            buttonClicked: function buttonClicked() {
                this.sendAction('action');
            }
        }
    });
});