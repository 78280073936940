define('huyang/routes/requests', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        model: function model() {
            return Ember.RSVP.hash({
                account: this.get('session.currentAccount'),
                user: this.get('session.currentUser'),
                buildings: this.store.findAll('building', { withRole: true }),
                users: this.store.findAll('user'),
                roles: this.store.findAll('role'),
                viewConfigs: this.store.findAll('view-config'),
                requestLabels: this.store.findAll('request-label'),
                requestTypes: this.store.findAll('request-type'),
                technicians: this.store.findAll('vendor-technician'),
                channels: this.store.findAll('channel'),
                vendors: this.store.findAll('vendor'),
                topOwners: this.store.findAll('top-owner')
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);

            this.store.findAll('filter').then(function (filters) {
                controller.set('model.filters', filters);
                controller.loadRequestCounts();
            }).catch(function () /* error */{
                console.log('Error connecting to search');
            });

            this.store.findAll('request-type-building');
        }
    });
});