define('huyang/routes/people/occupants/manage/users', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model() {
            var og = this.store.peekAll('occupant-group');

            return Ember.RSVP.hash({
                tenantUsers: this.modelFor('people.occupants').occupants,
                groups: og.filter(function (group) {
                    return group.get('isActive');
                })
            });
        }
    });
});