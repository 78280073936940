define('huyang/controllers/settings/buildings/building', ['exports', 'huyang-common/mixins/modal-helper'], function (exports, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalHelper.default, {
        /*
            no usage
            - show delete
            - confirm delete
            - API: delete account roles, subspaces, building request types
            usage - show archive
            - set active=false
        */
        inUse: function () {
            return this.get('model.usage.requests') > 0;
        }.property('model.usage.requests'),

        onComplete: function onComplete() {
            this.get('flashMessages').success('Changes saved!');
        },

        setActive: function setActive(value) {
            var self = this;
            var building = this.get('model.building');
            building.set('active', value);
            building.save().then(function () {
                self.onComplete();
            });
        },

        actions: {
            cancel: function cancel() {
                this.transitionToRoute('settings.buildings.index');
            },

            /*
                TODO:
                consistency with tenants:
                - archived vs active
                - confirm archive/un-archive or not?
            */

            clickArchive: function clickArchive() {
                this.openModal('confirmArchive');
            },

            confirmArchive: function confirmArchive() {
                this.setActive(false);
            },

            clickActive: function clickActive() {
                this.setActive(true);
            },

            confirmActive: function confirmActive() {},

            clickDelete: function clickDelete() {
                this.set('confirmDeleteTitle', 'Delete ' + this.get('model.building.name') + '?');
                this.openModal('confirmDelete');
            },

            confirmDelete: function confirmDelete() {
                var _this = this;

                this.closeModal();
                var building = this.get('model.building');

                // first, need to remove the request types attached to this account
                building.destroyRequestTypes().then(function () {
                    // now we can destroy the building
                    building.destroyRecord().then(function () {
                        // successful callback
                        _this.get('flashMessages').success('Location removed');
                        _this.transitionToRoute('settings.buildings');
                    }, function () {
                        // failure callback
                        // if request or tenant location created between page load, API will return BadRequest
                        _this.get('flashMessages').danger('Location could not be removed: requests or assets are associated with this location.');
                        _this.get('model.usage').reload();
                    });
                });
            }
        }

    });
});