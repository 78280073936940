define('huyang/components/set-request-label-info', ['exports', 'ember-validations'], function (exports, _emberValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var format = function format(str) {
        if (str) {
            // Convert to lowercase and remove all whitespace
            return str.toLowerCase().replace(/ /g, '');
        }
    };

    var validateUniqueName = function validateUniqueName(form) {
        var name = format(form.get('fieldValues.name'));
        if (!name) {
            return;
        } // Let 'presence' validation handle blank name

        var field = form.model.fields.find(function (f) {
            return f.name === 'name';
        });
        if (name === format(field.value)) {
            return;
        } // Unchanged or changed capitalization is ok

        var duplicate = field.currentRequestLabels.find(function (label) {
            return format(label.get('name')) === name;
        });
        if (duplicate) {
            form.errors.pushObject('Already in use. Label names must be unique.');
        }
    };

    exports.default = Ember.Component.extend({
        store: Ember.inject.service('store'),

        label: Ember.computed.alias('requestLabel'),
        labels: Ember.computed.alias('requestLabels'),

        possibleDefaults: ['isDefaultOpen', 'isDefaultClosed'],

        colorOptions: ['#dbdbdb', // default grey
        '#a6cee3', // light blue
        '#1f78b4', // blue
        '#cab2d6', // light purple
        '#6a3d9a', // purple
        '#fb9a99', // pink
        '#e31a1c', // red
        '#fdbf6f', // light orange
        '#ff7f00', // orange
        '#ffe500', // yellow
        '#b69600', // brown
        '#b2df8a', // light green
        '#33a02c'],

        fields: function () {
            return [{
                name: 'name',
                label: 'Name',
                value: this.get('label.name'),
                placeholder: 'Waiting for Parts',
                currentRequestLabels: this.get('labels'),
                helpText: 'What does the label indicate?',
                validations: {
                    presence: { message: 'Labels must have a name' },
                    inline: (0, _emberValidations.validator)(function () {
                        validateUniqueName(this);
                    })
                }
            }, {
                colorPicker: true,
                colors: this.colorOptions,
                name: 'color',
                label: 'Color',
                value: this.get('label.color') || '#dbdbdb',
                helpText: 'Choose a unique color to help distinguish labels at a glance.'
            }, {
                name: 'isDefaultOpen',
                label: 'Defaults',
                value: this.get('label.isDefaultOpen'),
                className: 'defaults',
                checkbox: true,
                checkboxText: 'Use as default when creating new requests',
                helpText: 'Default labels will be applied automatically when requests are created or closed. Staff are free to override the defaults when creating or closing requests.'
            }, {
                name: 'isDefaultClosed',
                value: this.get('label.isDefaultClosed'),
                checkbox: true,
                checkboxText: 'Use as default when closing requests'
            }];
        }.property('label.name', 'label.color', 'label.isDefaultOpen', 'label.isDefaultClosed'),

        actions: {
            colorSelected: function colorSelected(value) {
                this.set('selectedColor', value);
            },

            save: function save(labelInfo, onComplete /*, onError*/) {
                var _this = this;

                var label = this.get('label') || this.get('store').createRecord('request-label');
                label.set('account', this.get('account'));
                label.setProperties(labelInfo);

                var defaultsToClear = this.get('possibleDefaults').filter(function (_default) {
                    return label.get(_default);
                });
                var clearedDefaults = [];

                if (defaultsToClear.length) {
                    this.get('labels').forEach(function (l) {
                        if (l === label) {
                            return;
                        }

                        defaultsToClear.forEach(function (_default) {
                            if (l.get(_default)) {
                                l.set(_default, false);
                                clearedDefaults.push(l.save());
                            }
                        });
                    });
                }

                Ember.RSVP.all(clearedDefaults).then(function () {
                    return label.save();
                }).then(function () {
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                    _this.sendAction('action');
                });
            },

            cancel: function cancel() {
                var label = this.get('label');
                if (label) {
                    label.rollbackAttributes();
                }
                this.sendAction('onCancel');
            },

            clickDelete: function clickDelete() {
                this.sendAction('onDelete');
            }
        }
    });
});