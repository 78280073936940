define('huyang/routes/settings/customization/asset-categories/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),

        model: function model() {
            return Ember.RSVP.hash({
                categories: this.store.findAll('asset-category'),
                account: this.store.findRecord('account', this.get('session.data.authenticated.accountId'))
            });
        }
    });
});