define('huyang/mixins/creates-new-requests', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        requestsController: Ember.inject.controller('requests'),
        users: Ember.computed.alias('requestsController.model.users'),
        buildings: Ember.computed.alias('requestsController.model.buildings'),
        account: Ember.computed.alias('requestsController.model.account'),
        requestTypes: Ember.computed.alias('requestsController.model.requestTypes'),
        allActiveRequestTypes: Ember.computed.filterBy('requestTypes', 'active'),
        requestLabels: Ember.computed.alias('requestsController.model.requestLabels'),

        goBack: function goBack() {
            this.send('goBackToPreviousRoute', 'requests.mine');
        },

        staffBuildings: function () {
            var accountId = localStorage.getItem('accountId');
            var user = this.store.peekRecord('user', localStorage.getItem('userId'));
            var buildings = (this.get('buildings') || []).filter(function (building) {
                return building.get('active');
            });

            // Don't filter buildings for users with all-access
            if (user.hasRole(accountId, 'staff-all')) {
                return buildings;
            }

            var bids = user.get('staffBuildingIds');
            return buildings.filter(function (b) {
                return bids.includes(b.get('id'));
            });
        }.property('buildings'),

        actions: {
            requestSaved: function requestSaved(request, newUserIds) {
                if (newUserIds && newUserIds.length) {
                    // if there are new user records, send the user to confirmation page
                    this.transitionToRoute('requests.new-occupants', request.get('id'), {
                        queryParams: {
                            userIds: newUserIds.join(','),
                            create: true
                        }
                    });

                    return;
                }

                // show big flash banner with link to new request
                var banner = void 0;
                if (this.get('isProgram')) {
                    banner = 'new-program';
                }
                Ember.getOwner(this).lookup('controller:application').flashBanner({ 'goToRequestId': request.get('id') }, banner);
                this.goBack(request);
            },
            cancel: function cancel() {
                this.goBack();
            }
        }
    });
});