define('huyang/components/set-building-info', ['exports', 'ember-validations', 'huyang/config/environment'], function (exports, _emberValidations, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        intlTelInput: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);
            if (this.get('model')) {
                var bOwner = (this.get('model.pmProgramBuildingOwners') || []).findBy('pmProgram.id', undefined);

                if (bOwner) {
                    this.set('pmAssignee', bOwner.get('owner.id'));
                }
            }
        },


        fields: function () {
            var controller = this;

            return [{
                name: 'name',
                label: 'Name',
                placeholder: 'Empire State Building',
                validations: { presence: true },
                value: this.get('name')
            }, {
                name: 'address',
                component: 'huyang-form-address',
                properties: Ember.copy({
                    country: this.get('country'),
                    address1: this.get('address1'),
                    city: this.get('city'),
                    addressState: this.get('addressState'),
                    zipCode: this.get('zipCode')
                })
            }, {
                name: 'emergencyPhone',
                label: 'Emergency phone',
                component: 'huyang-form-phone',
                value: this.get('emergencyPhone'),
                properties: Ember.copy({
                    phone: this.get('emergencyPhone')
                }),
                validations: {
                    inline: (0, _emberValidations.validator)(function () {
                        var validation = controller.get('intlTelInput').validate();

                        var errors = {};
                        if (validation.error) {
                            errors.emergencyPhone = validation.error;
                            this.errors.pushObject(errors.emergencyPhone);
                        }
                    })
                }
            }, {
                name: 'backgroundUuid',
                label: 'Photo',
                helpText: 'The location photo will be visible to staff users and occupants',
                photoUpload: true,
                photoUrl: this.get('model') ? this.get('model.photoUrl') : this.get('photoUrl'),
                uploadUrl: this.get('uploadUrl')
            }, {
                value: this.get('created'),
                label: 'Date created',
                created: true
            }, {
                name: 'pmAssignee',
                label: 'Preventative maintenance',
                helpText: 'Repeating requests created from preventative maintenance programs will automatically be assigned to this staff member.',
                select: true,
                prompt: 'Select a staff user...',
                value: this.get('pmAssignee'),
                data: this.get('staffMembers'),
                keyPath: 'id',
                labelPath: 'name'
            }];
        }.property('name', 'address1', 'address2', 'city', 'addressState', 'zipCode', 'country', 'emergencyPhone', 'uploadUrl', 'model.backgroundUuid', 'photoUrl', 'created', 'users', 'pmAssignee'),

        staffMembers: Ember.computed('users', function () {
            var _this = this;

            // new building returns all staff
            if (!this.get('model.id')) {
                return this.get('users').filter(function (u) {
                    return u.hasAnyRole(_this.get('session.data.authenticated.accountId'), ['staff-all', 'staff', 'admin']);
                });
            }

            // existing building returns building-specific staff
            return this.get('users').filter(function (u) {
                var building = _this.get('model');
                var includeUser = !building || u.hasStaffRole(building.get('account.id'), building.get('id'));
                u.set('selected', includeUser && _this.get('pmAssignee') === u.get('id'));
                return includeUser;
            });
        }),

        photoUrl: function () {
            var uuid = this.get('backgroundUuid');
            return uuid ? _environment.default.S3_BASE_URL + '/' + this.get('session.data.authenticated.accountId') + '/buildings/' + uuid : null;
        }.property('backgroundUuid'),

        uploadUrl: function () {
            if (this.get('model.id')) {
                return '/api/' + this.get('session.data.authenticated.accountId') + '/buildings/' + this.get('model.id') + '/background';
            } else {
                return '/api/' + this.get('session.data.authenticated.accountId') + '/building_background';
            }
        }.property('model.id'),

        commitButtonLabel: function () {
            return this.get('model') ? 'Update' : 'Create location';
        }.property('model'),

        actions: {
            save: function save(fv, complete, error) {
                var uuid = this.get('backgroundUuid');

                if (uuid) {
                    fv['backgroundUuid'] = uuid;
                }

                fv.country = this.$('#hfa-country').val();
                fv.address1 = this.$('#hfa-address1').val();
                fv.city = this.$('#hfa-city').val();
                fv.addressState = this.$('#hfa-state').val();
                fv.zipCode = this.$('#hfa-zip').val();
                fv.emergencyPhone = this.get('intlTelInput.iti').getNumber();

                this.sendAction('changesSaved', fv, complete, error);
            },
            cancel: function cancel() {
                this.sendAction('onCancel');
            },
            fileUploaded: function fileUploaded(result) {
                // Photo upload updates the model async on completion, causing fields[]
                // to refresh and the address/phone js to re init, losing any unsaved
                // edits (editing address info & photo at the same time). This
                // sets those values ahead of the photo property being updated
                // so that they are used on reinit of the address/phone js.
                this.setProperties({
                    country: this.$('#hfa-country').val(),
                    address1: this.$('#hfa-address1').val(),
                    city: this.$('#hfa-city').val(),
                    addressState: this.$('#hfa-state').val(),
                    zipCode: this.$('#hfa-zip').val(),
                    emergencyPhone: this.get('intlTelInput.iti') ? this.get('intlTelInput.iti').getNumber() : ''
                });

                if (this.get('model')) {
                    this.get('model').set('backgroundUuid', result);
                } else {
                    this.set('backgroundUuid', result);
                }
            },
            uploadFailed: function uploadFailed(err) {
                console.log('failed result=', err);
                // TODO: notify
            }
        }
    });
});