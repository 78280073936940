define('huyang/blueprints/huyang-common', ['exports', 'huyang-common/blueprints/huyang-common'], function (exports, _huyangCommon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _huyangCommon.default;
    }
  });
});