define('huyang/mixins/building-request-types', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        requestTypes: function () {
            var reqTypes = [];
            var buildings = this.get('model.buildings') || this.get('buildings') || [];

            buildings.forEach(function (building) {
                building.get('requestTypes').forEach(function (reqType) {
                    var name = building.get('name') + ': ' + reqType.get('label');

                    reqTypes.push({
                        id: reqType.get('id'),
                        building: building.get('id'),
                        name: name,
                        label: name
                    });
                });
            });

            return reqTypes;
        }.property('buildings.[]')
    });
});