define('huyang/routes/settings/customization/request-labels', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return Ember.RSVP.hash({
                account: this.store.peekRecord('account', localStorage.getItem('accountId')),
                requestLabels: this.store.findAll('request-label')
            });
        }
    });
});