define('huyang/routes/vendor', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        beforeModel: function beforeModel(transition) {
            this._super(transition);
            this.controllerFor('application').set('isVendorRoute', true);
        },

        model: function model() {
            return Ember.RSVP.hash({
                account: this.get('session.currentAccount')
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('user', this.modelFor('application').user);
        }
    });
});