define('huyang/controllers/assets/asset/edit', ['exports', 'huyang/mixins/validate-custom-fields'], function (exports, _validateCustomFields) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_validateCustomFields.default, {
        changeset: Ember.computed('model.asset', function () {
            return this.get('model.asset').changeset();
        }),

        actions: {
            save: function save(data, onComplete, onError) {
                var _this = this;

                var asset = data;
                asset.set('customFields', asset.get('customFields').filter(function (cf) {
                    return cf.get('value');
                }));
                this.set('validationErrors', this.validateCustomFields(asset.get('customFields')));
                if (!this.get('validationErrors.length')) {
                    asset.save().then(function () {
                        if (typeof onComplete === 'function') {
                            onComplete();
                        }

                        _this.transitionToRoute('assets.asset', _this.get('model.asset.id'));
                    }).catch(onError);
                }
            },
            cancel: function cancel() {
                this.transitionToRoute('assets.asset', this.get('model.asset.id'));
            }
        }
    });
});