define('huyang/controllers/requests/preventative-maintenance/program', ['exports', 'huyang-common/mixins/header-navigation-controller', 'huyang-common/mixins/modal-helper', 'huyang-common/mixins/request-info'], function (exports, _headerNavigationController, _modalHelper, _requestInfo) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_headerNavigationController.default, _modalHelper.default, _requestInfo.default, {

        modelIdString: 'model.pmProgram.id',
        modelString: 'pm-program',
        routeString: 'requests.preventative-maintenance.program',

        actions: {
            openModal: function openModal(modalId) {
                this.openModal(modalId);
            },

            storeRepeatRule: function storeRepeatRule(props) {
                this.set('afterClose', {});
                this.set('repeatRule', props);
            },

            saveRepeat: function saveRepeat() {
                var _this = this;

                var repeatRule = this.get('repeatRule');
                var afterClose = this.get('afterClose');
                var schedule = this.get('model.pmProgram.schedule');

                var _ref = afterClose.createDate ? afterClose : repeatRule,
                    createDate = _ref.createDate,
                    dueAfter = _ref.dueAfter;

                // Assign all regardless of which repeat type it is, since this will
                // clear repeat rule fields if after close and vice versa


                schedule.setProperties({
                    repeatRule: repeatRule.repeatRule,
                    previousClose: repeatRule.previousClose,
                    previousRelabel: repeatRule.previousRelabel,
                    previousLabel: repeatRule.previousLabel,
                    afterClose: afterClose.afterClose,
                    start: createDate,
                    dueAfter: dueAfter
                });

                // some fields in the modal are on program rather than schedule
                var program = this.get('model.pmProgram');

                program.set('allowBalancing', repeatRule.allowBalancing);
                if (repeatRule.allowBalancing) {
                    program.set('balancingSchedule', repeatRule.balancingSchedule);
                }

                this.get('model.pmProgram').save();

                schedule.save().then(function () {
                    _this.get('flashMessages').success('Repeat schedule has been updated');
                });
            }
        }
    });
});