define('huyang/controllers/application', ['exports', 'huyang-common/mixins/flash-banner'], function (exports, _flashBanner) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    exports.default = Ember.Controller.extend(_flashBanner.default, {
        session: Ember.inject.service(),
        viewingRequest: Ember.inject.service('viewing-request'),

        user: Ember.computed.alias('session.currentUser'),

        // On fresh logins, model.account does not exist because the original
        // model hook returns [] and does not re-run when authenticated,
        // so find the account manually
        account: computed('session.data.authenticated.accountId', function () {
            if (this.get('session.data.authenticated.accountId')) {
                return this.store.peekRecord('account', this.get('session.data.authenticated.accountId'));
            }
        }),

        authedAndActive: Ember.computed('session.isAuthenticated', 'account', function () {
            return this.get('session.isAuthenticated') && this.get('account.status') !== 'inactive';
        }),

        isAdmin: function () {
            return this.get('session.data.authenticated.isAdmin');
        }.property('session.currentUser'),

        hasTimers: computed('timersToDisplay.length', function () {
            var account = this.get('account');

            if (!account) {
                return false;
            }

            return this.get('timersToDisplay.length') > 0;
        }),

        timersToDisplay: computed('user.openTimers.length', 'viewingRequest.id', function () {
            // If on a request with an open timer, do not show that timer in the banner
            var timers = this.get('user.openTimers') || [];
            var currentReqId = this.get('viewingRequest.id');

            return timers.filter(function (t) {
                return Number(t.get('request.id')) !== Number(currentReqId);
            });
        }),

        actions: {
            goToRequest: function goToRequest() {
                this.transitionToRoute('requests.request', this.get('goToRequestId'));
                this.dismissBanner();
            },

            goToProgram: function goToProgram() {
                this.transitionToRoute('requests.preventative-maintenance.program', this.get('goToRequestId'));
                this.dismissBanner();
            },

            toggleMobileNav: function toggleMobileNav() {
                Ember.$('.nav-menu').toggleClass('open');
            },

            performSearch: function performSearch() {
                var q = this.get('searchQuery');

                this.transitionToRoute('requests.search', {
                    queryParams: {
                        q: q
                    }
                });

                this.set('searchQuery', '');
            }
        }
    });
});