define('huyang/controllers/people/occupants/manage/users/inactive', ['exports', 'huyang/controllers/people/occupants/manage/users/filter-base'], function (exports, _filterBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _filterBase.default.extend({
        occupants: Ember.computed.alias('parentController.occupants'),

        blockedUsers: function () {
            return this.get('occupants').filterBy('isBlocked');
        }.property('occupants.@each.isBlocked'),

        blockedMessage: function () {
            var len = this.get('blockedUsers.length');
            var pluralized = len !== 1 ? 'users' : 'user';

            return len + ' blocked ' + pluralized;
        }.property('blockedUsers.length')
    });
});