define('huyang/components/editable-form-group', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['form-group'],

        actions: {
            edit: function edit() {
                var _this = this;

                this.set('editMode', true);
                setTimeout(function () {
                    return _this.$('input').focus();
                }, 200);
            }
        }
    });
});