define('huyang/routes/tenants', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        model: function model() /*params*/{
            var accountId = this.get('session.data.authenticated.accountId');
            var users = this.store.findAll('user');

            return Ember.RSVP.hash({
                'buildings': this.store.findAll('building', function (building) {
                    return building.get('active');
                }),
                'subspaces': this.store.findAll('subspace'),
                'users': users.filter(function (user) {
                    return user.hasRole(accountId, 'tenant');
                })
            });
        }
    });
});