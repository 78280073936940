define('huyang/components/event-summary', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['request-section', 'recent-activity'],

        filteredEvents: function () {
            var events = this.get('events').filter(function (e) {
                return e.get('eventType') !== 'open';
            });

            return events.sortBy('eventDate').reverseObjects();
        }.property(),

        eventLabel: function () {
            var count = this.get('filteredEvents.length');
            if (count < 1) {
                return;
            }
            return 'Showing ' + count + ' most recent event' + (count > 1 ? 's' : '') + '.';
        }.property()
    });
});