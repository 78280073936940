define('huyang/controllers/reports/saved/trend', ['exports', 'huyang/controllers/reports/report-controller'], function (exports, _reportController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _reportController.default.extend({
        isSaved: true,

        groupBy: Ember.computed.alias('model.params.group_by'),
        eventCollection: Ember.computed.alias('model.params.event_collection'),
        analysis: Ember.computed.alias('model.analysis'),
        interval: Ember.computed.alias('model.params.interval'),
        timeframe: Ember.computed.alias('model.params.timeframe'),
        category1: '',
        category2: '',
        viz: 'trend',
        postprocess: true,

        reportType: function () {
            return this.get('eventCollection') + ':' + this.get('analysis');
        }.property('eventCollection', 'analysis'),

        actions: {
            saveReport: function saveReport() {
                this.updateReport();
            }
        }
    });
});