define('huyang/components/occupant-group-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['group-list'],
        selectAllChecked: false,

        toggleSelectAll: function () {
            var _this = this;

            this.get('groups').forEach(function (g) {
                return g.set('selected', _this.get('selectAllChecked'));
            });
        }.observes('selectAllChecked'),

        groupsChanged: function () {
            this.sendAction('selectedGroupsChanged', this.get('groups').filterBy('selected'));
        }.observes('groups.@each.selected'),

        actions: {
            selectGroup: function selectGroup(group) {
                if (this.get('enableMultiSelect')) {
                    group.toggleProperty('selected');
                }
            }
        }
    });
});