define('huyang/components/set-occupant-info', ['exports', 'ember-validations', 'huyang-common/mixins/validates-email'], function (exports, _emberValidations, _validatesEmail) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_validatesEmail.default, {
        intlTelInput: Ember.inject.service(),

        locationAccessErrors: {},
        isEdit: false,

        setup: function () {
            this.set('selectedStatus', this.get('status'));
        }.on('init'),

        fields: function () {
            var controller = this;

            var fields = [{
                section: {
                    label: 'Contact info',
                    icon: 'fa-user'
                },
                name: 'email',
                label: 'Email address',
                validations: this.get('emailValidations'),
                placeholder: 'george@vandelay.com',
                value: this.get('user.email')
            }, {
                name: 'name',
                label: 'Name',
                placeholder: 'George Costanza',
                value: this.get('user.name')
            }, {
                name: 'mobilePhone',
                label: 'Mobile phone',
                component: 'huyang-form-phone',
                properties: Ember.copy({
                    phone: this.get('user.mobilePhone')
                }),
                validations: {
                    inline: (0, _emberValidations.validator)(function () {
                        var validation = controller.get('intlTelInput').validate();

                        var errors = {};
                        if (validation.error) {
                            errors.mobilePhone = validation.error;
                            this.errors.pushObject(errors.mobilePhone);
                        }
                    })
                }
            }, {
                name: 'locationAccess',
                section: {
                    label: 'Location access',
                    icon: 'fa-building-o'
                },
                component: 'occupant-location-access',
                properties: Ember.copy({
                    includeGroupOption: true,
                    noun: 'Occupant',
                    accessType: this.get('accessType'),
                    selectedGroup: this.get('occupantGroup.id'),
                    selectedGroupObject: this.get('occupantGroup'),
                    groups: this.get('occupantGroups'),
                    buildings: this.get('buildings'),
                    subspaces: this.get('subspaces'),
                    selectedLocations: this.get('locations'),
                    errors: this.get('locationAccessErrors')
                }),
                validations: {
                    inline: (0, _emberValidations.validator)(function () {
                        var access = controller.get('locationAccess');
                        if (!access) {
                            return;
                        }
                        var errors = {};
                        // if group, must choose a group
                        if (access.accessType === 'group' && (!access.selectedGroupObject || !access.selectedGroupObject.get('id'))) {
                            errors.group = 'must choose a group';
                            this.errors.pushObject(errors.group);
                        }
                        // if specific, must choose at least one location
                        if (access.accessType === 'specific' && (!access.selectedBuildings || !access.selectedBuildings.length)) {
                            errors.specific = 'must choose at least one location';
                            this.errors.pushObject(errors.specific);
                        }
                        if (this.errors.length) {
                            controller.set('accessType', access.accessType);
                            controller.set('locationAccessErrors', errors);
                        }
                    })
                },
                action: 'locationAccessChanged'
            }];
            return fields;
        }.property('user.email', 'user.name', 'user.mobilePhone', 'status', 'occupantGroup', 'occupantGrants', 'occupantGroups', 'buildings', 'subspaces', 'locationAccessErrors'),

        locations: function () {
            var grants = this.get('occupantGrants') || [];

            return grants.map(function (grant) {
                return {
                    building: grant.get('building.id'),
                    subspace: grant.get('subspace.id')
                };
            });
        }.property('occupantGrants.[]'),

        actions: {
            save: function save(info, callback) {
                info.mobilePhone = this.get('intlTelInput.iti').getNumber();
                info.locationAccess = this.get('locationAccess');
                this.sendAction('action', info, callback);
            },

            cancel: function cancel() {
                this.sendAction('cancel');
            },

            locationAccessChanged: function locationAccessChanged(properties) {
                this.set('locationAccess', properties);
            }
        }
    });
});