define('huyang/controllers/reports/report-controller', ['exports', 'huyang-common/mixins/modal-helper', 'huyang/mixins/keen', 'huyang/mixins/keen-query'], function (exports, _modalHelper, _keen, _keenQuery) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Controller.extend(_modalHelper.default, _keen.default, _keenQuery.default, {
        reportsController: Ember.inject.controller('reports'),

        buildings: Ember.computed.alias('reportsController.model.buildings'),
        requestTypes: Ember.computed.alias('reportsController.model.requestTypes'),
        requestLabels: Ember.computed.alias('reportsController.model.requestLabels'),
        costCategories: Ember.computed.alias('reportsController.model.costCategories'),
        groups: Ember.computed.alias('reportsController.model.groups'),
        users: Ember.computed.alias('reportsController.model.users'),
        roles: Ember.computed.alias('reportsController.model.roles'),

        session: Ember.inject.service(),

        groupBy: '',
        interval: '',
        filteredIds: [],
        reportName: '',
        reportType: 'request_open:count',
        repeating: '--all--',

        colors: ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a', '#ffff99', '#b15928'],

        groupLabels: function groupLabels(param) {
            var _this = this;

            var mapping = {};
            var objects = function () {
                switch (param) {
                    case 'building.id':
                        return _this.get('buildingObjects');
                    case 'request_type.id':
                        return _this.get('requestTypeObjects');
                    case 'request_label.id':
                        return _this.get('requestLabelObjects');
                    case 'group.id':
                        return _this.get('groupObjects');
                    case 'owner.id':
                        return _this.get('ownerObjects');
                    case 'worker.id':
                        return _this.get('ownerObjects');
                    case 'category.id':
                        return _this.get('costCategoryObjects');
                    default:
                        return [];
                }
            }();

            objects.forEach(function (obj) {
                return mapping[obj.get('id')] = obj.get('label');
            });

            return mapping;
        },

        currentGroupLabels: function () {
            return this.groupLabels(this.get('groupBy'));
        }.property('groupBy', 'buildingObjects.[]', 'requestTypeObjects.[]', 'requestLabelObjects.[]', 'groupObjects.[]', 'ownerObjects.[]', 'costCategoryObjects.[]'),

        category1Empty: function () {
            return this.get('category1') === 'owner.id' ? 'Unassigned' : 'n/a';
        }.property('category1'),

        category2Empty: function () {
            return this.get('category2') === 'owner.id' ? 'Unassigned' : 'n/a';
        }.property('category2'),

        category1Labels: function () {
            return this.get('data.meta.category1Labels') || {};
        }.property('category1', 'data.meta.category1Labels'),

        category2Labels: function () {
            return this.get('data.meta.category2Labels') || {};
        }.property('category2', 'data.meta.category2Labels'),

        category1: function () {
            return this.get('groupBy')[0];
        }.property('groupBy'),

        category2: function () {
            return this.get('groupBy')[1];
        }.property('groupBy'),

        isItemSelected: function isItemSelected(group, id) {
            var selected = false;

            if (this.get('groupBy') === group) {
                selected = this.get('filteredIds').includes(parseInt(id, 10));
            }

            return selected;
        },

        buildingObjects: function () {
            var _this2 = this;

            return this.get('buildings').map(function (b) {
                return Ember.Object.create({
                    id: b.get('id'),
                    label: b.get('name'),
                    selected: _this2.isItemSelected('building.id', b.get('id'))
                });
            });
        }.property('buildings', 'buildings.[]', 'groupBy'),

        requestTypeObjects: function () {
            var _this3 = this;

            return this.get('requestTypes').map(function (rt) {
                return Ember.Object.create({
                    id: rt.get('id'),
                    label: rt.get('label'),
                    active: rt.get('active'),
                    selected: _this3.isItemSelected('request_type.id', rt.get('id'))
                });
            });
        }.property('requestTypes', 'requestTypes.[]'),

        ownerObjects: function () {
            var _this4 = this;

            var t = this.get('users').map(function (user) {
                return Ember.Object.create({
                    id: user.get('id'),
                    label: user.get('name'),
                    selected: _this4.isItemSelected('owner.id', user.get('id'))
                });
            });

            return t;
        }.property('users.[]'),

        requestLabelObjects: function () {
            var _this5 = this;

            return this.get('requestLabels').map(function (requestLabel) {
                return Ember.Object.create({
                    id: requestLabel.get('id'),
                    label: requestLabel.get('name'),
                    position: requestLabel.get('position'),
                    selected: _this5.isItemSelected('request_label.id', requestLabel.get('id'))
                });
            });
        }.property('requestLabels', 'requestLabels.[]'),

        costCategoryObjects: function () {
            var _this6 = this;

            return this.get('costCategories').map(function (category) {
                return Ember.Object.create({
                    id: category.get('id'),
                    label: category.get('label'),
                    selected: _this6.isItemSelected('category.id', category.get('id'))
                });
            });
        }.property('costCategories', 'costCategories.[]'),

        groupObjects: function () {
            var _this7 = this;

            return this.get('groups').map(function (t) {
                return Ember.Object.create({
                    id: t.get('id'),
                    label: t.get('name'),
                    selected: _this7.isItemSelected('group.id', t.get('id'))
                });
            });
        }.property('groups', 'groups.[]'),

        downloadUrl: function () {
            var url = '/api/projects/gridium/csv/' + this.get('analysis');
            var q = this.get('query');
            if (!q) {
                return; // query not ready yet
            }
            if (q.params) {
                url += '?query=' + encodeURIComponent(JSON.stringify(q.params));

                if (this.get('reportName') || this.get('model.name')) {
                    var name = this.get('model.name') ? this.get('model.name') : this.get('reportName');
                    url += '&report_name=' + encodeURIComponent(name);
                }
                // get original title, not postprocessed title
                url += '&title=' + encodeURIComponent(this.get('report').title);
                url += '&viz=' + q.params.viz;
            }

            return url;
        }.property('reportType', 'groupBy', 'interval', 'timeframe', 'filteredIds.[]', 'repeating', 'query'),

        // for crosstab reports
        colorKeys: function () {
            var _this8 = this;

            var resultSet = new Set(); // IE11 doesn't support new Set([1,2,3])
            (this.get('data.result') || []).forEach(function (d) {
                resultSet.add(d[_this8.get('category2')]);
            });
            return [].concat(_toConsumableArray(resultSet)).sort();
        }.property('data.[]', 'category2'),

        xAxisTickFormat: function () {
            var rt = this.get('reportType');
            if (rt === 'request_closed:average') {
                return 'duration';
            }
            if (rt === 'time_log:average') {
                return 'duration-hours';
            }
            return 'count';
        }.property('reportType'),

        timeframeDateRange: function timeframeDateRange() {
            var tf = this.get('timeframe');

            if (tf === 'all') {
                return 'all dates';
            }
            var fromDt = moment();
            var toDt = moment();
            var now = moment();
            if (this.get('timeframe.start') && this.get('timeframe.end')) {
                // TODO: custom dates timezone?
                fromDt = moment(this.get('timeframe.start'));
                toDt = moment(this.get('timeframe.end'));
            } else {
                if (tf.indexOf('quarter') > 0) {
                    var quarters = ['01', '04', '07', '10'];
                    var quarter = Math.floor(now.get('month') / 3);
                    if (tf === 'this_quarter') {
                        fromDt = moment(fromDt.get('year') + '-' + quarters[quarter] + '-01', 'YYYY-MM-DD');
                    }
                    if (tf === 'previous_quarter') {
                        toDt = moment(fromDt.get('year') + '-' + quarters[quarter] + '-01', 'YYYY-MM-DD');
                        fromDt = moment(toDt).add(-3, 'months');
                        toDt.add(-1, 'days');
                    }
                }
                if (tf.indexOf('this_') >= 0) {
                    var parts = tf.split('_');
                    if (parts.length === 3) {
                        // this_x_days
                        fromDt = moment(toDt).add(-parseInt(parts[1], 10), parts[2]).add(1, 'day');
                    }
                    if (tf === 'this_month') {
                        fromDt = moment(fromDt.get('year') + '-' + (fromDt.get('month') + 1) + '-01', 'YYYY-MM-DD');
                    }
                    if (tf === 'this_year') {
                        fromDt = moment(fromDt.get('year') + '-01-01', 'YYYY-MM-DD');
                    }
                }
                if (tf.indexOf('previous_') >= 0) {
                    if (tf === 'previous_month') {
                        toDt = moment(fromDt.get('year') - 1 + '-' + (fromDt.get('month') + 1) + '-01', 'YYYY-MM-DD').add(-1, 'days');
                        fromDt = moment(toDt).add(-1, 'months').add(1, 'days');
                    }
                    if (tf === 'previous_year') {
                        toDt = moment(fromDt.get('year') + '-01-01', 'YYYY-MM-DD').add(-1, 'days');
                        fromDt = moment(toDt).add(-1, 'year').add(1, 'days');
                    }
                }
            }
            return fromDt.format('MMMM D, YYYY') + ' - ' + toDt.format('MMMM D, YYYY');
        },

        crosstabReportDescription: function () {
            var _this9 = this;

            var report = this.get('reportTypes')[this.get('reportType')];
            var groupBy = this.get('groupByOptions');
            var c1 = groupBy.find(function (opt) {
                return opt.get('id') === _this9.get('category1');
            });
            var c2 = groupBy.find(function (opt) {
                return opt.get('id') === _this9.get('category2');
            });
            return report.title + ' per ' + c1.label.toLowerCase() + ', grouped by ' + c2.label.toLowerCase() + ': ' + this.timeframeDateRange(this.get('timeframe'));
        }.property('reportType', 'category1', 'category2', 'timeframe'),

        updateReport: function updateReport() {
            var _this10 = this;

            var query = this.get('query');
            var report = this.get('model');

            report.set('eventCollection', query.params.event_collection);
            report.set('analysis', query.analysis);
            report.set('params', query.params);

            report.save().then(function () {
                return _this10.get('flashMessages').success('Changes saved');
            });
        },

        actions: {
            showDeleteModal: function showDeleteModal() {
                this.openModal('confirmDelete');
            },
            deleteReport: function deleteReport() {
                var _this11 = this;

                this.get('model').destroyRecord().then(function () {
                    _this11.get('flashMessages').success('Report deleted');
                    _this11.transitionToRoute('reports.index');
                });
            },
            showRenameModal: function showRenameModal() {
                this.openModal('confirmRename');
            },
            rollbackReport: function rollbackReport() {
                this.get('model').rollbackAttributes();
            },
            showSaveModal: function showSaveModal() {
                this.openModal('confirmSave');
                setTimeout(function () {
                    return Ember.$('#confirmSave input').select();
                }, 400);
            },
            saveReport: function saveReport() {
                var _this12 = this;

                var query = this.get('query');

                if (!query) {
                    return;
                }

                var data = {
                    eventCollection: query.params.event_collection,
                    analysis: query.analysis,
                    params: query.params,
                    name: this.get('reportName'),
                    user: this.get('session.data.authenticated.userId'),
                    account: localStorage.getItem('accountId'),
                    viz: query.params.viz
                };

                var report = this.store.createRecord('report-query', data);

                report.save().then(function () {
                    _this12.closeModal();
                    _this12.transitionToRoute('reports.report', report.get('id'));
                });
            }
        }
    });
});