define('huyang/components/follow-unfollow-button', ['exports', 'huyang-common/components/follow-unfollow-button'], function (exports, _followUnfollowButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _followUnfollowButton.default;
    }
  });
});