define('huyang/routes/people/occupants/manage/groups', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model() {
            return this.store.findAll('occupant-group');
        }
    });
});