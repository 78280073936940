define('huyang/controllers/admin/account', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),

    actions: {
      onSelect: function onSelect(selectedValue) {
        this.set('selectedAccount', this.get('store').findRecord('account', selectedValue));
        // TODO
        // this.set('requestLabels', this.get('store').findAll('request-label'));
        // this.set('assetCategories', this.get('store').findAll('asset-category'));
        this.transitionToRoute('admin.account.info');
      }
    }
  });
});