define('huyang/controllers/settings/customization/request-labels/edit', ['exports', 'huyang-common/mixins/modal-helper'], function (exports, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalHelper.default, {
        requestCount: Ember.computed.alias('model.requestCount'),

        hasRequests: function () {
            return this.get('requestCount') > 0;
        }.property('requestCount'),

        countLabel: function () {
            var count = this.get('requestCount');
            var label = count + ' request';
            if (count > 1) {
                label += 's';
            }
            return label;
        }.property('requestCount'),

        actions: {
            save: function save() {
                this.get('flashMessages').success('Request label updated!');
                this.transitionToRoute('settings.customization.request-labels');
            },

            cancel: function cancel() {
                this.transitionToRoute('settings.customization.request-labels');
            },

            clickDelete: function clickDelete() {
                this.openModal('confirmDelete');
            },

            doDelete: function doDelete() {
                var _this = this;

                this.closeModal();
                this.get('model').destroyRecord().then(function () {
                    _this.get('flashMessages').success('Request label deleted!');
                    _this.transitionToRoute('settings.customization.request-labels');
                });
            }
        }
    });
});