define('huyang/routes/settings/buildings/building', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            return Ember.RSVP.hash({
                building: this.store.findRecord('building', params.building_id),
                // reload in case the counts have changed (ie new request)
                usage: this.store.findRecord('building-usage', params.building_id, { reload: true }),
                users: this.store.findAll('user')
            });
        }
    });
});