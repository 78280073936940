define('huyang/models/asset-category-custom-field', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    category: _emberData.default.belongsTo('asset-category'),

    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    required: _emberData.default.attr('boolean'),
    defaultValue: _emberData.default.attr(),
    listItems: _emberData.default.attr([]),
    position: _emberData.default.attr(),

    isNumber: Ember.computed.equal('type', 'number'),
    isShortText: Ember.computed.equal('type', 'string'),
    isLongText: Ember.computed.equal('type', 'text'),
    isDate: Ember.computed.equal('type', 'date'),
    isCheckbox: Ember.computed.equal('type', 'checkbox'),
    isList: Ember.computed.equal('type', 'list'),
    isVendor: Ember.computed.equal('type', 'vendor')
  });
});