define('huyang/routes/requests/request/messages/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        afterModel: function afterModel(model) {
            var request = model.request;
            var comments = request.get('comments');
            var primaryComment = comments.findBy('isCreationComment');

            if (primaryComment) {
                var channel = primaryComment.get('channel');
                this.transitionTo('requests.request.messages.channel', request.get('id'), channel.get('id'));
            } else {
                // this should almost never happen - all requests should have a primary comment automatically
                var channels = this.modelFor('requests').channels;
                var staffChannel = channels.findBy('isStaff');
                this.transitionTo('requests.request.messages.channel', request.get('id'), staffChannel.get('id'));
            }
        }
    });
});