define('huyang/components/render-alert', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didRender: function () {
            this.sendAction('rendered');
        }.on('didInsertElement')
    });
});