define('huyang/serializers/pm-program', ['exports', 'huyang-common/serializers/rest-new'], function (exports, _restNew) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _restNew.default.extend({
        serialize: function serialize(snapshot, options) {
            var data = this._super(snapshot, options);

            // add attachment id if it's present (not part of the the request  model)
            // for single-request update/attach operation; adapters/request sends to attach endpoint
            if (snapshot.record.attachment && snapshot.record.attachment.length) {
                data.attachment = snapshot.record.attachment;
            }

            if (snapshot.record.checklist) {
                data.checklist = snapshot.record.get('checklist.id');
            }

            return data;
        }
    });
});