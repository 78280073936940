define('huyang/controllers/requests/request/send', ['exports', 'huyang-common/mixins/creates-vendors'], function (exports, _createsVendors) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_createsVendors.default, {
        requestsController: Ember.inject.controller('requests'),
        requestController: Ember.inject.controller('requests.request'),
        request: Ember.computed.alias('requestController.model.request'),
        trades: Ember.computed.alias('model.trades'),

        vendors: Ember.computed.alias('requestController.model.vendors'),
        serviceRequests: Ember.computed.alias('requestController.model.serviceRequests'),

        queryParams: ['assign', 'user', 'email'],

        sending: false,
        sent: false,
        loadingVendor: false,
        editVendorData: false,
        message: '',
        subject: '',
        email: null,
        assign: false,
        vendorFormData: {},

        focusAndSelectSubject: function () {
            if (this.get('recipient')) {
                Ember.$('.message-subject').select();
            }
        }.observes('recipient'),

        setup: function setup() {
            this.set('vendor', {});
            this.set('recipient', null);
            this.set('message', this.get('request.description'));
            this.set('subject', this.get('defaultSubject'));
            this.set('sending', false);
            this.set('sent', false);
            this.set('loadingVendor', false);
            this.set('editVendorData', false);
            this.set('vendorFormData', {});

            this.setRecipient();
        },

        reset: function reset() {
            this.set('user', '');
            this.set('assign', false);
            this.set('email', '');
        },

        setRecipient: function setRecipient() {
            if (this.get('user')) {
                var recipient = this.get('vendors').findBy('primaryUser.id', this.get('user'));

                if (recipient) {
                    this.set('recipient', recipient.get('primaryUser'));
                } else {
                    // if individual user sent in rather than vendor
                    recipient = this.get('requestsController.model.users').findBy('id', this.get('user'));

                    if (recipient) {
                        this.set('recipient', recipient);
                    }
                }
            }
        },

        disableSendButton: function () {
            if (this.get('assign')) {
                return !this.get('recipient');
            }

            return !this.get('recipient') || !this.get('message') || !this.get('subject');
        }.property('recipient', 'message', 'subject'),

        defaultSubject: function () {
            return '[#' + this.get('request.workOrder') + '] ' + this.get('request.title') + ' (' + this.get('request.building.address1') + ')';
        }.property('request.title', 'request.location'),

        assigneeValue: function () {
            if (this.get('recipient')) {
                return this.get('recipient.displayName');
            } else if (this.get('email')) {
                return this.get('email');
            }

            return '';
        }.property('recipient', 'email'),

        needsToCreateVendor: function () {
            return !this.get('recipient.id') && this.get('email');
        }.property('email', 'recipient'),

        actions: {
            lookupVendor: function lookupVendor() {
                var _this = this;

                this.set('loadingVendor', true);

                this.lookupEmail(this.get('email')).always(function () {
                    _this.set('loadingVendor', false);
                    _this.set('editVendorData', true);
                });
            },
            createUserWithEmail: function createUserWithEmail(email) {
                if (!email) {
                    this.set('email', null);
                } else {
                    this.set('email', email);
                }
            },
            sendAndAssign: function sendAndAssign() {
                this.send('reassign');
                this.send('sendMessage');
            },
            sendMessage: function sendMessage(vendor) {
                var _this2 = this;

                var user = this.get('recipient');
                var message = this.get('message') || this.get('request.description');

                var subject = this.get('subject');

                if (!subject) {
                    subject = this.get('defaultSubject');
                }

                if (user) {
                    this.set('sending', true);
                    var technician = void 0;

                    if (typeof vendor === 'undefined') {
                        // find recipient's vendor ID and possible technician ID
                        technician = this.get('model.technicians').findBy('user.id', user.get('id'));

                        if (technician) {
                            vendor = technician.get('vendor');
                        } else {
                            vendor = this.get('vendors').findBy('primaryUser.id', user.get('id'));
                        }
                    }

                    if (vendor) {
                        var accountId = localStorage.getItem('accountId');
                        var account = this.store.peekRecord('account', accountId);

                        var serviceRequest = this.store.createRecord('vendorServiceRequest', {
                            account: account,
                            vendor: vendor,
                            technician: technician,
                            request: this.get('request'),
                            commentSubject: subject,
                            commentText: message
                        });

                        serviceRequest.save().then(function () {
                            _this2.set('sending', false);
                            _this2.set('sent', true);
                            _this2.get('request').reload();
                        });
                    } else {
                        console.error('Missing vendor.');
                    }
                } else {
                    // TODO: show error on form
                }
            },
            updateVendorData: function updateVendorData(data) {
                var _this3 = this;

                Object.keys(data).forEach(function (key) {
                    _this3.set('vendorFormData.' + key, data[key]);
                });
            },
            createVendorAndSend: function createVendorAndSend() {
                var _this4 = this;

                this.set('editVendorData', false);
                this.set('sending', true);

                var vendorInfo = this.get('vendorFormData');

                // make sure the required fields are set
                var hasRequiredFields = true;
                var requiredFields = this.get('formFields').filterBy('validations.presence').map(function (field) {
                    return field.name;
                });

                console.log('checking required fields', requiredFields);

                requiredFields.forEach(function (rf) {
                    if (typeof vendorInfo[rf] === 'undefined') {
                        hasRequiredFields = false;
                    }
                });

                if (hasRequiredFields) {
                    this.saveVendor(vendorInfo).then(function (vendor) {
                        var user = vendor.get('primaryUser');

                        _this4.set('recipient', user);
                        _this4.send('sendMessage', vendor);

                        if (_this4.get('assign')) {
                            _this4.send('reassign');
                        }
                    });
                } else {
                    console.error('Missing required fields. Vendor info is:', vendorInfo);
                }
            },
            reassign: function reassign() {
                var _this5 = this;

                if (this.get('recipient')) {
                    this.get('request').set('owner', this.get('recipient'));

                    this.get('request').save().then(function () {
                        _this5.get('flashMessages').success('Reassigned request');
                    });

                    this.send('leaveRoute');
                }
            }
        }
    });
});