define('huyang/components/assets/custom-fields/field-row', ['exports', 'huyang/components/inline-editable-table', 'ember-sortable/mixins/sortable-item', 'huyang/mixins/validate-custom-fields'], function (exports, _inlineEditableTable, _sortableItem, _validateCustomFields) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _inlineEditableTable.default.extend(_sortableItem.default, _validateCustomFields.default, {
    tagName: 'tr',
    classNames: ['list-row'],
    classNameBindings: ['typeCssClass'],

    typeCssClass: Ember.computed('item.type', function () {
      return 'custom-field-type-' + this.get('item.type');
    }),

    selectId: Ember.computed('item', function () {
      return 'select-type-' + this.get('item.id');
    }),

    config: {
      multiple: false,
      placeholder: 'Select type*'
    },

    types: [{ value: 'number', label: 'Number' }, { value: 'string', label: 'Short text' }, { value: 'text', label: 'Long text' }, { value: 'date', label: 'Date' }, { value: 'checkbox', label: 'Checkbox' }, { value: 'list', label: 'List' }, { value: 'vendor', label: 'Vendor' }],

    selectedType: Ember.computed('item', 'item.type', function () {
      var _this = this;

      return this.get('types').find(function (obj) {
        return obj.value === _this.get('item.type');
      });
    }),

    customFieldTypes: Ember.computed('item', 'item.type', function () {
      var _this2 = this;

      var selectedType = this.get('types').find(function (obj) {
        return obj.value === _this2.get('item.type');
      });

      if (selectedType) {
        selectedType.selected = true;
      }

      if (!this.get('vendors.length')) {
        var vendorObject = this.get('types').findBy('value', 'vendor');
        this.get('types').removeObject(vendorObject);
      }

      return this.get('types');
    }),

    selectedVendor: Ember.computed('item.type', 'item.defaultValue', function () {
      if (this.get('item.type') === 'vendor' && this.get('item.defaultValue')) {
        var vendor = this.get('vendors').findBy('id', this.get('item.defaultValue'));
        this.get('vendors').setEach('selected', false);
        vendor.set('selected', true);
        return vendor;
      }
    }),

    // for some reason ED doesn't want to rollback this attr correctly
    // when it is an array, so we need to track it here to rollback manually
    // in cancelEdit() below
    initialValueObserver: Ember.observer('item.isEditing', function () {
      this.set('initialValue', Ember.copy(this.get('item.listItems'), true));
    }),

    initListDefaultValue: function initListDefaultValue(item) {
      // set inital value of list item as array so objects can be pushed to it
      if (item.get('isList') && !item.get('listItems.length')) {
        item.set('listItems', [{ string: null }]);
      }
    },
    init: function init() {
      var _this3 = this;

      this._super.apply(this, arguments);

      // For some reason on prod (can repro, but not locally) new field rows
      // carry over the last selected type.
      // Tikkit #5482
      this.get('types').forEach(function (type) {
        delete type.selected;
      });

      // enter keypress action
      $(document).on('keypress.listEnter', function (e) {
        var isEnter = e.which === 13;
        var $listItem = $(e.target).parent();
        var isThisItem = _this3.$()[0] === $(e.target).closest('.list-row')[0];
        var isListItemInput = $listItem.hasClass('editable-list-item');

        if (isEnter && isListItemInput && isThisItem) {
          var isLastChild = $listItem.is(':last-child');

          Ember.run.next(function () {
            if (isLastChild) {
              _this3.send('addOption');
            } else {
              $listItem.next().find('input').focus().select();
            }
          });
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      $(document).off('keypress.listEnter');
    },


    actions: {
      onSelect: function onSelect(selectedItem) {
        this.set('item.type', selectedItem);
        this.initListDefaultValue(this.get('item'));
      },
      editItem: function editItem(item) {
        this.initListDefaultValue(item);
        this._super(item);
      },
      saveEdit: function saveEdit(item) {
        var _this4 = this;

        if (this.validateCategoryCustomFieldEdit(item)) {
          item.save().then(function () {
            _this4.get('flashMessages').success('Custom field saved!');
            item.set('isEditing', false);
          });
        }
      },


      // overwritten and reordered due to rollback issue noted above
      cancelEdit: function cancelEdit(item) {
        item.rollbackAttributes();
        this.set('item.listItems', this.get('initialValue'));
        this.set('editedItem', null);
        item.set('isEditing', false);
        item.set('error', null);
      },

      deleteItem: function deleteItem(item) {
        this.set('deletedItem', item);
        this.set('deleteTitle', 'Delete \'' + this.get('item.name') + '\'');
        this.openModal('confirmDeleteItem');
      },

      addOption: function addOption() {
        var _this5 = this;

        this.get('item.listItems').pushObject({ string: null });
        Ember.run.next(function () {
          _this5.$('.editable-list-item:last-child input').focus();
        });
      },
      removeOption: function removeOption(option) {
        this.get('item.listItems').removeObject(option);
      },
      reorderItems: function reorderItems(itemModels) {
        this.set('item.listItems', itemModels);
      }
    }
  });
});