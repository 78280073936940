define('huyang/routes/settings/buildings/building/request-types', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            var building = this.modelFor('settings.buildings.building').building;
            var requestTypes = this.store.query('request-type', { active: true });

            return Ember.RSVP.hash({
                building: building,
                requestTypes: requestTypes.filter(function (reqType) {
                    return reqType.get('active');
                }),
                requestTypeBuildings: this.store.findAll('request-type-building')
            });
        }
    });
});