define('huyang/components/assets/custom-fields/field-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    inputClass: 'form-control',
    classNames: ['custom-display-field'],
    vendorItems: [],

    isNumber: Ember.computed.equal('categoryField.type', 'number'),
    isShortText: Ember.computed.equal('categoryField.type', 'string'),
    isLongText: Ember.computed.equal('categoryField.type', 'text'),
    isDate: Ember.computed.equal('categoryField.type', 'date'),
    isCheckbox: Ember.computed.equal('categoryField.type', 'checkbox'),
    isList: Ember.computed.equal('categoryField.type', 'list'),
    isVendor: Ember.computed.equal('categoryField.type', 'vendor'),
    showDefault: true,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.get('value') && !this.get('isList')) {
        this.set('value', this.get('categoryField.defaultValue'));

        // setting value above doesn't seem to set the value on the actual record,
        // so we need this for use on the individual asset form
        Ember.run.later(function () {
          if (_this.get('customField')) {
            _this.set('customField.value', _this.get('value'));
          }
        });
      }

      var vendorItems = void 0;
      if (this.get('vendors')) {
        vendorItems = this.get('vendors').map(function (v) {
          return Ember.Object.create({
            id: v.id,
            name: v.get('name')
          });
        });
        this.set('vendorItems', vendorItems);
      }

      if (this.get('isVendor')) {
        var selectedValue = this.get('value') || this.get('categoryField.defaultValue');

        if (selectedValue) {
          this.set('value', selectedValue);

          if (this.get('customField')) {
            this.set('customField.value', selectedValue);
          }

          var vendor = this.get('vendorItems').findBy('id', selectedValue);
          this.get('vendorItems').setEach('selected', null);
          if (vendor) {
            vendor.set('selected', true);
          }
        }
      }

      if (this.get('isList')) {
        var _selectedValue = this.get('value') || this.get('categoryField.defaultValue');
        if (_selectedValue) {
          this.set('value', _selectedValue);

          if (this.get('customField')) {
            this.set('customField.value', _selectedValue);
          }

          var listItems = this.get('categoryField.listItems');
          var selectedListItem = listItems.findBy('string', _selectedValue);
          listItems.setEach('selected', null);
          if (selectedListItem) {
            selectedListItem.selected = true;
          }
        }
      }
    },


    datepickerOptions: {
      format: 'MM/DD/YYYY',
      useCurrent: true
    },

    pickerId: Ember.computed('fieldId', function () {
      return 'date' + this.get('fieldId');
    }),

    inputId: Ember.computed('pickerId', function () {
      return this.get('pickerId') + 'Picker';
    }),

    inputDisplay: Ember.computed('value', function () {
      if (this.get('value')) {
        return moment(this.get('value')).format('MM/DD/YY');
      }
      return '';
    }),

    selectId: Ember.computed('fieldId', function () {
      return 'select-vendor-' + this.get('fieldId');
    }),

    listSelectId: Ember.computed('fieldId', function () {
      return 'select-list-' + this.get('fieldId');
    }),

    config: {
      multiple: false,
      placeholder: 'Select vendor'
    },

    listConfig: {
      multiple: false,
      placeholder: 'Select item'
    },

    actions: {
      setDate: function setDate(date) {
        this.set('value', new Date(date));
      },
      onSelect: function onSelect(selectedItem) {
        this.set('value', selectedItem);
      },
      clearField: function clearField() {
        if (this.get('isList')) {
          Ember.$("#" + this.get('listSelectId')).val(null).trigger('change');
        } else if (this.get('isVendor')) {
          Ember.$("#" + this.get('selectId')).val(null).trigger('change');
          this.get('vendorItems').setEach('selected', null);
        }
        this.set('value', null);
      }
    }
  });
});