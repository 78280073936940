define('huyang/components/pm-program/schedule-text-well', ['exports', 'huyang-common/utils/rrule-utils'], function (exports, _rruleUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    programRepeatText: Ember.computed('repeatRule', 'allowBalancing', function () {
      var timezone = this.get('session.currentUser.timezone');
      var repeatRule = this.get('repeatRule');
      var repeatText = void 0;

      // use nextCreate when editing an existing program
      if (this.get('nextCreate')) {
        // Use nextCreate rather than start, to avoid handling start dates in past
        repeatText = (0, _rruleUtils.rruleToText)(repeatRule, this.get('nextCreate'), timezone);
      } else {
        // use createDate for new program
        var start = this.get('program.createDate');

        if (start && timezone) {
          start = moment(start);
          start.tz(timezone);
        }

        repeatText = (0, _rruleUtils.rruleToText)(repeatRule, start);
      }

      return repeatText ? repeatText[0].toLowerCase() + repeatText.slice(1) : null;
    })
  });
});