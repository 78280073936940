define('huyang/components/user-search-select', ['exports', 'huyang/components/dynamic-component'], function (exports, _dynamicComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _dynamicComponent.default.extend({
        query: '',
        addedUsers: [],

        propertiesToSend: ['addedUsers'],

        watchForChanges: function () {
            Ember.run.once(this, 'notifyOfChanges');
        }.observes('addedUsers.[]').on('init'),

        listenToKeyboard: function () {
            var _this = this;

            var UP_ARROW = 38;
            var DOWN_ARROW = 40;
            var ENTER_KEY = 13;
            var TAB_KEY = 9;

            this.$('.user-search-input').on('keydown', function (e) {
                var capturedKeys = [UP_ARROW, DOWN_ARROW, ENTER_KEY, TAB_KEY];

                if (capturedKeys.includes(e.keyCode)) {
                    e.preventDefault();
                    e.stopPropagation();
                }

                if (e.keyCode === DOWN_ARROW) {
                    _this.highlightNextUser();
                } else if (e.keyCode === UP_ARROW) {
                    _this.highlightPreviousUser();
                } else if (e.keyCode === ENTER_KEY || e.keyCode === TAB_KEY) {
                    _this.addHighlightedUser();
                }
            });
        }.on('didInsertElement'),

        highlightUser: function highlightUser(diff) {
            var results = this.get('searchResults');

            if (results) {
                var currentUser = results.findBy('selected');
                var currentUserIndex = 0;
                var nextUserIndex = 0;

                if (currentUser) {
                    currentUserIndex = results.indexOf(currentUser);
                    nextUserIndex = currentUserIndex + diff;
                }

                if (nextUserIndex < 0) {
                    // select last item in list
                    nextUserIndex = results.length - 1;
                } else if (nextUserIndex > results.length - 1) {
                    // select first item
                    nextUserIndex = 0;
                }

                var nextUser = this.get('searchResults', nextUserIndex);

                if (nextUser) {
                    results.forEach(function (u) {
                        return u.set('selected', false);
                    });
                    nextUser.set('selected', true);
                }
            }
        },

        highlightNextUser: function highlightNextUser() {
            this.highlightUser(1);
        },

        highlightPreviousUser: function highlightPreviousUser() {
            this.highlightUser(-1);
        },

        addHighlightedUser: function addHighlightedUser() {
            var user = this.get('searchResults').findBy('selected');

            if (user) {
                user.set('selected', false);
                this.send('selectUser', user);
            }
        },

        searchResults: function () {
            var _this2 = this;

            return this.get('users').filter(function (user) {
                if (_this2.get('addedUsers').includes(user)) {
                    return false;
                }

                var q = _this2.get('query').toLowerCase();

                if (!q) {
                    return false;
                }

                var name = user.get('name');

                if (name) {
                    name = name.toLowerCase();
                }

                var email = user.get('email').toLowerCase();

                var emailContains = email.indexOf(q) > -1;

                if (name) {
                    var nameContains = name.indexOf(q) > -1;
                    return nameContains || emailContains;
                }

                return emailContains;
            });
        }.property('users.[]', 'query'),

        actions: {
            selectUser: function selectUser(user) {
                if (!this.get('addedUsers').includes(user)) {
                    this.get('addedUsers').pushObject(user);
                    this.set('query', '');

                    setTimeout(function () {
                        Ember.$('#searchInput').focus();
                    }, 0);
                }
            },
            removeUser: function removeUser(user) {
                this.get('addedUsers').removeObject(user);
            }
        }
    });
});