define('huyang/serializers/asset-category', ['exports', 'ember-data', 'huyang-common/serializers/rest-new'], function (exports, _emberData, _restNew) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _restNew.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      customFields: { embedded: 'always' }
    },

    // customFields come in { id: {...}, id: {...} } which doesn't jive
    // with Ember Data, so this converts to [ {id: x, ...}, {id: x, ...} ]
    normalize: function normalize(model, hash) {
      if (hash.customFields) {
        var arrayifiedCustomFields = [];
        $.each(hash.customFields, function (index, value) {
          value.id = index;
          arrayifiedCustomFields.push(value);
        });
        hash.customFields = arrayifiedCustomFields;
      }

      return this._super.apply(this, arguments);
    }
  });
});