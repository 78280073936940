define('huyang/mixins/shows-request-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        requestsController: Ember.inject.controller('requests'),
        session: Ember.inject.service(),

        buildings: function () {
            return this.get('requestsController.model.buildings').filter(function (building) {
                return building.get('active');
            });
        }.property('requestsController.model.buildings'),

        staffUsers: function () {
            var accountId = this.get('session.data.authenticated.accountId');
            return this.get('requestsController.model.users').filter(function (user) {
                return user.hasAnyRole(accountId, ['staff', 'staff-all', 'admin']);
            });
        }.property('requestsController.model.users'),

        availableAssignees: function () {
            var accountId = this.get('session.data.authenticated.accountId');
            var request = this.get('model');
            var buildingId = request.get('building.id');

            if (buildingId) {
                return this.get('requestsController.model.users').filter(function (user) {
                    return user.hasStaffRole(accountId, buildingId);
                });
            }

            return [];
        }.property('requestsController.model.users.[]', 'model.building')
    });
});