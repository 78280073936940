define('huyang/app', ['exports', 'huyang/resolver', 'ember-load-initializers', 'huyang/config/environment', 'huyang/overrides/route', 'npm:@sentry/browser', 'npm:@sentry/integrations'], function (exports, _resolver, _emberLoadInitializers, _environment, _route, _browser, _integrations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // eslint-disable-line

  if (_environment.default.SENTRY_ENABLED) {
    _browser.default.init({
      dsn: _environment.default.sentry.dsn,
      beforeSend: function beforeSend(event, hint) {
        var error = hint.originalException;

        // Ignoring these errors due to https://github.com/emberjs/ember.js/issues/12505
        // and https://github.com/emberjs/ember.js/issues/18416
        if (error && error.name === 'TransitionAborted') {
          return null;
        }

        return event;
      },

      ignoreErrors: ['Adapter operation failed', 'Attempted to handle event `reloadRecord`', 'Cannot read property \'offset\' of undefined', 'ResizeObserver loop limit exceeded', 'TransitionAborted', 'Unhandled Promise error detected'],
      integrations: [new _integrations.default.Ember()]
    });
  }

  // this is coming from huyang-common, gets auto-copied to /app


  var App = void 0;

  Ember.MODEL_FACTORY_INJECTIONS = true;

  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});