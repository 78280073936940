define('huyang/models/request-type-usage', ['exports', 'huyang-common/models/request-type-usage'], function (exports, _requestTypeUsage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _requestTypeUsage.default;
    }
  });
});