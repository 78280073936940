define('huyang/routes/login-token/index', ['exports', 'huyang/mixins/redirects-to-default'], function (exports, _redirectsToDefault) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_redirectsToDefault.default, {
        intercom: Ember.inject.service(),
        session: Ember.inject.service(),

        model: function model(params, transition) {
            var _this = this;

            var token = transition.params['login-token'].token;

            // if there is a currently-logged-in-user, set the id
            // so we can check it later to see if we need to reload
            if (localStorage.getItem('userId')) {
                this.set('currentUserId', localStorage.getItem('userId'));
            }

            return this.get('session').authenticate('authenticator:custom', {
                token: token
            }).then(function () {
                var accountId = localStorage.getItem('accountId');
                var account = _this.store.peekRecord('account', accountId);

                if (account.get('status') === 'inactive') {
                    _this.transitionTo('inactive');
                }
            });
        },

        // authenticate succeed: go to my requests
        afterModel: function afterModel() {
            var accountId = localStorage.getItem('accountId');
            var userId = localStorage.getItem('userId');
            var account = this.store.peekRecord('account', accountId);
            var user = this.store.peekRecord('user', userId);

            // if a different user was already logged in when navigating to
            // this route, reload the page to avoid stale data
            if (this.get('currentUserId') !== userId) {
                window.location.href = '/';
            }

            this.get('intercom').addChat(account, user);
            this.redirectToDefault();
        },

        actions: {
            // authenticate failed: show error
            error: function error() /*error, transition*/{
                console.log('error');
                this.transitionTo('login-failed');
            }
        }
    });
});