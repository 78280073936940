define('huyang/models/primary-building', ['exports', 'huyang-common/models/primary-building'], function (exports, _primaryBuilding) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _primaryBuilding.default;
    }
  });
});