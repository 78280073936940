define('huyang/routes/people', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        beforeModel: function beforeModel(transition) {
            var _this = this;

            this._super(transition);
            this.get('session.currentUser').then(function (user) {
                if (!user.get('isAdmin')) {
                    _this.transitionTo('not-found');
                }
            });
        },


        /*
            load these now to make it easier to work on sub-pages
        */
        model: function model() {
            return Ember.RSVP.hash({
                account: this.store.findRecord('account', this.get('session.data.authenticated.accountId')),
                user: this.store.findRecord('user', this.get('session.data.authenticated.userId'))
            });
        }
    });
});