define('huyang/models/channel', ['exports', 'ember-data', 'huyang-common/models/channel'], function (exports, _emberData, _channel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _channel.default.extend({
        vendor: _emberData.default.belongsTo('vendor', { async: true }),

        displayName: function () {
            var name = this.get('name');

            if (name === 'staff') {
                return this.get('account.name');
            } else if (name === 'occupant') {
                return 'Occupants';
            } else if (name === 'vendor') {
                if (this.get('vendor.displayName')) {
                    return this.get('vendor.displayName');
                } else {
                    return 'Vendor';
                }
            }

            return name;
        }.property('name', 'vendor.displayName', 'account.name'),

        isVendor: function () {
            return this.get('name') === 'vendor';
        }.property('name'),

        unreadKey: function () {
            if (this.get('isVendor')) {
                return parseInt(this.get('vendor.id'));
            }

            return this.get('name');
        }.property('name')
    });
});