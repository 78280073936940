define('huyang/controllers/settings/customization/request-types/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        finishSave: function finishSave(callback) {
            if (Ember.typeOf(callback) === 'function') {
                callback();
            }

            this.transitionToRoute('settings.customization.request-types');
        },

        actions: {
            save: function save(info, callback /*, onError*/) {
                var _this = this;

                info.account = this.store.peekRecord('account', localStorage.getItem('accountId'));
                info.active = true;

                var reqType = this.store.createRecord('request-type', info);

                reqType.save().then(function (rt) {
                    // add to buildings if specified
                    if (info.buildingIds && info.buildingIds.length) {
                        rt.setBuildings(info.buildingIds).then(function () {
                            _this.finishSave(callback);
                        });
                    } else {
                        _this.finishSave(callback);
                    }
                });
            },

            cancel: function cancel() {
                this.transitionToRoute('settings.customization.request-types');
            }
        }
    });
});