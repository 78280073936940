define('huyang/components/filter-bar-dropdown', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNameBindings: [':bar-item', 'slim:bar-item-slim', 'withSubcategories'],

        templates: function () {
            if (this.get('slim')) {
                if (this.get('slimDate')) {
                    return {
                        button: '<button type="button" class="multiselect dropdown-toggle" data-toggle="dropdown"><div class="item-text"><i class="fa fa-calendar"></i> <span class="multiselect-selected-text"></span> <b class="caret"></b></div></button>'
                    };
                } else {
                    return {
                        button: '<button type="button" class="multiselect dropdown-toggle" data-toggle="dropdown"><div class="item-text">' + this.get('label') + ' <span class="multiselect-selected-text badge"></span> <b class="caret"></b></div></button>'
                    };
                }
            }

            return {
                button: '<button type="button" class="multiselect dropdown-toggle" data-toggle="dropdown"><div class="item-label">' + this.get('label') + '</div><div class="item-text"><span class="multiselect-selected-text"></span> <b class="caret"></b></div></button>'
            };
        }.property('slim', 'slimDate'),

        multiselectId: function () {
            return this.get('selectId') + 'Multiselect';
        }.property('selectId'),

        rightAlign: function () {
            return Ember.isArray(this.get('classNames')) ? this.get('classNames').includes('pull-right') : false;
        }.property('classNames.[]'),

        isMultiple: function () {
            var multi = this.get('multiple');

            if (typeof multi !== 'undefined') {
                return multi;
            }

            return false;
        }.property('multiple'),

        actions: {
            selectAction: function selectAction(id) {
                if (typeof event !== 'undefined' && event.target && event.target.value) {
                    this.sendAction('action', id, event.target.value);
                } else {
                    this.sendAction('action', id);
                }
            },

            showDropdown: function showDropdown() {
                if (this.get('onDropdownShow')) {
                    this.sendAction('onDropdownShow');
                }
            }
        }
    });
});