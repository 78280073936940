define('huyang/routes/login-vendor/webview', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),

        model: function model(params, transition) {
            return {
                destination: transition.params['login-vendor'].destination,
                serviceRequestHash: transition.params['login-vendor'].serviceRequest,
                serviceRequestId: this.get('session.data.authenticated.serviceRequestId')
            };
        }
    });
});