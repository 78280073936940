define('huyang/controllers/requests/request/timer/delete', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            confirmDelete: function confirmDelete() {
                var _this = this;

                this.get('model').destroyRecord().then(function () {
                    _this.get('flashMessages').success('Timer deleted!');
                    _this.send('leaveRoute');
                });
            }
        }
    });
});