define('huyang/controllers/people/staff/index', ['exports', 'huyang-common/mixins/modal-helper'], function (exports, _modalHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Controller.extend(_modalHelper.default, {
    api: Ember.inject.service(),
    people: Ember.inject.controller(),
    session: Ember.inject.service(),

    tableColumns: [{ key: 'selection', label: '', checkbox: true, disableSorting: true }, { key: 'displayName', label: 'Name', link: 'people.staff.member', linkParamId: true }, { key: 'email', label: 'Email' }, { key: 'created', label: 'Created', isDate: true, dateFormat: 'MM/DD/YY' }],

    timerUsers: computed('model.openTimers.@each', function () {
      var timers = this.get('model.openTimers');
      var byUser = {};

      // Convert timers into a list of users
      timers.forEach(function (timer) {
        byUser[timer.get('worker.id')] = timer.get('worker');
      });

      return Object.keys(byUser).map(function (u) {
        return byUser[u];
      });
    }),

    staffCountText: computed('timerUsers.length', function () {
      var length = this.get('timerUsers.length');

      return length === 1 ? 'is 1' : 'are ' + length;
    }),

    selectedUsers: computed('model.staff.@each.isSelected', function () {
      return this.get('model.staff').filterBy('isSelected');
    }),

    sendEmail: true,

    recipients: computed('selectedUsers', function () {
      var names = this.get('selectedUsers').mapBy('name');

      if (names.length > 5) {
        names = names.slice(0, 4);
        names.push('and ' + (this.get('selectedUsers').length - 4) + ' more...');
      }

      return names.join(', ');
    }),

    multipleUsersSelected: computed('selectedUsers', function () {
      return this.get('selectedUsers.length') > 1;
    }),

    passwordTitleText: computed('multipleUsersSelected', function () {
      return this.get('multipleUsersSelected') ? 'Set mobile password' : 'Set password for ' + this.get('selectedUsers.firstObject.name');
    }),

    passwordConfirmText: computed('multipleUsersSelected', function () {
      return this.get('multipleUsersSelected') ? 'Set passwords' : 'Set password';
    }),

    setPasswordDisabled: computed('multipleUsersSelected', 'password', function () {
      return !this.get('multipleUsersSelected') && (this.get('password') || '').length < 8;
    }),

    setRandomPassword: function setRandomPassword() {
      // example: y96hvuvk
      this.set('password', Math.random().toString(36).substr(2, 8));
    },


    actions: {
      openSetPasswordModal: function openSetPasswordModal() {
        this.setRandomPassword();
        this.openModal('setPasswordModal');
      },
      savePasswords: function savePasswords() {
        var _this = this;

        this.get('api').ajax({
          type: 'PUT',
          url: '/api/' + this.get('people.model.account.id') + '/users/update',
          data: JSON.stringify({
            userIds: this.get('selectedUsers').mapBy('id'),
            password: this.get('password'),
            sendEmail: this.get('sendEmail')
          })
        }).then(function () {
          var message = _this.get('multipleUsersSelected') ? 'Passwords updated' : 'Password updated';

          _this.get('flashMessages').success(message);
          _this.get('model.staff').setEach('isSelected', false);
          _this.setRandomPassword();
          _this.set('sendEmail', true);
        }, function (error) {
          console.log(error);

          var message = _this.get('multipleUsersSelected') ? 'Updating passwords failed' : 'Updating password failed';

          _this.get('flashMessages').danger(message);
        });
      },
      selectAll: function selectAll() {
        this.get('model.staff').setEach('isSelected', !this.get('selectAllToggle'));
        this.set('selectAllToggle', !this.get('selectAllToggle'));
      },
      rowClicked: function rowClicked(id) {
        var user = this.get('model.staff').findBy('id', id);

        user.set('isSelected', !user.get('isSelected'));
      },
      removeStaff: function removeStaff(userId) {
        var _this2 = this;

        return this.store.findRecord('user', userId).then(function (user) {
          if (!user) {
            return;
          }

          _this2.get('session.currentAccount').then(function (account) {
            user.removeRole(account, 'staff');
            user.removeRole(account, 'staff-all');
            // if user is an admin, remove that too
            user.removeRole(account, 'admin');
          });
        });
      }
    }
  });
});