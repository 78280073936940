define('huyang/routes/vendor/response/yes', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model(params) {
            return this.store.findRecord('vendor-service-request', params.service_request_id);
        },

        afterModel: function afterModel(serviceReq) {
            var _this = this;

            serviceReq.set('responded', new Date());
            serviceReq.set('response', 'yes');

            serviceReq.save().then(function () {
                _this.transitionTo('vendor.request', serviceReq.get('id'));
            });
        }
    });
});