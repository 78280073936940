define('huyang/routes/settings/customization/request-types/edit', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            return Ember.RSVP.hash({
                requestType: this.store.findRecord('request-type', params.req_type_id, { reload: true }),
                baseRequestTypes: this.store.findAll('base-request-type')
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);

            controller.set('baseRequestTypes', this.modelFor('settings.customization.request-types').baseRequestTypes);
            controller.set('requestTypes', this.modelFor('settings.customization.request-types').requestTypes);
            controller.set('requestTypeBuildings', this.modelFor('settings.customization.request-types').requestTypeBuildings);
            controller.set('buildings', this.modelFor('settings.customization.request-types').buildings);

            var counts = { open: 0, closed: 0 };
            var meta = model.requestType.get('meta');

            if (meta && meta.status) {
                Object.keys(meta.status).forEach(function (status) {
                    counts[status] = meta.status[status];
                });
            }

            controller.set('requestCounts', counts);
        }
    });
});