define('huyang/components/open-timers-banner/timer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    exports.default = Ember.Component.extend({
        classNames: ['open-timers-banner-timer'],
        classNameBindings: ['isPaused'],

        clickLog: function clickLog() {},
        clickCancel: function clickCancel() {},


        isPaused: computed('timer.active', function isPaused() {
            return !this.get('timer.active');
        }),

        playPauseLabel: computed('isPaused', function playPauseLabel() {
            return this.get('isPaused') ? 'Resume' : 'Pause';
        }),

        playPauseIcon: computed('isPaused', function playPauseIcon() {
            return this.get('isPaused') ? 'play-circle-o' : 'pause-circle-o';
        }),

        actions: {
            toggleTimer: function toggleTimer() {
                this.get('timer').toggleActive();
            },
            logTimer: function logTimer() {
                this.get('clickLog')(this.get('timer'));
            },
            cancelTimer: function cancelTimer() {
                this.get('clickCancel')(this.get('timer'));
            }
        }
    });
});