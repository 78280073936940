define('huyang/models/pm-program-building-owner', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    pmProgram: _emberData.default.belongsTo('pm-program'),
    building: _emberData.default.belongsTo('building'),
    owner: _emberData.default.belongsTo('user')
  });
});