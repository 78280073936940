define('huyang/controllers/requests/preventative-maintenance/index', ['exports', 'huyang/mixins/request-list', 'huyang/mixins/saves-view-config', 'huyang-common/mixins/sorts-multiple-columns'], function (exports, _requestList, _savesViewConfig, _sortsMultipleColumns) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_requestList.default, _savesViewConfig.default, _sortsMultipleColumns.default, {
    queryParams: ['sortColumns'],
    defaultColumns: ['requestType.label', 'title', 'requestLabel.name', 'updated', 'originator.name', 'priority'],
    requests: Ember.computed.alias('model.pmPrograms'),
    // CHANGE: add cp
    linkToRouteName: 'requests.preventative-maintenance.program',

    columns: [Ember.Object.create({
      key: 'requestType.label',
      label: 'Type',
      editLabel: 'Request type',
      iconClass: 'requestType.cssClass',
      nowrap: false
    }), Ember.Object.create({
      key: 'requestLabel.name',
      label: 'Label',
      editLabel: 'Request label',
      isRequestLabel: true,
      nowrap: false,
      mobileIcon: 'fa-tag',
      emptyText: ''
    }), Ember.Object.create({
      key: 'title',
      label: 'Short description',
      link: 'requests.preventative-maintenance.program',
      disableLinkAction: true,
      permanent: true,
      unreadKey: 'totalUnreadComments',
      unreadLink: 'requests.request.messages'
    }), Ember.Object.create({
      key: 'description',
      label: 'Description',
      limit: 50,
      mobileIcon: 'fa-commenting-o',
      emptyText: '-'
    }), Ember.Object.create({
      key: 'originator.name',
      displayKey: 'originator.displayName',
      label: 'Creator',
      nowrap: false,
      mobilePhraseFormat: 'Filed by %@'
    }), Ember.Object.create({
      key: 'created',
      label: 'Created',
      editLabel: 'Creation date',
      isDate: true,
      relativeDate: true,
      nowrap: false,
      mobilePhraseFormat: 'Created %@'
    }), Ember.Object.create({
      key: 'updated',
      label: 'Updated',
      editLabel: 'Last updated',
      isSorted: true,
      isDate: true,
      relativeDate: true,
      nowrap: false,
      mobilePhraseFormat: 'Updated %@'
    }), Ember.Object.create({
      key: 'priority',
      label: 'Priority',
      isPriority: true,
      nowrap: false,
      align: 'center'
    }), Ember.Object.create({
      key: 'estimatedHours',
      label: 'Est. Hours',
      nowrap: false,
      align: 'center'
    }), Ember.Object.create({
      key: 'assetCategory.title',
      label: 'Asset Category',
      component: 'request-table-asset'
    })],

    viewConfig: Ember.computed('viewName', 'model.viewConfig', function () {
      var viewConfigs = this.get('store').peekAll('viewConfig');

      return viewConfigs.findBy('viewName', this.get('viewName'));
    }),

    headerLabel: function () {
      return this.get('total') + ' preventative maintenance programs';
    }.property('pmPrograms.length'),

    actions: {
      saveColumns: function saveColumns() {
        var cols = this.get('orderedColumns') || this.get('columns');
        var selected = cols.filter(function (col) {
          return col.selected;
        });

        this.saveViewConfig(selected.map(function (col) {
          return col.key;
        }), this.get('viewName'));
      },

      newPMProgram: function newPMProgram() {
        this.transitionToRoute('requests.preventative-maintenance.new');
      }
    }
  });
});