define('huyang/components/assets/date-display-and-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    datepickerOptions: {
      format: 'MM/DD/YYYY',
      useCurrent: true
    },

    inputId: Ember.computed('property', function () {
      return this.get('property') + 'Picker';
    }),

    inputDisplay: Ember.computed('property', function () {
      return moment(this.get('asset.' + this.get('property'))).format('MM/DD/YY');
    }),

    selectedDate: Ember.computed('property', function () {
      return 'asset.' + this.get('property');
    }),

    actions: {
      setDate: function setDate(date) {
        this.set('asset.' + this.get('property'), new Date(date));
      }
    }
  });
});