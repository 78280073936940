define('huyang/routes/assets/index', ['exports', 'huyang/routes/assets/asset-filter-base'], function (exports, _assetFilterBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _assetFilterBase.default.extend({
    documentTitle: 'All assets',
    filter: 'all',

    templateName: 'assets/asset-list-base'
  });
});