define('huyang/controllers/people/occupants/manage/groups/search', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        groupsController: Ember.inject.controller('people.occupants.manage.groups'),
        q: Ember.computed.alias('groupsController.q'),
        groups: Ember.computed.alias('model'),

        searchResults: function () {
            var _this = this;

            return this.get('groups').filter(function (group) {
                var name = group.get('name').toLowerCase();
                var q = _this.get('q').toLowerCase();

                return name.indexOf(q) > -1;
            });
        }.property('groups', 'groups.[]', 'q')
    });
});