define('huyang/components/sms-threshold-label', ['exports', 'huyang-common/mixins/displays-priority'], function (exports, _displaysPriority) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_displaysPriority.default, {
        // tagName: 'label',
        classNames: ['sms-threshold-label'],
        classNameBindings: ['checked:checked'],
        checked: false,
        priority: 4,
        showMenu: false,

        didInsertElement: function didInsertElement() {
            this.updateSelectedPriority();
        },


        updateSelectedPriority: function () {
            var p = this.get('priorityMap')[this.get('priority')];
            this.set('selectedPriority', p);
        }.observes('priority'),

        priorities: function () {
            var priorityMap = this.get('priorityMap');
            return Object.keys(priorityMap).map(function (p) {
                var priority = priorityMap[p];
                priority.id = p;

                return priority;
            });
        }.property('priorityMap'),

        actions: {
            showMenu: function showMenu() {
                this.toggleProperty('showMenu');
            },
            setPriority: function setPriority(p) {
                this.set('priority', p.id);
            }
        }
    });
});