define('huyang/routes/dashboard', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        model: function model() {
            return Ember.RSVP.hash({
                user: this.store.findRecord('user', this.get('session.data.authenticated.userId')),
                account: this.store.findRecord('account', this.get('session.data.authenticated.accountId')),
                requests: this.store.findAll('request'),
                energy_use: {
                    demand_management_alerts: 0
                },
                utility_bills: {
                    new_bills: 0,
                    variance_alerts: 0
                }
            });
        }
    });
});