define('huyang/components/icon-picker', ['exports', 'huyang/components/popover-menu'], function (exports, _popoverMenu) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _popoverMenu.default.extend({
        elementSelector: '.icon-menu',
        targetSelector: '.icon-button',
        attachment: 'top left',
        targetAttachment: 'top right',
        targetOffset: '12px 0',
        buttonSelector: '.icon-button',

        query: '',
        selectedIcon: 'question',

        propertiesToSend: ['selectedIcon'],

        watchForChanges: function () {
            Ember.run.once(this, 'notifyOfChanges');
        }.observes('selectedIcon').on('init'),

        displayedIcons: function () {
            var icons = this.get('iconSet');
            var query = this.get('query').toLowerCase().trim();
            var filteredIcons = icons.sortBy('label');

            if (query) {
                filteredIcons = icons.filter(function (icon) {
                    var name = icon.label.toLowerCase().trim();
                    return name.indexOf(query) > -1;
                });
            }

            return filteredIcons;
        }.property('iconSet.[]', 'query'),

        actions: {
            chooseIcon: function chooseIcon(icon) {
                this.set('selectedIcon', icon);
                Ember.$('.icon-menu').hide();
            }
        }
    });
});