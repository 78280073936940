define('huyang/components/search-results', ['exports', 'huyang/components/request-list'], function (exports, _requestList) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _requestList.default.extend({
        unsortable: ['description', 'schedule.repeatDescription'],

        headerLabel: function () {
            var results = this.get('requests.length');
            return results === 1 ? '1 matching result' : results + ' matching results';
        }.property('requests'),

        tableColumns: function () {
            var _this = this;

            var userColumns = this.get('userColumns');
            var displayedColumns = userColumns.length > 0 ? userColumns : this.get('defaultColumns');
            return displayedColumns.map(function (key) {
                var col = _this.get('columns').findBy('key', key);
                if (_this.get('unsortable').indexOf(col.get('key')) >= 0) {
                    col.set('disableSorting', true);
                }
                return col;
            });
        }.property('columns', 'defaultColumns', 'userColumns.[]'),

        // pagination
        page: function () {
            return Math.floor(this.get('from') / this.get('limit'));
        }.property('from', 'limit'),

        previousFrom: function () {
            return this.get('from') - this.get('limit');
        }.property('from', 'limit'),

        hasPreviousPage: function () {
            return this.get('from') >= this.get('limit');
        }.property('from', 'limit'),

        nextFrom: function () {
            return this.get('from') + this.get('limit');
        }.property('from', 'limit'),

        hasNextPage: function () {
            return this.get('from') + this.get('limit') < this.get('total');
        }.property('from', 'limit', 'total'),

        showPaginator: function () {
            return this.get('hasNextPage') || this.get('hasPreviousPage');
        }.property('hasNextPage', 'hasPreviousPage'),

        pages: function () {
            // show up to 10 pages in pager
            var maxPages = 10;
            var limit = this.get('limit');
            var page = this.get('page');
            var pageCount = Math.ceil(this.get('total') / limit);
            var startPage = pageCount > maxPages ? page - maxPages / 2 : 0;
            var start = Math.max(startPage, 0);
            var end = Math.min(startPage + maxPages, pageCount);
            var pages = [];
            for (var i = start; i < end; i++) {
                pages.push({ page: i + 1, from: i * limit });
            }
            if (pages.length > page) {
                pages[page].active = true;
            }
            return pages;
        }.property('limit', 'page', 'total'),

        actions: {
            sortBy: function sortBy(sortColumn, sortDirection) {
                this.sendAction('sortBy', sortColumn, sortDirection);
            },

            gotoPage: function gotoPage(from) {
                if (from >= 0 && from < this.get('total')) {
                    this.set('from', from);
                }
            }
        }
    });
});