define('huyang/components/table-column-picker', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['table-column-picker'],

        resetColumns: function () {
            this.set('orderedColumns', null);
        }.observes('columns'),

        displayedColumns: function () {
            return this.get('orderedColumns') || this.get('columns');
        }.property('columns', 'orderedColumns'),

        actions: {
            reorderItems: function reorderItems(orderedColumns) {
                this.set('orderedColumns', orderedColumns);
                this.sendAction('onUpdate', orderedColumns);
            }
        }
    });
});