define('huyang/routes/requests/request/send', ['exports', 'huyang/routes/base-account', 'huyang/mixins/modal-route'], function (exports, _baseAccount, _modalRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend(_modalRoute.default, {
        modalSelector: '.forward-request',
        returnRoute: 'requests.request',

        /* all routes that use this class will use THIS controller! */
        controllerName: 'requests/request/send',

        /* all routes that use this class will have THIS template! */
        renderTemplate: function renderTemplate() {
            this.render('requests/request/send');
        },

        model: function model() {
            return Ember.RSVP.hash({
                technicians: this.store.findAll('vendorTechnician'),
                trades: this.store.findAll('trade')
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.setup();
        },

        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                controller.reset();
            }
        },

        actions: {
            loading: function loading() {
                var controller = this.controllerFor('requests/request');
                controller.set('vendorLoading', true);

                this.router.one('didTransition', function () {
                    return controller.set('vendorLoading', false);
                });
            }
        }
    });
});