define('huyang/routes/reports/saved/trend', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params) {
            return this.store.findRecord('report-query', params.report_id);
        },

        setupController: function setupController(controller, model) {
            controller.set('keenQueryString', '');
            controller.set('filteredIds', []);

            var params = model.get('params');
            if (params.filters) {
                params.filters.forEach(function (filter) {
                    if (Ember.isArray(filter.property_value)) {
                        filter.property_value.forEach(function (val) {
                            return controller.get('filteredIds').pushObject(val);
                        });
                    }
                });
            }

            controller.set('model', model);
            //controller.createQuery();
        }
    });
});