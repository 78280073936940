define('huyang/controllers/admin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // default date range of 90 days ago to now
    fromDate: Ember.computed('', function fromDate() {
      return moment().add(-90, 'day').format('YYYY-MM-DD');
    }),
    toDate: Ember.computed('', function toDate() {
      return moment().format('YYYY-MM-DD');
    })
  });
});