define('huyang/components/crosstab-table-cell', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        value: function () {
            var item = this.get('item');
            var itemType = item.get(this.get('key') + 'Type');
            var value = item.get(this.get('key'));

            if (!value) {
                return '';
            }

            if (itemType.indexOf('duration') >= 0) {
                var dur = moment.duration(value, 'seconds');
                if (itemType === 'duration-hours') {
                    return Math.round(dur.asHours() * 10) / 10 + 'h';
                }
                return parseInt(dur.as('days')) + 'd ' + dur.hours() + 'h ' + dur.minutes() + 'm';
            }

            if (itemType.indexOf('currency') >= 0) {
                return (0, _formatMoney.default)(value);
            }

            return value.toLocaleString();
        }.property('item', 'key'),

        cellClass: function () {
            return this.get('item').get(this.get('key') + 'Class') || 'standard';
        }.property('item', 'key')
    });
});