define('huyang/components/cost-log-form', ['exports', 'huyang-common/mixins/handles-attachments'], function (exports, _handlesAttachments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_handlesAttachments.default, {
    classNames: ['cost-log-form'],
    inputId: 'costLogFileUpload',

    sortedCategories: Ember.computed('request.account.activeCostCategories.@each.label', function () {
      return this.get('request.account.activeCostCategories').sortBy('label');
    }),

    filteredCategories: Ember.computed('categoryFilter', function () {
      var categories = this.get('sortedCategories');
      var filter = (this.get('categoryFilter') || '').toLowerCase();

      if (filter) {
        return categories.filter(function (c) {
          return c.get('label').toLowerCase().indexOf(filter) > -1;
        });
      }

      return categories;
    }),

    categoryName: Ember.computed('log.category', function () {
      return this.get('log.category.label');
    }),

    fileAdded: function fileAdded() {
      this.set('submitDisabled', true);
    },

    uploadComplete: function uploadComplete() {
      this.set('submitDisabled', false);
      if (!this.get('hasPendingUploads')) {
        this.saveLog();
      }
    },

    uploadFailed: function uploadFailed() {
      this.set('submitDisabled', false);
    },

    canAddAttachment: Ember.computed('log.attachments.length', 'hasAttachments', function () {
      return !this.get('log.attachments.length') && !this.get('hasAttachments');
    }),

    saveLog: function saveLog() {
      var attachments = this.get('attachmentsList');
      if (attachments && attachments.length) {
        this.set('log.attachment', attachments.map(function (file) {
          return file.get('id');
        }));
      }
    },

    actions: {
      selectCategory: function selectCategory(category) {
        this.get('log').set('category', category);
      },
      removeFile: function removeFile(file) {
        this.set('attachmentTrash', {
          log: this.get('log'),
          attachment: file
        });
        this.set('log.attachments', []);
        this.set('log.attachment', []);
      }
    }
  });
});