define('huyang/serializers/asset', ['exports', 'ember-data', 'huyang-common/serializers/rest-new'], function (exports, _emberData, _restNew) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _restNew.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        attrs: {
            customFields: { embedded: 'always' }
        },

        normalize: function normalize(model, hash) {
            if (hash.customFields) {
                var arrayifiedCustomFields = [];
                $.each(hash.customFields, function (index, value) {
                    value.id = index;
                    arrayifiedCustomFields.push(value);
                });
                hash.customFields = arrayifiedCustomFields;
            }

            return this._super.apply(this, arguments);
        },


        serialize: function serialize(snapshot, options) {
            var data = this._super(snapshot, options);
            // add attachment id if it's present (not part of the the asset model)
            // for single-request update/attach operation; adapters/asset sends to with-attach endpoint
            if (snapshot.record.attachment && snapshot.record.attachment.length) {
                data.attachment = snapshot.record.attachment;
            }
            if (snapshot.record.makeModelAttachment && snapshot.record.makeModelAttachment.length) {
                data.makeModelAttachment = snapshot.record.makeModelAttachment;
            }

            return data;
        }
    });
});