define('huyang/controllers/assets', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        api: Ember.inject.service(),
        session: Ember.inject.service(),

        loadAssetCounts: function loadAssetCounts() {
            var _this = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                _this.get('api').ajax({
                    type: 'GET',
                    url: '/api/' + _this.get('session.data.authenticated.accountId') + '/assets/counts',
                    dataType: 'json'
                }).then(function (response) {
                    var props = {};
                    Object.keys(response).forEach(function (key) {
                        props[key] = response[key];
                    });
                    Ember.run(function () {
                        _this.set('counts', props);
                        _this.set('countsLoaded', new Date().getTime());
                        resolve();
                    });
                }, function () {
                    reject();
                });
            });
            this.set('assetCountPromise', promise);

            return promise;
        },

        byCategory: function () {
            if (this.get('countsLoaded')) {
                var categories = [];
                var counts = this.get('counts.categories');
                this.get('model.categories').forEach(function (category) {
                    if (!category.get('inactive')) {
                        var count = counts && counts[category.get('id')] ? counts[category.get('id')] : 0;
                        categories.pushObject({
                            id: category.get('id'),
                            title: category.get('title'),
                            count: count
                        });
                    }
                });
                return categories.sortBy('title');
            } else {
                return this.get('model.categories').sortBy('title');
            }
        }.property('model.categories.[]', 'countsLoaded'),

        byLocation: function () {
            if (this.get('countsLoaded')) {
                var locations = [];
                var counts = this.get('counts.buildings');
                this.get('model.buildings').forEach(function (building) {
                    var count = counts && counts[building.get('id')] ? counts[building.get('id')] : 0;
                    if (count) {
                        locations.pushObject({
                            id: building.get('id'),
                            name: building.get('name'),
                            count: count
                        });
                    }
                });
                return locations.sortBy('name');
            } else {
                return this.get('model.buildings').sortBy('name');
            }
        }.property('model.buildings.[]', 'countsLoaded')
    });
});