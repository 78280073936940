define('huyang/routes/assets/building', ['exports', 'huyang/routes/assets/asset-filter-base'], function (exports, _assetFilterBase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _assetFilterBase.default.extend({
        templateName: 'assets/asset-list-base',

        model: function model(params) {
            var _this = this;

            var modelParams = this.getInfinityModelParams('building', params);

            modelParams.building = params.building_id;

            return Ember.RSVP.hash({
                assets: this.infinityModel('asset', modelParams),
                building: this.store.findRecord('building', params.building_id).then(function (b) {
                    _this.set('documentTitle', b.get('name'));
                    return b;
                }),
                viewConfig: this.store.queryRecord('view-config', { view_name: this.get('viewName') })
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('filter', 'building');
            controller.set('buildingId', model.building.get('id'));
        }
    });
});