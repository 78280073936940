define('huyang/authenticators/custom', ['exports', 'ember-simple-auth/authenticators/base', 'huyang/config/environment', 'npm:@sentry/browser'], function (exports, _base, _environment, _browser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        api: Ember.inject.service(),
        session: Ember.inject.service(),
        store: Ember.inject.service(),

        setSentryUserContext: function setSentryUserContext(id, email) {
            if (_environment.default.SENTRY_ENABLED) {
                _browser.default.configureScope(function (scope) {
                    scope.setUser({
                        id: id,
                        email: email
                    });
                });
            }
        },


        /*
        Restores the session from a set of properties. This method is invoked by
        the session either after the application starts up and session data was
        restored from the store or when properties in the store have changed due
        to external events
        This method returns a promise. A resolving promise will result in the session
        being authenticated.
         */
        restore: function restore() /*data*/{
            // make sure there's an auth cookie
            var store = this.get('store');
            var _this = this;
            var accountId = localStorage.getItem('accountId');
            var userId = localStorage.getItem('userId');
            var token = localStorage.getItem('token');
            if ((token || document.cookie.indexOf('auth_token') >= 0) && accountId && userId) {
                return Ember.RSVP.hash({
                    account: store.findRecord('account', accountId),
                    user: store.findRecord('user', userId),
                    roles: store.findAll('role')
                }).then(function (response) {
                    _this.setSentryUserContext(response.user.get('id'), response.user.get('email'));
                    return new Ember.RSVP.resolve({
                        token: token,
                        accountId: accountId,
                        userId: userId,
                        isAdmin: response.user.get('isAdmin')
                    });
                }, function () {
                    return new Ember.RSVP.reject();
                });
            } else {
                return new Ember.RSVP.reject();
            }
        },

        /*
        Authenticates the session with the specified options. These options
        vary depending on the actual authentication mechanism the authenticator
        implements (e.g. a set of credentials or a Facebook account id etc.).
        The session will invoke this method when an action in the application
        triggers authentication
         This method returns a promise. A resolving promise will result in the
        session being authenticated. Any properties the promise resolves with
        will be saved in and accessible via the session. A rejecting promise
        indicates that authentication failed and the session will remain unchanged.
         All data the authenticator resolves with will be saved in the session's secure property.
        */
        authenticate: function authenticate(options) {
            var _this2 = this;

            console.log('logging in user ' + options.token);
            var store = this.get('store');
            var _this = this;

            // see https://github.com/simplabs/ember-simple-auth/blob/master/examples/4-authenticated-account.html
            return new Ember.RSVP.Promise(function (resolve, reject) {
                var url = '/api/building/email_login';
                var data = {};
                if (options.token) {
                    data['token'] = options.token;
                }
                if (options.serviceRequest) {
                    url = '/api/vendor/request_login';
                    data['serviceRequest'] = options.serviceRequest;
                }
                _this2.get('api').ajax({
                    type: 'POST',
                    url: url,
                    data: JSON.stringify(data),
                    dataType: 'json'
                }).then(function (response) {
                    Ember.run(function () {
                        localStorage.setItem('accountId', response.account.id);
                        console.log('localStorage.accountId set:', localStorage.getItem('accountId'));
                        localStorage.setItem('userId', response.user.id);
                        localStorage.setItem('token', response.token);

                        store.push(store.normalize('account', response.account));

                        _this.setSentryUserContext(response.user.id, response.user.email);

                        var user = store.push(store.normalize('user', response.user));

                        ['accountRole', 'role', 'timeLog'].forEach(function (modelName) {
                            if (response[modelName + 's']) {
                                response[modelName + 's'].forEach(function (modelData) {
                                    store.push(store.normalize(modelName, modelData));
                                });
                            }
                        });

                        if (response['costCategories']) {
                            response['costCategories'].forEach(function (modelData) {
                                store.push(store.normalize('costCategory', modelData));
                            });
                        }

                        _this.set('session.data.authenticated.accountId', response.account.id);
                        _this.set('session.data.authenticated.userId', response.user.id);
                        _this.set('session.data.authenticated.isAdmin', user.get('isAdmin'));
                        _this.set('session.data.authenticated.token', response.token);
                        _this.set('session.data.authenticated.requestId', response.requestId);
                        _this.set('session.data.authenticated.serviceRequestId', response.serviceRequestId);

                        resolve({
                            accountId: response.account.id,
                            userId: response.user.id,
                            isAdmin: user.get('isAdmin'),
                            token: response.token,
                            requestId: response.requestId,
                            serviceRequestId: response.serviceRequestId
                        });
                    });
                }, function () /*xhr, status, error*/{
                    // email not found
                    Ember.run(function () {
                        reject();
                    });
                });
            });
        },

        invalidate: function invalidate() /*data*/{
            return new Ember.RSVP.Promise(function (resolve /*, reject*/) {
                localStorage.removeItem('accountId');
                localStorage.removeItem('userId');
                localStorage.removeItem('token');

                resolve();
            });
        }
    });
});