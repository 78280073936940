define('huyang/controllers/reports/trend', ['exports', 'huyang/controllers/reports/report-controller'], function (exports, _reportController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _reportController.default.extend({
        resetData: function resetData() {
            this.set('reportName', 'New trend report');
            this.set('reportDescription', 'Trend reports show how metrics vary over time.');
            this.set('reportType', 'request_open:count');
            this.set('timeframe', 'this_7_days');
            this.set('viz', 'trend');
            this.set('groupBy', '');
            this.set('category1', '');
            this.set('category2', '');
            this.set('keenQueryString', '');
            this.set('interval', 'daily');
            this.get('filteredIds').clear();
            this.set('postprocess', true);
        }
    });
});