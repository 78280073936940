define('huyang/components/mini-comment-form', ['exports', 'huyang-common/components/new-comment-form', 'huyang/templates/components/mini-comment-form', 'huyang-common/mixins/formats-channel-name'], function (exports, _newCommentForm, _miniCommentForm, _formatsChannelName) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _newCommentForm.default.extend(_formatsChannelName.default, {
        layout: _miniCommentForm.default,
        classNames: ['mini-comment-form-wrapper'],

        setupTooltips: function () {
            this.$('.btn-primary').tooltip();
        }.on('didInsertElement'),

        tooltipVisibilityText: function () {
            return 'Message will be visible to ' + this.get('channelTitle');
        }.property('longDisplayName')
    });
});