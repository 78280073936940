define('huyang/controllers/settings/customization/asset-categories/edit', ['exports', 'huyang/mixins/validate-custom-fields'], function (exports, _validateCustomFields) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_validateCustomFields.default, {
    invalidCustomFields: false,

    actions: {
      save: function save() {
        var _this = this;

        if (!this.get('model.category.title')) {
          this.set('showErrors', true);
        } else {
          // We don't really want to toss out custom field changes on category
          // save, so we go through each, validate them, and save if valid.
          //
          this.set('invalidCustomFields', false);
          var currentlyEditingFields = this.get('model.category.customFields').filterBy('isEditing');
          currentlyEditingFields.forEach(function (cf) {
            if (!_this.validateCategoryCustomFieldEdit(cf)) {
              // flag to essentially stop execution if a custom field is invalid
              _this.set('invalidCustomFields', true);
            } else {
              cf.set('isEditing', false);
            }
          });

          if (!this.get('invalidCustomFields')) {
            // wait for all custom fields to save before saving category
            var chainedCustomFieldSaves = currentlyEditingFields.reduce(function (previous, model) {
              return previous.then(model.save.bind(model));
            }, Ember.RSVP.resolve());

            chainedCustomFieldSaves.then(function () {
              _this.get('model.category').save().then(function () {
                _this.transitionToRoute('settings.customization.asset-categories');
              }).catch(function () {
                _this.set('showErrors', true);
              });
            });
          }
        }
      },
      cancel: function cancel() {
        this.get('model.category').rollbackAttributes();
        this.get('model.category.customFields').forEach(function (cf) {
          cf.rollbackAttributes();
          cf.set('isEditing', false);
        });
        this.transitionToRoute('settings.customization.asset-categories');
      },
      delete: function _delete() {
        var _this2 = this;

        this.set('model.category.inactive', new Date());
        this.get('model.category').save().then(function () {
          _this2.transitionToRoute('settings.customization.asset-categories');
        });
      }
    }
  });
});