define('huyang/components/tenant-occupant-form', ['exports', 'huyang-common/components/set-user-info', 'huyang-common/templates/components/set-user-info', 'huyang-common/mixins/validates-email'], function (exports, _setUserInfo, _setUserInfo2, _validatesEmail) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _setUserInfo.default.extend(_validatesEmail.default, {
        layout: _setUserInfo2.default,
        fields: function () {
            // validate email exists only on edit
            if (this.get('validateEmailExists')) {
                this.set('emailValidations.email', { currentValue: this.get('email') });
            }
            return [{
                name: 'name',
                label: 'Name',
                placeholder: 'John Smith',
                validations: { presence: true },
                value: this.get('name')
            }, {
                name: 'email',
                label: 'Email',
                placeholder: 'name@company.com',
                validations: this.get('emailValidations'),
                value: this.get('email')
            }, {
                name: 'mobilePhone',
                label: 'Phone',
                helpText: 'Provide a phone number to contact occupant',
                placeholder: '555-867-5309',
                value: this.get('mobilePhone'),
                validations: {
                    phone: true
                }
            }, {
                name: 'title',
                label: 'Role',
                helpText: 'Describe occupant role',
                placeholder: 'Admistrative assistant',
                value: this.get('title')
            }];
        }.property('name', 'email', 'mobilePhone', 'title'),

        actions: {
            cancel: function cancel() {
                this.sendAction('onCancel');
            }
        }
    });
});