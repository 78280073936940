define('huyang/helpers/value-for-key', ['exports', 'huyang-common/helpers/value-for-key'], function (exports, _valueForKey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _valueForKey.default;
    }
  });
  Object.defineProperty(exports, 'valueForKey', {
    enumerable: true,
    get: function () {
      return _valueForKey.valueForKey;
    }
  });
});