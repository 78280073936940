define('huyang/components/owner-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    vendor: Ember.computed.alias('item.owner.vendorTechnician.vendor'),

    text: Ember.computed('vendor.name', 'item.owner.name', function () {
      if (this.get('vendor')) {
        return this.get('vendor.name') + ': ' + this.get('item.owner.name');
      } else if (this.get('item.owner.name')) {
        return this.get('item.owner.name');
      }

      return 'Unassigned';
    })
  });
});