define('huyang/components/set-make-model-attachment', ['exports', 'huyang-common/mixins/handles-attachments'], function (exports, _handlesAttachments) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_handlesAttachments.default, {
        fileUploadId: "makeModelUpload",

        session: Ember.inject.service(),

        uploadUrl: function () {
            return '/api/' + this.get('session.data.authenticated.accountId') + '/assetMakeModelAttachments';
        }.property('model'),

        actions: {
            uploadComplete: function uploadComplete(uuid, data) {
                var self = this;

                if (data.files) {
                    data.files.forEach(function (file) {
                        // find pending file object
                        var pending = self.get('attachmentsList').find(function (f) {
                            return f.get('id') === file.id;
                        });

                        if (pending) {
                            pending.set('id', data.result.assetMakeModelAttachment.id);
                            pending.set('uuid', uuid);
                        }
                    });
                    self.uploadComplete();
                    self.sendAction('addMakeModelAttachments', self.get('attachmentsList'));
                }
            }
        }
    });
});