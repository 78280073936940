define('huyang/routes/login-token/webview', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(params, transition) {
            return transition.params['login-token'].token;
        }
    });
});