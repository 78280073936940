define('huyang/components/next-scheduled-request-dates', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('store').findAll('request-schedule').then(function () {
        if (_this.isDestroyed) {
          return;
        }
        var dates = _this.get('request.schedule.nextYearDates').slice(0, _this.get('count'));

        _this.set('dates', dates);
      });
    }
  });
});