define('huyang/controllers/profiles/user', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),

        isSelf: function () {
            return parseInt(this.get('model.user.id')) === parseInt(this.get('session.data.authenticated.userId'));
        }.property('model.user.id'),

        sortedRequests: function () {
            return this.get('model.requests').sortBy('created').reverse();
        }.property('model.requests'),

        actions: {
            editProfile: function editProfile() {
                this.transitionToRoute('settings.user');
            }
        }
    });
});