define('huyang/mixins/saves-view-config', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        session: Ember.inject.service(),

        saveViewConfig: function saveViewConfig(columns, viewName) {
            var _this = this;

            console.log('saves-view-config.saveColumns ', columns);
            var model = this.get('model.viewConfig') || this.get('viewConfig');
            if (model) {
                model.set('columnList', columns);
            } else {
                model = this.store.createRecord('viewConfig', {
                    account: this.get('session.currentAccount'),
                    user: this.get('session.currentUser'),
                    viewName: viewName,
                    columnList: columns
                });

                this.set('model.viewConfig', model);
            }
            model.save().then(function () {
                _this.get('flashMessages').success('Columns saved!');
            });
        },

        defaultColumns: ['status', 'requestType.label', 'title', 'location', 'owner.name', 'updated', 'priority'],

        columns: Ember.computed(function () {
            var cols = [Ember.Object.create({
                key: 'status',
                label: 'Status',
                editLabel: 'Request status',
                isStatus: true
            }), Ember.Object.create({
                key: 'workOrder',
                label: 'ID',
                editLabel: 'Request ID',
                phraseFormat: '#%@'
            }), Ember.Object.create({
                key: 'requestType.label',
                label: 'Type',
                editLabel: 'Request type',
                iconClass: 'requestType.cssClass',
                nowrap: false
            }), Ember.Object.create({
                key: 'title',
                label: 'Short description',
                link: 'requests.request',
                disableLinkAction: true,
                permanent: true,
                unreadKey: 'totalUnreadComments',
                unreadLink: 'requests.request.messages'
            }), Ember.Object.create({
                key: 'description',
                label: 'Description',
                limit: 50,
                mobileIcon: 'fa-commenting-o',
                emptyText: '-'
            }), Ember.Object.create({
                key: 'location',
                label: 'Location',
                mobileIcon: 'fa-building-o'
            }), Ember.Object.create({
                key: 'owner.name',
                label: 'Assigned to',
                emptyText: 'Unassigned',
                nowrap: false,
                mobilePhraseFormat: 'Assigned to %@',
                component: 'owner-string'
            }), Ember.Object.create({
                key: 'originator.name',
                displayKey: 'originator.displayName',
                label: 'Creator',
                nowrap: false,
                mobilePhraseFormat: 'Filed by %@'
            }), Ember.Object.create({
                key: 'created',
                label: 'Created',
                editLabel: 'Creation date',
                isDate: true,
                relativeDate: true,
                nowrap: false,
                mobilePhraseFormat: 'Created %@'
            }), Ember.Object.create({
                key: 'updated',
                label: 'Updated',
                editLabel: 'Last updated',
                isSorted: true,
                isDate: true,
                relativeDate: true,
                nowrap: false,
                mobilePhraseFormat: 'Updated %@'
            }), Ember.Object.create({
                key: 'dueDate',
                label: 'Due',
                editLabel: 'Due date',
                isDate: true,
                relativeDate: true,
                nowrap: false,
                mobilePhraseFormat: 'Due %@',
                dateDiffUnit: 'days',
                dateDiffBuckets: [{ from: -999, label: 'overdue' }, { from: 0, label: 'short' }, { from: 7, label: 'medium' }, { from: 28, label: 'long' }]
            }), Ember.Object.create({
                key: 'priority',
                label: 'Priority',
                isPriority: true,
                nowrap: false,
                align: 'center'
            }), Ember.Object.create({
                key: 'originator.occupantGroup.name',
                label: 'Occupant group',
                emptyText: '-',
                nowrap: false
            }), Ember.Object.create({
                key: 'requestLabel.name',
                label: 'Label',
                editLabel: 'Request label',
                isRequestLabel: true,
                nowrap: false,
                mobileIcon: 'fa-tag',
                emptyText: ''
            }), Ember.Object.create({
                key: 'schedule.repeatDescription',
                label: 'Repeats',
                editLabel: 'Repeating schedule',
                emptyText: 'Never'
            }), Ember.Object.create({
                key: 'vendorResponse',
                label: 'Vendor response'
            }), Ember.Object.create({
                key: 'timeLoggedTotalString',
                label: 'Total time logged'
            }), Ember.Object.create({
                key: 'timeLoggedBillableString',
                label: 'Billable time logged'
            }), Ember.Object.create({
                key: 'timeLoggedNonBillableString',
                label: 'Non-billable time logged'
            }), Ember.Object.create({
                key: 'costTotal',
                label: 'Total cost',
                currency: true,
                align: 'right'
            }), Ember.Object.create({
                key: 'costBillableTotal',
                label: 'Billable cost',
                currency: true,
                align: 'right'
            }), Ember.Object.create({
                key: 'costNonBillableTotal',
                label: 'Non-billable cost',
                currency: true,
                align: 'right'
            }), Ember.Object.create({
                key: 'asset.tag',
                label: 'Asset tag',
                component: 'request-table-asset'
            }), Ember.Object.create({
                key: 'asset.description',
                label: 'Asset description',
                component: 'request-table-asset'
            }), Ember.Object.create({
                key: 'latestComment',
                label: 'Last comment',
                disableSorting: true,
                isComment: true,
                component: 'request-table-comment'
            })];

            return cols;
        }),

        columnObjects: function () {
            var _this2 = this;

            var viewConfig = this.get('viewConfig');

            if (viewConfig && viewConfig.get('length')) {
                viewConfig = viewConfig.get('firstObject');
            }

            var columns = this.get('columns');

            if (!viewConfig || !viewConfig.get('columnList')) {
                // return default set
                columns.forEach(function (col) {
                    col.set('selected', _this2.get('defaultColumns').indexOf(col.key) >= 0);
                });
                return columns;
            }
            columns.forEach(function (col) {
                col.set('order', col.label.toLowerCase());
                col.set('selected', false);
            });
            var index = 0;
            viewConfig.get('columnList').forEach(function (key) {
                var column = columns.find(function (col) {
                    return col.get('key') === key;
                });
                if (column) {
                    column.set('order', index);
                    column.set('selected', true);
                }
                index += 1;
            });
            return columns.sortBy('order');
        }.property('viewConfig.columnList'),

        actions: {
            showModal: function showModal() {
                this.openModal('editView');
                this.set('orderedColumns', this.get('columnObjects'));
            },
            setColumns: function setColumns(columns) {
                this.set('orderedColumns', columns);
            }
        }
    });
});