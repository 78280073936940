define('huyang/routes/settings/user/base', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        model: function model() {
            var userId = this.get('session.data.authenticated.userId');

            return this.store.findRecord('user', userId, { reload: true });
        }
    });
});