define('huyang/routes/people/staff/index', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        session: Ember.inject.service(),

        model: function model() {
            var m = this.modelFor('people.staff');
            var allUsers = m.users;
            var accountId = this.get('session.data.authenticated.accountId');

            return {
                staff: allUsers.filter(function (u) {
                    return u.hasAnyRole(accountId, ['staff-all', 'staff', 'admin']);
                }),
                openTimers: m.openTimers
            };
        }
    });
});