define('huyang/components/set-technician-info', ['exports', 'ember-validations', 'huyang-common/mixins/validates-email'], function (exports, _emberValidations, _validatesEmail) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_validatesEmail.default, {
        classNames: ['set-technician-info'],

        api: Ember.inject.service(),
        intlTelInput: Ember.inject.service(),
        session: Ember.inject.service(),
        store: Ember.inject.service(),

        commitButtonLabel: function () {
            return this.get('technician') ? 'Save' : 'Add';
        }.property('technician'),

        sortedTrades: function () {
            return this.get('trades').sortBy('label');
        }.property('trades'),

        technicianFields: function () {
            var controller = this;
            var validations = this.get('emailValidations');
            validations['technician'] = {
                vendorId: this.get('vendor.id'),
                current: this.get('technician.user.email'),
                message: 'This email is already in use by another technician.'
            };

            return [{
                name: 'email',
                label: 'Email',
                value: this.get('technician.user.email'),
                placeholder: 'jdoe@service.com',
                validations: validations
            }, {
                name: 'name',
                label: 'Name',
                value: this.get('technician.user.name'),
                placeholder: 'Joan Doe'
            }, {
                name: 'trade',
                label: 'Trade',
                value: this.get('technician.trade.id'),
                prompt: 'Practices vendor\'s primary trade',
                helpText: 'Does the technician\'s trade differ from the vendor\'s primary trade?',
                select: true,
                data: this.get('sortedTrades'),
                keyPath: 'id',
                labelPath: 'label'
            }, {
                name: 'mobilePhone',
                label: 'Mobile phone',
                component: 'huyang-form-phone',
                properties: Ember.copy({
                    phone: this.get('technician.user.mobilePhone')
                }),
                validations: {
                    inline: (0, _emberValidations.validator)(function () {
                        var validation = controller.get('intlTelInput').validate();

                        var errors = {};
                        if (validation.error) {
                            errors.mobilePhone = validation.error;
                            this.errors.pushObject(errors.mobilePhone);
                        }
                    })
                }
            }];
        }.property('technician.user.name', 'technician.trade', 'technician.user.email', 'technician.user.mobilePhone'),

        actions: {
            cancel: function cancel() {
                this.sendAction('cancel');
            },

            saveTechnician: function saveTechnician(formInfo, onComplete) {
                var _this = this;

                var store = this.get('store');
                var account = this.get('technician.account') || store.peekRecord('account', this.get('session.data.authenticated.accountId'));
                var trade = void 0;

                if (formInfo.trade) {
                    trade = store.peekRecord('trade', formInfo.trade);
                }

                store.query('user', { email: formInfo.email }).then(function (users) {
                    return users.get('firstObject');
                }).then(function (user) {
                    if (!user) {
                        user = store.createRecord('user', { email: formInfo.email });
                    }

                    var phoneNumber = _this.get('intlTelInput.iti').getNumber();
                    user.set('name', formInfo.name); // DANGER DANGER DANGER
                    user.set('mobilePhone', phoneNumber); // This updates the user GLOBALLY
                    return user.save();
                }).then(function (user) {
                    var technician = _this.get('technician');
                    if (technician) {
                        technician.set('user', user);
                        technician.set('trade', trade);

                        return technician.save();
                    } else {
                        return store.createRecord('vendorTechnician', {
                            vendor: _this.get('vendor'),
                            user: user,
                            account: account,
                            trade: trade
                        }).save();
                    }
                }).then(function (tech) {
                    if (typeof onComplete === 'function') {
                        onComplete(); // Sets huyang-form isLoading to false
                    }
                    _this.get('flashMessages').success(tech.get('user.name') + ' saved!');
                    _this.sendAction('cancel');
                });
            }
        }
    });
});