define('huyang/routes/people/occupants/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            // for location control
            controller.set('buildings', this.modelFor('people.occupants').buildings);
            controller.set('subspaces', this.modelFor('people.occupants').subspaces);
            controller.set('occupantGroups', this.modelFor('people.occupants').occupantGroups);
        }
    });
});