define('huyang/components/request-list', ['exports', 'huyang-common/mixins/modal-helper', 'huyang/mixins/saves-view-config'], function (exports, _modalHelper, _savesViewConfig) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_modalHelper.default, _savesViewConfig.default, {
        scrollTo: function () {
            var scrollTo = this.get('scrollToId');
            if (scrollTo) {
                var req = Ember.$('#row' + scrollTo);
                if (req && req.length) {
                    Ember.$('html, body').animate({
                        scrollTop: req.offset().top - 130 // for fixed header
                    }, 0);
                }
            }
        }.on('didRender'),

        userColumns: function () {
            var viewConfig = this.get('viewConfig');

            if (viewConfig && viewConfig.get('length')) {
                viewConfig = viewConfig.get('firstObject');
            }

            if (!viewConfig || !viewConfig.get('columnList')) {
                return [];
            }
            return viewConfig.get('columnList');
        }.property('viewConfig.columnList.[]'),

        tableColumns: function () {
            var _this = this;

            var userColumns = this.get('userColumns');
            var displayedColumns = userColumns.length > 0 ? userColumns : this.get('defaultColumns');

            return displayedColumns.map(function (key) {
                return _this.get('columns').findBy('key', key);
            });
        }.property('columns', 'defaultColumns', 'userColumns.[]'),

        hasMultipleLocations: function () {
            return this.get('buildings.length') > 1;
        }.property('buildings.length'),

        sortedBuildings: function () {
            if (this.get('buildings')) {
                return this.get('buildings').sortBy('name');
            }
            return null;
        }.property('buildings.[]', 'buildings.@each.name'),

        columnButtonFullSize: function () {
            if (this.get('showRepeatingRequestButton') || this.get('showPMProgramButton')) {
                return false;
            }

            return true;
        }.property('showRepeatingRequestButton'),

        actions: {
            goToRequest: function goToRequest(id, routeName) {
                this.sendAction('goToRequest', id, routeName);
            },
            infinityLoad: function infinityLoad() {
                this.sendAction('infinityLoad');
            },
            sortBy: function sortBy(sortColumn, sortDirection) {
                this.sendAction('sortBy', sortColumn, sortDirection);
            },
            newRepeatingRequest: function newRepeatingRequest() {
                this.sendAction('newRepeatingRequest');
            },
            newPMProgram: function newPMProgram() {
                this.sendAction('newPMProgram');
            },
            saveColumns: function saveColumns() {
                var cols = this.get('orderedColumns') || this.get('columns');
                var selected = cols.filter(function (col) {
                    return col.selected;
                });
                console.log('request-list.saveColumns', selected);
                this.sendAction('saveColumns', selected.map(function (col) {
                    return col.key;
                }));
            }
        }
    });
});