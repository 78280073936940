define('huyang/models/filter', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        // for save but not displayed so no need for relationship
        account: _emberData.default.attr('number'),
        // for save but not displayed so no need for relationship
        user: _emberData.default.attr('number'),
        name: _emberData.default.attr(),
        // 1 d, 2 w, 3 m etc
        dateRange: _emberData.default.attr('string'),
        dueDateRange: _emberData.default.attr('string'),
        // array of values
        building: _emberData.default.attr(),
        subspace: _emberData.default.attr(),
        owner: _emberData.default.attr(),
        originator: _emberData.default.attr(),
        priority: _emberData.default.attr(),
        requestType: _emberData.default.attr(),
        requestLabel: _emberData.default.attr(),
        status: _emberData.default.attr(),
        group: _emberData.default.attr(),
        recurring: _emberData.default.attr(),
        sort: _emberData.default.attr(),
        asset: _emberData.default.attr()
    });
});