define('huyang/components/request-cost-logs', ['exports', 'huyang-common/models/cost-log', 'huyang-common/mixins/handles-attachments', 'huyang-common/mixins/shows-gallery', 'huyang-common/mixins/modal-helper'], function (exports, _costLog, _handlesAttachments, _showsGallery, _modalHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var _changesetNew = 'changesetNew';
  var _changesetEdit = 'changesetEdit';
  var _toDelete = 'recordToDelete';

  exports.default = Ember.Component.extend(_handlesAttachments.default, _showsGallery.default, _modalHelper.default, {
    classNames: ['request-cost-logs', 'request-logs'],
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('request.costLogs').forEach(function (cl) {
        _this.preloadCostLogAttachment(cl);
      });
    },
    preloadCostLogAttachment: function preloadCostLogAttachment(log) {
      var _this2 = this;

      if (log.get('attachments.length')) {
        log.get('attachments').forEach(function (a) {
          _this2.preloadImage(a);
        });
      }
    },
    user: function user() {
      return this.get('session.currentUser');
    },
    newChangeset: function newChangeset() {
      return _costLog.default.changeset({
        account: this.get('request.account'),
        request: this.get('request'),
        logger: this.user()
      });
    },


    uploadUrl: function () {
      return '/api/' + this.get('request.account.id') + '/requestAttachments';
    }.property('request.account.id'),

    attachmentsList: [],

    actions: {
      cancelModal: function cancelModal() {
        this.removeAllPendingAttachments();

        // roll back trashed attachment relationship
        if (this.get('attachmentTrash')) {
          var log = this.get('attachmentTrash.log');
          var att = this.get('attachmentTrash.attachment');

          if (log) {
            log.get('attachments').pushObject(att);
          }
          this.set('attachmentTrash', {});
        }
      },
      addCost: function addCost() {
        this.set(_changesetNew, this.newChangeset());
        this.openModal('addCost');
      },
      confirmAdd: function confirmAdd() {
        var _this3 = this;

        var changeset = this.get(_changesetNew);

        changeset.validate().then(function () {
          if (!changeset.get('isValid')) {
            return;
          }

          _this3.closeModal();

          changeset.save().then(function (_ref) {
            var _content = _ref._content;

            var cl = _this3.get('store').createRecord('cost-log', _content);
            cl.save().then(function (log) {
              _this3.preloadCostLogAttachment(log);
            });
          }).then(function () {
            _this3.set(_changesetNew, _this3.newChangeset());
            _this3.get('attachmentsList').clear();
          });
        });
      },
      editCost: function editCost(log) {
        this.set(_changesetEdit, log.changeset({ logger: this.user() }));
        this.openModal('editCost');
      },
      confirmEdit: function confirmEdit() {
        var _this4 = this;

        var changeset = this.get(_changesetEdit);

        changeset.validate().then(function () {
          if (!changeset.get('isValid')) {
            return;
          }

          // FIXME: catch errors
          return changeset.save().then(function (cl) {
            if (!cl.get('attachments.length') && _this4.get('attachmentTrash.attachment')) {
              _this4.get('attachmentTrash.attachment').deleteRecord();
            }

            _this4.preloadCostLogAttachment(cl);
            _this4.closeModal();
            _this4.set(_changesetEdit, null);
            _this4.get('attachmentsList').clear();
          });
        });
      },
      deleteCost: function deleteCost(log) {
        this.set(_toDelete, log);
        this.openModal('deleteCost');
        // FIXME: catch errors - can I un-delete?
      },
      confirmDelete: function confirmDelete() {
        this.get(_toDelete).destroyRecord();
      },
      cancelDelete: function cancelDelete() {
        this.set(_toDelete, null);
      }
    }
  });
});