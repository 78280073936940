define('huyang/components/crosstab-table', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Component.extend({
        classNames: ['crosstab-table'],
        data: {},
        columns: [],
        category1Label: {
            'building.id': 'Location',
            'request_type.id': 'Request type',
            'group.id': 'Occupant group',
            'owner.id': 'Assignee',
            'worker.id': 'Staff',
            'category.id': 'Cost category'
        },

        setTableVisibility: function () {
            if (this.get('running')) {
                this.set('tableData', null);
            }
        }.observes('running'),

        tableColumns: function () {
            var _this = this;

            var cat1 = this.get('category1');
            var columns = [{
                key: 'label',
                label: cat1 ? this.get('category1Label')[cat1] || cat1 : '',
                emptyText: this.get('category1Empty')
            }, {
                key: 'average',
                label: 'Average',
                emptyText: '',
                align: 'right',
                component: 'crosstab-table-cell'
            }];
            // get unique values for category
            var valueSet = new Set(); // IE11 doesn't support new Set([1,2,3])
            (this.get('data.result') || []).forEach(function (d) {
                valueSet.add(d[_this.get('category2')]);
            });
            var category2Values = [].concat(_toConsumableArray(valueSet));
            var category2Labels = this.get('data.meta.category2Labels') || {};
            // get labels and sort by label
            var sortedColumns = category2Values.map(function (c) {
                return {
                    id: c,
                    label: c ? category2Labels[c] || '' + c : _this.get('category2Empty')
                };
            }).sort(function (a, b) {
                return a.label.localeCompare(b.label);
            });

            sortedColumns.forEach(function (col) {
                columns.push({
                    key: 'value' + col.id,
                    label: col.label || _this.get('category2Empty'),
                    emptyText: '',
                    align: 'right',
                    component: 'crosstab-table-cell'
                });
            });

            return columns;
        }.property('columns.[]', 'data.result.[]', 'category1', 'category2'),

        drawTable: function () {
            var _this2 = this;

            if (!this.get('data.result') || !this.get('data.result.length')) {
                return;
            }

            // [ {label: 'Building XYZ', average: 200, value1: 300, value2: 100 } ]
            var category1 = this.get('category1');
            var category1Labels = this.get('data.meta.category1Labels') || {};
            var category2 = this.get('category2');

            var byCategory = this.get('data.result').reduce(function (acc, item) {
                var key = item[category1];

                acc[key] = acc[key] || [];
                acc[key].push(item);

                return acc;
            }, {});
            var showDuration = !(this.get('reportType') === 'request_open:count' || this.get('reportType') === 'request_closed:count' || this.get('reportType') === 'cost_log:sum');
            var durationFormat = this.get('reportType') === 'time_log:average' ? 'duration-hours' : 'duration';

            var cellDataType = void 0;
            if (showDuration) {
                cellDataType = durationFormat;
            } else if (this.get('reportType') === 'cost_log:sum') {
                cellDataType = 'currency';
            } else {
                cellDataType = 'int';
            }

            var tableData = Object.keys(byCategory).map(function (category, rowIdx) {
                var points = byCategory[category];
                var sum = 0;
                var count = 0;
                var d = {
                    label: category ? category1Labels[category] || category : _this2.get('category1Empty')
                };

                points.forEach(function (p) {
                    var v = p.result;
                    var col = p[category2];
                    var c = p.count || 1;

                    sum += v * c;
                    count += c;
                    d['value' + col + 'Type'] = cellDataType;
                    d['value' + col] = v;
                });

                var avg = count > 0 ? sum / count : 0;
                d.averageType = cellDataType;
                d.average = parseInt(avg * 10) / 10;

                points.forEach(function (p) {
                    var col = p[category2];
                    var v = p.result;

                    if (p.significant) {
                        d['value' + col + 'Class'] = v < avg ? 'significant-below' : 'significant-above';
                    }
                });
                d.id = rowIdx;
                return d;
            });

            this.set('tableData', tableData.map(function (d) {
                return Ember.Object.create(d);
            }));
        }.observes('data.result.[]', 'category1', 'category2')
    });
});