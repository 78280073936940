define('huyang/controllers/requests/request/info/schedule', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        request: Ember.computed.alias('model.request'),
        requestsController: Ember.inject.controller('requests'),

        selectedCreateDate: function () {
            if (this.get('createDate') || this.get('scheduleUpdated')) {
                return this.get('createDate');
            }
            // else get from request.schedule (may also be empty)
            return this.get('request.schedule.nextCreate');
        }.property('model.request.schedule.nextCreate', 'createDate', 'scheduleUpdated'),

        hideCreateOn: function () {
            return this.get('request.status') === 'pending';
        }.property('request.status'),

        showScheduling: function () {
            return Boolean(this.get('request.schedule'));
        }.property('request.schedule'),

        actions: {
            setScheduleDates: function setScheduleDates(dates) {
                // save values to temp location (won't stick if modal cancelled)
                this.set('dueDate', dates.dueDate);
                this.set('createDate', dates.createDate);
                this.set('scheduleUpdated', true);
            },
            cancelEdit: function cancelEdit() {
                this.get('request').rollbackAttributes();
                this.send('leaveRoute');
            },
            saveEdit: function saveEdit() {
                var _this = this;

                this.set('isLoading', true);
                var request = this.get('request');
                var dt = this.get('dueDate');
                if (dt) {
                    dt = moment(dt).toDate();
                }
                request.set('dueDate', dt);
                var createDate = this.get('createDate');
                if (!createDate || createDate.isBefore(moment())) {
                    // create now: promote to live
                    if (request.get('status') === 'pending') {
                        request.set('status', 'open');
                    }
                    // if it's in some other state, don't change it
                } else {
                    // update schedule; save will update request-schedule
                    request.set('createDate', createDate.toDate());
                }
                request.save().then(function () {
                    _this.set('isLoading', false);
                    _this.get('flashMessages').success('Request saved!');
                    _this.send('leaveRoute');
                    Ember.run.later(_this, function () {
                        _this.get('requestsController').loadRequestCounts();
                    }, 500);
                });
            }
        }
    });
});