define('huyang/controllers/people/occupants/edit', ['exports', 'huyang-common/mixins/modal-helper'], function (exports, _modalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modalHelper.default, {
        accessType: function () {
            if (this.get('model.grantAll')) {
                return 'all';
            }

            return this.get('model.occupantGroup.id') ? 'group' : 'specific';
        }.property('model.grantAll', 'model.occupantGroup.id', 'model.occupantGrants.[]'),

        statusLabel: function () {
            return 'Status: ' + this.get('model.status');
        }.property('model.status'),

        selectedLocations: function () {
            var grants = this.get('model.occupantGrants') || [];
            return grants.map(function (grant) {
                return {
                    building: grant.building,
                    subspace: grant.subspace
                };
            });
        }.property('model.occupantGrants.[]'),

        showActivate: function () {
            return this.get('model.status') !== 'active';
        }.property('model.status'),

        showInactivate: function () {
            return this.get('model.status') === 'active';
        }.property('model.status'),

        showBlock: function () {
            return this.get('model.status') !== 'blocked';
        }.property('model.status'),

        actions: {
            saveEdit: function saveEdit(info, onComplete /*, onError*/) {
                var _this = this;

                var promises = [];
                // user fields
                var user = this.get('model.user');
                ['name', 'email', 'mobilePhone'].forEach(function (field) {
                    user.set(field, info[field]);
                });
                promises.push(user.save());

                // occupant fields
                var occupant = this.get('model');
                var locationAccess = info.locationAccess;
                var at = locationAccess.accessType;
                occupant.set('grantAll', at === 'all');
                occupant.set('occupantGroup', at === 'group' ? locationAccess.selectedGroupObject : null);
                promises.push(occupant.save());
                // specific locations
                if (at === 'specific') {
                    promises.push(occupant.setGrants(locationAccess.selectedBuildings));
                } else {
                    promises.push(occupant.removeAllGrants());
                }
                Ember.RSVP.all(promises).then(function () {
                    _this.get('flashMessages').success('Occupant info saved!');
                    onComplete();
                });
            },
            cancelEdit: function cancelEdit() {
                this.get('model').rollbackAttributes();
                this.transitionToRoute('people.occupants.manage');
            },
            resendWelcome: function resendWelcome() {
                this.get('model').resendWelcome();
                this.get('flashMessages').success('Welcome email sent to ' + this.get('model.user.email'));
            },
            setStatus: function setStatus(status) {
                this.set('model.status', status);
                this.get('model').save();
            },
            showDeleteModal: function showDeleteModal() {
                this.openModal('confirmDeleteOccupant');
            },
            confirmDeleteOccupant: function confirmDeleteOccupant() {
                var _this2 = this;

                this.get('model').destroyRecord().then(function () {
                    return _this2.transitionToRoute('people.occupants');
                });
            }
        }
    });
});