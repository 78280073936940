define('huyang/controllers/people/vendors/vendor/cois', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        vendorController: Ember.inject.controller('people/vendors/vendor'),
        vendor: Ember.computed.alias('vendorController.model.vendor')
    });
});