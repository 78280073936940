define('huyang/validators/technician', ['exports', 'huyang-common/validators/technician'], function (exports, _technician) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _technician.default;
    }
  });
});