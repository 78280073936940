define('huyang/routes/people/occupants/approve', ['exports', 'huyang/routes/base-account'], function (exports, _baseAccount) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseAccount.default.extend({
        model: function model(params) {
            return this.store.findRecord('tenant-user', params.user_id);
        },

        afterModel: function afterModel(model) {
            var _this = this;

            model.set('status', 'active');
            model.save().then(function () {
                var userName = model.get('user.displayName');
                Ember.getOwner(_this).lookup('controller:application').flashBanner({ userName: userName }, 'occupant-approved');
                _this.transitionTo('people.occupants.edit', model.get('id'));
            });
        }
    });
});